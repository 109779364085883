import React, { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { overlayContext, OverlayContext } from './context';
import Root from '../Root';
import ModalRoot from '../ModalRoot';
import { createIterator } from './utils';

enum ModalOrder {
  First = 'first',
  Second = 'second',
  Third = 'third',
}

const nextModalOrder = {
  [ModalOrder.First]: ModalOrder.Second,
  [ModalOrder.Second]: ModalOrder.Third,
  [ModalOrder.Third]: ModalOrder.First,
};

const ModalWrapper: React.FC<{ id: string; dynamicContentHeight: boolean; settlingHeight: number }> = memo((props) => (
  <div>{props.children}</div>
));

const nextSnackbarKey = createIterator('overlay-snackbar');

const OverlayProvider: React.FC = memo(({ children }) => {
  /* Modals */

  const [modalContents, setModalContents] = useState<{
    activeModal: ModalOrder | null;
    prevActiveModal: ModalOrder;
    contents: { [key in ModalOrder]?: { modalId: string; content: React.ReactElement } };
  }>({
    activeModal: null,
    prevActiveModal: ModalOrder.First,
    contents: {},
  });

  const onClose = useCallback(
    (modalId: ModalOrder) => {
      const action = modalContents.contents[modalId]?.content?.props.onClose();
      if (action instanceof Function) {
        action();
      }
    },
    [modalContents],
  );

  const modal = useMemo(
    () => (
      <ModalRoot activeModal={modalContents.activeModal} onClose={onClose}>
        {Object.values(ModalOrder).map((key) => (
          <ModalWrapper id={key} key={key} dynamicContentHeight settlingHeight={100}>
            {modalContents.contents[key]?.content}
          </ModalWrapper>
        ))}
      </ModalRoot>
    ),
    [modalContents, onClose],
  );

  /* Popout */

  const [popout, setPopout] = useState<{ id: string; component: React.ReactNode } | null>(null);

  /* Snackbar  */

  const [snackbar, setSnackbar] = useState<React.ReactNode>(null);

  const contextСonstants = useMemo<Omit<OverlayContext, 'overlayOpened'>>(
    () => ({
      showModal(modalId, content): void {
        setModalContents((prev) => {
          let order: ModalOrder;

          if (prev.contents[ModalOrder.First]?.modalId === modalId) {
            order = ModalOrder.First;
          } else if (prev.contents[ModalOrder.Second]?.modalId === modalId) {
            order = ModalOrder.Second;
          } else if (prev.contents[ModalOrder.Third]?.modalId === modalId) {
            order = ModalOrder.Third;
          } else {
            order = nextModalOrder[prev.prevActiveModal];
          }

          return {
            ...prev,
            activeModal: order,
            prevActiveModal: order,
            contents: { ...prev.contents, [order]: { modalId, content } },
          };
        });
      },
      hideModal(modalId): void {
        setModalContents((prev) => {
          if (
            (prev.activeModal === ModalOrder.First && prev.contents[ModalOrder.First]?.modalId === modalId) ||
            (prev.activeModal === ModalOrder.Second && prev.contents[ModalOrder.Second]?.modalId === modalId) ||
            (prev.activeModal === ModalOrder.Third && prev.contents[ModalOrder.Third]?.modalId === modalId)
          ) {
            return { ...prev, activeModal: null };
          }

          return prev;
        });
      },
      showPopout(popoutId, component): void {
        setPopout({
          id: popoutId,
          component: React.cloneElement(component, { key: popoutId }),
        });
      },
      hidePopout(popoutId): void {
        setPopout((prev) => (prev?.id === popoutId ? null : prev));
      },
      openSnackbar(element): void {
        setSnackbar(
          <element.type
            {...element.props}
            key={nextSnackbarKey()}
            onClose={(): void => {
              setSnackbar(null);
              element.props.onClose && element.props.onClose();
            }}
          />,
        );
      },
    }),
    [],
  );

  const overlayOpened =
    modalContents.activeModal !== null || Boolean(popout && (popout.component as any)?.type?.name !== 'ScreenSpinner');

  const previousPaddingRight = useRef(document.body.style.paddingRight);
  const previousBodyOverflow = useRef(document.body.style.overflow);

  useEffect(() => {
    if (overlayOpened) {
      const scrollWidth = window.innerWidth - document.body.scrollWidth;
      previousPaddingRight.current = document.body.style.paddingRight;
      document.body.style.paddingRight = `${scrollWidth}px`;
      previousBodyOverflow.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = previousBodyOverflow.current;
      document.body.style.paddingRight = previousPaddingRight.current;
    }
  }, [overlayOpened]);

  const context = useMemo<OverlayContext>(
    () => ({
      ...contextСonstants,
      overlayOpened,
    }),
    [contextСonstants, overlayOpened],
  );

  return (
    <overlayContext.Provider value={context}>
      <Root modal={modal} popout={popout?.component} activeView="overlay-root.view">
        <div id="overlay-root.view" style={{ height: '100%' }}>
          {children}
          {snackbar}
        </div>
      </Root>
    </overlayContext.Provider>
  );
});

export default OverlayProvider;
