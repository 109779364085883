import React, { memo, useCallback, useState, useMemo } from 'react';
import RecordInfo from 'src/components/atomic/RecordInfo';
import FooterButtons from 'src/components/atomic/FooterButtons';
import {
  Button,
  PanelHeaderBack,
  ScreenSpinner,
  OverlayPopoutWrapper,
  SimpleCell,
  Input,
  Textarea,
  Panel,
} from '@overrided-vkui';
import NotificationBadge from 'src/components/atomic/NotificationBadge';
import { L } from 'src/lang/L';
import { InfoRow } from '@vkontakte/vkui';

export interface ConfirmData {
  email: string;
  comment: string;
}

interface ServiceStaff {
  id: number;
  staff: {
    id: number;
    name: string;
    avatarUrl?: string | null;
    specialization: string;
  };
  priceMin: number;
  priceMax: number;
  duration: number;
}

interface OrganizationUnitStepConfirmPanelProps {
  id: string;
  organizationUnit: {
    id: number;
    title: string;
    logoUrl?: string | null;
    city: {
      title: string;
    };
    address: string;
    bookingConfig: {
      isEmailRequired: boolean;
      isCommentRequired: boolean;
      commentPlaceholder?: string | null;
    };
  };
  service: {
    title: string;
  };
  serviceStaff: ServiceStaff;
  slotDatetime: string;
  onBack(): void;
  onNext(data: ConfirmData): void;
  onNextLoading: boolean;
  onStaffClick?(): void;
  areNotificationsEnabled: boolean;
}

const FORM_ID = 'record-form';

const OrganizationUnitStepConfirmPanel = memo((props: OrganizationUnitStepConfirmPanelProps) => {
  const {
    organizationUnit,
    service,
    serviceStaff,
    slotDatetime,
    onStaffClick,
    onBack,
    onNext,
    areNotificationsEnabled,
    id: panelId,
    onNextLoading,
  } = props;

  const { isCommentRequired, isEmailRequired, commentPlaceholder } = organizationUnit.bookingConfig;

  const [comment, setComment] = useState('');
  const [email, setEmail] = useState('');

  const onCreateRecordSumbit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onNext({ comment: comment.trim(), email: email.trim() });
    },
    [comment, email, onNext],
  );

  const nextEnabled = useMemo(() => {
    return (!isCommentRequired || Boolean(comment.trim())) && (!isEmailRequired || Boolean(email.trim()));
  }, [isCommentRequired, comment, isEmailRequired, email]);

  return (
    <Panel id={panelId}>
      <Panel.Header left={<PanelHeaderBack onClick={onBack} />}>{L.t('make_record_step_confirm_header')}</Panel.Header>
      <RecordInfo
        organizationUnit={organizationUnit}
        serviceStaff={{
          service,
          staff: serviceStaff.staff,
        }}
        priceMin={serviceStaff.priceMin}
        priceMax={serviceStaff.priceMax}
        datetime={slotDatetime}
        onStaffClick={onStaffClick}
        hidePhone
      />
      <form id={FORM_ID} onSubmit={onCreateRecordSumbit}>
        <SimpleCell disabled>
          <InfoRow style={{ marginBottom: 6 }} header={L.t('record_meta_comment')} />
          <Textarea
            placeholder={commentPlaceholder || L.t('record_meta_comment_placeholder')}
            maxLength={255}
            required={isCommentRequired}
            rows={3}
            value={comment}
            grow={false}
            onChange={(e) => setComment(e.target.value)}
          />
        </SimpleCell>
        {isEmailRequired && (
          <SimpleCell disabled>
            <InfoRow style={{ marginBottom: 6 }} header={L.t('record_meta_email')} />
            <Input
              placeholder={L.t('record_meta_email_placeholder')}
              type="email"
              required={isEmailRequired}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={255}
            />
          </SimpleCell>
        )}
      </form>
      <Panel.Footer fixed>
        <FooterButtons info={!areNotificationsEnabled && <NotificationBadge />}>
          <Button type="submit" form={FORM_ID} disabled={!nextEnabled || onNextLoading}>
            {L.t('make_record_step_confirm_button_create')}
          </Button>
        </FooterButtons>
      </Panel.Footer>
      <OverlayPopoutWrapper show={onNextLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </Panel>
  );
});

export default OrganizationUnitStepConfirmPanel;
