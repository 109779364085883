import React, { memo } from 'react';
import { Panel, Placeholder, PanelHeaderBack, Button, Footer, Group } from '@overrided-vkui';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import { L } from 'src/lang/L';
import { List } from '@vkontakte/vkui';
import StaffItem, { Staff } from 'src/components/atomic/StaffItem';
import FooterButtons from '../atomic/FooterButtons';

interface OrganizationUnitStepStaffPanelProps {
  id: string;
  title: string;
  staffLoading?: boolean;
  staffError?: boolean;
  staff?: Staff[];
  onStaffSelect(staffId: number): void;
  onSkipStaffSelect?(): void;
  onBack(): void;
}

const OrganizationUnitStepStaffPanel = memo((props: OrganizationUnitStepStaffPanelProps) => {
  const { id: panelId, staffLoading, title, staffError, staff, onStaffSelect, onSkipStaffSelect, onBack } = props;

  return (
    <Panel id={panelId}>
      <Panel.Header left={<PanelHeaderBack onClick={onBack} />}>{title}</Panel.Header>
      {staff && staff.length === 0 ? (
        <Panel.Content center>
          <Placeholder>{L.t('make_record_step_staff_empty')}</Placeholder>
        </Panel.Content>
      ) : staff ? (
        <>
          <Panel.Content>
            <Group>
              <List>
                {staff.map((staffItem) => (
                  <StaffItem key={staffItem.id} staff={staffItem} onStaffClick={() => onStaffSelect(staffItem.id)} />
                ))}
              </List>
            </Group>
            <Footer>{L.t('staff_amout', { count: staff.length })}</Footer>
          </Panel.Content>
          {onSkipStaffSelect && (
            <Panel.Footer fixed>
              <FooterButtons>
                <Button mode="secondary" onClick={() => onSkipStaffSelect()}>
                  {L.t('make_record_step_staff_button_skip')}
                </Button>
              </FooterButtons>
            </Panel.Footer>
          )}
        </>
      ) : staffLoading ? (
        <Panel.Content center>
          <StatusPlaceholder status="loading" />
        </Panel.Content>
      ) : staffError ? (
        <Panel.Content center>
          <StatusPlaceholder status="error" errorText={L.t('make_record_step_staff_fetch_error')} />
        </Panel.Content>
      ) : null}
    </Panel>
  );
});

export default OrganizationUnitStepStaffPanel;
