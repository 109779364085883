import React, { memo } from 'react';
import DatePicker from '../../DatePicker';
import { Separator } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';
import { DatePickerSubheadProps } from '.';

const useStyles = makeStyles({
  subhead: {
    background: 'var(--background_content)',
  },
});

const DatePickerSubheadMobile: React.FC<DatePickerSubheadProps> = memo((datePickerProps) => {
  const mc = useStyles();

  return (
    <div className={mc.subhead}>
      <DatePicker {...datePickerProps} />
      <Separator />
    </div>
  );
});

export default DatePickerSubheadMobile;
