import React, { memo, useEffect } from 'react';
import { ConfigProvider as VKConfigProvider } from '@vkontakte/vkui';
import { useSelector } from 'src/hooks/useSelector';
import vkBridge from '@vkontakte/vk-bridge';

/**
 * HOC VKUI ConfigProvider
 * Устанавливает тему приложению, оключает анимацию переходов на десктопе, изменяет размер окна приложения на десктопе.
 */
const ConfigProvider = memo(({ children }) => {
  const { scheme, isDesktop, viewportHeight, isGroupApp, isLayer } = useSelector((state) => ({
    scheme: state.appConfig.scheme,
    isDesktop: state.launchParams.platform === 'desktop_web',
    viewportHeight: state.appConfig.viewportHeight,
    isGroupApp: state.launchParams.groupId !== null,
    isLayer: state.appConfig.isLayer,
  }));

  useEffect(() => {
    // Изменяем высоту desktop фрейма под высоту активной вкладки браузера за вычетом шапки и футера ВК
    // (только в том случае, когда приложение открыто не из модалки)

    if (viewportHeight > 0 && isDesktop && !isLayer) {
      const vkInterfaceOffset = isGroupApp ? 112 : 128;
      vkBridge.send('VKWebAppResizeWindow', { height: viewportHeight - vkInterfaceOffset }).catch(() => null);
    }
  }, [isDesktop, viewportHeight, isGroupApp, isLayer]);

  return (
    <VKConfigProvider scheme={scheme} isWebView={!isDesktop} transitionMotionEnabled={!isDesktop}>
      {children}
    </VKConfigProvider>
  );
});

export default ConfigProvider;
