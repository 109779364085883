import React, { memo, useCallback } from 'react';
import { useRouteNode } from 'react-router5';
import { RootRoute } from 'src/router';
import { Epic, Tabbar, TabbarItem } from '@vkontakte/vkui';
import SettingsView from '../../views/SettingsView';
import RecordsView from '../../views/RecordsView';
import CatalogView from '../../views/CatalogView';
import Icon28ArticleOutline from '@vkontakte/icons/dist/28/article_outline';
import Icon28CheckCircleOutline from '@vkontakte/icons/dist/28/check_circle_outline';
import Icon28SettingsOutline from '@vkontakte/icons/dist/28/settings_outline';
import { L } from 'src/lang/L';
import { MainViewProps } from './index';

const MainViewMobile = memo((props: MainViewProps) => {
  const { onCatalogBack, id: viewId } = props;

  const { route, router } = useRouteNode(RootRoute.MAIN);
  const activeTab = route.name.split('.').slice(0, 2).join('.') as RootRoute;

  const onRouteClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      router.navigate(e.currentTarget.dataset.route as RootRoute);
    },
    [router],
  );

  return (
    <Epic
      id={viewId}
      activeStory={activeTab}
      tabbar={
        <Tabbar>
          <TabbarItem
            onClick={onRouteClick}
            selected={activeTab === RootRoute.MAIN_CATALOG}
            data-route={RootRoute.MAIN_CATALOG}
            text={L.t('main_tab_catalog')}
          >
            <Icon28ArticleOutline />
          </TabbarItem>
          <TabbarItem
            onClick={onRouteClick}
            selected={activeTab === RootRoute.MAIN_RECORDS}
            data-route={RootRoute.MAIN_RECORDS}
            text={L.t('main_tab_own_records')}
          >
            <Icon28CheckCircleOutline />
          </TabbarItem>
          <TabbarItem
            onClick={onRouteClick}
            selected={activeTab === RootRoute.MAIN_SETTINGS}
            data-route={RootRoute.MAIN_SETTINGS}
            text={L.t('settings_header')}
          >
            <Icon28SettingsOutline />
          </TabbarItem>
        </Tabbar>
      }
    >
      <CatalogView id={RootRoute.MAIN_CATALOG} onBack={onCatalogBack} />
      <RecordsView id={RootRoute.MAIN_RECORDS} />
      <SettingsView id={RootRoute.MAIN_SETTINGS} />
    </Epic>
  );
});

export default MainViewMobile;
