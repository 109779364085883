import React, { useState, memo } from 'react';
import Card from '../vkui/Card';
import { makeStyles } from '@material-ui/styles';
import { formatPrice, formatDurationRange } from '../../../utils/formatter';
import Icon16Recent from '@vkontakte/icons/dist/16/recent';
import c from 'classnames';
import { getPlatformClassName, Link, Text, Subhead, Headline } from '@overrided-vkui';
import { getStaffMinPrice, getStaffMaxPrice, getStaffMinDuration, getStaffMaxDuration } from 'src/utils/selectors';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  card: {
    overflow: 'hidden',
  },
  card_active: {
    '& .Card__in': {
      boxShadow: 'inset 0 0 0 1.5px var(--accent)',
      background: 'var(--modal_card_background)',
    },
  },
  card__in: {
    padding: '14px 16px',
    boxSizing: 'border-box',
    '&$--desktop': {
      padding: '16px 20px',
    },
    cursor: 'pointer',
  },
  card__description: {
    marginTop: 4,
    color: 'var(--text_secondary)',
  },
  card__subline: {
    marginTop: 7,
    display: 'flex',
    '& > * + *': {
      marginLeft: 9,
    },
  },
  card__duration: {
    color: 'var(--text_secondary)',
    display: 'inline-flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: 5,
    },
  },
  card__durationIcon: {
    color: 'var(--icon_secondary)',
    marginTop: 2,
  },
  card__expandLink: {
    display: 'inline-block',
  },
  card__price: {},
  card__price_none: {
    fontWeight: 400,
    color: 'var(--text_secondary)',
    textTransform: 'lowercase',
  },
});

interface ServiceCardProps {
  service: {
    id: number;
    title: string;
    description?: string | null;
    serviceStaff: Array<{ priceMin: number; priceMax: number; duration: number }>;
  };
  isSelected?: boolean;
  className?: string;
  onClick?(): void;
}

const DESCRIPTION_TEXT_LIMIT = 75;

const ServiceCard = memo((props: ServiceCardProps) => {
  const { service, className, isSelected, onClick } = props;

  const [expanded, setExpanded] = useState(!service.description || service.description.length < DESCRIPTION_TEXT_LIMIT);

  const mc = useStyles();

  const minPrice = getStaffMinPrice(service.serviceStaff);
  const maxPrice = getStaffMaxPrice(service.serviceStaff);
  const minDuration = getStaffMinDuration(service.serviceStaff);
  const maxDuration = getStaffMaxDuration(service.serviceStaff);

  const hasPrice = minPrice !== 0 && maxPrice !== 0 && Number.isFinite(minPrice) && Number.isFinite(maxPrice);
  const hasDuration = Number.isFinite(minDuration) && Number.isFinite(maxDuration);

  return (
    <Card size="l" mode="tint" className={c(className, mc.card, { [mc.card_active]: isSelected })} onClick={onClick}>
      <div className={getPlatformClassName(mc.card__in)}>
        <Headline weight="medium">{service.title}</Headline>
        {service.description && (
          <>
            {service.description && (
              <Subhead weight="regular" className={mc.card__description}>
                {expanded ? service.description : service.description.substr(0, DESCRIPTION_TEXT_LIMIT) + '…'}
              </Subhead>
            )}
            {!expanded && (
              <Subhead weight="regular">
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpanded(true);
                  }}
                >
                  {L.t('common:expand_text')}
                </Link>
              </Subhead>
            )}
          </>
        )}
        <div className={mc.card__subline}>
          <Text weight="semibold" className={c(mc.card__price, { [mc.card__price_none]: !hasPrice })}>
            {hasPrice ? formatPrice(minPrice, maxPrice) : L.t('price_not_specified')}
          </Text>
          {hasDuration && (
            <Subhead weight="regular" className={mc.card__duration}>
              <Icon16Recent className={mc.card__durationIcon} />
              <Text weight="regular">{formatDurationRange(minDuration, maxDuration)}</Text>
            </Subhead>
          )}
        </div>
      </div>
    </Card>
  );
});

export default ServiceCard;
