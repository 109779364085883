import createRouter5 from 'router5';
import loggerPlugin from 'router5-plugin-logger';
import browserPlugin from 'router5-plugin-browser';

export enum RootRoute {
  ONBOARD = 'onboard',

  MAIN = 'main',
  MAIN_CATALOG = 'main.catalog',
  MAIN_CATALOG_MAIN = 'main.catalog.main',
  MAIN_CATALOG_CATEGORIES = 'main.catalog.categories',
  MAIN_CATALOG_CATEGORY = 'main.catalog.category',
  MAIN_RECORDS = 'main.records',
  MAIN_SETTINGS = 'main.settings',

  ORGANIZATION = 'organization',

  ORGANIZATION_SETTINGS = 'organization-settings',
  ORGANIZATION_SETTINGS_UNITS = 'organization-settings.units',
  ORGANIZATION_SETTINGS_WIDGET = 'organization-settings.widget',
  ORGANIZATION_SETTINGS_ACTION_BUTTON = 'organization-settings.action-button',

  CURRENT_YCLIENTS_UNITS_EDIT = 'current-yClients-units-edit',

  ORGANIZATION_UNIT = 'organization-unit',

  ADD_TO_COMMUNITY = 'add-to-community',
  ADD_TO_COMMUNITY_ONBOARD = 'add-to-community.onboard',
  ADD_TO_COMMUNITY_RESULT = 'add-to-community.result',

  REGISTER = 'register',
  REGISTER_YCLIENTS_ORGANIZATIONS = 'register.yclients-organizations',
  REGISTER_YCLIENTS_ORGANIZATION_UNITS = 'register.yclients-organization-units',
  REGISTER_WIDGET = 'register.widget',
  REGISTER_ACTION_BUTTON = 'register.action-button',

  RELOGIN = 'relogin',

  TRASFER_RECORD = 'transfer-record',
}

export const createRouter = () => {
  const router = createRouter5(
    [
      { name: RootRoute.ONBOARD, path: '~/onboard' },

      { name: RootRoute.MAIN, path: '/main', forwardTo: RootRoute.MAIN_CATALOG },
      { name: RootRoute.MAIN_CATALOG, path: '~/catalog', forwardTo: RootRoute.MAIN_CATALOG_MAIN },
      { name: RootRoute.MAIN_CATALOG_MAIN, path: '/:categoryGroupSlug', defaultParams: { categoryGroupSlug: 'all' } },
      { name: RootRoute.MAIN_CATALOG_CATEGORIES, path: '/categories' },
      { name: RootRoute.MAIN_CATALOG_CATEGORY, path: '/category/:categoryGroupSlug' },
      { name: RootRoute.MAIN_RECORDS, path: '~/records' },
      { name: RootRoute.MAIN_SETTINGS, path: '~/settings' },

      { name: RootRoute.ORGANIZATION, path: '/organization' },

      { name: RootRoute.ORGANIZATION_SETTINGS, path: '/organization/settings' },
      { name: RootRoute.ORGANIZATION_SETTINGS_UNITS, path: '/units' },
      { name: RootRoute.ORGANIZATION_SETTINGS_WIDGET, path: '/widget' },
      { name: RootRoute.ORGANIZATION_SETTINGS_ACTION_BUTTON, path: '/settings/actionButton' },

      { name: RootRoute.CURRENT_YCLIENTS_UNITS_EDIT, path: '/organization/units/edit' },

      { name: RootRoute.ORGANIZATION_UNIT, path: '/unit/:organizationUnitId' },

      { name: RootRoute.ADD_TO_COMMUNITY, path: '/addToCommunity', forwardTo: RootRoute.ADD_TO_COMMUNITY_ONBOARD },
      { name: RootRoute.ADD_TO_COMMUNITY_ONBOARD, path: '/addToCommunity/onboard' },
      { name: RootRoute.ADD_TO_COMMUNITY_RESULT, path: '/addToCommunity/result' },

      { name: RootRoute.REGISTER, path: '/register' },
      { name: RootRoute.REGISTER_YCLIENTS_ORGANIZATIONS, path: '/organizations' },
      { name: RootRoute.REGISTER_YCLIENTS_ORGANIZATION_UNITS, path: '/organizationUnits' },
      { name: RootRoute.REGISTER_WIDGET, path: '/widget' },
      { name: RootRoute.REGISTER_ACTION_BUTTON, path: '/actionButton' },

      { name: RootRoute.RELOGIN, path: '/relogin' },

      { name: RootRoute.TRASFER_RECORD, path: '/transferRecord' },
    ],
    { defaultRoute: RootRoute.MAIN_CATALOG },
  );

  router.usePlugin(loggerPlugin);
  router.usePlugin(
    browserPlugin({
      useHash: true,
      base: window.location.search,
    }),
  );

  router.start();

  // fixes desktop safary first back navigation problemd
  const route = router.getState();

  if (route) {
    router.navigate(route.name, { ...route.params, fix: 'safari' }, { force: true });
  }

  return router;
};
