import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { FooterButtonsProps } from '.';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > * + *': {
      marginRight: 10,
    },
  },
  buttonContainer__info: {
    marginRight: 20,
  },
});

const FooterButtonsDesktop: React.FC<FooterButtonsProps> = memo((props) => {
  const { children, info, className, ...rest } = props;
  const mc = useStyles();

  return (
    <div className={c(mc.content, className)} {...rest}>
      <div className={mc.buttonContainer}>
        {React.Children.map(
          children,
          (child) => child && React.cloneElement(child as React.ReactElement, { size: 'l' }),
        )}
      </div>
      {info && <div className={mc.buttonContainer__info}>{info}</div>}
    </div>
  );
});

export default FooterButtonsDesktop;
