import { DataProxy } from '@apollo/client';
import {
  GetUserRecordsDocument,
  GetUserRecordsQuery,
  AccessTokenStatus,
  GetVkGroupAttachedOrganizationDocument,
  GetVkGroupAttachedOrganizationQuery,
} from 'src/gql/generated/types';

export const rewriteVkGroupAccessTokenStatus = (cache: DataProxy, vkGroupAccessTokenStatus: AccessTokenStatus) => {
  try {
    const cachedOrganization = cache.readQuery<GetVkGroupAttachedOrganizationQuery>({
      query: GetVkGroupAttachedOrganizationDocument,
    });

    if (!cachedOrganization || !cachedOrganization.attachedOrganization) return;

    cache.writeQuery<GetVkGroupAttachedOrganizationQuery>({
      query: GetVkGroupAttachedOrganizationDocument,
      data: {
        attachedOrganization: {
          ...cachedOrganization.attachedOrganization,
          vkGroupAccessTokenStatus,
        },
      },
    });
  } catch {}
};

export const deleteCacheRecord = (cache: DataProxy, recordId: number) => {
  try {
    const cachedUserRecords = cache.readQuery<GetUserRecordsQuery>({ query: GetUserRecordsDocument });

    if (!cachedUserRecords) return;

    const { currentUser } = cachedUserRecords;

    cache.writeQuery<GetUserRecordsQuery>({
      query: GetUserRecordsDocument,
      data: {
        currentUser: {
          ...currentUser,
          records: currentUser.records.filter((record) => record.id !== recordId),
        },
      },
    });
  } catch {}
};

export const insertCacheRecord = (cache: DataProxy, record: GetUserRecordsQuery['currentUser']['records'][number]) => {
  try {
    const cachedUserRecords = cache.readQuery<GetUserRecordsQuery>({ query: GetUserRecordsDocument });

    if (!cachedUserRecords) return;

    const { currentUser } = cachedUserRecords;

    cache.writeQuery<GetUserRecordsQuery>({
      query: GetUserRecordsDocument,
      data: {
        currentUser: {
          ...currentUser,
          records: currentUser.records.concat(record),
        },
      },
    });
  } catch {}
};
