import React from 'react';
import { ButtonProps } from '@vkontakte/vkui/dist/components/Button/Button';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import getClassname from '@vkontakte/vkui/dist/helpers/getClassName';

const useStyles = makeStyles({
  button: {
    cursor: 'pointer',
    borderRadius: 8,
    '&.Button': {
      border: 0,
      borderRadius: 4,
      padding: '0 16px',
    },
    '&.Button.Button--sz-xl': {
      padding: '0 20px',
    },
    '& .Button__content, & .Button__before': {
      position: 'relative',
    },
    '&.Button--sz-m': {
      '& .Button__content': {
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 400,
        padding: '5px 0',
      },
    },
    '&.Button--sz-l .Button__content': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      padding: '6px 0',
    },
    '&.Button--sz-xl .Button__content': {
      fontSize: 14.5,
      lineHeight: '20px',
      fontWeight: 500,
      padding: '9px 0',
    },
    // eslint-disable-next-line
    '&.Button--lvl-primary, &.Button--lvl-commerce, &.Button--lvl-destructive, &.Button--lvl-overlay_primary, &.Button--lvl-overlay_secondary': {
      '&:hover': {
        opacity: 0.88,
      },
    },
    '&.Button--lvl-overlay_secondary': {
      backgroundColor: 'var(--white_alpha24)',
    },
    '&:active .Button__content, &:active .Button__before': {
      top: 1,
    },
    '&:disabled': {
      pointerEvents: 'none',
    },
  },
  '@global': {
    'body:not([scheme=space_gray]) $button': {
      '&': {
        '--button_primary_background': 'var(--blue_400)',
        '--button_primary_foreground': 'var(--white)',
        '--button_secondary_background': 'var(--light_blue_40)',
        '--button_secondary_foreground': 'var(--steel_gray_A540)',
        '--button_tertiary_background': 'transparent',
        '--button_tertiary_foreground': 'var(--blue_640)',
        '--button_commerce_background': 'var(--green)',
        '--button_commerce_foreground': 'var(--white)',
      },
      '&:hover': {
        '--button_secondary_background': 'var(--light_blue_80)',
        '--button_tertiary_background': 'var(--light_blue_80)',
        '--button_tertiary_foreground': 'var(--steel_gray_A540)',
      },
      '&:active': {
        '--button_primary_background': 'var(--blue_420)',
        '--button_secondary_background': 'var(--light_blue_100)',
        '--button_tertiary_background': 'var(--light_blue_100)',
        '--button_commerce_background': 'var(--green_dark)',
      },
    },
  },
});

const ButtonDesktop: React.FC<ButtonProps> = (props) => {
  const { className, size, mode, stretched, align, children, before, after, Component, ...restProps } = props;

  const RootComponent = restProps.href ? 'a' : Component || 'button';

  return (
    <RootComponent
      {...restProps}
      className={c(
        getClassname('Button'),
        useStyles().button,
        className,
        `Button--sz-${size || 'm'}`,
        `Button--lvl-${mode || 'primary'}`,
        `Button--aln-${align || 'center'}`,
        { 'Button--str': stretched },
      )}
    >
      <div className="Button__in">
        {before && <div className="Button__before">{before}</div>}
        {children && <div className="Button__content">{children}</div>}
        {after && <div className="Button__after">{after}</div>}
      </div>
    </RootComponent>
  );
};

export default ButtonDesktop;
