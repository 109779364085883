import React from 'react';
import { HorizontalScroll, Avatar, Caption, Div } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    width: 'fit-content',
  },
  user: {
    textAlign: 'center',
    width: 80,
    cursor: 'pointer',
    '&:first-child': {
      marginLeft: -8,
    },
    '&:last-child': {
      marginRight: -8,
    },
  },
  user__avatar: {
    margin: '4px 8px',
    display: 'inline-block',
  },
  user__name: {
    padding: '2px 4px',
  },
});

interface User {
  id: number;
  name: string;
  avatarUrl?: string | null;
}

interface UserHorizontalListProps<T> {
  users: T[];
  onUserClick(user: T): void;
}

function UserHorizontalList<T extends User>(props: UserHorizontalListProps<T>) {
  const { users, onUserClick } = props;

  const mc = useStyles();

  return (
    <HorizontalScroll>
      <Div className={mc.list}>
        {users.map((user) => (
          <div className={mc.user} key={user.id} onClick={() => onUserClick(user)}>
            <Avatar className={mc.user__avatar} src={user.avatarUrl || void 0} size={64} />
            <Caption className={mc.user__name} level="2" weight="regular">
              {user.name}
            </Caption>
          </div>
        ))}
      </Div>
    </HorizontalScroll>
  );
}

export default UserHorizontalList;
