import React from 'react';
import getClassname from '@vkontakte/vkui/dist/helpers/getClassName';
import c from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { SeanceTimeProps } from '.';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  seanceTime: {
    padding: '0!important',
    minWidth: 72,
    '& .Button__content': {
      lineHeight: '20px',
    },
  },
});

const SeanceTimeMobile: React.FC<SeanceTimeProps> = ({ datetime, active, onClick }) => (
  <div
    className={c(
      getClassname('Button'),
      useStyles().seanceTime,
      `Button--sz-m`,
      `Button--lvl-${active ? 'primary' : 'secondary'}`,
      `Button--aln-center`,
    )}
    onClick={onClick}
  >
    <div className="Button__in">
      <div className="Button__content">{L.format.time(datetime)}</div>
    </div>
  </div>
);

export default SeanceTimeMobile;
