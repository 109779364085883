import { Platform } from '../types/launch-params';

export function openCoordinates(options: { lat: number; lng: number; platform?: Platform }) {
  const { lat, lng, platform } = options;

  const isApple = platform === 'mobile_iphone' || platform === 'mobile_iphone_messenger';

  const url = isApple
    ? `https://maps.apple.com/?q=${lat},${lng}&z=20&t=s`
    : `https://www.google.com/maps/place/${lat},${lng}/@${lat},${lng},16z`;

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noopener noreferrer');
  link.click();
}
