export interface Config {
  gqlHttpUrl: string;
  sentryDsn: string;
}

// We don't check if variables are passed, due to this logic is placed in
// prebuild.js file
const gqlHttpUrl = process.env.REACT_APP_GQL_HTTP_URL || '';
const sentryDsn = process.env.REACT_APP_SENTRY_DSN || '';

// Application build config
const config: Config = { gqlHttpUrl, sentryDsn };

export default config;
