export interface AppReducerState {
  categoryGroups: Array<{
    id: number;
    slug: string;
    title: string;
  }>;
}

const initialState: AppReducerState = {
  categoryGroups: [],
};

export function appReducer(state: AppReducerState = initialState) {
  return state;
}
