import styled from '@material-ui/styles/styled';
import { CardGrid } from '@vkontakte/vkui';
import { CardGridProps } from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';

export default styled(CardGrid)({
  paddingLeft: 24,
  paddingRight: 24,
  '& .Card': {
    marginTop: 12,
  },
}) as React.FC<CardGridProps>;
