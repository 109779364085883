import StaffSelectModalMobile from './StaffSelectModalMobile';
import StaffSelectModalDesktop from './StaffSelectModalDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';

interface ServiceStaff {
  id: number;
  avatarUrl?: string | null;
  specialization: string;
  name: string;
}

export interface StaffSelectModalProps {
  show: boolean;
  onClose(activeStaff: number): void;
  activeStaffId: number;
  staffItems: ServiceStaff[];
  datetime: string;
}

export default getPlatformComponent<StaffSelectModalProps>({
  mobile: StaffSelectModalMobile,
  desktop: StaffSelectModalDesktop,
});
