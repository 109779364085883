import styled from '@material-ui/styles/styled';
import { Switch } from '@vkontakte/vkui';
import { SwitchProps } from '@vkontakte/vkui/dist/components/Switch/Switch';

export default styled(Switch)({
  '& .Switch__pseudo': {
    height: 10,
    width: 28,
    border: 0,
    background: 'var(--steel_gray_120)!important',
    cursor: 'pointer',
    '&:before': {
      height: 16,
      width: 16,
      border: '1px solid var(--steel_gray_200)',
      boxShadow: 'none',
      boxSizing: 'border-box',
      transform: 'translateX(-2px)',
      top: -3,
    },
  },
  '& .Switch__self:checked+.Switch__pseudo': {
    background: '#a3bad5!important',

    '&:before': {
      transform: 'translateX(14px)',
      background: 'var(--blue_400)',
      border: 0,
    },
  },
}) as React.FC<SwitchProps>;
