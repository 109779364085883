import React, { memo } from 'react';
import Panel, { PanelProps } from '@overrided-vkui/Panel';
import { SimpleCell, isDesktop, PanelHeaderBack } from '@overrided-vkui';
import { useSelector } from 'src/hooks/useSelector';
import { List } from '@vkontakte/vkui';
import CategoryIcon from 'src/components/atomic/CategoryIcon';
import { L } from 'src/lang/L';

interface CategoriesPanelProps extends PanelProps {
  onBack(): void;
  onCategoryClick(categorySlug: string): void;
}

const CategoriesPanel: React.FC<CategoriesPanelProps> = memo((props) => {
  const { onBack, onCategoryClick, ...panelProps } = props;

  const categoryGroups = useSelector((state) => state.app.categoryGroups);

  return (
    <Panel {...panelProps}>
      <Panel.Header left={<PanelHeaderBack onClick={onBack} />}>{L.t('categories_title')}</Panel.Header>
      <Panel.Content>
        <List style={{ margin: '4px 0 12px' }}>
          {categoryGroups.map((categoryGroup) => (
            <SimpleCell
              key={categoryGroup.id}
              before={<CategoryIcon size={isDesktop ? 24 : 28} categoryTitle={categoryGroup.title} />}
              onClick={() => onCategoryClick(categoryGroup.slug)}
              expandable
            >
              {L.t(categoryGroup.title)}
            </SimpleCell>
          ))}
        </List>
      </Panel.Content>
    </Panel>
  );
});

export default CategoriesPanel;
