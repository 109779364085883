import React, { memo, useEffect } from 'react';
import Snackbar, { SnackbarProps } from '@overrided-vkui/Snackbar';
import Icon28ErrorOutline from '@vkontakte/icons/dist/28/error_outline';
import { tapticNotification } from 'src/utils/taptic';
import { L } from 'src/lang/L';

interface ErrorRetrySnackbarProps extends SnackbarProps {
  text?: string;
  onRetry?(): void;
}

const ErrorRetrySnackbar: React.FC<ErrorRetrySnackbarProps> = memo(({ text, onRetry, ...snackbarProps }) => {
  useEffect(() => {
    tapticNotification('error');
  }, []);

  return (
    <Snackbar
      layout="horizontal"
      action={onRetry && L.t('retry')}
      onActionClick={onRetry}
      before={<Icon28ErrorOutline fill="var(--destructive)" />}
      {...snackbarProps}
    >
      <span dangerouslySetInnerHTML={{ __html: text || L.t('fetching_error') }} />
    </Snackbar>
  );
});

export default ErrorRetrySnackbar;
