import React, { memo, useMemo, useEffect, useState } from 'react';
import { Panel, Link, Search, isDesktop, getPlatformClassName, Header, Group, PanelHeaderBack } from '@overrided-vkui';
import { useSelector } from 'src/hooks/useSelector';
import { L } from 'src/lang/L';
import OrganizationUnitList from '../../layout/OrganizationUnitList';
import withGeodata, { WithGeodataComponentProps } from '../../hocs/withGeodata';
import StatusPlaceholder from '../../atomic/StatusPlaceholder';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { RootRoute } from 'src/router';
import GeodataBanner from '../../atomic/GeodataBanner';
import { useRouteNode } from 'react-router5';
import CategoryTabs from '../../atomic/CategoryTabs';
import { makeStyles } from '@material-ui/styles';
import Icon16Chevron from '@vkontakte/icons/dist/16/chevron';
import { tapticSelectionChanged } from 'src/utils/taptic';
import { StatEvent, statEvents } from '../../../utils/statEvents';

const useStyles = makeStyles({
  tabs: {
    marginTop: -8,
    '&--desktop': {
      marginBottom: -8,
    },
  },
});

interface CatalogViewContentProps extends WithGeodataComponentProps {
  searchValue: string;
  categoryGroupId?: number;
  onOrganizationUnitClick(organizationUnitId: number): void;
}

const CatalogViewContent = withGeodata((props: CatalogViewContentProps) => {
  const {
    geodata,
    searchValue,
    geodataSuggestSkipped,
    skipGeodataSuggest,
    requestGeodata,
    onOrganizationUnitClick,
    categoryGroupId,
  } = props;

  const geodataInited = Boolean(geodata);
  const [geodataInitLoading, setGeodataInitLoading] = useState(!geodataInited);

  useEffect(() => {
    if (geodataInited) return;
    setGeodataInitLoading(true);
    requestGeodata().finally(() => setGeodataInitLoading(false));
  }, []); // eslint-disable-line

  const coord = useMemo(() => {
    return geodata?.permission === 'available' ? { lat: geodata.lat, lng: geodata.long } : void 0;
  }, [geodata]);

  return geodataInitLoading ? (
    <StatusPlaceholder status="loading" />
  ) : (
    <>
      <Group
        separator="hide"
        header={
          <Header mode="primary">
            {coord ? L.t('catalog_section_title_closest_units') : L.t('catalog_section_title_units')}
          </Header>
        }
      />
      {geodata?.permission === 'prompt' && !geodataSuggestSkipped && (
        <GeodataBanner onSkip={skipGeodataSuggest} requestGeodata={requestGeodata} />
      )}
      <OrganizationUnitList
        search={searchValue}
        categoryGroupId={categoryGroupId}
        coord={coord}
        openOrganizationUnit={onOrganizationUnitClick}
      />
    </>
  );
});

let searchStatEventSent = false;

interface CatalogPanelProps {
  id: string;
  route: RootRoute;
  panelStart?: React.ReactNode;
  openCategories(): void;
  onOrganizationUnitClick(organizationUnitId: number): void;
  onBack?(): void;
}

const CatalogPanel: React.FC<CatalogPanelProps> = memo((props: CatalogPanelProps) => {
  const { route: routePath, panelStart, onBack, openCategories, onOrganizationUnitClick, id: panelId } = props;

  const { route, router } = useRouteNode(routePath);
  const categoryGroupSlug = route.params.categoryGroupSlug;

  const categoryGroups = useSelector((state) => state.app.categoryGroups);

  const categoryGroupId = useMemo(
    () => categoryGroups.find((categoryGroup) => categoryGroup.slug === categoryGroupSlug)?.id,
    [categoryGroups, categoryGroupSlug],
  );

  /* Поиск */

  const [searchValue, setSearchValue] = useSearchQuery(routePath, 'q');

  useEffect(() => {
    if (!searchStatEventSent && searchValue.trim() !== '') {
      searchStatEventSent = true;
      statEvents.send(StatEvent.Search, {});
    }
  }, [searchValue]);

  /* Рендер */

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      {panelStart || (
        <Panel.Header left={onBack && <PanelHeaderBack onClick={onBack} />} separator={false}>
          {L.t('main_tab_catalog')}
        </Panel.Header>
      )}
      <Search
        value={searchValue}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        maxLength={255}
        onChange={(e) => {
          window.scrollTo(0, 0);
          setSearchValue(e.target.value);
        }}
      />

      {categoryGroups.length > 0 && (
        <Group
          separator="hide"
          header={
            <Header
              mode="primary"
              aside={
                <Link onClick={openCategories}>
                  {L.t('catalog_show_all_categories')}
                  {isDesktop && (
                    <Icon16Chevron
                      fill="var(--light_blue_300)"
                      height={12}
                      width={12}
                      style={{ verticalAlign: 'middle' }}
                    />
                  )}
                </Link>
              }
            >
              {L.t('catalog_section_title_categories')}
            </Header>
          }
        >
          <CategoryTabs
            className={getPlatformClassName(mc.tabs)}
            categories={categoryGroups}
            selectedCategoryId={categoryGroupId}
            onClick={(categoryGroup) => {
              tapticSelectionChanged();
              if (!categoryGroup || categoryGroupId === categoryGroup.id) {
                router.navigate(routePath, { q: route.params.q }, { replace: true });
              } else {
                router.navigate(
                  routePath,
                  { q: route.params.q, categoryGroupSlug: categoryGroup.slug },
                  { replace: true },
                );
              }
            }}
          />
        </Group>
      )}
      <CatalogViewContent
        searchValue={searchValue}
        categoryGroupId={categoryGroupId}
        onOrganizationUnitClick={onOrganizationUnitClick}
      />
    </Panel>
  );
});

export default CatalogPanel;
