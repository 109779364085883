import FooterButtonsMobile from './FooterButtonsMobile';
import FooterButtonsDesktop from './FooterButtonsDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';
import { HTMLAttributes } from 'react';

export interface FooterButtonsProps extends HTMLAttributes<HTMLDivElement> {
  info?: React.ReactNode;
}

export default getPlatformComponent<FooterButtonsProps>({ mobile: FooterButtonsMobile, desktop: FooterButtonsDesktop });
