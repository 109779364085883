import RecordInfoModalMobile from './RecordInfoModalMobile';
import RecordInfoModalDesktop from './RecordInfoModalDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';
import { Record } from 'src/types/yclients';

export interface RecordInfoModalProps {
  show: boolean;
  onClose(): void;
  record: Record | null;
  onCancelRecord(): void;
  onRepeatRecord(): void;
  onTransferRecord(): void;
}

export default getPlatformComponent<RecordInfoModalProps>({
  mobile: RecordInfoModalMobile,
  desktop: RecordInfoModalDesktop,
});
