import { delay } from 'src/utils/misc';

// Выполняем запрос изменения виджета не чаще раза в 10s (ограничение API VK)
const UPDATE_WIGDET_CALL_THROTTLE_DELAY = 10000;

export const widgetThrottleDelay = {
  prevPoint: -UPDATE_WIGDET_CALL_THROTTLE_DELAY,
  delay() {
    return delay(UPDATE_WIGDET_CALL_THROTTLE_DELAY - performance.now() + this.prevPoint).then(() =>
      this.registerDelayPoint(),
    );
  },
  registerDelayPoint() {
    this.prevPoint = performance.now();
  },
};
