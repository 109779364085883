import React, { memo, useState } from 'react';
import { Button, Banner } from '@overrided-vkui';
import Icon28LocationOutline from '@vkontakte/icons/dist/28/location_outline';
import { L } from 'src/lang/L';
import c from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Spinner } from '@vkontakte/vkui';
import { GetGeodataResult } from 'src/utils/getGeodata';

const useStyles = makeStyles({
  banner: {
    margin: '4px 0 8px',
  },
});

interface GeodataBannerProps {
  className?: string;
  onSkip(): void;
  requestGeodata(): Promise<GetGeodataResult>;
}

const GeodataBanner: React.FC<GeodataBannerProps> = memo((props) => {
  const { className, requestGeodata, onSkip } = props;

  const mc = useStyles();

  const [requestLoading, setRequestLoading] = useState(false);

  const onRequestClickHandler = () => {
    setRequestLoading(true);
    requestGeodata().finally(() => setRequestLoading(false));
  };

  return (
    <Banner
      before={<Icon28LocationOutline fill="var(--accent)" />}
      asideMode="dismiss"
      onDismiss={onSkip}
      className={c(className, mc.banner)}
      header={L.t('geodata_banner_title')}
      subheader={L.t('geodata_banner_text')}
      actions={
        <Button
          mode="primary"
          onClick={onRequestClickHandler}
          disabled={requestLoading}
          after={requestLoading && <Spinner size="small" style={{ color: 'inherit' }} />}
        >
          {L.t('geodata_banner_button_request')}
        </Button>
      }
    />
  );
});

export default GeodataBanner;
