import OrganizationPanelMobile from './OrganizationPanelMobile';
import OrganizationPanelDesktop from './OrganizationPanelDesktop';
import { AttachedOrganizationUnit } from 'src/types/yclients';
import { getPlatformComponent } from '@overrided-vkui';

export interface OrganizationPanelProps {
  id: string;
  cityTitle: string;
  onCityTitleClick?(): void;
  units: Array<AttachedOrganizationUnit & { coords: { lat: number; lng: number } }>;
  searchValue: string;
  setSearchValue(searchValue: string): void;
  openCatalog(): void;
  openUnit(unitId: number): void;
}

export default getPlatformComponent<OrganizationPanelProps>({
  mobile: OrganizationPanelMobile,
  desktop: OrganizationPanelDesktop,
});
