import React, { memo } from 'react';
import { getPlatformClassName } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { getClassName } from '@vkontakte/vkui';

const useStyles = makeStyles({
  buttonTab: {
    background: 'var(--modal_card_background)',
    boxShadow: '0 0 0 1px var(--black_alpha8)',
    color: 'var(--text_primary)',

    borderRadius: '10px!important',
    height: 42,
    whiteSpace: 'pre',
    '& .Button__content': {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 400,
    },
    '&$buttonTab_selected': {
      color: 'var(--button_primary_foreground)',
      background: 'var(--button_primary_background)',
      boxShadow: 'none',
      '--accent': 'var(--background_content)',
    },
    '&--desktop': {
      borderRadius: '8px!important',
      '--accent': 'var(--blue_400)',
      cursor: 'pointer',
      '&$buttonTab_selected': {
        background: 'var(--modal_card_background)',
        color: 'var(--text_primary)',
        boxShadow: '0 0 0 2px var(--blue_bright)',
        '--accent': 'var(--blue_400)',
      },
    },
  },
  buttonTab_selected: {},
  buttonTab__icon: {
    height: 24,
    width: 24,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: 8,
    marginLeft: 2,
  },
});

interface ButtonTabProps {
  before?: React.ReactNode;
  children?: string;
  onClick?(): void;
  className?: string;
  selected?: boolean;
}

const ButtonTab: React.FC<ButtonTabProps> = memo((props) => {
  const { before, children, onClick, className, selected } = props;
  const mc = useStyles();

  return (
    <button
      className={c(
        getClassName('Button'),
        className,
        getPlatformClassName(mc.buttonTab),
        { [mc.buttonTab_selected]: selected },
        `Button--sz-m`,
        `Button--lvl-tertiary`,
        `Button--aln-center`,
      )}
      onClick={onClick}
    >
      <div className="Button__in">
        <div className="Button__before">{before}</div>
        <div className="Button__content">{children}</div>
      </div>
    </button>
  );
});

export default ButtonTab;
