import React, { memo, useCallback } from 'react';
import { View } from '@vkontakte/vkui';
import { useRouter } from 'react-router5';
import { RootRoute } from 'src/router';
import UserSettingsPanel from '../panels/UserSettingsPanel';

interface SettingsViewProps {
  id: string;
  onBack?(): void;
}

const SettingsView: React.FC<SettingsViewProps> = memo((props) => {
  const { id: viewId, onBack } = props;
  const router = useRouter();

  const openAddToComunityHandler = useCallback(() => {
    router.navigate(RootRoute.ADD_TO_COMMUNITY);
  }, [router]);

  return (
    <View id={viewId} activePanel="settings.panel">
      <UserSettingsPanel id="settings.panel" openAddToComunity={openAddToComunityHandler} onBack={onBack} />
    </View>
  );
});

export default SettingsView;
