import React, { useEffect, useRef, useCallback, memo } from 'react';
import { CityId } from 'src/types/yclients';
import DG from '2gis-maps';

export interface OrganizationUnit {
  id: number;
  title: string;
  logoUrl?: string | null;
  city: {
    id: number;
    title: string;
  };
  coords: {
    lat: number;
    lng: number;
  };
}

interface OrganizationUnitsMapProps extends React.HTMLAttributes<HTMLDivElement> {
  units: OrganizationUnit[];
  cityId: number;
  onUnitClick(unitId: number): void;
}

const OrganizationUnitsMap = memo(
  (props: OrganizationUnitsMapProps) => {
    const { cityId, units, onUnitClick, ...restProps } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<any>(null);

    const fitToCityCompaniesBounds = useCallback(
      (cityId: CityId) => {
        if (mapRef.current && units.length > 0) {
          try {
            mapRef.current.fitBounds(
              units.filter((unit) => unit.city.id === cityId).map((unit) => [unit.coords.lat, unit.coords.lng]),
            );
          } catch {}
        }
      },
      [units],
    );

    useEffect(() => {
      if (containerRef.current) {
        try {
          mapRef.current =
            mapRef.current ||
            DG.map(containerRef.current, {
              zoomControl: false,
              fullscreenControl: false,
            });
        } catch {}
      }
    }, []);

    const markers = useRef<any[]>([]).current;

    useEffect(() => {
      if (mapRef.current) {
        try {
          const map = mapRef.current;

          markers.forEach((marker) => marker.remove());

          units.forEach((unit) => {
            const marker = DG.marker([unit.coords.lat, unit.coords.lng], {
              icon: DG.divIcon({
                html: `<div style="\
                height: 32px;\
                width: 32px;\
                border-radius: 50%;\
                transform: translate(-10px, -10px);\
                border: 2px solid #fff;\
                box-sizing: border-box;\
                background-size: cover;\
                background-position: center;\
                box-shadow: 0 2px 8px rgba(0,0,0,.16);\
                background-image: url('${unit.logoUrl}');\
                background-color: #f0f0f0;\
              "></div>`.replace(/\s+/g, ' '),
              }),
            });
            marker.on('click', () => onUnitClick(unit.id));
            marker.addTo(map);
            markers.push(marker);
          });
        } catch {}
      }
    }, [markers, units, onUnitClick]);

    useEffect(() => {
      fitToCityCompaniesBounds(cityId);
    }, [fitToCityCompaniesBounds, cityId]);

    return <div ref={containerRef} {...restProps} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.units.map((unit) => unit.id).join() === nextProps.units.map((unit) => unit.id).join() &&
      prevProps.cityId === nextProps.cityId &&
      prevProps.className === nextProps.className
    );
  },
);

export default OrganizationUnitsMap;
