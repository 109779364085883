import { Snackbar } from '@vkontakte/vkui';
import { SnackbarProps } from '@vkontakte/vkui/dist/components/Snackbar/Snackbar';
import { styled } from '@material-ui/styles';

export default styled(Snackbar)({
  width: 360,
  maxWidth: '100%',
  zIndex: 1000,
  '& .Snackbar__body': {
    padding: 12,
    boxShadow:
      '0px 0px 2px var(--black_alpha8), 0px 8px 24px var(--black_alpha8), inset 0 0 0 0.5px var(--black_alpha8)',
  },
  '& .Snackbar__before': {
    marginLeft: 0,
  },
  '& .Snackbar__content-text': {
    marginRight: 10,
  },
  '& .Snackbar__action': {
    fontWeight: 400,
    fontSize: 13,
    '--accent': 'var(--blue_600)',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '&.Snackbar--l-horizontal .Snackbar__action': {
    margin: '0 -12px',
  },
  '&.Snackbar--l-vertical .Snackbar__action': {
    marginTop: 0,
  },
  '&.Snackbar--closing .Snackbar__in': {
    transform: 'translate3d(-140%,0,0)',
  },
}) as React.FC<SnackbarProps>;
