import React, { memo } from 'react';
import { Alert, withPopoutOverlayWrapper } from '@overrided-vkui';
import { L } from 'src/lang/L';

interface CancelRecordAlertProps {
  onClose(): void;
  onCancelRecord(): void;
}

const CancelRecordAlert: React.FC<CancelRecordAlertProps> = memo((props) => {
  const { onClose, onCancelRecord } = props;

  return (
    <Alert
      onClose={onClose}
      actionsLayout="horizontal"
      actions={[
        {
          title: L.t('cancel_alert_button_cancel'),
          autoclose: true,
          mode: 'cancel',
        },
        {
          title: L.t('cancel_alert_button_confirm'),
          autoclose: true,
          mode: 'destructive',
          action: onCancelRecord,
        },
      ]}
    >
      <h2>{L.t('cancel_alert_title')}</h2>
      <p>{L.t('cancel_alert_description')}</p>
    </Alert>
  );
});

export default withPopoutOverlayWrapper(CancelRecordAlert);
