import { useState, useEffect } from 'react';
import { useRouteNode } from 'react-router5';
import useThrottle from './useThrottle';

const useSearchQuery = (routeNode: string, queryKey: string) => {
  const { route, router } = useRouteNode(routeNode);
  const q = decodeURIComponent(route.params[queryKey] || '');

  const [searchValue, setSearchValue] = useState<string>(q);

  const newSearchValue = useThrottle(searchValue, 250);

  useEffect(() => {
    setSearchValue(q);
  }, [q]);

  useEffect(() => {
    router.navigate(route.name, { ...route.params, [queryKey]: encodeURIComponent(newSearchValue) }, { replace: true });
  }, [newSearchValue]); // eslint-disable-line

  return [searchValue, setSearchValue] as const;
};

export default useSearchQuery;
