import React, { memo, useCallback } from 'react';
import { Button, Panel, Avatar, Title, Input, PanelHeaderButton, Caption } from '@overrided-vkui';
import yClientsLogoUrl from 'src/assets/YCLIENTS.svg';
import FormLayout from '../../atomic/vkui/FormLayout';
import { L } from 'src/lang/L';
import { makeStyles } from '@material-ui/styles';
import Icon28HelpOutline from '@vkontakte/icons/dist/28/help_outline';
import { LoginData, LoginPanelProps, YCLIENTS_REGISTRATION_URL, YCLIENTS_RESET_URL } from './index';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    marginBottom: 8,
    marginTop: 8,
  },
  title: {
    margin: '16px 0',
  },
  form: {
    width: '100%',
    '& .FormLayout__row': {
      paddingBottom: 0,
    },
    '& .FormLayout__row .FormField,& .FormLayout__row .Button': {
      margin: 0,
      width: '100%',
    },
  },
  passwordGroup: {
    position: 'relative',
    '& $passwordGroup__input .Input__el': {
      paddingRight: 46,
    },
  },
  passwordGroup__input: {},
  passwordGroup__forgotIconButton: {
    position: 'absolute',
    right: 2,
    top: '50%',
    zIndex: 10,
    transform: 'translateY(-50%)',
    color: 'var(--icon_secondary)',
  },
  noAccountLabel: {
    textAlign: 'center',
    color: 'var(--text_secondary)',
    marginBottom: 20,
    justifyContent: 'center',
  },
  panelContent: { padding: 24 },
  panelFooter: {
    '& .Div': {
      padding: 24,
    },
  },
});

const LoginPanelMobile = memo((props: LoginPanelProps) => {
  const { id: panelId, onNext, onNextLoading } = props;

  const mc = useStyles();

  const onSubmitHandler = useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();

      const formEl = e.target as HTMLFormElement;
      const { login, password } = Object.fromEntries(new FormData(formEl));

      onNext({ login, password } as LoginData);
    },
    [onNext],
  );

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Header transparent separator={false} />
      <Panel.Content center className={mc.panelContent}>
        <div className={mc.root}>
          <Avatar src={yClientsLogoUrl} size={80} mode="app" className={mc.logo} />
          <Title weight="bold" level="1" className={mc.title}>
            {L.t('register_ycl_title')}
          </Title>
          <FormLayout onSubmit={onSubmitHandler} className={mc.form}>
            <Input name="login" placeholder={L.t('register_ycl_input_top_login')} />
            <div className={mc.passwordGroup}>
              <Input
                name="password"
                type="password"
                placeholder={L.t('register_ycl_input_top_password')}
                className={mc.passwordGroup__input}
              />
              <PanelHeaderButton
                type="button"
                className={mc.passwordGroup__forgotIconButton}
                href={YCLIENTS_RESET_URL}
                target="_blank"
              >
                <Icon28HelpOutline />
              </PanelHeaderButton>
            </div>
            <Button size="xl" disabled={onNextLoading}>
              {L.t('register_ycl_button_submit')}
            </Button>
          </FormLayout>
        </div>
      </Panel.Content>
      <Panel.Footer className={mc.panelFooter}>
        <Caption weight="semibold" level="3" className={mc.noAccountLabel} caps>
          {L.t('register_ycl_no_account_label')}
        </Caption>
        <Button size="xl" mode="secondary" type="button" href={YCLIENTS_REGISTRATION_URL} target="_blank">
          {L.t('register_ycl_button_register')}
        </Button>
      </Panel.Footer>
    </Panel>
  );
});

export default LoginPanelMobile;
