import React, { memo } from 'react';
import SeanceTime from '../SeanceTime';
import { makeStyles } from '@material-ui/styles';
import { getPlatformClassName } from '@overrided-vkui';

const useStyles = makeStyles({
  seanceTimeList: {
    gridGap: 10,
    overflow: 'visible',
    gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
    display: 'grid',
    '&--desktop': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(72px, 1fr))',
    },
  },
});

export interface SeanceTimeListProps {
  datetimes: string[];
  activeSeanceDatetime?: string;
  onSeanceClick(seanceDatetime: string): void;
}

const SeanceTimeList: React.FC<SeanceTimeListProps> = memo((props) => {
  const { datetimes, activeSeanceDatetime, onSeanceClick } = props;
  const mc = useStyles();

  return (
    <div className={getPlatformClassName(mc.seanceTimeList)}>
      {datetimes.map((datetime) => (
        <SeanceTime
          key={datetime}
          datetime={datetime}
          active={datetime === activeSeanceDatetime}
          onClick={onSeanceClick.bind(null, datetime)}
        />
      ))}
    </div>
  );
});

export default SeanceTimeList;
