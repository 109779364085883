import { L } from './L';
import dayjs from 'dayjs';

export const INTERPOLATION_PREFIX = '{';
export const INTERPOLATION_SUFFIX = '}';

export interface Format {
  monthOf(monthNumber: number, options?: { short?: boolean }): string;
  weekDay(dayNumber: number, options?: { short?: boolean }): string;
  date(
    datetime: string | number,
    options?: { shortMonth?: boolean; withWeekDay?: boolean; alwaysDate?: boolean },
  ): string;
  dateTime(datetime: string | number, options?: { shortMonth?: boolean; alwaysDate?: boolean }): string;
  timeFormat: '12hour' | '24hour';
  time(datetime: string | number): string;
  number(value: number): string;
}

export const format: Format = {
  /**
   * Возвращает название месяца в родительном падеже
   * @param monthNumber номер месяца (начиная с нуля)
   */
  monthOf(monthNumber, options) {
    if (options?.short) {
      return L.t('common:months_short_of', { returnObjects: true })[monthNumber];
    }
    return L.t('common:months_of', { returnObjects: true })[monthNumber];
  },
  /**
   * Возвращает название дня недели
   * @param dayNumber номер дня недели (начиная с нуля воскресенья)
   */
  weekDay(dayNumber, options) {
    if (options?.short) {
      return L.t('common:week_days_short', { returnObjects: true })[(dayNumber + 6) % 7];
    }
    return L.t('common:week_days', { returnObjects: true })[(dayNumber + 6) % 7];
  },
  /**
   * Возвращает дату
   * Вчера, сегодня, завтра или число с месяцем (и годом, если отличается от текущего)
   * @param datetime ISO8601
   * @param options
   */
  date(datetime, options) {
    const isShortMonth = Boolean(options?.shortMonth);
    const isAlwaysDate = Boolean(options?.alwaysDate);
    const withWeekDay = Boolean(options?.withWeekDay);

    const targetDatetime = dayjs(datetime);
    const dayDiff = targetDatetime.hour(0).minute(0).second(0).diff(dayjs().format('YYYY-MM-DD'), 'day');
    const isCurrentYear = targetDatetime.year() === dayjs().year();

    const [, date, yesterday, today, tomorrow] = L.t(isCurrentYear ? 'common:short_date' : 'common:date', {
      returnObjects: true,
      month: this.monthOf(targetDatetime.month(), { short: isShortMonth }),
      day: targetDatetime.date(),
      year: targetDatetime.year(),
    });

    switch (isAlwaysDate ? 2 : dayDiff) {
      case -1:
        return yesterday;
      case 0:
        return today;
      case 1:
        return tomorrow;
      default:
        const weekDay = this.weekDay(targetDatetime.day(), { short: true });
        return withWeekDay ? `${weekDay}, ${date}` : date;
    }
  },
  /**
   * Возвращает дату и время
   * Вчера, сегодня, завтра или число с месяцем (и годом, если отличается от текущего) + время
   * @param datetime ISO8601
   * @param options
   */
  dateTime(datetime, options) {
    const isShortMonth = Boolean(options?.shortMonth);
    const isAlwaysDate = Boolean(options?.alwaysDate);

    const targetDatetime = dayjs(datetime);
    const dayDiff = targetDatetime.hour(0).minute(0).second(0).diff(dayjs().format('YYYY-MM-DD'), 'day');
    const isCurrentYear = targetDatetime.year() === dayjs().year();

    const [, date, yesterday, today, tomorrow] = L.t(
      isCurrentYear ? 'common:short_date_time' : 'common:date_year_time',
      {
        returnObjects: true,
        day: targetDatetime.date(),
        month: this.monthOf(targetDatetime.month(), { short: isShortMonth }),
        year: targetDatetime.year(),
        hour: targetDatetime.format(this.timeFormat === '12hour' ? 'h' : 'HH'),
        minute: targetDatetime.format('mm'),
        am_pm: targetDatetime.format('a'),
      },
    );

    if (isAlwaysDate) {
      return date;
    }

    switch (dayDiff) {
      case -1:
        return yesterday;
      case 0:
        return today;
      case 1:
        return tomorrow;
      default:
        return date;
    }
  },
  get timeFormat() {
    return L.t('config:timeSys', { returnObjects: true })[0] as any;
  },
  /**
   * Возвращает время
   */
  time(datetime) {
    return dayjs(datetime).format(this.timeFormat === '12hour' ? 'h:mm a' : 'HH:mm');
  },
  /**
   * Возвращает форматированное число
   */
  number(value) {
    const parts = String(value).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, L.t('config:numDelS'));

    return parts.join(L.t('config:numDec'));
  },
};
