import React, { memo } from 'react';
import { ModalPage, Search, SimpleCell } from '@overrided-vkui';
import { List, Footer } from '@vkontakte/vkui';
import Icon24CheckCircleOn from '@vkontakte/icons/dist/24/check_circle_on';
import { makeStyles } from '@material-ui/styles';
import { CitySelectModalPageProps } from './index';
import ModalPageHeaderWithClose from '../../atomic/ModalPageHeaderWithClose';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  search: {
    position: 'sticky',
    top: 0,
  },
});

const CitySelectModalPageMobile = memo((props: CitySelectModalPageProps) => {
  const { id: modalId, onClose, searchValue, setSearchValue, selectedCityId, onSelectCity, cities } = props;

  const mc = useStyles();

  return (
    <ModalPage
      id={modalId}
      onClose={onClose}
      fullHeight
      header={
        <ModalPageHeaderWithClose noShadow onClose={onClose}>
          {L.t('city_select_modal_title')}
        </ModalPageHeaderWithClose>
      }
    >
      <Search
        className={mc.search}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        maxLength={255}
      />
      <List>
        {cities.map((city) => (
          <SimpleCell
            key={city.id}
            description={
              typeof city.unitsCount === 'number'
                ? L.t('organization_units_amount', { count: city.unitsCount })
                : void 0
            }
            onClick={() => onSelectCity(city.id)}
            after={selectedCityId === city.id && <Icon24CheckCircleOn />}
          >
            {city.title}
          </SimpleCell>
        ))}
      </List>
      <Footer>{L.t('cities_amout', { count: cities.length })}</Footer>
    </ModalPage>
  );
});

export default CitySelectModalPageMobile;
