import React from 'react';
import Title, { TitleProps } from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import styled from '@material-ui/styles/styled';

export default styled(Title)({
  '&.Title--l-3': {
    fontSize: 16,
    lineHeight: '20px',
  },
  '&.Title--l-2': {
    fontSize: 20,
    lineHeight: '24px',
  },
  '&.Title--l-1': {
    fontSize: 24,
    lineHeight: '28px',
  },
  '&.Title--w-medium': {
    fontWeight: 500,
  },
  '&.Title--w-semibold': {
    fontWeight: 500,
  },
  '&.Title--w-bold': {
    fontWeight: 600,
    fontFamily: 'var(--font-tt)',
  },
  '&.Title--w-heavy': {
    fontWeight: 700,
    fontFamily: 'var(--font-tt)',
  },
  '&.Title--w-regular': {
    fontWeight: 400,
  },
}) as React.FC<TitleProps>;
