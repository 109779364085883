import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import Icon28Notifications from '@vkontakte/icons/dist/28/notifications';
import { Subhead, isDesktop } from '@overrided-vkui';
import { L } from 'src/lang/L';

const useStyles = makeStyles<any, { isDesktop: boolean }>({
  notificationBadge: {
    display: 'flex',
    alignItems: 'center',
    color: 'var(--text_subhead)',
    '& .Icon': {
      marginRight: ({ isDesktop }) => (isDesktop ? 15 : 10),
    },
  },
});

const NotificationBadge: React.FC = memo(() => {
  const mc = useStyles({ isDesktop });

  return (
    <div className={mc.notificationBadge}>
      <Icon28Notifications fill={isDesktop ? 'var(--content_placeholder_icon)' : 'var(--accent)'} />
      <Subhead weight="regular">{L.t('badge_notification')}</Subhead>
    </div>
  );
});

export default NotificationBadge;
