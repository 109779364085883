import React, { memo } from 'react';
import c from 'classnames';

import { ReactComponent as Icon24BeautyOutline } from '../../../assets/beauty-outline-24.svg';
import { ReactComponent as Icon24HealthOutline } from '../../../assets/health-outline-24.svg';
import { ReactComponent as Icon24EducationOutline } from '../../../assets/education-outline-24.svg';
import { ReactComponent as Icon24GameOutline } from '../../../assets/game-outline-24.svg';
import { ReactComponent as Icon24BallOutline } from '../../../assets/ball-outline-24.svg';
import { ReactComponent as Icon24CarOutline } from '../../../assets/car-outline-24.svg';
import { ReactComponent as Icon24MarketOutline } from '../../../assets/market-outline-24.svg';
import { ReactComponent as Icon24PaintRollerOutline } from '../../../assets/paint-roller-outline-24.svg';
import Icon24StorefrontOutline from '@vkontakte/icons/dist/24/storefront_outline';

const categoryTitleIcon = {
  category_title_beauty: Icon24BeautyOutline,
  category_title_medicine: Icon24HealthOutline,
  category_title_sport: Icon24BallOutline,
  category_title_auto: Icon24CarOutline,
  category_title_training: Icon24EducationOutline,
  category_title_hobby: Icon24GameOutline,
  category_title_retail: Icon24MarketOutline,
  category_title_other_business: Icon24StorefrontOutline,
  category_title_domestic_services: Icon24PaintRollerOutline,
};

const FallbackCategoryIcon = Icon24StorefrontOutline;

interface CategoryIconProps extends React.HTMLAttributes<HTMLDivElement> {
  categoryTitle: string;
  size: 24 | 28;
  fill?: string;
}

const CategoryIcon: React.FC<CategoryIconProps> = memo((props) => {
  const { categoryTitle, size, style, fill, className, ...iconProps } = props;

  const IconComponent = (categoryTitleIcon as any)[categoryTitle] || FallbackCategoryIcon;

  return (
    <IconComponent
      style={{ display: 'block', color: fill || 'var(--accent)', ...style }}
      width={size}
      height={size}
      className={c(
        'Icon',
        {
          ['Icon--24 Icon--w-24 Icon--h-24 Icon Icon--24']: size === 24, // eslint-disable-line
          ['Icon--28 Icon--w-28 Icon--h-28 Icon Icon--28']: size === 28, // eslint-disable-line
        },
        className,
      )}
      {...iconProps}
    />
  );
});

export default CategoryIcon;
