import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FooterButtonsProps } from '.';

const useStyles = makeStyles({
  buttonContainer: {
    '& > * + *': {
      marginTop: 10,
    },
  },
  info: {
    marginBottom: 12,
  },
});

const FooterButtonsMobile: React.FC<FooterButtonsProps> = memo((props) => {
  const { children, info, ...rest } = props;
  const mc = useStyles();

  return (
    <div {...rest}>
      {info && <div className={mc.info}>{info}</div>}
      <div className={mc.buttonContainer}>
        {React.Children.map(
          children,
          (child) => child && React.cloneElement(child as React.ReactElement, { size: 'xl' }),
        )}
      </div>
    </div>
  );
});

export default FooterButtonsMobile;
