import { useSetVkGroupAccessTokenMutation } from 'src/gql/generated/types';
import { useCallback } from 'react';
import { getCommunityToken, validateCommunityTokenScope } from 'src/utils/community';
import { useSelector } from './useSelector';
import { L } from 'src/lang/L';

const SCOPE = ['app_widget', 'manage'];

function useSetVkGroupOrganizationToken() {
  const [setVkGroupAccessToken] = useSetVkGroupAccessTokenMutation({ fetchPolicy: 'no-cache' });

  const { appId, groupId } = useSelector((state) => ({
    appId: state.launchParams.appId,
    groupId: state.launchParams.groupId,
  }));

  const setVkGroupOrganizationToken = useCallback(async () => {
    if (!groupId) return null;

    const accessToken = await getCommunityToken(appId, groupId, SCOPE).catch(() => {
      throw new Error(L.t('group_token_request_error'));
    });

    if (!accessToken) return null;

    const isCorrectCommmunityTokenScope = await validateCommunityTokenScope(accessToken, SCOPE).catch(() => {
      throw new Error(L.t('group_token_request_error'));
    });

    if (!isCorrectCommmunityTokenScope) {
      return null;
    }

    await setVkGroupAccessToken({ variables: { accessToken } }).catch(() => {
      throw new Error(L.t('group_token_request_error'));
    });

    return accessToken;
  }, [appId, groupId, setVkGroupAccessToken]);

  return setVkGroupOrganizationToken;
}

export default useSetVkGroupOrganizationToken;
