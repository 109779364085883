import React, { memo } from 'react';
import { Panel, Group, Header, SimpleCell, Avatar } from '@overrided-vkui';
import { L } from 'src/lang/L';
import Icon28LinkCircleOutline from '@vkontakte/icons/dist/28/link_circle_outline';
import Icon28ServicesOutline from '@vkontakte/icons/dist/28/services_outline';
import { formatCityAddress } from 'src/utils/formatter';
import { makeStyles } from '@material-ui/styles';
import { AccessTokenStatus, WidgetVariant } from 'src/gql/generated/types';
import { ReactComponent as Icon24InfoCircleOutline } from 'src/assets/info-circle-outline-24.svg';
import { OrganizationSettingsPanelProps } from './index';

const useStyles = makeStyles({
  logoutCell: {
    '& .SimpleCell__children': {
      color: 'var(--destructive)',
    },
  },
  invalidIcon: {
    display: 'block',
    color: 'var(--destructive)',
    marginLeft: 10,
  },
});

const OrganizationSettingsPanelMobile = memo((props: OrganizationSettingsPanelProps) => {
  const {
    id: panelId,
    openOrganizationUnits,
    openUnit,
    organization,
    widgetVariant,
    openWidgetSettings,
    openActionButtonSettings,
    onLogout,
    isSingleUnitYClientsOrganization,
  } = props;

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      <Panel.Header>{L.t('organization_settings_title')}</Panel.Header>
      <Panel.Content>
        {isSingleUnitYClientsOrganization && organization.units.length === 1 ? (
          <Group header={<Header>{L.t('organization_settings_attached_single_unit_title')}</Header>}>
            <SimpleCell
              before={<Avatar mode="image" size={72} src={organization.units[0].logoUrl || void 0} />}
              expandable
              description={formatCityAddress(organization.units[0].city.title, organization.units[0].address)}
              onClick={() => openUnit(organization.units[0].id)}
            >
              {organization.units[0].title}
            </SimpleCell>
          </Group>
        ) : (
          <Group header={<Header>{L.t('organization_settings_attached_title')}</Header>}>
            <SimpleCell
              before={<Avatar mode="image" size={72} src={organization.units[0]?.logoUrl || void 0} />}
              expandable
              description={L.t('attached_units_amount', { count: organization.units.length })}
              onClick={openOrganizationUnits}
            >
              {organization.title}
            </SimpleCell>
          </Group>
        )}
        {widgetVariant && (
          <Group>
            {widgetVariant && (
              <SimpleCell
                before={<Icon28ServicesOutline />}
                onClick={openWidgetSettings}
                expandable
                indicator={organization.isVkGroupWidgetEnabled ? L.t('switch_on_short') : L.t('switch_off_short')}
                after={
                  organization.isVkGroupWidgetEnabled &&
                  organization.vkGroupAccessTokenStatus === AccessTokenStatus.Invalid && (
                    <Icon24InfoCircleOutline className={mc.invalidIcon} />
                  )
                }
              >
                {widgetVariant === WidgetVariant.Services
                  ? L.t('organization_settings_nav_services_widget')
                  : L.t('organization_settings_nav_units_widget')}
              </SimpleCell>
            )}
            {false && (
              <SimpleCell
                before={<Icon28LinkCircleOutline />}
                onClick={openActionButtonSettings}
                expandable
                indicator={organization.isVkGroupActionButtonEnabled ? L.t('switch_on_short') : L.t('switch_off_short')}
              >
                {L.t('organization_settings_nav_action_button')}
              </SimpleCell>
            )}
          </Group>
        )}
        <Group>
          <SimpleCell onClick={onLogout} className={mc.logoutCell}>
            {L.t('organization_settings_logout_title')}
          </SimpleCell>
        </Group>
      </Panel.Content>
    </Panel>
  );
});

export default OrganizationSettingsPanelMobile;
