import React, { useCallback, useState, memo, useMemo } from 'react';
import YClientsUnitsSelectPanel from '../panelComponents/YClientsUnitsSelectPanel';
import YClientsSingleUnitSelectPanel from '../panelComponents/YClientsSingleUnitSelectPanel';
import { PanelHeaderButton, PanelHeaderBack, isDesktop, useSnackbar } from '@overrided-vkui';
import { useAttachYClientsOrganizationMutation } from 'src/gql/generated/types';
import ErrorRetrySnackbar from 'src/components/atomic/snackbars/ErrorRetrySnackbar';
import { L } from 'src/lang/L';
import { YClientsOrganizationUnit } from 'src/types/yclients';
import { filterUnitBySearchValue } from 'src/utils/filter';

interface YClientsOrganizationUnitsSelectPanelProps {
  id: string;
  organizationId: number;
  units: YClientsOrganizationUnit[];
  authData: { login: string; password: string };
  isSingleOrganization: boolean;
  onBack(): void;
  onNext(attachedUnitIds: number[], showWidgetSuggest: boolean): void;
}

const YClientsOrganizationUnitsSelectPanel = memo((props: YClientsOrganizationUnitsSelectPanelProps) => {
  const { id: panelId, authData, organizationId, onBack, onNext, isSingleOrganization, units } = props;

  const isSingleUnit = units.length === 1;

  const openSnackbar = useSnackbar();

  /* Выбранные салоны */

  const [selectedUnitIds, setSelectedUnitIds] = useState<number[]>([]);

  /* Процесс привязки */

  const [
    attachYClientsOrganization,
    { loading: attachYClientsOrganizationLoading },
  ] = useAttachYClientsOrganizationMutation({
    context: {
      headers: {
        'x-auth-login': encodeURIComponent(authData.login),
        'x-auth-password': encodeURIComponent(authData.password),
      },
    },
    onCompleted: (data) => {
      const attachedOrganization = data.attachYClientsOrganization;
      onNext(
        attachedOrganization.units.map((unit) => unit.id),
        Boolean(attachedOrganization.vkGroupWidgetPreview),
      );
    },
    onError: () => {
      openSnackbar(
        <ErrorRetrySnackbar text={L.t('attach_ycl_organization_units_submit_error')} onClose={() => null} />,
      );
    },
  });

  const onNextHandler = useCallback(() => {
    const unitIds = isSingleUnit ? [units[0].id] : selectedUnitIds;

    if (unitIds.length === 0) {
      return Promise.resolve();
    }

    return attachYClientsOrganization({
      variables: { yClientsOrganizationId: organizationId, yClientsOrganizationUnitIds: unitIds },
    }).catch(() => null);
  }, [attachYClientsOrganization, selectedUnitIds, units, isSingleUnit, organizationId]);

  /* Поиск */

  const [searchValue, setSearchValue] = useState('');

  const foundUnits = useMemo(() => filterUnitBySearchValue(units, searchValue), [searchValue, units]);

  /* Рендер */

  const exitButton = <PanelHeaderButton onClick={onBack}>{L.t('attach_ycl_logout')}</PanelHeaderButton>;
  const backButton = <PanelHeaderBack onClick={onBack} />;

  const headerLeft = isSingleOrganization && !isDesktop ? exitButton : !isSingleOrganization ? backButton : null;
  const headerRight = isSingleOrganization && isDesktop && exitButton;

  if (isSingleUnit) {
    return (
      <YClientsSingleUnitSelectPanel
        id={panelId}
        unit={units[0]}
        headerLeft={headerLeft}
        headerRight={headerRight}
        nextButtonText={L.t('attach_ycl_organization_units_submit')}
        onNext={onNextHandler}
        onNextLoading={attachYClientsOrganizationLoading}
      />
    );
  }

  return (
    <YClientsUnitsSelectPanel
      id={panelId}
      units={foundUnits}
      selectedUnitIds={selectedUnitIds}
      setSelectedUnitIds={setSelectedUnitIds}
      headerLeft={headerLeft}
      headerRight={headerRight}
      headerTitleText={L.t('attach_ycl_organization_units_title')}
      nextButtonText={L.t('attach_ycl_organization_units_submit')}
      withNextButtonCounter
      onNext={onNextHandler}
      onNextLoading={attachYClientsOrganizationLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
});

export default YClientsOrganizationUnitsSelectPanel;
