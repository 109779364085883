import styled from '@material-ui/styles/styled';
import { Link } from '@vkontakte/vkui';
import { LinkProps } from '@vkontakte/vkui/dist/components/Link/Link';

export default styled(Link)({
  'body:not([scheme=space_gray]) &': {
    '--accent': 'var(--blue_600)',
    cursor: 'pointer',
  },
  '&:hover': {
    textDecoration: 'underline',
  },
}) as React.FC<LinkProps>;
