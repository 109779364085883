import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UnitHeadSectionProps } from './index';
import { Avatar, Caption, Title, Link } from '@overrided-vkui';
import Icon16Chevron from '@vkontakte/icons/dist/16/chevron';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 24,
  },
  root__title: {
    marginTop: 12,
    textAlign: 'center',
  },
  root__address: {
    margin: '4px 0 2px',
    color: 'var(--text_secondary)',
    textAlign: 'center',
  },
  root__more: {
    display: 'flex',
    alignItems: 'center',
    '& .Icon': {
      marginTop: 2,
      marginLeft: 1,
    },
  },
});

const UnitHeadSectionMobile = memo((props: UnitHeadSectionProps) => {
  const { logoUrl, title, address, onMoreInfo } = props;
  const mc = useStyles();

  return (
    <div className={mc.root}>
      <Avatar src={logoUrl} size={80} mode="app" />
      <Title className={mc.root__title} level="2" weight="semibold">
        {title}
      </Title>
      <Caption className={mc.root__address} level="1" weight="regular">
        {address}
      </Caption>
      <Caption level="1" weight="regular">
        <Link Component="span" onClick={onMoreInfo} className={mc.root__more}>
          <span>{L.t('unit_open_meta_link')}</span>
          <Icon16Chevron height={12} width={12} />
        </Link>
      </Caption>
    </div>
  );
});

export default UnitHeadSectionMobile;
