import { YClientsOrganization } from 'src/types/yclients';
import YClientsOrganizationSelectPanelMobile from './YClientsOrganizationSelectPanelMobile';
import YClientsOrganizationSelectPanelDesktop from './YClientsOrganizationSelectPanelDesktop';
import { getPlatformComponent } from '@overrided-vkui';

export interface YClientsOrganizationSelectPanelProps {
  id: string;
  yClientsOrganizations: YClientsOrganization[];
  onBack(): void;
  onNext(yClientsOrganization: YClientsOrganization): void;
}

export default getPlatformComponent<YClientsOrganizationSelectPanelProps>({
  mobile: YClientsOrganizationSelectPanelMobile,
  desktop: YClientsOrganizationSelectPanelDesktop,
});
