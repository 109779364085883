import React, { memo } from 'react';
import { L } from 'src/lang/L';
import { Alert } from '@overrided-vkui';

interface OrganizationLogoutConfirmAlertProps {
  onCancel(): void;
  onLogout(): void;
}

const OrganizationLogoutConfirmAlert = memo((props: OrganizationLogoutConfirmAlertProps) => {
  const { onCancel, onLogout } = props;

  return (
    <Alert
      onClose={onCancel}
      actionsLayout="vertical"
      actions={[
        {
          mode: 'destructive',
          title: L.t('organization_logout_confirm_button_logout'),
          action: onLogout,
        },
        {
          mode: 'cancel',
          title: L.t('cancel'),
          autoclose: true,
        },
      ]}
    >
      <h2>{L.t('organization_logout_confirm_title')}</h2>
      <p>{L.t('organization_logout_confirm_text')}</p>
    </Alert>
  );
});

export default OrganizationLogoutConfirmAlert;
