import React, { memo, useCallback, useState } from 'react';
import OrganizationActionButtonSettingsPanelComponent from '../panelComponents/OrganizationActionButtonSettingsPanel';
import { useSnackbar } from '@overrided-vkui';
import {
  AccessTokenStatus,
  useGetVkGroupActionButtonDataQuery,
  useSetVkGroupActionButtonMutation,
  useUnsetVkGroupActionButtonMutation,
  GqlErrorCode,
} from 'src/gql/generated/types';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import useSetVkGroupOrganizationToken from 'src/hooks/useSetVkGroupOrganizationToken';
import { getGqlErrorCode } from 'src/types/gql';
import { rewriteVkGroupAccessTokenStatus } from 'src/utils/apolloCache';
import { L } from 'src/lang/L';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';

interface OrganizationActionButtonSettingsPanelProps {
  id: string;
  onBack?(): void;
  onSkip?(): void;
  onNext(): void;
  hideSuccessSnackbar?: boolean;
}

const OrganizationActionButtonSettingsPanel = memo((props: OrganizationActionButtonSettingsPanelProps) => {
  const { id: panelId, onBack, onSkip, onNext, hideSuccessSnackbar } = props;

  const setVkGroupOrganizationToken = useSetVkGroupOrganizationToken();
  const openSnackbar = useSnackbar();

  const {
    data,
    loading: actionButtonLoading,
    error: actionButtonError,
    client: apolloClient,
  } = useGetVkGroupActionButtonDataQuery({ fetchPolicy: 'cache-and-network' });
  const actionButtonData = data?.attachedOrganization || void 0;

  const [setVkGroupActionButton] = useSetVkGroupActionButtonMutation();
  const [unsetVkGroupActionButton] = useUnsetVkGroupActionButtonMutation();

  const [actionButtonSwitchLoading, setActionButtonSwitchLoading] = useState(false);

  const switchActionButton = useCallback(async () => {
    try {
      setActionButtonSwitchLoading(true);

      if (!actionButtonData) return;

      /* Получаем и устанавливаем токен сообщества, если его нет */

      if (actionButtonData.vkGroupAccessTokenStatus !== AccessTokenStatus.Exists) {
        const accesstToken = await setVkGroupOrganizationToken().catch((e) => {
          openSnackbar(<ErrorRetrySnackbar text={e?.message || L.t('fetch_error')} onClose={() => null} />);
          return null;
        });

        if (!accesstToken) return;
      }

      /* Меняем состояние включенности кнопки */

      const switchActionButton = actionButtonData.isVkGroupActionButtonEnabled
        ? unsetVkGroupActionButton
        : setVkGroupActionButton;

      await switchActionButton()
        .then(() => {
          onNext();
          if (!hideSuccessSnackbar) {
            openSnackbar(
              <SuccessSnackbar
                text={L.t(
                  actionButtonData.isVkGroupActionButtonEnabled
                    ? 'organization_action_button_settings_unset_success'
                    : 'organization_action_button_settings_set_success',
                )}
                onClose={() => null}
              />,
            );
          }
        })
        .catch((e) => {
          if (getGqlErrorCode(e) === GqlErrorCode.WidgetTokenInvalidated) {
            rewriteVkGroupAccessTokenStatus(apolloClient.cache, AccessTokenStatus.Invalid);
          }

          openSnackbar(
            <ErrorRetrySnackbar
              text={
                actionButtonData.isVkGroupActionButtonEnabled
                  ? L.t('organization_action_button_settings_action_unset_error')
                  : L.t('organization_action_button_settings_action_set_error')
              }
              onClose={() => null}
            />,
          );
        });
    } finally {
      setActionButtonSwitchLoading(false);
    }
  }, [
    actionButtonData,
    hideSuccessSnackbar,
    unsetVkGroupActionButton,
    setVkGroupActionButton,
    onNext,
    setVkGroupOrganizationToken,
    openSnackbar,
    apolloClient,
  ]);

  return (
    <OrganizationActionButtonSettingsPanelComponent
      id={panelId}
      onBack={onBack}
      onSkip={onSkip}
      actionButtonLoading={actionButtonLoading}
      actionButtonError={Boolean(actionButtonError)}
      actionButton={actionButtonData && { enabled: actionButtonData.isVkGroupActionButtonEnabled }}
      onActionButtonSwitch={switchActionButton}
      actionButtonSwitchLoading={actionButtonSwitchLoading}
    />
  );
});

export default OrganizationActionButtonSettingsPanel;
