import React, { memo, useMemo } from 'react';
import { Root } from '@overrided-vkui';
import { useRouteNode } from 'react-router5';
import { RootRoute } from '../../router';
import MainView from '../views/MainView';
import OrganizationView from '../views/OrganizationView';
import OrganizationSettingsView from '../views/OrganizationSettingsView';
import AddToCommunityView from '../views/AddToCommunityView';
import OnboardView from '../views/OnboardView';
import RegisterView from '../views/RegisterView';
import ReloginView from '../views/ReloginView';
import OrganizationUnitView from '../views/OrganizationUnitView';
import TransferRecordView from '../views/TransferRecordView';
import CurrentYClientsUnitsEditView from '../views/CurrentYClientsUnitsEditView';
import { useSelector } from 'src/hooks/useSelector';
import { canManageVkGroupApp } from 'src/utils/canManageVkGroupApp';

const App = memo(() => {
  const { route, router } = useRouteNode('');
  const activeView = route.name.split('.')[0];

  const isVkGroupAppManager = useSelector((store) => canManageVkGroupApp(store.launchParams.viewerGroupRole));
  const attachedUnitIds = useSelector((state) => state.organization.attachedUnitIds);

  const openToVkGroupOrganization = useMemo(() => {
    if (!attachedUnitIds) return void 0;

    return () => {
      if (isVkGroupAppManager) {
        router.navigate(RootRoute.ORGANIZATION_SETTINGS);
        return;
      }

      if (attachedUnitIds.length === 1) {
        router.navigate(RootRoute.ORGANIZATION_UNIT, { organizationUnitId: attachedUnitIds[0] });
      } else {
        router.navigate(RootRoute.ORGANIZATION);
      }
    };
  }, [attachedUnitIds, router, isVkGroupAppManager]);

  return (
    <Root activeView={activeView}>
      <MainView id={RootRoute.MAIN} onCatalogBack={openToVkGroupOrganization} />
      <OrganizationView id={RootRoute.ORGANIZATION} />
      <OrganizationSettingsView id={RootRoute.ORGANIZATION_SETTINGS} />
      <OnboardView id={RootRoute.ONBOARD} />
      <AddToCommunityView id={RootRoute.ADD_TO_COMMUNITY} />
      <OrganizationUnitView id={RootRoute.ORGANIZATION_UNIT} />
      <TransferRecordView id={RootRoute.TRASFER_RECORD} />
      <RegisterView id={RootRoute.REGISTER} />
      <ReloginView id={RootRoute.RELOGIN} />
      <CurrentYClientsUnitsEditView id={RootRoute.CURRENT_YCLIENTS_UNITS_EDIT} />
    </Root>
  );
});

export default App;
