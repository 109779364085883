import React, { memo, useMemo } from 'react';
import { L } from './L';
import { INTERPOLATION_PREFIX, INTERPOLATION_SUFFIX } from './format';

export interface LJsxProps {
  t: string;
  vars?: Record<string, React.ReactElement | string>;
}

const interpolationRegex = new RegExp(`${INTERPOLATION_PREFIX}.+${INTERPOLATION_SUFFIX}`, 'g');

const allowedHtmlTags = ['b', 'br', 'i', 'strong', 'span'];
const allowerHtmlTagsRe = new RegExp(`<\\/?(${allowedHtmlTags.join('|')})\\s*?\\/?>`, 'g');

/**
 * Позволяет поддерживать html разметку и использовать JSX в качестве переменных
 */
const LJsx: React.FC<LJsxProps> = memo((props) => {
  const { t, vars = {} } = props;

  const value = L.t(t, { interpolation: { skipOnVariables: true } });

  const hasBlockedHtml = useMemo(() => {
    // Проверяем, что строка содержит только допустимые html-теги,
    // тогда размещаем ее с помощью dangerouslySetInnerHTML
    return (value.match(/<\/?[a-zA-Z]+?(\s+?.*?)?\/?>/g) || [])
      .map((tag) => (tag.match(allowerHtmlTagsRe) || [])[0] as string | undefined)
      .includes(void 0);
  }, [value]);

  const parts = useMemo(() => {
    const plainParts = value.split(interpolationRegex);
    const dynamicParts = (value.match(interpolationRegex) || []).map(
      (x) => vars[x.slice(INTERPOLATION_PREFIX.length, -INTERPOLATION_SUFFIX.length)] || '',
    );

    return plainParts.reduce((acc, curr, index) => {
      acc.push(curr);
      acc.push(dynamicParts[index]);
      return acc;
    }, [] as React.ReactNode[]);
  }, [value, vars]);

  return (
    <>
      {parts.map((part, index) =>
        !hasBlockedHtml && typeof part === 'string' ? (
          <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </>
  );
});

export default LJsx;
