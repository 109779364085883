import React, { memo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Root } from '@vkontakte/vkui';
import { RootProps } from '@vkontakte/vkui/dist/components/Root/Root';
import c from 'classnames';

const useStyles = makeStyles({
  root: {
    '& .PopoutWrapper': {
      pointerEvents: 'initial',
    },
    '& .PopoutWrapper__mask': {
      transition: 'none!important',
      animationDuration: 'unset!important',
    },
    '& .Alert': {
      animation: 'none',
      animationDuration: 'unset!important',
    },
  },
});

const RootDesktop = memo((props: RootProps) => {
  return <Root {...props} className={c(props.className, useStyles().root)} />;
});

export default RootDesktop;
