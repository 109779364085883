import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';

const useStyles = makeStyles({
  centeredLayout: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: 'var(--background_content)',
  },
});

const CenteredLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = memo((props) => {
  const { children, className, ...restProps } = props;

  return (
    <div className={c(className, useStyles().centeredLayout)} {...restProps}>
      {children}
    </div>
  );
});

export default CenteredLayout;
