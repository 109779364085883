import React, { memo, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import c from 'classnames';
import { Panel as VKPanel } from '@vkontakte/vkui';
import { PanelProps as VKPanelProps } from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelContent from '../PanelContent';
import PanelHeader from '../PanelHeader';
import PanelFooter from '../PanelFooter';

const useStyles = makeStyles({
  panel: {
    '& .Panel__in': {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
    },
  },
});

export interface PanelProps extends VKPanelProps {
  elastic?: boolean;
}

const Panel: React.FC<PanelProps> = memo((props) => {
  const { className, elastic = true, ...rest } = props;
  const mc = useStyles();

  useEffect(() => {
    if (elastic) return;

    document.documentElement.style.height = '100%';
    document.documentElement.style.width = '100%';
    document.documentElement.style.overflow = 'hidden';
    document.body.style.height = '100%';
    document.body.style.width = '100%';
    document.body.style.overflow = 'auto';
    document.body.style['-webkit-overflow-scrolling' as any] = 'touch';

    return () => {
      document.documentElement.style.height = 'initial';
      document.documentElement.style.width = 'initial';
      document.documentElement.style.overflow = 'initial';
      document.body.style.height = 'initial';
      document.body.style.width = 'initial';
      document.body.style.overflow = 'initial';
      document.body.style['-webkit-overflow-scrolling' as any] = 'initial';
    };
  }, [elastic]);

  return <VKPanel className={c(className, mc.panel)} {...rest} />;
});

export default Object.assign(Panel, { Header: PanelHeader, Content: PanelContent, Footer: PanelFooter });
