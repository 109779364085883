import React, { useCallback, useState, memo, useMemo } from 'react';
import YClientsUnitsSelectPanel from '../panelComponents/YClientsUnitsSelectPanel';
import { useSnackbar, PanelHeaderClose } from '@overrided-vkui';
import { useGetVkGroupAttachedOrganizationQuery, useAttachYClientsOrganizationMutation } from 'src/gql/generated/types';
import ErrorRetrySnackbar from 'src/components/atomic/snackbars/ErrorRetrySnackbar';
import { L } from 'src/lang/L';
import { filterUnitBySearchValue } from 'src/utils/filter';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';
import { useActions } from 'src/hooks/useActions';
import { organizationActions } from 'src/redux/reducers/organization';

interface YClientsOrganizationUnitsEditPanelProps {
  id: string;
  onBack(): void;
}

const YClientsOrganizationUnitsEditPanel = memo((props: YClientsOrganizationUnitsEditPanelProps) => {
  const { id: panelId, onBack } = props;

  const openSnackbar = useSnackbar();
  const setAttachedUnitIds = useActions(organizationActions.setAttachedUnitIds);

  /* Выбранные салоны */

  const [selectedUnitIds, setSelectedUnitIds] = useState<number[]>([]);
  const [alreadySelectedUnitIds, setAlreadySelectedUnitIds] = useState<number[]>([]);

  const isAllSelectedUnitsNotAlreadySelected = useMemo(() => {
    return (
      alreadySelectedUnitIds.length === selectedUnitIds.length &&
      alreadySelectedUnitIds.sort().toString() === selectedUnitIds.sort().toString()
    );
  }, [alreadySelectedUnitIds, selectedUnitIds]);

  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useGetVkGroupAttachedOrganizationQuery({
    variables: { withSettingsData: true },
    onCompleted(data) {
      const unitIds = data.attachedOrganization?.units.map((unit) => unit.yClientsId).filter(Boolean) || [];
      setSelectedUnitIds(unitIds as number[]);
      setAlreadySelectedUnitIds(unitIds as number[]);
    },
  });

  const units = organizationData?.attachedOrganization?.yClientsOrganization?.yClientsOrganizationUnits || void 0;
  const yClientsOrganizationId = organizationData?.attachedOrganization?.yClientsOrganization?.id || void 0;

  /* Процесс привязки */

  const [
    attachYClientsOrganization,
    { loading: attachYClientsOrganizationLoading },
  ] = useAttachYClientsOrganizationMutation();

  const onNextHandler = useCallback(() => {
    if (typeof yClientsOrganizationId === 'number' && selectedUnitIds.length > 0) {
      attachYClientsOrganization({
        variables: { yClientsOrganizationId, yClientsOrganizationUnitIds: selectedUnitIds },
      })
        .then(({ data }) => {
          if (data) {
            setAttachedUnitIds(data.attachYClientsOrganization.units.map((unit) => unit.id) || null);
            onBack();
            openSnackbar(
              <SuccessSnackbar text={L.t('attach_ycl_organization_units_submit_edit_success')} onClose={() => null} />,
            );
          }
        })
        .catch(() => {
          openSnackbar(
            <ErrorRetrySnackbar text={L.t('attach_ycl_organization_units_submit_edit_error')} onClose={() => null} />,
          );
        });
    }
  }, [attachYClientsOrganization, setAttachedUnitIds, onBack, openSnackbar, yClientsOrganizationId, selectedUnitIds]);

  /* Поиск */

  const [searchValue, setSearchValue] = useState('');

  const foundUnits = useMemo(() => filterUnitBySearchValue(units || [], searchValue), [searchValue, units]);

  /* Рендер */

  const HeaderLeftButton = <PanelHeaderClose onClick={onBack}>{L.t('cancel')}</PanelHeaderClose>;

  return (
    <YClientsUnitsSelectPanel
      id={panelId}
      onNext={onNextHandler}
      onNextLoading={attachYClientsOrganizationLoading}
      onNextDisabled={isAllSelectedUnitsNotAlreadySelected}
      headerLeft={HeaderLeftButton}
      headerTitleText={L.t('attach_ycl_organization_units_title_edit')}
      nextButtonText={L.t('attach_ycl_organization_units_submit_edit')}
      selectedUnitIds={selectedUnitIds}
      setSelectedUnitIds={setSelectedUnitIds}
      units={foundUnits}
      unitsLoading={organizationLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      unitsError={Boolean(organizationError)}
    />
  );
});

export default YClientsOrganizationUnitsEditPanel;
