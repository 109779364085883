import LoginPanelMobile from './LoginPanelMobile';
import LoginPanelDesktop from './LoginPanelDesktop';
import { getPlatformComponent } from '@overrided-vkui';

export const APP_INFO_URL = 'https://vk.com/@adminsclub-onlain-zapis';
export const YCLIENT_MAIN_URL =
  'https://yclients.com/?utm_source=vk&utm_medium=partnership&utm_campaign=adminsclub&utm_content=adminsclub-onlain-zapis'; // eslint-disable-line
export const YCLIENTS_RESET_URL = 'https://yclients.com/signin/reset';
export const YCLIENTS_REGISTRATION_URL =
  'https://yclients.com/onboarding/first?utm_source=vkontakte&utm_medium=partnership&utm_campaign=miniapp';

export type LoginData = {
  login: string;
  password: string;
};

export interface LoginPanelProps {
  id: string;
  onNext(loginData: LoginData): void;
  onNextLoading: boolean;
}

export default getPlatformComponent<LoginPanelProps>({ mobile: LoginPanelMobile, desktop: LoginPanelDesktop });
