import React, { memo, useState, useMemo, useCallback } from 'react';
import { AttachedOrganizationUnit } from 'src/types/yclients';
import OrganizationPanelComponent from '../panelComponents/OrganizationPanel';
import CitySelectModalPage, { City } from '../panelComponents/CitySelectModalPage';
import { filterUnitBySearchValue, filterByTitle } from 'src/utils/filter';
import { OverlayModalPageWrapper } from '@overrided-vkui';
import { RootRoute } from 'src/router';
import { useRouteNode } from 'react-router5';
import useSearchQuery from 'src/hooks/useSearchQuery';

interface OrganizationPanelProps {
  id: string;
  routeName: RootRoute;
  units: Array<AttachedOrganizationUnit & { coords: { lat: number; lng: number } }>;
  openCatalog(): void;
  openUnit(unitId: number): void;
}

const SELECTED_CITY_ID_ROUTE_PARAM = 'cityId';
const SELECT_CITY_MODAL_ROUTE_PARAM = 'cityModal';
const UNITS_SEARCH_ROUTE_PARAM = 'q';

const OrganizationPanel = memo((props: OrganizationPanelProps) => {
  const { id: panelId, routeName, units, openCatalog, openUnit } = props;

  const { route, router } = useRouteNode(routeName);

  /* Список городов всех филиалов */

  const cities = useMemo(() => {
    const cityByIds: Record<number, City> = {};

    units.forEach((unit) => {
      if (unit.city.id in cityByIds) {
        cityByIds[unit.city.id].unitsCount = (cityByIds[unit.city.id].unitsCount || 0) + 1;
      } else {
        cityByIds[unit.city.id] = { ...unit.city, unitsCount: 1 };
      }
    });

    return Object.values(cityByIds);
  }, [units]);

  /* Модалка с выбором городов и поиск в ней */

  const cityModalOpened = Boolean(route.params[SELECT_CITY_MODAL_ROUTE_PARAM]);
  const setCityModalOpened = useCallback(
    (opened: boolean) => {
      if (cityModalOpened === opened) return;

      if (opened) {
        router.navigate(route.name, { ...route.params, [SELECT_CITY_MODAL_ROUTE_PARAM]: 1 });
      } else {
        window.history.back();
      }
    },
    [route, router, cityModalOpened],
  );

  const [citySearchValue, setCitySearchValue] = useState('');

  const foundCities = useMemo(() => filterByTitle(cities, citySearchValue), [cities, citySearchValue]);

  /* Выбранный город и филиалы в нем */

  const selectedCityId = Number(route.params[SELECTED_CITY_ID_ROUTE_PARAM]) || void 0;
  const setSelectedCityId = useCallback(
    (cityId: number) => {
      router.navigate(
        route.name,
        { ...route.params, [SELECTED_CITY_ID_ROUTE_PARAM]: cityId, [SELECT_CITY_MODAL_ROUTE_PARAM]: void 0 },
        { replace: true },
      );
    },
    [route, router],
  );

  const selectedCity = useMemo((): City | undefined => {
    return cities.find((city) => city.id === selectedCityId) || cities[0] || void 0;
  }, [cities, selectedCityId]);

  const selectedCityUnits = useMemo(() => {
    return selectedCity ? units.filter((unit) => unit.city.id === selectedCity.id) : units;
  }, [selectedCity, units]);

  /* Поиск по филиалам в городе */

  const [searchValue, setSearchValue] = useSearchQuery(routeName, UNITS_SEARCH_ROUTE_PARAM);

  const foundSelectedCityUnits = useMemo(() => filterUnitBySearchValue(selectedCityUnits, searchValue), [
    searchValue,
    selectedCityUnits,
  ]);

  /* Рендер */

  return (
    <>
      <OrganizationPanelComponent
        id={panelId}
        openCatalog={openCatalog}
        openUnit={openUnit}
        units={foundSelectedCityUnits}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        cityTitle={selectedCity?.title || ''}
        onCityTitleClick={
          cities.length > 1
            ? () => {
                setCitySearchValue('');
                setCityModalOpened(true);
              }
            : void 0
        }
      />
      <OverlayModalPageWrapper show={cityModalOpened}>
        <CitySelectModalPage
          id={`${panelId}-modal`}
          searchValue={citySearchValue}
          setSearchValue={setCitySearchValue}
          cities={foundCities}
          onClose={() => setCityModalOpened(false)}
          selectedCityId={selectedCity?.id}
          onSelectCity={(cityId) => {
            setSelectedCityId(cityId);
            setSearchValue('');
          }}
        />
      </OverlayModalPageWrapper>
    </>
  );
});

export default OrganizationPanel;
