import React, { memo } from 'react';
import { OverlayPopoutWrapper } from '..';

function withPopoutOverlayWrapper<T>(Component: React.FC<T>): React.FC<T & { show: boolean }> {
  return memo(({ show, ...modalPageProps }) => (
    <OverlayPopoutWrapper show={show}>
      <Component {...(modalPageProps as T)} />
    </OverlayPopoutWrapper>
  ));
}

export default withPopoutOverlayWrapper;
