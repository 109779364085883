import React, { memo, useMemo, useState } from 'react';
import OrganizationUnitsPanelComponent from '../panelComponents/OrganizationUnitsPanel';
import { AttachedOrganization } from 'src/types/yclients';
import { filterUnitBySearchValue } from '../../utils/filter';

interface OrganizationUnitsPanelProps {
  id: string;
  organization: AttachedOrganization;
  openUnitsEdit(): void;
  openUnit(unitId: number): void;
  onBack(): void;
}

const OrganizationUnitsPanel = memo((props: OrganizationUnitsPanelProps) => {
  const { id: panelId, onBack, openUnit, openUnitsEdit, organization } = props;

  /* Поиск */

  const [searchValue, setSearchValue] = useState('');

  const foundUnits = useMemo(() => filterUnitBySearchValue(organization.units, searchValue), [
    searchValue,
    organization,
  ]);

  /* Рендер */

  return (
    <OrganizationUnitsPanelComponent
      id={panelId}
      onBack={onBack}
      openUnit={openUnit}
      openUnitsEdit={openUnitsEdit}
      organization={{
        ...organization,
        units: foundUnits,
      }}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
});

export default OrganizationUnitsPanel;
