import React, { memo, useState, useCallback } from 'react';
import { useActions } from 'src/hooks/useActions';
import { organizationActions } from 'src/redux/reducers/organization';
import {
  AccessTokenStatus,
  WidgetVariant,
  useDetachVkGroupOrganizationMutation,
  GqlErrorCode,
} from 'src/gql/generated/types';
import { useSnackbar, ScreenSpinner, OverlayPopoutWrapper } from '@overrided-vkui';
import ErrorRetrySnackbar from 'src/components/atomic/snackbars/ErrorRetrySnackbar';
import { widgetThrottleDelay } from 'src/utils/widgetThrottleDelay';
import OrganizationSettingsPanelComponent from '../panelComponents/OrganizationSettingsPanel';
import OrganizationLogoutConfirmAlert from '../panelComponents/OrganizationLogoutConfirmAlert';
import { AttachedOrganization } from 'src/types/yclients';
import useSetVkGroupOrganizationToken from 'src/hooks/useSetVkGroupOrganizationToken';
import { rewriteVkGroupAccessTokenStatus } from 'src/utils/apolloCache';
import { getGqlErrorCode } from 'src/types/gql';
import { L } from 'src/lang/L';
import { tapticNotification } from 'src/utils/taptic';

interface OrganizationSettingsPanelProps {
  id: string;
  openWidgetSettings(): void;
  openActionButtonSettings(): void;
  openOrganizationUnits(): void;
  openUnit(unitId: number): void;
  openRegister(): void;
  organization: AttachedOrganization & {
    isVkGroupWidgetEnabled: boolean;
    isVkGroupActionButtonEnabled: boolean;
    vkGroupAccessTokenStatus: AccessTokenStatus;
  };
  widgetVariant?: WidgetVariant;
  isSingleUnitYClientsOrganization: boolean;
}

const OrganizationSettingsPanel = memo((props: OrganizationSettingsPanelProps) => {
  const {
    openWidgetSettings,
    openActionButtonSettings,
    openOrganizationUnits,
    openRegister,
    openUnit,
    id: panelId,
    organization,
    widgetVariant,
    isSingleUnitYClientsOrganization,
  } = props;

  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const openSnackbar = useSnackbar();

  const setAttachedUnitIds = useActions(organizationActions.setAttachedUnitIds);
  const setVkGroupOrganizationToken = useSetVkGroupOrganizationToken();

  const [detachVkGroupOrganizationUnit, { client: apolloClient }] = useDetachVkGroupOrganizationMutation();

  const onLogoutHandler = useCallback(async () => {
    if (!organization) return;

    /**
     * Запрашиваем токен сообщества, если его нет, чтобы
     * удалить виджет и кнопку действия (если они установлены)
     */

    if (
      organization.vkGroupAccessTokenStatus !== AccessTokenStatus.Exists &&
      (organization.isVkGroupWidgetEnabled || organization.isVkGroupActionButtonEnabled)
    ) {
      const accesstToken = await setVkGroupOrganizationToken().catch((e) => {
        openSnackbar(<ErrorRetrySnackbar text={e?.message || L.t('fetch_error')} onClose={() => null} />);
        return null;
      });

      if (!accesstToken) return;

      rewriteVkGroupAccessTokenStatus(apolloClient.cache, AccessTokenStatus.Exists);
    }

    /* Если с токеном все хорошо, показываем алерт с подтверждением выхода из профиля */

    setShowConfirm(true);
  }, [openSnackbar, organization, apolloClient, setVkGroupOrganizationToken]);

  const onLogoutCancelHandler = useCallback(() => setShowConfirm(false), []);

  const onLogoutConfirmHandler = useCallback(async () => {
    setShowConfirm(false);

    if (!organization) return;

    try {
      setLoading(true);

      if (organization.isVkGroupWidgetEnabled) {
        await widgetThrottleDelay.delay();
      }

      /* Отвязываем организацию */

      await detachVkGroupOrganizationUnit()
        .then(() => {
          widgetThrottleDelay.registerDelayPoint();
          apolloClient.clearStore();
          setAttachedUnitIds(null);
          tapticNotification('success');
          openRegister();
        })
        .catch((e) => {
          if (getGqlErrorCode(e) === GqlErrorCode.WidgetTokenInvalidated) {
            rewriteVkGroupAccessTokenStatus(apolloClient.cache, AccessTokenStatus.Invalid);
          }

          openSnackbar(<ErrorRetrySnackbar text={L.t('organization_logout_error')} onClose={() => null} />);
        });
    } finally {
      setLoading(false);
    }
  }, [detachVkGroupOrganizationUnit, openSnackbar, organization, openRegister, setAttachedUnitIds, apolloClient]);

  return (
    <>
      <OrganizationSettingsPanelComponent
        id={panelId}
        onLogout={onLogoutHandler}
        openWidgetSettings={openWidgetSettings}
        openActionButtonSettings={openActionButtonSettings}
        openOrganizationUnits={openOrganizationUnits}
        openUnit={openUnit}
        organization={organization}
        widgetVariant={widgetVariant}
        isSingleUnitYClientsOrganization={isSingleUnitYClientsOrganization}
      />
      <OverlayPopoutWrapper show={showConfirm}>
        <OrganizationLogoutConfirmAlert onCancel={onLogoutCancelHandler} onLogout={onLogoutConfirmHandler} />
      </OverlayPopoutWrapper>
      <OverlayPopoutWrapper show={loading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </>
  );
});

export default OrganizationSettingsPanel;
