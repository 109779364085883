import React, { memo } from 'react';
import { Spinner } from '@vkontakte/vkui';
import CenteredLayout from '../atomic/CenteredLayout';

/**
 * Вью которая отображается в случае, когда приложение загружается.
 * @type {React.NamedExoticComponent<object>}
 */
export const AppLoadingView = memo(() => (
  <CenteredLayout>
    <Spinner size="large" style={{ color: 'var(--activity_indicator_tint)' }} />
  </CenteredLayout>
));
