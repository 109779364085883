import { formatCityAddress } from './formatter';

export function filterUnitBySearchValue<T extends { title: string; city: { title: string }; address: string }>(
  units: T[],
  searchValue: string,
) {
  const formattedSearchValue = searchValue.toLocaleLowerCase().trim();

  return units.filter((unit) => {
    const key = unit.title + ' / ' + formatCityAddress(unit.city.title, unit.address);
    return key.toLocaleLowerCase().indexOf(formattedSearchValue) !== -1;
  });
}

export function filterByTitle<T extends { title: string }>(items: T[], searchValue: string) {
  const formattedSearchValue = searchValue.toLocaleLowerCase().trim();

  return items.filter((item) => {
    return item.title.toLocaleLowerCase().indexOf(formattedSearchValue) !== -1;
  });
}

export function filterByName<T extends { name: string }>(items: T[], searchValue: string) {
  const formattedSearchValue = searchValue.toLocaleLowerCase().trim();

  return items.filter((item) => {
    return item.name.toLocaleLowerCase().indexOf(formattedSearchValue) !== -1;
  });
}
