import unionize, { ofType, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';

export type OrganizationReducerState = {
  attachedUnitIds: number[] | null;
};

export const organizationActions = unionize(
  {
    setAttachedUnitIds: ofType<number[] | null>(),
  },
  unionizeConfig,
);

type OrganizationAction = UnionOf<typeof organizationActions>;

const initialState: OrganizationReducerState = {
  attachedUnitIds: null,
};

/**
 * @param {OrganizationUnitReducerState} state
 * @returns {unknown}
 */
export function organizationReducer(state: OrganizationReducerState = initialState, action: OrganizationAction) {
  return organizationActions.match<OrganizationReducerState>(action, {
    setAttachedUnitIds: (attachedUnitIds) => ({ ...state, attachedUnitIds }),
    default: () => state,
  });
}
