import React, { memo, useCallback } from 'react';
import { Button, Text, Panel, Avatar, Title, Input, Separator } from '@overrided-vkui';
import yClientsLogoUrl from 'src/assets/YCLIENTS.svg';
import FormLayout from '../../atomic/vkui/FormLayout';
import { L } from 'src/lang/L';
import { makeStyles } from '@material-ui/styles';
import { LoginData, LoginPanelProps, YCLIENTS_REGISTRATION_URL, YCLIENTS_RESET_URL } from './index';

const useStyles = makeStyles({
  root: {
    width: 360,
  },
  logo: {
    margin: '8px auto',
    display: 'inline-block',
    '& .Avatar__img, & .Avatar__shadow': {
      borderRadius: '12px!important',
    },
  },
  title: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 500,
    margin: '4px 0 8px',
  },
  form: {
    width: '100%',
    '& .FormLayout__row': {
      paddingBottom: 0,
    },
    '& .FormLayout__row .FormField,& .FormLayout__row .Button': {
      margin: 0,
      width: '100%',
    },
  },
  forgotPasswordLink: {
    display: 'block',
  },
  noAccountLabel: {
    textAlign: 'center',
    color: 'var(--text_secondary)',
    marginBottom: 20,
    justifyContent: 'center',
  },
  separator: {
    margin: '16px 0 24px',
  },
});

const LoginPanelDesktop = memo((props: LoginPanelProps) => {
  const { id: panelId, onNext, onNextLoading } = props;

  const mc = useStyles();

  const onSubmitHandler = useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();

      const formEl = e.target as HTMLFormElement;
      const { login, password } = Object.fromEntries(new FormData(formEl));

      onNext({ login, password } as LoginData);
    },
    [onNext],
  );

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Content center>
        <div className={mc.root}>
          <Avatar src={yClientsLogoUrl} size={56} mode="app" className={mc.logo} />
          <Title weight="bold" level="1" className={mc.title}>
            {L.t('register_ycl_title')}
          </Title>
          <FormLayout onSubmit={onSubmitHandler} className={mc.form}>
            <Input name="login" placeholder={L.t('register_ycl_input_top_login')} />
            <Input name="password" type="password" placeholder={L.t('register_ycl_input_top_password')} />
            <Button size="xl" disabled={onNextLoading}>
              {L.t('register_ycl_button_submit')}
            </Button>
            <Button
              size="xl"
              mode="tertiary"
              href={YCLIENTS_RESET_URL}
              target="_blank"
              className={mc.forgotPasswordLink}
            >
              {L.t('register_ycl_button_forgot_password')}
            </Button>
          </FormLayout>
          <Separator wide className={mc.separator} />
          <Text weight="medium" className={mc.noAccountLabel}>
            {L.t('register_ycl_no_account_label')}
          </Text>
          <Button size="xl" mode="secondary" type="button" href={YCLIENTS_REGISTRATION_URL} target="_blank">
            {L.t('register_ycl_button_register')}
          </Button>
        </div>
      </Panel.Content>
    </Panel>
  );
});

export default LoginPanelDesktop;
