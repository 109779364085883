import React, { memo, useCallback, useMemo } from 'react';
import { Panel, Placeholder, PanelHeaderBack, Button, OverlayPopoutWrapper } from '@overrided-vkui';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import { L } from 'src/lang/L';
import { FixedLayout, ScreenSpinner } from '@vkontakte/vkui';
import FooterButtons from '../atomic/FooterButtons';
import DatePickerSubhead from 'src/components/atomic/staff-datetime/DatePickerSubhead';
import DatetimeGroup from 'src/components/atomic/staff-datetime/DatetimeGroup';
import { tapticSelectionChanged } from 'src/utils/taptic';
import PhoneBadge from '../atomic/PhoneBadge';
import { getIntervalDatetimes } from 'src/utils/selectors';

interface SelectDateSlotPanelProps {
  id: string;
  availableDatesLoading?: boolean;
  availableDatesError?: boolean;
  availableDates?: string[];
  selectedDate?: string;
  startDate: string;
  datesCountMin: number;
  selectDate(date: string): void;
  selectedDateSlotsLoading?: boolean;
  selectedDateSlotsError?: boolean;
  selectedDateSlots?: string[];
  showPhoneBadge?: boolean;
  selectedSlot?: string;
  selectSlot(slot?: string): void;
  onBack(): void;
  onNext(): void;
  onNextLoading: boolean;
}

const SelectDateSlotPanel = memo((props: SelectDateSlotPanelProps) => {
  const {
    id: panelId,
    startDate,
    availableDatesLoading,
    selectedDate,
    datesCountMin,
    selectDate,
    availableDatesError,
    availableDates,
    showPhoneBadge,
    selectedDateSlots,
    selectedDateSlotsError,
    selectedDateSlotsLoading,
    selectedSlot,
    selectSlot,
    onBack,
    onNext,
    onNextLoading,
  } = props;

  /* Делим слоты по времени суток */

  const sortedSelectedDateSlots = useMemo(
    () =>
      selectedDateSlots && selectedDate
        ? {
            morning: getIntervalDatetimes(selectedDateSlots, selectedDate, null, '12:00'),
            day: getIntervalDatetimes(selectedDateSlots, selectedDate, '12:00', '18:00'),
            evening: getIntervalDatetimes(selectedDateSlots, selectedDate, '18:00', null),
          }
        : void 0,
    [selectedDateSlots, selectedDate],
  );

  const hasSelectedDateSlots =
    sortedSelectedDateSlots &&
    Boolean(
      sortedSelectedDateSlots.day.length > 0 ||
        sortedSelectedDateSlots.evening.length > 0 ||
        sortedSelectedDateSlots.morning.length > 0,
    );

  /* Обработчики */

  const toggleSelectedSlot = useCallback(
    (slot: string) => {
      tapticSelectionChanged();
      selectSlot(selectedSlot === slot ? void 0 : slot);
    },
    [selectedSlot, selectSlot],
  );

  const onDateClickHandler = useCallback(
    (date: string) => {
      tapticSelectionChanged();
      selectDate(date);
    },
    [selectDate],
  );

  /* Рендер */

  return (
    <Panel id={panelId}>
      <Panel.Header left={<PanelHeaderBack onClick={onBack} />} separator={false}>
        {L.t('make_record_step_slots_header')}
      </Panel.Header>
      <FixedLayout vertical="top">
        <DatePickerSubhead
          dateFrom={startDate}
          dateCountMin={datesCountMin}
          selectedDate={selectedDate}
          onDateClick={onDateClickHandler}
          availableDates={availableDates || []}
          disabled={!availableDates}
        />
      </FixedLayout>
      <div style={{ height: 48 }} />
      {availableDates ? (
        <>
          {sortedSelectedDateSlots && !hasSelectedDateSlots ? (
            <Panel.Content center>
              <Placeholder
                header={L.t('make_record_step_slots_date_slots_not_found_title')}
                action={
                  availableDates[0] && (
                    <Button mode="tertiary" size="m" onClick={() => availableDates[0] && selectDate(availableDates[0])}>
                      {L.t('closest_staff_available_date', { date: L.format.date(availableDates[0]) })}
                    </Button>
                  )
                }
              >
                {L.t('make_record_step_slots_date_slots_not_found_text')}
              </Placeholder>
            </Panel.Content>
          ) : sortedSelectedDateSlots ? (
            <Panel.Content>
              {sortedSelectedDateSlots.morning.length > 0 && (
                <DatetimeGroup
                  title={L.t('day_interval_morning')}
                  datetimes={sortedSelectedDateSlots.morning}
                  onSeanceClick={toggleSelectedSlot}
                  activeSeanceDatetime={selectedSlot}
                />
              )}
              {sortedSelectedDateSlots.day.length > 0 && (
                <DatetimeGroup
                  title={L.t('day_interval_day')}
                  datetimes={sortedSelectedDateSlots.day}
                  onSeanceClick={toggleSelectedSlot}
                  activeSeanceDatetime={selectedSlot}
                />
              )}
              {sortedSelectedDateSlots.evening.length > 0 && (
                <DatetimeGroup
                  title={L.t('day_interval_evening')}
                  datetimes={sortedSelectedDateSlots.evening}
                  onSeanceClick={toggleSelectedSlot}
                  activeSeanceDatetime={selectedSlot}
                />
              )}
            </Panel.Content>
          ) : selectedDateSlotsLoading ? (
            <Panel.Content center>
              <StatusPlaceholder status="loading" />
            </Panel.Content>
          ) : selectedDateSlotsError ? (
            <Panel.Content center>
              <StatusPlaceholder status="error" errorText={L.t('make_record_step_slots_date_slots_fetch_error')} />
            </Panel.Content>
          ) : null}
          <Panel.Footer fixed>
            <FooterButtons info={showPhoneBadge && <PhoneBadge />}>
              <Button disabled={!selectedSlot || onNextLoading} onClick={onNext}>
                {L.t('make_record_step_slots_button_select')}
              </Button>
            </FooterButtons>
          </Panel.Footer>
        </>
      ) : availableDatesLoading ? (
        <Panel.Content center>
          <StatusPlaceholder status="loading" />
        </Panel.Content>
      ) : availableDatesError ? (
        <Panel.Content center>
          <StatusPlaceholder status="error" errorText={L.t('make_record_step_slots_dates_fetch_error')} />
        </Panel.Content>
      ) : null}
      <OverlayPopoutWrapper show={onNextLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </Panel>
  );
});

export default SelectDateSlotPanel;
