import React, { memo } from 'react';
import { Avatar, SimpleCell } from '@overrided-vkui';
import { Checkbox } from '@vkontakte/vkui';
import { formatCityAddress } from 'src/utils/formatter';
import { makeStyles } from '@material-ui/styles';
import { YClientsOrganizationUnitCheckboxProps } from '.';

const useStyles = makeStyles({
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .Checkbox__icon': {
      alignSelf: 'center',
      marginRight: 2,
    },
  },
});

const YClientsOrganizationUnitMobile = memo((props: YClientsOrganizationUnitCheckboxProps) => {
  const { title, logoUrl, address, city, checked, onClick } = props;

  return (
    <Checkbox checked={checked} onClick={onClick} onChange={() => null} className={useStyles().checkbox}>
      <SimpleCell
        disabled
        before={<Avatar size={72} src={logoUrl} mode="image" />}
        description={formatCityAddress(city, address)}
      >
        {title}
      </SimpleCell>
    </Checkbox>
  );
});

export default YClientsOrganizationUnitMobile;
