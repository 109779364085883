import React, { PureComponent, ReactNode } from 'react';

import { AppLoadingView } from '../views/AppLoadingView';
import AppCrashView from '../views/AppCrashView';
import App from '../App';
import { ApolloProvider, NormalizedCacheObject, ApolloClient } from '@apollo/client';
import GlobalStyleSheet from '../tools/GlobalStyleSheet';
import { Provider as StoreProvider } from 'react-redux';
import ServicePanel from '../tools/ServicePanel';
import StatusBar from '../tools/StatusBar';
import ThemeProvider from '../tools/ThemeProvider';

import createReduxStore from '../../redux';
import vkBridge, { VKBridgeSubscribeHandler } from '@vkontakte/vk-bridge';
import { getStorage, setStorageValue } from '../../utils/storage';
import { createApolloClient } from './utils';
import { getLaunchParams } from '../../utils/launch-params';
import { deviceActions } from '../../redux/reducers/device';
import { appConfigActions } from '../../redux/reducers/app-config';

import { Store } from 'redux';
import { Config } from '../../config';
import { ReduxState } from '../../redux/types';
import { Insets } from '@vkontakte/vk-bridge';
import { LaunchParams, StorageField } from '../../types';
import { OverlayProvider } from '@overrided-vkui';
import RouterProvider from '../tools/RouterProvider';
import ConfigProvider from '../tools/ConfigProvider';
import {
  GetVkGroupAttachedOrganizationDocument,
  GetVkGroupAttachedOrganizationQuery,
  GetVkGroupAttachedOrganizationQueryVariables,
  GetCategoryGroupsQuery,
  GetCategoryGroupsDocument,
} from 'src/gql/generated/types';
import * as Sentry from '@sentry/browser';
import { initL, L } from 'src/lang/L';
import { importLocale } from 'src/lang/importLocale';
import { userActions } from 'src/redux/reducers/user';
import { storageActions } from 'src/redux/reducers/storage';
import { initStatEventInstance } from 'src/utils/statEvents';
import { canManageVkGroupApp } from 'src/utils/canManageVkGroupApp';
import { isDevelopment, isProduction } from 'src/utils/environment';

interface State {
  loading: boolean;
  error: string | null;
}

interface Props {
  /**
   * Environments-based config
   */
  config: Config;
  /**
   * Application launch parameters
   */
  launchParams: string;
  /**
   * Ссылка инициализации приложения
   */
  url: string;
  /**
   * Device insets
   */
  insets: Insets;
}

/**
 * Root application component. Everything application requires for showing
 * first screen is being loaded here.
 */
export class AppRoot extends PureComponent<Props, State> {
  /**
   * ApolloClient used to send requests
   * @type {ApolloClient<any>}
   */
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;

  private readonly launchParams: LaunchParams;

  /**
   * Redux store
   */
  private store: Store<ReduxState>;

  public state: Readonly<State> = {
    loading: true,
    error: null,
  };

  public constructor(props: Readonly<Props>) {
    super(props);

    const { launchParams, insets, config } = props;

    this.launchParams = getLaunchParams(launchParams);

    // Create initial redux store
    this.store = createReduxStore({
      config,
      launchParams: this.launchParams,
      device: {
        insets,
        currentInsets: insets,
        currentStatusBarMode: 'default',
        customStatusBarStyle: { color: '#fff', style: 'dark' },
      },
    });

    // Create Apollo client
    this.apolloClient = createApolloClient({
      httpURI: config.gqlHttpUrl,
      launchParams,
    });

    // Binding
    this.init = this.init.bind(this);
  }

  public async componentDidMount() {
    // Поддерживаем uri-encoded хэш-роутинг, который может быть указан в сниппете ВК,
    // опубликованном через андроид-приложение (BUG)
    this.replaceToURIDecodedHash();
    window.addEventListener('popstate', this.replaceToURIDecodedHash);

    // When component did mount, we are waiting for application config from
    // bridge and add event listener
    vkBridge.subscribe(this.onVKBridgeEvent);

    // Notify native application, initialization done. It will make native
    // application hide loader and display this application.
    vkBridge.send('VKWebAppInit');

    // Init application
    this.init();
  }

  public componentDidCatch(error: Error) {
    // Catch error if it did not happen before
    this.setState({ error: error.message });
    Sentry.captureException(error);
  }

  public componentWillUnmount() {
    window.removeEventListener('popstate', this.replaceToURIDecodedHash);

    vkBridge.unsubscribe(this.onVKBridgeEvent);
  }

  public render() {
    const { loading, error } = this.state;
    let content: ReactNode = null;

    // Display loader
    if (loading) {
      content = <AppLoadingView />;
    }
    // Display error
    else if (error) {
      content = <AppCrashView onRestart={this.init} error={error} />;
    }
    // Display application
    else {
      content = (
        <RouterProvider>
          <ApolloProvider client={this.apolloClient}>
            <ServicePanel onRestart={this.init} />
            <App />
          </ApolloProvider>
        </RouterProvider>
      );
    }

    return (
      <StoreProvider store={this.store}>
        <ThemeProvider>
          <GlobalStyleSheet />
          <ConfigProvider>
            <OverlayProvider>
              <StatusBar />
              {content}
            </OverlayProvider>
          </ConfigProvider>
        </ThemeProvider>
      </StoreProvider>
    );
  }

  private replaceToURIDecodedHash = () => {
    window.history.replaceState(null, '', decodeURIComponent(window.location.hash));
  };

  /**
   * Checks if event is VKWebAppUpdateConfig to know application config
   * sent from bridge
   * @param {VKBridgeEvent<ReceiveMethodName>} event
   */
  private onVKBridgeEvent: VKBridgeSubscribeHandler = (event) => {
    switch (event.detail.type) {
      case 'VKWebAppAllowNotificationsResult':
        this.store.dispatch(userActions.setAreNotificationsEnabled(true));
        return;
      case 'VKWebAppDenyNotificationsResult':
        this.store.dispatch(userActions.setAreNotificationsEnabled(false));
        return;
      case 'VKWebAppUpdateInsets':
        this.store.dispatch(deviceActions.setCurrentInsets(event.detail.data.insets));
        return;
      case 'VKWebAppUpdateConfig':
        this.store.dispatch(appConfigActions.updateConfig(event.detail.data));
        return;
    }
  };

  /**
   * Initializes application
   */
  private async init() {
    this.setState({ loading: true, error: null });

    try {
      const isVkGroupApp = Boolean(this.launchParams.groupId);
      const isVkGroupAppManager = canManageVkGroupApp(this.launchParams.viewerGroupRole);

      const [storage, attachedOrganization, accessToken, categoryGroups] = await Promise.all([
        /* Получаем значения, относящиеся к пользователю, записанные в vkBridge Storage */
        getStorage().catch(() => void 0),
        /* Пытаемся получить информацию о привязанной к группе ВК организации */
        isVkGroupApp
          ? this.apolloClient
              .query<GetVkGroupAttachedOrganizationQuery, GetVkGroupAttachedOrganizationQueryVariables>({
                query: GetVkGroupAttachedOrganizationDocument,
                variables: { withSettingsData: isVkGroupAppManager },
              })
              .then(({ data }) => data.attachedOrganization)
          : null,
        /* Получаем токен пользователя с пустым скоупом для получения общедоступной информации */
        vkBridge
          .send('VKWebAppGetAuthToken', { app_id: this.launchParams.appId, scope: '' })
          .then(({ access_token }) => access_token),
        /* Запоминаем информацию о структуре категорий */
        this.apolloClient
          .query<GetCategoryGroupsQuery>({ query: GetCategoryGroupsDocument })
          .then(({ data }) => data.organizationUnitCategoryGroups),
        /* Подключаем файл переводов (в режиме разработки - локальный) */
        initL({
          lng: this.launchParams.language,
          name: 'booking',
          translationsPrefix: 'vkui_hs_booking',
          source: isDevelopment ? 'fallback' : 'vk',
          fallback: () => importLocale(this.launchParams.language).catch(() => importLocale('ru')),
          debug: isDevelopment,
        }),
      ]);

      /* Записываем полученные данные в redux */

      const isFirstVisit = Boolean(storage && !storage[StorageField.ApplicationVisited]);

      this.store = createReduxStore({
        ...this.store.getState(),
        storage: storage || {},
        user: {
          geodata: void 0,
          phone: null,
          isFirstVisit,
          areNotificationsEnabled: this.launchParams.areNotificationsEnabled,
          accessToken: accessToken || '',
        },
        organization: {
          attachedUnitIds: attachedOrganization?.units.map((unit) => unit.id) || null,
        },
        app: {
          categoryGroups: categoryGroups || [],
        },
      });

      /* Отмечаем посещенность приложения пользователем */

      if (isFirstVisit) {
        await setStorageValue(StorageField.ApplicationVisited, true)
          .then(() => {
            this.store.dispatch(storageActions.memoize({ [StorageField.ApplicationVisited]: true }));
          })
          .catch(() => null);
      }

      /* Инициализируем метод отправки статистики (только на проде) */

      if (isProduction) {
        initStatEventInstance({
          accessToken: accessToken as string,
          appId: this.launchParams.appId,
          url: this.props.url,
          userId: this.launchParams.userId,
          vkPlatform: this.launchParams.platform,
        });
      }

      /* Определяем стартовый роут */

      const getSuggestedHashRoute = () => {
        if (!isVkGroupApp) {
          return isFirstVisit ? '#/onboard' : null;
        }

        if (!attachedOrganization) {
          return isVkGroupAppManager ? '#/register' : '#/organization';
        }

        if (isVkGroupAppManager) {
          const isYClientsOrganizationSynced = Boolean(attachedOrganization?.isYClientsOrganizationSynced);
          return isYClientsOrganizationSynced ? '#/organization/settings' : '#/relogin';
        }

        const isSingleUnitOrganization = attachedOrganization.units.length === 1;
        return isSingleUnitOrganization ? `#/unit/${attachedOrganization.units[0].id}` : '#/organization';
      };

      /* Если не задан начальный роутинг, то устанавливаем определенный роут */

      const hasInitialHashRoute = Boolean(window.location.hash);
      const suggestedHashRoute = getSuggestedHashRoute();

      if (!hasInitialHashRoute && suggestedHashRoute) {
        window.history.replaceState(null, '', suggestedHashRoute);
      }
    } catch (error) {
      this.setState({ error: error?.message || L.t('common:error_unknown'), loading: false });
      Sentry.captureException(error);
    } finally {
      this.setState({ loading: false });
    }
  }
}
