import React, { memo, useCallback } from 'react';
import SelectStaffPanel from '../panelComponents/SelectStaffPanel';
import { Staff } from '../../components/atomic/StaffItem';
import { L } from 'src/lang/L';

interface OrganizationUnitStepStaffProps {
  id: string;
  staff: Staff[];
  onNext(staff: Staff): void;
  onBack(): void;
}

const OrganizationUnitStepStaff = memo((props: OrganizationUnitStepStaffProps) => {
  const { staff, onBack, onNext, id: panelId } = props;

  const onStaffSelectHandler = useCallback(
    (staffId: number) => {
      const foundStaff = staff.find((staffItem) => staffItem.id === staffId);
      foundStaff && onNext(foundStaff);
    },
    [staff, onNext],
  );

  return (
    <SelectStaffPanel
      title={L.t('make_record_step_staff_title')}
      id={panelId}
      onBack={onBack}
      onStaffSelect={onStaffSelectHandler}
      staff={staff}
    />
  );
});

export default OrganizationUnitStepStaff;
