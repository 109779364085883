import React from 'react';
import { ModalPageHeader } from '@vkontakte/vkui';
import { ModalPageHeaderProps } from '@vkontakte/vkui/dist/components/ModalPageHeader/ModalPageHeader';
import styled from '@material-ui/styles/styled';

export default styled(ModalPageHeader)({
  position: 'relative',
  'body:not([scheme=space_gray]) &': {
    '--header_tint_alternate': 'var(--steel_gray_400)',
    '--separator_common': 'var(--steel_gray_80)',
  },
  '& .ModalPageHeader__left': {
    paddingLeft: 12,
    height: '100%',
  },
  '& .ModalPageHeader__right': {
    paddingRight: 12,
    height: '100%',
  },
  '& .ModalPageHeader__in': {
    height: 48,
    padding: 0,
  },
  '& .PanelHeaderButton': {
    fontSize: 15,
    cursor: 'pointer',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  '& .ModalPageHeader__content-in': {
    fontFamily: 'var(--font-common)',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 500,
  },
  '& .ModalPageHeader__content-in>*': {
    padding: '0 8px',
  },
  '& .ModalPageHeader__shadow': {
    opacity: 1,
    zIndex: 999,
    '&:after': {
      bottom: 0,
      top: 'initial',
      transform: 'none',
    },
  },
}) as React.FC<ModalPageHeaderProps>;
