import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { L } from 'src/lang/L';

dayjs.extend(utc);

/**
 * Возврщает дату в формате YYYY-MM-DD в UTC
 * @param date дата
 */
export const formatToUTCDate = (date: string): string => {
  return dayjs(date).utc().format('YYYY-MM-DD');
};

/**
 * Форматирует значение стоимости
 * @param price стоимость денег в рублях
 */
export const formatPrice = (price: number, priceMax?: number) => {
  const parts = price.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (parts[1]) {
    parts[1] = parts[1].substr(0, 2).padEnd(2, '0');
  }

  const priceLabel = L.t('price_format', {
    amount: parts.join(','),
    symbol: '₽',
  });

  return price !== priceMax ? L.t('price_from', { price: priceLabel }) : priceLabel;
};

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

/**
 * Форматирует длительность услуги
 * @param price длительность в секундах
 */
const formatDuration = (duration: number) => {
  const date = dayjs(duration * 1000).utc();

  if (duration < MINUTE) {
    return L.t('duration_seconds_short', { seconds: date.format('s') });
  } else if (duration < HOUR) {
    return L.t('duration_minutes_short', { minutes: date.format('m') });
  } else if (duration < DAY) {
    const minutes = L.t('duration_minutes_short', { minutes: date.format('m') });
    const hours = L.t('duration_hours_short', { hours: date.format('H') });

    return date.format('m') === '0' ? hours : `${hours} ${minutes}`;
  }

  return L.t('duration_days_short', { days: date.format('d') });
};

export const formatDurationRange = (minDuration: number, maxDuration: number) => {
  const durationLabel = formatDuration(maxDuration);

  return minDuration !== maxDuration ? L.t('duration_until', { duration: durationLabel }) : durationLabel;
};

/**
 * Фотрматирует годод и адрес в одну строку
 */
export const formatCityAddress = (city: string, address: string) => {
  if (!address) {
    return city;
  }

  if (address.toLocaleLowerCase().includes(city.toLocaleLowerCase())) {
    return address;
  }

  return `${city}, ${address}`;
};

/**
 * Возвращает строку с первой заглавной буквой
 * @param text строка
 */
export const capitalizeFirstLetter = (text: string): string => {
  if (text.length === 0) {
    return text;
  }

  return text[0].toUpperCase().concat(text.slice(1));
};
