import Headline, { HeadlineProps } from '@vkontakte/vkui/dist/components/Typography/Headline/Headline';
import styled from '@material-ui/styles/styled';

export default styled(Headline)({
  '&.Headline--w-regular': {
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 400,
  },
  '&.Headline--w-medium, &.Headline--w-semibold': {
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 500,
  },
}) as React.FC<HeadlineProps>;
