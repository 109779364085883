import React, { memo } from 'react';
import { Panel, Search, Placeholder, Button, Group, Header, Link, Separator } from '@overrided-vkui';
import { formatCityAddress } from 'src/utils/formatter';
import { L } from 'src/lang/L';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { OrganizationUnitStepMainPanelProps } from './index';
import UnitHeadSection from 'src/components/atomic/UnitHeadSection';
import ServiceCategorySelection from '../../atomic/ServiceCategorySelection';
import UserHorizontalList from '../../atomic/UserHorizontalList';
import Icon16Chevron from '@vkontakte/icons/dist/16/chevron';

const OrganizationUnitStepMainPanelDesktop = memo((props: OrganizationUnitStepMainPanelProps) => {
  const {
    id: panelId,
    unit,
    staff,
    leftButton,
    categoryServicesSearchValue,
    setCategoryServicesSearchValue,
    selectCategory,
    selectedCategoryId,
    selectedCategoryServices,
    selectedCategoryServicesLoading,
    selectedCategoryServicesError,
    refetchSelectedCategoryServices,
    selectedCategoryServiceId,
    selectCategoryService,
    openStaffStep,
    onNext,
    onStaffClick,
    onMoreInfo,
  } = props;

  const hasStaff = staff.length > 0;
  const hasCategories = unit.serviceCategories.length > 0;
  const isSearchMode = Boolean(categoryServicesSearchValue.trim());

  return (
    <Panel id={panelId}>
      <Panel.Header left={leftButton}>{unit.title}</Panel.Header>
      <UnitHeadSection
        logoUrl={unit.logoUrl || void 0}
        title={unit.title}
        address={formatCityAddress(unit.city.title, unit.address)}
        onMoreInfo={onMoreInfo}
      />
      <Search
        value={categoryServicesSearchValue}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        maxLength={255}
        onChange={(e) => setCategoryServicesSearchValue(e.target.value)}
      />
      {hasStaff && (
        <>
          <Group
            header={
              <Header
                aside={
                  !isSearchMode && (
                    <Link Component="span" onClick={openStaffStep}>
                      {L.t('make_record_step_services_section_staff_show_more')}
                      <Icon16Chevron
                        fill="var(--light_blue_300)"
                        height={12}
                        width={12}
                        style={{ verticalAlign: 'middle' }}
                      />
                    </Link>
                  )
                }
              >
                {L.t('make_record_step_services_section_staff')}
              </Header>
            }
          >
            <UserHorizontalList users={staff} onUserClick={onStaffClick} />
          </Group>
          <Separator wide style={{ marginTop: 12 }} />
        </>
      )}
      {hasCategories ? (
        <>
          <ServiceCategorySelection
            refetchSelectedCategoryServices={refetchSelectedCategoryServices}
            selectCategory={selectCategory}
            selectedCategoryServicesLoading={selectedCategoryServicesLoading}
            selectedCategoryServicesError={selectedCategoryServicesError}
            selectCategoryService={selectCategoryService}
            selectedCategoryServiceId={selectedCategoryServiceId}
            selectedCategoryServices={selectedCategoryServices}
            selectedServiceCategoryId={selectedCategoryId}
            serviceCategories={unit.serviceCategories}
          />
          <Panel.Footer fixed>
            <FooterButtons>
              <Button disabled={!selectedCategoryServiceId} onClick={onNext}>
                {L.t('make_record_step_slots_button_select')}
              </Button>
            </FooterButtons>
          </Panel.Footer>
        </>
      ) : (
        <Panel.Content center>
          <Placeholder>{L.t('make_record_step_services_empty')}</Placeholder>
        </Panel.Content>
      )}
    </Panel>
  );
});

export default OrganizationUnitStepMainPanelDesktop;
