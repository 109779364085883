import { GqlErrorCode } from '../gql/generated/types';

export function getGqlErrorCode(error: any): GqlErrorCode {
  const errorCode =
    error &&
    typeof error === 'object' &&
    Array.isArray(error.graphQLErrors) &&
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors[0].message;

  if (errorCode in GqlErrorCode) {
    return errorCode as GqlErrorCode;
  }

  return GqlErrorCode.NotFound;
}
