import AddToCommunityResultPanelMobile from './AddToCommunityResultPanelMobile';
import AddToCommunityResultPanelDesktop from './AddToCommunityResultPanelDesktop';
import { getPlatformComponent } from '@overrided-vkui';

export interface AddToCommunityResultPanelProps {
  id: string;
  groupId: number;
  appId: number;
  photoUrl?: string;
  onClose(): void;
}

export default getPlatformComponent<AddToCommunityResultPanelProps>({
  mobile: AddToCommunityResultPanelMobile,
  desktop: AddToCommunityResultPanelDesktop,
});
