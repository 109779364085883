import i18next, { TFunction } from 'i18next';
import { format, Format, INTERPOLATION_PREFIX, INTERPOLATION_SUFFIX } from './format';
import LJsx, { LJsxProps } from './LJsx';

export interface VKLang {
  id: number;
  code: string;
  keys: {
    [key: string]: any;
  };
  config: {
    id: number;
    numDel: string;
    numDelS: string;
    numDec: string;
    timeSys: string[];
    RTL: boolean;
    yearOffset: number;
    [key: string]: any;
  };
}

function fetchLangPack(lang: string, name: string): Promise<VKLang> {
  const encodedName = encodeURIComponent(name);
  const encodedLang = encodeURIComponent(lang);

  const langUrl = `https://vk.com/js/vkui_lang.js?name=${encodedName}&lang=${encodedLang}&format=json`;

  return fetch(langUrl).then((response) => response.json());
}

function getPluralForms(key: string, values: string[]): [string, string][] {
  switch (values.length) {
    case 0:
      return [];
    case 1:
      return [[key, values[0]]];
    case 2:
      return [
        [key, values[0]],
        [`${key}_plural`, values[1]],
      ];
    default:
      return values.map((value, index) => [`${key}_${index}`, value]);
  }
}

const COMMON_PREFIX = 'vkui_common';

enum LanguageNamespace {
  // Ключи из объекта config
  CONFIG = 'config',
  // Ключи из объекта keys
  KEYS = 'keys',
  // Ключи из объекта keys с основным префиксом для текущего проекта (указанным в mainPrefix)
  TRANSLATION = 'translation',
  // Ключи из объекта keys с общим vkui-префиксом (указанным в COMMON_PREFIX)
  COMMON = 'common',
}

interface ResourceLanguage {
  [namespace: string]: {
    [key: string]: any;
  };
}

function getNamespaceKey(key: string, translationsPrefix: string): [LanguageNamespace, string] | null {
  if (key.startsWith(translationsPrefix)) {
    return [LanguageNamespace.TRANSLATION, key.slice(translationsPrefix.length + 1)];
  }

  if (key.startsWith(COMMON_PREFIX)) {
    return [LanguageNamespace.COMMON, key.slice(COMMON_PREFIX.length + 1)];
  }

  return null;
}

const entities = [
  ['amp', '&'],
  ['apos', "'"],
  ['#x27', "'"],
  ['#x2F', '/'],
  ['#39', "'"],
  ['#47', '/'],
  ['lt', '<'],
  ['gt', '>'],
  ['nbsp', ' '],
  ['quot', '"'],
];

function decodeHTMLEntities(text: string) {
  for (let i = 0, max = entities.length; i < max; i++) {
    text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
  }

  return text;
}

function prepareVKLangPack(VKLangPack: VKLang, translationsPrefix: string): ResourceLanguage {
  const resource: ResourceLanguage = {};

  // VKLangPack.сonfig кладем в неймспейс CONFIG

  resource[LanguageNamespace.CONFIG] = VKLangPack.config;

  // Преобразуем html-коды ключей VKLangPack.keys

  for (const key in VKLangPack.keys) {
    const value = VKLangPack.keys[key];
    if (typeof value === 'string') {
      VKLangPack.keys[key] = decodeHTMLEntities(value);
    } else if (Array.isArray(value) && typeof value[0] === 'string') {
      value.forEach((innerValue, index) => {
        VKLangPack.keys[key][index] = decodeHTMLEntities(innerValue);
      });
    }
  }

  // VKLangPack.keys кладем в неймспейс KEYS

  resource[LanguageNamespace.KEYS] = VKLangPack.keys;

  // Также разбиваем ключи из VKLangPack.keys на TRANSLATION и COMMONS

  Object.entries(VKLangPack.keys).forEach(([key, value]) => {
    const nameSpaceKey = getNamespaceKey(key, translationsPrefix);
    if (nameSpaceKey) {
      const [ns, nsKey] = nameSpaceKey;
      resource[ns] = resource[ns] || {};
      resource[ns][nsKey] = value;
    }
  }, resource);

  // Для основного нэймспейса TRANSLATION подключаем склонения

  const translationResourceNamespace = resource[LanguageNamespace.TRANSLATION];

  if (translationResourceNamespace) {
    Object.entries(translationResourceNamespace).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const pluralForms = getPluralForms(key, value.slice(1));
        delete translationResourceNamespace[key];

        pluralForms.forEach(([newKey, newValue]) => {
          translationResourceNamespace[newKey] = newValue;
        });
      }
    });
  }

  return resource;
}

function prepareI18nLang(lng: string) {
  // для Украины есть два обозначения: ua и uk. Приводим к одному - uk,
  // т.к. i18n знает именно его правила плюрализации
  return lng === 'ua' ? 'uk' : lng;
}

interface L {
  lang: string;
  t: TFunction;
  format: Format;
  Jsx: React.FC<LJsxProps>;
}

export const L: L = {
  lang: 'dev',
  t: (arg: string | string[]) => (Array.isArray(arg) ? arg[arg.length - 1] : arg),
  format,
  Jsx: LJsx,
};

interface InitLOptions {
  /* Язык переводов */
  lng: string;
  /* Имя источника загрузки данных */
  name: string;
  /* Префикс, по которому лежат основные ключи */
  translationsPrefix: string;
  /**
   * Источник получения переводов
   * - vk - через платформу преводов ВК
   * - fallback - альтернативным способом с помощью fallback
   */
  source: 'vk' | 'fallback';
  /* Альтернатинвный способ получения данных */
  fallback?: (lng: string) => Promise<VKLang>;
  /* Дебаг сообщения в консоли */
  debug?: boolean;
}

export async function initL(options: InitLOptions): Promise<L> {
  const { lng, source, fallback, name, translationsPrefix, debug } = options;

  const getVKLangPack = {
    vk: () => {
      return fetchLangPack(lng, name).catch((e) => (fallback ? fallback(lng) : Promise.reject(e)));
    },
    fallback: () => {
      return fallback ? fallback(lng) : Promise.reject(new Error('Fallback action required.'));
    },
  };

  const VKLangPack = await getVKLangPack[source]();

  const i18nLang = prepareI18nLang(VKLangPack.code);

  return i18next
    .init({
      debug,
      lng: i18nLang,
      resources: {
        [i18nLang]: prepareVKLangPack(VKLangPack, translationsPrefix),
      },
      ns: Object.values(LanguageNamespace),
      interpolation: { prefix: INTERPOLATION_PREFIX, suffix: INTERPOLATION_SUFFIX },
    })
    .then((t) => {
      L.t = t;
      L.lang = i18nLang;
      return L;
    });
}
