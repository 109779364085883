/**
 * Возвращает промис, который резолвится через указанное время
 * @param ms время в миллисекундах
 */
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

/**
 * Возвращает промис, который резолвится не чаще 1 раза в указанный промежуток времени
 * @param timeoutMs промежуток времени в миллисекундах
 * @param action промис
 */
export const throttlePromise = <T extends (...args: any[]) => any>(timeoutMs: number, action: T): T => {
  let prevCall = -delay;

  const throttledAction = async (...args: any[]) => {
    const now = performance.now();
    const delta = now - prevCall;

    if (delta < timeoutMs) {
      await delay(timeoutMs - delta);
    }

    prevCall = performance.now();

    return action(...args);
  };

  return throttledAction as T;
};

// Кэш загрузки изображений
const loadImageCache: Record<string, Promise<HTMLImageElement>> = {};

// Возвращает объект изображения HTMLImageElement после его загрузки
export const loadImage = (src: string): Promise<HTMLImageElement> => {
  if (src in loadImageCache) {
    return loadImageCache[src];
  }

  loadImageCache[src] = new Promise((res, rej) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => res(img);
    img.onerror = rej;
    img.src = src;
  });

  return loadImageCache[src];
};
