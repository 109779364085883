import React, { memo, useCallback, useMemo, useState } from 'react';
import OrganizationWidgetSettingsPanelComponent from '../panelComponents/OrganizationWidgetSettingsPanel';
import {
  useGetVkGroupWidgetDataQuery,
  useSetVkGroupWidgetMutation,
  useUnsetVkGroupWidgetMutation,
  AccessTokenStatus,
  GqlErrorCode,
} from 'src/gql/generated/types';
import useSetVkGroupOrganizationToken from 'src/hooks/useSetVkGroupOrganizationToken';
import ErrorRetrySnackbar from 'src/components/atomic/snackbars/ErrorRetrySnackbar';
import { useSnackbar } from '@overrided-vkui';
import { widgetThrottleDelay } from 'src/utils/widgetThrottleDelay';
import { getGqlErrorCode } from 'src/types/gql';
import { rewriteVkGroupAccessTokenStatus } from 'src/utils/apolloCache';
import { L } from 'src/lang/L';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';

interface OrganizationWidgetSettingsPanelProps {
  id: string;
  onNext(): void;
  onBack?(): void;
  onSkip?(): void;
  hideSuccessSnackbar?: boolean;
}

const OrganizationWidgetSettingsPanel = memo((props: OrganizationWidgetSettingsPanelProps) => {
  const { onBack, onNext, onSkip, id: panelId, hideSuccessSnackbar } = props;

  const setVkGroupOrganizationToken = useSetVkGroupOrganizationToken();
  const openSnackbar = useSnackbar();

  const { data, loading: widgetLoading, error: widgetError, client: apolloClient } = useGetVkGroupWidgetDataQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [setVkGroupWidget] = useSetVkGroupWidgetMutation();
  const [unsetVkGroupWidget] = useUnsetVkGroupWidgetMutation();

  const widgetData = data?.attachedOrganization || void 0;
  const widgetStatus = useMemo(() => {
    if (!widgetData) return 'disabled';
    return widgetData.isVkGroupWidgetEnabled
      ? widgetData.vkGroupAccessTokenStatus === AccessTokenStatus.Invalid
        ? 'invalid'
        : 'enabled'
      : 'disabled';
  }, [widgetData]);

  const [widgetSwitchLoading, setWidgetSwitchLoading] = useState(false);

  const switchWidget = useCallback(
    async (enabled: boolean) => {
      try {
        setWidgetSwitchLoading(true);

        if (!widgetData) return;

        /* Получаем и устанавливаем токен сообщества, если его нет */

        if (widgetData.vkGroupAccessTokenStatus !== AccessTokenStatus.Exists) {
          const accesstToken = await setVkGroupOrganizationToken().catch((e) => {
            openSnackbar(<ErrorRetrySnackbar text={e?.message || L.t('fetch_error')} onClose={() => null} />);
            return null;
          });

          if (!accesstToken) return;
        }

        /* Устанавливаем виджет в нужное положение */

        const switchWidgetAction = enabled ? setVkGroupWidget : unsetVkGroupWidget;

        await widgetThrottleDelay.delay();

        await switchWidgetAction()
          .then(() => {
            widgetThrottleDelay.registerDelayPoint();
            onNext();
            if (!hideSuccessSnackbar) {
              openSnackbar(
                <SuccessSnackbar
                  text={L.t(enabled ? 'widget_settings_set_success' : 'widget_settings_unset_success')}
                  onClose={() => null}
                />,
              );
            }
          })
          .catch((e) => {
            if (getGqlErrorCode(e) === GqlErrorCode.WidgetTokenInvalidated) {
              rewriteVkGroupAccessTokenStatus(apolloClient.cache, AccessTokenStatus.Invalid);
            }

            openSnackbar(
              <ErrorRetrySnackbar
                text={L.t(enabled ? 'widget_settings_set_error' : 'widget_settings_unset_error')}
                onClose={() => null}
              />,
            );
          });
      } finally {
        setWidgetSwitchLoading(false);
      }
    },
    [
      onNext,
      openSnackbar,
      hideSuccessSnackbar,
      setVkGroupWidget,
      unsetVkGroupWidget,
      apolloClient,
      widgetData,
      setVkGroupOrganizationToken,
    ],
  );

  return (
    <OrganizationWidgetSettingsPanelComponent
      id={panelId}
      onBack={onBack}
      onSkip={onSkip}
      onWidgetDisable={() => switchWidget(false)}
      onWidgetEnable={() => switchWidget(true)}
      widgetLoading={widgetLoading}
      widgetError={Boolean(widgetError)}
      widgetSwitchLoading={widgetSwitchLoading}
      widget={
        widgetData?.vkGroupWidgetPreview
          ? {
              code: widgetData.vkGroupWidgetPreview.code,
              status: widgetStatus,
              variant: widgetData.vkGroupWidgetPreview.variant,
            }
          : void 0
      }
    />
  );
});

export default OrganizationWidgetSettingsPanel;
