import React, { memo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { HoverTultipProps } from './index';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  wrapper__placeholder: {
    position: 'absolute',
    pointerEvents: 'none',
    transition: 'opacity 150ms linear',
    opacity: 0,
    zIndex: 999999,
    width: 'max-content',
    maxWidth: 150,
    textAlign: 'center',
    [`&[data-placement="top"]`]: {
      bottom: '100%',
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    [`&[data-placement="top-start"]`]: {
      bottom: '100%',
      left: 0,
    },
    [`&[data-placement="top-end"]`]: {
      bottom: '100%',
      right: 0,
    },
    [`&[data-placement="bottom"]`]: {
      top: '100%',
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    [`&[data-placement="bottom-start"]`]: {
      top: '100%',
      left: 0,
    },
    [`&[data-placement="bottom-end"]`]: {
      top: '100%',
      right: 0,
    },
  },
  wrapper__children: {
    '&:hover + $wrapper__placeholder': {
      opacity: 1,
    },
  },
  placeholder: {
    fontSize: 13,
    lineHeight: '16px',
    color: 'var(--white)',
    padding: '8px 12px',
    background: 'var(--gray_800_alpha88)',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 8,
  },
});

const HoverTultipDesktop: React.FC<HoverTultipProps> = memo((props) => {
  const { placement = 'top', title, margin, children, ...wrapperProps } = props;
  const mc = useStyles();

  return (
    <div {...wrapperProps}>
      <div className={mc.wrapper__children}>{children}</div>
      <div className={mc.wrapper__placeholder} style={margin ? { margin } : void 0} data-placement={placement}>
        <div className={mc.placeholder}>{title}</div>
      </div>
    </div>
  );
});

export default HoverTultipDesktop;
