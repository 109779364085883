import React, { memo, useCallback } from 'react';
import {
  Panel,
  PanelHeaderBack,
  Group,
  Header,
  Link,
  SimpleCell,
  Footer,
  Search,
  isDesktop,
  Avatar,
  PanelHeaderButton,
  getPlatformClassName,
} from '@overrided-vkui';
import { List } from '@vkontakte/vkui';
import { AttachedOrganization } from 'src/types/yclients';
import { formatCityAddress } from 'src/utils/formatter';
import { makeStyles } from '@material-ui/styles';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  item: {
    '&--desktop': {
      '&:first-child': {
        marginTop: 16,
      },
      marginTop: -6,
      '& .Group__separator': {
        marginTop: 6,
      },
    },
  },
});

interface OrganizationUnitsPanelProps {
  id: string;
  organization: AttachedOrganization;
  openUnitsEdit(): void;
  openUnit(unitId: number): void;
  onBack(): void;
  searchValue: string;
  setSearchValue(searchValue: string): void;
}

const OrganizationUnitsPanel = memo((props: OrganizationUnitsPanelProps) => {
  const { id: panelId, openUnitsEdit, openUnit, onBack, organization, searchValue, setSearchValue } = props;

  const onUnitClickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const unitId = Number(e.currentTarget.dataset.unitId);
      unitId && openUnit(unitId);
    },
    [openUnit],
  );

  const Wrapper = isDesktop ? Group : 'div';

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      <Panel.Header
        left={<PanelHeaderBack onClick={onBack} />}
        right={
          isDesktop && (
            <PanelHeaderButton onClick={openUnitsEdit}>{L.t('attached_organization_units_nav_edit')}</PanelHeaderButton>
          )
        }
        separator={isDesktop}
      >
        {organization?.title || L.t('organization')}
      </Panel.Header>
      <Panel.Content>
        <Search
          defaultValue={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={L.t('common:search_placeholder')}
          after={L.t('common:cancel')}
          maxLength={255}
        />
        {!isDesktop && (
          <Header
            aside={
              <Link Component="span" onClick={openUnitsEdit}>
                {L.t('attached_organization_units_nav_edit')}
              </Link>
            }
          >
            {L.t('organization_units')}
          </Header>
        )}
        <List>
          {organization.units.map((unit) => (
            <Wrapper key={unit.id} className={getPlatformClassName(mc.item)}>
              <SimpleCell
                before={<Avatar src={unit.logoUrl || void 0} size={72} mode="image" />}
                onClick={onUnitClickHandler}
                data-unit-id={unit.id}
                expandable
                description={formatCityAddress(unit.city.title, unit.address)}
              >
                {unit.title}
              </SimpleCell>
            </Wrapper>
          ))}
        </List>
        <Footer>{L.t('organization_units_amount', { count: organization.units.length })}</Footer>
      </Panel.Content>
    </Panel>
  );
});

export default OrganizationUnitsPanel;
