import React, { memo } from 'react';
import { UserSettingsPanelProps } from './index';
import { Link, SimpleCell, Panel, Switch, Group, PanelHeaderBack } from '@overrided-vkui';
import { YCLIENT_MAIN_URL } from '../LoginPanel';
import { L } from 'src/lang/L';
import { tapticSelectionChanged } from 'src/utils/taptic';

const UserSettingsPanelMobile = memo((props: UserSettingsPanelProps) => {
  const { id: panelId, areNotificationsEnabled, onBack, setAreNotificationsEnabled, openAddToComunity } = props;

  return (
    <Panel id={panelId}>
      <Panel.Header left={onBack && <PanelHeaderBack onClick={onBack} />}>{L.t('settings_header')}</Panel.Header>
      <Group description={L.t('settings_push_description')}>
        <SimpleCell
          after={
            <Switch
              checked={areNotificationsEnabled}
              onChange={(e) => {
                tapticSelectionChanged();
                setAreNotificationsEnabled(e.target.checked);
              }}
            />
          }
          multiline
          disabled
        >
          {L.t('settings_push_label')}
        </SimpleCell>
      </Group>
      <Group
        description={
          <L.Jsx
            t="settings_group_attach_descrription"
            vars={{
              yclients_link: (
                <Link
                  href={YCLIENT_MAIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  {L.t('yclients_name')}
                </Link>
              ),
            }}
          />
        }
      >
        <SimpleCell onClick={openAddToComunity} multiline expandable>
          {L.t('settings_group_attach_label')}
        </SimpleCell>
      </Group>
    </Panel>
  );
});

export default UserSettingsPanelMobile;
