import React, { memo, HTMLAttributes } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Div from '../Div';
import c from 'classnames';

const useStyles = makeStyles({
  panelContent: {},
  panelContent_center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    boxSizing: 'border-box',
    flex: '1 0 auto',
    justifyContent: 'center',
  },
});

export interface PanelContentProps extends HTMLAttributes<HTMLDivElement> {
  center?: boolean;
  indented?: boolean;
}

const PanelContent: React.FC<PanelContentProps> = memo((props) => {
  const { className, center, indented, children, ...rest } = props;
  const mc = useStyles();

  const Wrapper = indented ? Div : 'div';

  return (
    <Wrapper
      className={c(className, mc.panelContent, {
        [mc.panelContent_center]: center,
      })}
      {...rest}
    >
      {children}
    </Wrapper>
  );
});

export default PanelContent;
