import UserSettingsPanelMobile from './UserSettingsPanelMobile';
import UserSettingsPanelDesktop from './UserSettingsPanelDesktop';
import { getPlatformComponent } from '@overrided-vkui';

export interface UserSettingsPanelProps {
  id: string;
  openAddToComunity(): void;
  areNotificationsEnabled: boolean;
  setAreNotificationsEnabled(enabled: boolean): Promise<boolean>;
  onBack?(): void;
}

export default getPlatformComponent<UserSettingsPanelProps>({
  mobile: UserSettingsPanelMobile,
  desktop: UserSettingsPanelDesktop,
});
