import React, { memo } from 'react';
import { Placeholder, Panel } from '@overrided-vkui';
import { PullToRefresh } from '@vkontakte/vkui';
import { L } from 'src/lang/L';
import StatusPlaceholder from 'src/components/atomic/StatusPlaceholder';
import RecordList from 'src/components/atomic/RecordList';
import { RecordListItem } from '../atomic/RecodItem';

interface UserRecordsPanelProps {
  id: string;
  panelHeader?: React.ReactNode;
  openRecord(recordId: number): void;
  refetchRecords(): void;
  isRecordsFetching: boolean;
  isRecordsFetchError: boolean;
  records?: {
    future: RecordListItem[];
    previous: RecordListItem[];
  };
}

const UserRecordsPanel = memo((props: UserRecordsPanelProps) => {
  const {
    id: panelId,
    panelHeader,
    openRecord,
    records,
    refetchRecords,
    isRecordsFetching,
    isRecordsFetchError,
  } = props;

  return (
    <Panel id={panelId}>
      {panelHeader || <Panel.Header>{L.t('main_tab_own_records')}</Panel.Header>}
      <PullToRefresh onRefresh={refetchRecords} isFetching={isRecordsFetching} style={{ flexGrow: 1 }}>
        {records && records.future.length === 0 && records.previous.length === 0 ? (
          <Placeholder>{L.t('own_records_empty')}</Placeholder>
        ) : records ? (
          <>
            {records.future.length > 0 && (
              <RecordList title={L.t('own_records_group_coming')} records={records.future} onRecordClick={openRecord} />
            )}
            {records.previous.length > 0 && (
              <RecordList
                title={L.t('own_records_group_past')}
                records={records.previous}
                onRecordClick={openRecord}
                previous
              />
            )}
          </>
        ) : isRecordsFetching ? (
          <Panel.Content center>
            <StatusPlaceholder status="loading" />
          </Panel.Content>
        ) : isRecordsFetchError ? (
          <StatusPlaceholder status="error" errorText={L.t('own_records_error')} />
        ) : null}
      </PullToRefresh>
    </Panel>
  );
});

export default UserRecordsPanel;
