import SeanceTimeMobile from './SeanceTimeMobile';
import SeanceTimeDesktop from './SeanceTimeDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';

export interface SeanceTimeProps {
  datetime: string;
  active?: boolean;
  onClick?(): void;
}

export default getPlatformComponent<SeanceTimeProps>({ mobile: SeanceTimeMobile, desktop: SeanceTimeDesktop });
