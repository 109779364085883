import React, { memo, useMemo, useState, useEffect } from 'react';
import { Search, isDesktop, PanelHeaderBack, Panel } from '@overrided-vkui';
import { useSelector } from 'src/hooks/useSelector';
import OrganizationUnitList from '../../layout/OrganizationUnitList';
import withGeodata, { WithGeodataComponentProps } from '../../hocs/withGeodata';
import StatusPlaceholder from 'src/components/atomic/StatusPlaceholder';
import { RootRoute } from 'src/router';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { L } from 'src/lang/L';
import { useRouteNode } from 'react-router5';

interface CategoryCatalogPanelContentProps extends WithGeodataComponentProps {
  categoryGroupId?: number;
  searchValue: string;
  onOrganizationUnitClick(organizationUnitId: number): void;
}

const CategoryCatalogPanelContent = withGeodata(
  ({
    geodata,
    requestGeodata,
    searchValue,
    categoryGroupId,
    onOrganizationUnitClick,
  }: CategoryCatalogPanelContentProps) => {
    const geodataInited = Boolean(geodata);
    const [geodataInitLoading, setGeodataInitLoading] = useState(!geodataInited);

    useEffect(() => {
      if (geodataInited) return;
      setGeodataInitLoading(true);
      requestGeodata().finally(() => setGeodataInitLoading(false));
    }, []); // eslint-disable-line

    const coord = useMemo(() => {
      return geodata?.permission === 'available' ? { lat: geodata.lat, lng: geodata.long } : void 0;
    }, [geodata]);

    return geodataInitLoading ? (
      <StatusPlaceholder status="loading" />
    ) : (
      <OrganizationUnitList
        search={searchValue}
        categoryGroupId={categoryGroupId}
        coord={coord}
        openOrganizationUnit={onOrganizationUnitClick}
      />
    );
  },
);

interface CategoryCatalogPanelProps {
  id: string;
  route: RootRoute;
  onBack(): void;
  onOrganizationUnitClick(organizationUnitId: number): void;
}

const CategoryCatalogPanel = memo((props: CategoryCatalogPanelProps) => {
  const { route, onBack, onOrganizationUnitClick, ...panelProps } = props;

  const categoryGroupSlug = useRouteNode(route).route.params.categoryGroupSlug;

  const categoryGroups = useSelector((state) => state.app.categoryGroups);

  const categoryGroup = useMemo(
    () => categoryGroups.find((categoryGroup) => categoryGroup.slug === categoryGroupSlug),
    [categoryGroups, categoryGroupSlug],
  );

  const [searchValue, setSearchValue] = useSearchQuery(route, 'q');

  return (
    <Panel id={panelProps.id}>
      <Panel.Header separator={isDesktop} left={<PanelHeaderBack onClick={onBack} />}>
        {L.t(categoryGroup?.title || 'unknown_category_title')}
      </Panel.Header>
      <Search
        value={searchValue}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        maxLength={255}
        onChange={(e) => {
          window.scrollTo(0, 0);
          setSearchValue(e.target.value);
        }}
      />
      <CategoryCatalogPanelContent
        searchValue={searchValue}
        categoryGroupId={categoryGroup?.id}
        onOrganizationUnitClick={onOrganizationUnitClick}
      />
    </Panel>
  );
});

export default CategoryCatalogPanel;
