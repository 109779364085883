import React, { memo } from 'react';
import { ModalPage as VKModalPage } from '@vkontakte/vkui';
import { ModalPageProps as VKModalPageProps } from '@vkontakte/vkui/dist/components/ModalPage/ModalPage';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { getPlatformClassName } from '../utils/getPlatformClassName';
import { isDesktop } from '../utils/platform';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';

const useStyles = makeStyles({
  close: {
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    margin: 10,
    background: 'rgba(0, 0, 0, 0.35)',
    top: 0,
    position: 'absolute',
    right: -24,
    transform: 'translate(100%, 0)',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'opacity .2s ease-out',
    '&:hover': {
      opacity: 0.88,
    },
  },
  modal: {
    '&--desktop': {
      '& .ModalPage__in-wrap': {
        transform: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
      },
      '& .ModalPage__in': {
        pointerEvents: 'all',
        height: 'initial',
        margin: 0,
        '--modalheader_height_ios': '48px',
        width: 450,
        maxHeight: 'min(600px, 90vh)',
        borderRadius: 8,
        overflow: 'initial',
      },
      '& .ModalPage__header > .ModalPageHeader': {
        borderRadius: '8px 8px 0 0',
        overflow: 'hidden',
      },
      '& .ModalPage__content': {
        overflowY: 'auto',
        zIndex: 0,
      },
      '&$modalPageFullHeight': {
        '& .ModalPage__in': {
          height: 'min(600px, 90vh)',
        },
      },
    },
  },
  modalPageFullHeight: {
    '& .ModalPage__content-in': {
      minHeight: '100%',
    },
  },
});

export interface ModalPageProps extends VKModalPageProps {
  fullHeight?: boolean;
}

const ModalPage: React.FC<ModalPageProps> = memo((props) => {
  const mc = useStyles();
  const { fullHeight, className, children, header, ...modalProps } = props;

  return (
    <VKModalPage
      className={c(getPlatformClassName(mc.modal), className, { [mc.modalPageFullHeight]: fullHeight })}
      header={
        <>
          {header}
          {isDesktop && modalProps.onClose && (
            <div className={mc.close} onClick={modalProps.onClose}>
              <Icon24Cancel height={20} width={20} />
            </div>
          )}
        </>
      }
      {...modalProps}
    >
      {children}
    </VKModalPage>
  );
});

export default ModalPage;
