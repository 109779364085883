import React, { memo } from 'react';
import { Placeholder, Link } from '@overrided-vkui';
import { L } from 'src/lang/L';
import { YCLIENT_MAIN_URL } from '../LoginPanel';
import Icon56ErrorOutline from '@vkontakte/icons/dist/56/error_outline';

const EmptyOrganizationsPlaceholder = memo(() => (
  <Placeholder header={L.t('attach_ycl_organization_list_empty_header')} icon={<Icon56ErrorOutline />}>
    <L.Jsx
      t="attach_ycl_organization_list_empty_content"
      vars={{
        yclients_link: (
          <Link href={YCLIENT_MAIN_URL} target="_blank" rel="noopener noreferrer">
            {L.t('yclients_name')}
          </Link>
        ),
      }}
    />
  </Placeholder>
));

export default EmptyOrganizationsPlaceholder;
