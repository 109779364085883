import CatalogOrganizationUnitMobile from './CatalogOrganizationUnitMobile';
import CatalogOrganizationUnitDesktop from './CatalogOrganizationUnitDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';

export interface CatalogOrganizationUnitProps {
  logoUrl?: string | null;
  title: string;
  address: string;
  onClick?(): void;
}

export default getPlatformComponent<CatalogOrganizationUnitProps>({
  mobile: CatalogOrganizationUnitMobile,
  desktop: CatalogOrganizationUnitDesktop,
});
