import { useSelector } from './useSelector';
import { useActions } from './useActions';
import { appConfigActions } from '../redux/reducers/app-config';
import { Scheme } from '@vkontakte/vkui/dist/components/ConfigProvider/ConfigProviderContext';
import { useCallback } from 'react';

const oppositeScheme = {
  [Scheme.BRIGHT_LIGHT]: Scheme.SPACE_GRAY,
  [Scheme.SPACE_GRAY]: Scheme.BRIGHT_LIGHT,
  [Scheme.DEPRECATED_CLIENT_LIGHT]: Scheme.SPACE_GRAY,
  [Scheme.DEPRECATED_CLIENT_DARK]: Scheme.BRIGHT_LIGHT,
};

export const useScheme = () => {
  const scheme = useSelector((state) => state.appConfig.scheme);
  const updateScheme = useActions(appConfigActions.updateAppearanceScheme);
  const toggleScheme = useCallback(() => updateScheme(oppositeScheme[scheme]), [updateScheme, scheme]);

  return { scheme, updateScheme, toggleScheme };
};
