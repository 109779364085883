import DatetimeGroupMobile from './DatetimeGroupMobile';
import DatetimeGroupDesktop from './DatetimeGroupDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';
import { SeanceTimeListProps } from '../../SeanceTimeList';

export interface DatetimeGroupProps extends SeanceTimeListProps {
  title: string;
}

export default getPlatformComponent({ mobile: DatetimeGroupMobile, desktop: DatetimeGroupDesktop });
