import React, { memo, useCallback, useState, useRef } from 'react';
import { StatEvent, statEvents } from '../../utils/statEvents';
import { useCreateRecordMutation } from 'src/gql/generated/types';
import { useSnackbar } from '@overrided-vkui';
import ErrorRetrySnackbar from 'src/components/atomic/snackbars/ErrorRetrySnackbar';
import RecordInfoModal from 'src/components/panelComponents/StaffSelectModal';
import OrganizationUnitStepConfirmPanel, {
  ConfirmData,
} from 'src/components/panelComponents/OrganizationUnitStepConfirmPanel';
import { insertCacheRecord } from 'src/utils/apolloCache';
import { tapticNotification } from 'src/utils/taptic';
import { L } from 'src/lang/L';
import withUserNotifications, { WithUserNotificationsInjectedProps } from 'src/components/hocs/withUserNotifications';

export interface RecordData {
  datetime: string;
  serviceTitle: string;
  unitTitle: string;
}

interface ServiceStaff {
  id: number;
  staff: {
    id: number;
    name: string;
    avatarUrl?: string | null;
    specialization: string;
  };
  priceMin: number;
  priceMax: number;
  duration: number;
}

interface OrganizationUnitStepConfirmProps extends WithUserNotificationsInjectedProps {
  id: string;
  phone: { number: string; sign: string };
  organizationUnit: {
    id: number;
    title: string;
    logoUrl?: string | null;
    city: {
      title: string;
    };
    organizationUnitCategory: {
      id: number;
      categoryGroup: {
        id: number;
        slug: string;
      };
    };
    address: string;
    bookingConfig: {
      isEmailRequired: boolean;
      isCommentRequired: boolean;
      commentPlaceholder?: string | null;
    };
  };
  service: {
    id: number;
    title: string;
    description?: string | null;
  };
  serviceStaff: ServiceStaff;
  slotServiceStaff: ServiceStaff[];
  slotDatetime: string;
  onNext(record: RecordData): void;
  onBack(): void;
  onChangeServiceStaff(serviceStaff: ServiceStaff): void;
}

const OrganizationUnitStepConfirm = memo((props: OrganizationUnitStepConfirmProps) => {
  const {
    organizationUnit,
    service,
    serviceStaff,
    slotDatetime,
    slotServiceStaff,
    onChangeServiceStaff,
    onBack,
    onNext,
    phone,
    id: panelId,
    areNotificationsEnabled,
    setAreNotificationsEnabled,
  } = props;

  const openSnackbar = useSnackbar();

  const areNotificationsEnabledMemoized = useRef(areNotificationsEnabled).current;

  const [staffSelectModalOpened, setStaffSelectModalOpened] = useState(false);

  const [createRecord, { loading: createRecordLoading }] = useCreateRecordMutation({
    update(cache, { data }) {
      data && insertCacheRecord(cache, data.createRecord);
    },
    onCompleted(data) {
      statEvents.send(StatEvent.Booked, {
        organizationUnitId: data.createRecord.organizationUnit.id,
        type: organizationUnit.organizationUnitCategory.categoryGroup.slug,
        bookDate: slotDatetime,
      });
      tapticNotification('success');
      onNext({
        datetime: data.createRecord.datetime,
        serviceTitle: data.createRecord.serviceStaff.service.title,
        unitTitle: data.createRecord.organizationUnit.title,
      });
    },
    onError() {
      openSnackbar(<ErrorRetrySnackbar text={L.t('make_record_step_confirm_error')} onClose={() => null} />);
    },
  });

  const onNextClickHandler = useCallback(
    async ({ email, comment }: ConfirmData) => {
      if (!areNotificationsEnabled) {
        await setAreNotificationsEnabled(true);
      }

      return createRecord({
        variables: {
          datetime: slotDatetime,
          serviceStaffId: serviceStaff.id,
          comment,
          phone: phone.number,
          phoneSign: phone.sign,
          email,
        },
      }).catch(() => null);
    },
    [createRecord, phone, serviceStaff.id, setAreNotificationsEnabled, areNotificationsEnabled, slotDatetime],
  );

  const hasMoreStaff = slotServiceStaff.length > 1;

  return (
    <>
      <OrganizationUnitStepConfirmPanel
        id={panelId}
        areNotificationsEnabled={areNotificationsEnabledMemoized}
        onBack={onBack}
        onNextLoading={createRecordLoading}
        organizationUnit={organizationUnit}
        service={service}
        serviceStaff={serviceStaff}
        slotDatetime={slotDatetime}
        onStaffClick={hasMoreStaff ? () => setStaffSelectModalOpened(true) : void 0}
        onNext={onNextClickHandler}
      />
      <RecordInfoModal
        show={staffSelectModalOpened}
        onClose={(serviceStaffId) => {
          const newServiceStaff = slotServiceStaff.find((item) => item.staff.id === serviceStaffId);
          newServiceStaff && onChangeServiceStaff(newServiceStaff);
          setStaffSelectModalOpened(false);
        }}
        staffItems={slotServiceStaff.map((item) => item.staff) || []}
        activeStaffId={serviceStaff.staff.id}
        datetime={slotDatetime}
      />
    </>
  );
});

export default withUserNotifications(OrganizationUnitStepConfirm);
