import styled from '@material-ui/styles/styled';
import { Radio } from '@vkontakte/vkui';
import { RadioProps } from '@vkontakte/vkui/dist/components/Radio/Radio';

export default styled(Radio)({
  padding: '10px 24px',
  '--separator_common': 'transparent',
  '&:not([disabled])': {
    cursor: 'pointer',
    '&:hover .Radio__icon': {
      background: 'var(--steel_gray_40)',
    },
  },
  '& .Radio__icon': {
    '--accent': 'var(--blue_400)',
    '--icon_secondary': 'var(--steel_gray_140)',
    margin: '1px 14px 1px 1px',
    borderWidth: 1,
    width: 16,
    height: 16,
    '&:after': {
      height: 8,
      width: 8,
      left: 3,
      top: 3,
    },
  },
  '& .Radio__input:disabled~.Radio__container': {
    opacity: 0.4,
  },
}) as React.FC<RadioProps>;
