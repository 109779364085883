import React, { memo } from 'react';
import { Panel, OverlayPopoutWrapper, ScreenSpinner, Placeholder, Avatar, Button, isDesktop } from '@overrided-vkui';
import { YClientsOrganizationUnit } from 'src/types/yclients';
import { makeStyles } from '@material-ui/styles';
import FooterButtons from '../atomic/FooterButtons';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  panelFooter: {
    '& .Div': {
      padding: 24,
    },
  },
});

interface YClientsSingleUnitSelectPanelProps {
  id: string;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  nextButtonText: string;
  onNext(): void;
  onNextLoading: boolean;
  unit: YClientsOrganizationUnit;
}

const YClientsSingleUnitSelectPanel = memo((props: YClientsSingleUnitSelectPanelProps) => {
  const { id: panelId, headerLeft, headerRight, nextButtonText, onNext, unit, onNextLoading } = props;

  const mc = useStyles();

  const action = (
    <Button size="l" onClick={onNext}>
      {nextButtonText}
    </Button>
  );

  return (
    <Panel id={panelId}>
      <Panel.Header left={headerLeft} right={headerRight} transparent separator={false} />
      <Panel.Content center>
        <Placeholder
          icon={<Avatar src={unit.logoUrl} mode="image" size={72} />}
          header={L.t('attach_ycl_single_organization_unit_title', { title: unit.title })}
          action={isDesktop && action}
        >
          {L.t('attach_ycl_single_organization_unit_info')}
        </Placeholder>
      </Panel.Content>
      {!isDesktop && (
        <Panel.Footer fixed className={mc.panelFooter}>
          <FooterButtons>{action}</FooterButtons>
        </Panel.Footer>
      )}
      <OverlayPopoutWrapper show={onNextLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </Panel>
  );
});

export default YClientsSingleUnitSelectPanel;
