import React, { memo } from 'react';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { makeStyles } from '@material-ui/styles';
import logoUrl from 'src/assets/logo.svg';
import { Button, getPlatformClassName, Title, Panel } from '@overrided-vkui';
import Icon28SlidersOutline from '@vkontakte/icons/dist/28/sliders_outline';
import Icon28LocationOutline from '@vkontakte/icons/dist/28/location_outline';
import Icon28CalendarOutline from '@vkontakte/icons/dist/28/calendar_outline';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  panel: {
    '&--desktop': {
      '& .PanelHeader': {
        display: 'none',
      },
      '& $panelContent': {
        flex: 'none',
        padding: 0,
        maxWidth: 330,
        margin: 'auto auto 0',
      },
      '& $panelFooter': {
        marginTop: 0,
        marginBottom: 'auto',
        '& .Div': {
          padding: 12,
        },
        '& .Separator': {
          display: 'none',
        },
      },
      '& $feature': {
        margin: '20px 0',
      },
      '& $feature__icon': {
        marginRight: 20,
      },
      '& $title': {
        margin: '32px 0 4px',
      },
      '& $logo': {
        '& .Avatar__in': {
          height: '64px!important',
          width: '64px!important',
        },
      },
      '& $panelFooterButtons': {
        justifyContent: 'center',
        '& > div': {
          flexDirection: 'row',
          '& > * + *': {
            marginRight: 0,
            marginLeft: 8,
          },
        },
      },
    },
  },
  logo: {
    height: 80,
    width: 80,
    backgroundImage: `url(${logoUrl})`,
    backgroundSize: 'contain',
    borderRadius: 10,
  },
  panelContent: {
    padding: 24,
  },
  title: {
    margin: '24px 0 8px',
  },
  featurelist: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  feature: {
    margin: '24px 0',
    display: 'flex',
    textAlign: 'left',
  },
  feature__icon: {
    color: 'var(--accent)',
    marginRight: 16,
    display: 'block',
    height: 28,
    width: 28,
    flexShrink: 0,
  },
  feature__text: {
    margin: 'auto 0',
  },
  panelFooter: {
    '& .Div': {
      padding: 24,
    },
  },
  panelFooterButtons: {},
});

interface UserOnboardPanelProps {
  id: string;
  onNext(): void;
}

const UserOnboardPanel = memo((props: UserOnboardPanelProps) => {
  const { id: panelId, onNext } = props;

  const mc = useStyles();

  return (
    <Panel id={panelId} className={getPlatformClassName(mc.panel)} elastic={false}>
      <Panel.Header transparent={true} separator={false} />
      <Panel.Content center indented className={mc.panelContent}>
        <div className={mc.logo} />
        <Title level="1" weight="bold" className={mc.title}>
          <L.Jsx t="user_onboard_title" />
        </Title>
        <ul className={mc.featurelist}>
          <li className={mc.feature}>
            <Icon28CalendarOutline className={mc.feature__icon} />
            <Title className={mc.feature__text} level="3" weight="regular">
              <L.Jsx t="user_onboard_feature_make_records" />
            </Title>
          </li>
          <li className={mc.feature}>
            <Icon28SlidersOutline className={mc.feature__icon} />
            <Title className={mc.feature__text} level="3" weight="regular">
              <L.Jsx t="user_onboard_feature_manage_records" />
            </Title>
          </li>
          <li className={mc.feature}>
            <Icon28LocationOutline className={mc.feature__icon} />
            <Title className={mc.feature__text} level="3" weight="regular">
              <L.Jsx t="user_onboard_feature_closest_units" />
            </Title>
          </li>
        </ul>
      </Panel.Content>
      <Panel.Footer className={mc.panelFooter}>
        <FooterButtons className={mc.panelFooterButtons}>
          <Button size="l" onClick={onNext}>
            {L.t('user_onboard_button_continue')}
          </Button>
        </FooterButtons>
      </Panel.Footer>
    </Panel>
  );
});

export default UserOnboardPanel;
