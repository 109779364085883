import React, { memo } from 'react';
import { Div, Title } from '@overrided-vkui';
import SeanceTimeList from '../../SeanceTimeList';
import { makeStyles } from '@material-ui/styles';
import { DatetimeGroupProps } from '.';

const useStyles = makeStyles({
  seanceGroup: {},
  seanceGroup__title: {
    lineHeight: '44px!important',
    marginBottom: 2,
  },
});

const DatetimeGroupMobile: React.FC<DatetimeGroupProps> = memo(({ title, ...seanceTimeProps }) => {
  const mc = useStyles();

  return (
    <Div className={mc.seanceGroup}>
      <Title weight="semibold" level="3" className={mc.seanceGroup__title}>
        {title}
      </Title>
      <SeanceTimeList {...seanceTimeProps} />
    </Div>
  );
});

export default DatetimeGroupMobile;
