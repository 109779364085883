import React, { useEffect, useRef, memo } from 'react';
import { usePlatform, FixedLayout, Separator, getClassName } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { PanelHeaderProps } from './index';

const useStyles = makeStyles({
  header: {
    '& .PanelHeader__fixed': {
      background: 'transparent',
    },
    '&.PanelHeader--trnsp $header__background': {
      background: 'transparent',
    },
    '& .PanelHeader__content': {
      zIndex: 'initial',
    },
    '& .PanelHeader__right': {
      minWidth: 90,
    },
  },
  header__background: {
    content: '""',
    display: 'block',
    background: 'var(--header_background)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  children: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const PanelHeader: React.FC<PanelHeaderProps> = memo((props) => {
  const {
    className,
    left,
    children,
    right,
    separator,
    visor,
    transparent,
    getRef,
    getRootRef,
    hiddenOffset = 0,
    appearanceDistance = 0,
    onAppeared,
    onHidding,
    ...restProps
  } = props;

  const platform = usePlatform();

  const isPrimitive = typeof children === 'string' || typeof children === 'number';

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hiddenOffset === 0 && appearanceDistance === 0) return;

    let rafId: number;

    const listener = () => {
      const headerBackgroundEl = headerRef.current?.parentElement?.firstElementChild as HTMLDivElement | null;
      const headerContentEl = headerRef.current as HTMLDivElement | null;

      if (!headerContentEl || !headerBackgroundEl) return;

      const prevIntOpacity = Number(headerContentEl.style.opacity || '0');

      let nextOpacity: string;

      if (window.pageYOffset < hiddenOffset) {
        nextOpacity = '0';
      } else if (window.pageYOffset < hiddenOffset + appearanceDistance) {
        nextOpacity = String((window.pageYOffset - hiddenOffset) / appearanceDistance);
      } else {
        nextOpacity = '1';
      }

      cancelAnimationFrame(rafId);

      rafId = requestAnimationFrame(() => {
        headerContentEl.style.opacity = nextOpacity;
        headerBackgroundEl.style.opacity = nextOpacity;

        const nextIntOpacity = Number(nextOpacity);

        if (prevIntOpacity < 0.75 && nextIntOpacity >= 0.75) {
          onAppeared && onAppeared();
        }

        if (prevIntOpacity >= 0.25 && nextIntOpacity < 0.25) {
          onHidding && onHidding();
        }

        // const isHidden = nextIntOpacity === 0;

        // headerEl.style.height = isHidden ? '0' : 'auto';
        // headerEl.style.overflow = isHidden ? 'hidden' : 'auto';
      });
    };

    // вычисляем значение прозрачности в первый раз
    listener();

    // следим за изменением скролла
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [hiddenOffset, onHidding, onAppeared, appearanceDistance]);

  const mc = useStyles();

  return (
    <div
      {...restProps}
      className={c(
        mc.header,
        getClassName('PanelHeader', platform),
        {
          'PanelHeader--trnsp': transparent,
          'PanelHeader--vis': visor,
          'PanelHeader--sep': separator && visor,
          'PanelHeader--no-left': left === undefined,
          'PanelHeader--no-right': right === undefined,
        },
        className,
      )}
      ref={getRootRef}
    >
      <FixedLayout vertical="top" className="PanelHeader__fixed" getRootRef={getRef}>
        <div className="PanelHeader__in">
          <div className="PanelHeader__left">{left}</div>
          <div className="PanelHeader__content">
            <div className={mc.children}>{isPrimitive ? <span>{children}</span> : children}</div>
            <div className={mc.header__background} ref={headerRef} />
          </div>
          <div className="PanelHeader__right">{right}</div>
        </div>
      </FixedLayout>
      {separator && visor && <Separator className="PanelHeader__separator" />}
    </div>
  );
});

PanelHeader.defaultProps = {
  separator: true,
  transparent: false,
  visor: true,
};

export default PanelHeader;
