import React, { memo } from 'react';
import { ModalPage, Div, Button } from '@overrided-vkui';
import { L } from 'src/lang/L';
import { PhoneRequestModalCardProps } from './index';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';

const useStyles = makeStyles({
  modal: {
    '& .ModalPage__in': {
      paddingTop: 0,
      width: 400,
    },
    '& h2': {
      fontSize: 16,
      lineHeight: '20px',
      margin: '8px 0',
      fontWeight: 500,
    },
    '& p': {
      fontSize: 13,
      lineHeight: '16px',
      color: 'var(--gray_600)',
      margin: '8px 0 16px',
    },
  },
  buttons: {
    textAlign: 'right',
    paddingTop: 8,
    '& > * + *': {
      marginLeft: 8,
    },
    '&$buttons_reversed': {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& > * + *': {
        marginRight: 8,
        marginLeft: 0,
      },
    },
  },
});

const PhoneRequestModalCardDesktop: React.FC<PhoneRequestModalCardProps> = memo((props) => {
  const { onReject, onConfirm, className, ...modalCardProps } = props;

  const mc = useStyles();

  return (
    <ModalPage id="PHONE_REQUEST" {...modalCardProps} className={c(className, mc.modal)} header={null}>
      <Div>
        <h2>{L.t('native_phone_request_title')}</h2>
        <p>{L.t('native_phone_request_description')}</p>
        <div className={mc.buttons}>
          <Button mode="secondary" size="l" onClick={onReject}>
            {L.t('native_phone_request_button_reject')}
          </Button>
          <Button mode="primary" size="l" onClick={onConfirm}>
            {L.t('native_phone_request_button_confirm')}
          </Button>
        </div>
      </Div>
    </ModalPage>
  );
});

export default PhoneRequestModalCardDesktop;
