import React, { memo, useState } from 'react';
import {
  SimpleCell,
  Header,
  Group,
  ModalPage,
  withModalPageOverlayWrapper,
  isDesktop,
  getPlatformClassName,
  Separator,
  Text,
  Link,
} from '@overrided-vkui';
import { InfoRow } from '@vkontakte/vkui';
import Icon28PhoneOutline from '@vkontakte/icons/dist/28/phone_outline';
import Icon28LogoVkOutline from '@vkontakte/icons/dist/28/logo_vk_outline';
import OrganizationUnitPreviewMap from '../atomic/OrganizationUnitPreviewMap';
import { makeStyles } from '@material-ui/styles';
import { openPhoneCall } from 'src/utils/openPhoneCall';
import { openVKGroup } from 'src/utils/openVKGroup';
import { openCoordinates } from 'src/utils/openCoordinates';
import { L } from 'src/lang/L';
import { Platform } from 'src/types';
import ModalPageHeaderWithClose from '../../components/atomic/ModalPageHeaderWithClose';

const useStyles = makeStyles({
  mapCell: {
    '&--desktop': {
      padding: 0,
      '& $map': {
        borderRadius: 0,
      },
    },
    '& .leaflet-control-container': {
      width: '100%',
      height: '100%',
      zIndex: 400,
      position: 'absolute',
      left: 0,
      top: 0,
      transform: 'translate3d(0px, 0px, 0px)',
    },
  },
  map: {
    minHeight: 120,
    height: '35vw',
    borderRadius: 12,
    overflow: 'hidden',
  },
});

interface UnitMoreInfoModalProps {
  platform: Platform;
  unit: {
    title: string;
    logoUrl?: string;
    description: string;
    city: string;
    address: string;
    coords?: {
      lat: number;
      lng: number;
    };
    vkGroupScreenNames: string[];
    phone?: string;
  };
  onClose(): void;
}

const DESCRIPTION_TEXT_LIMIT = 140;

const UnitMoreInfoModal = memo((props: UnitMoreInfoModalProps) => {
  const { onClose, unit, platform } = props;

  const [expanded, setExpanded] = useState(unit.description.length < DESCRIPTION_TEXT_LIMIT);

  const mc = useStyles();

  const hasCoords = unit.coords && !(unit.coords.lat === 0 && unit.coords.lng === 0);

  return (
    <ModalPage
      id="UNIT_MORE_INFO"
      onClose={onClose}
      header={
        <ModalPageHeaderWithClose noShadow={!isDesktop} onClose={onClose}>
          {L.t('unit_meta_header')}
        </ModalPageHeaderWithClose>
      }
    >
      {!isDesktop && <Separator />}
      {unit.description && (
        <Group header={<Header>{L.t('unit_meta_description')}</Header>}>
          <SimpleCell disabled multiline>
            <Text weight="regular">
              {expanded ? unit.description : unit.description.substr(0, DESCRIPTION_TEXT_LIMIT) + '…'}
            </Text>
            {!expanded && (
              <Text weight="semibold">
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpanded(true);
                  }}
                >
                  {L.t('common:expand_text')}
                </Link>
              </Text>
            )}
          </SimpleCell>
        </Group>
      )}
      <Group header={<Header>{L.t('unit_meta_address')}</Header>} separator={hasCoords ? 'hide' : 'auto'}>
        <SimpleCell multiline disabled>
          <InfoRow header={unit.city}>{unit.address}</InfoRow>
        </SimpleCell>
        {unit.coords && hasCoords && (
          <SimpleCell disabled className={getPlatformClassName(mc.mapCell)}>
            <OrganizationUnitPreviewMap
              className={mc.map}
              onClick={() =>
                unit.coords &&
                !isDesktop &&
                openCoordinates({
                  lat: unit.coords.lat,
                  lng: unit.coords.lng,
                  platform,
                })
              }
              logoUrl={unit.logoUrl}
              lat={unit.coords.lat}
              lng={unit.coords.lng}
              watchOnly={!isDesktop}
            />
          </SimpleCell>
        )}
      </Group>
      {(unit.vkGroupScreenNames.length > 0 || unit.phone) && (
        <Group header={<Header>{L.t('unit_meta_contacts')}</Header>}>
          {unit.vkGroupScreenNames.map((screenName) => (
            <SimpleCell
              key={screenName}
              onClick={() => openVKGroup(screenName, { isDesktop })}
              before={<Icon28LogoVkOutline />}
            >
              {screenName}
            </SimpleCell>
          ))}
          {unit.phone && (
            <SimpleCell
              disabled={isDesktop}
              onClick={() => !isDesktop && unit.phone && openPhoneCall(unit.phone)}
              before={<Icon28PhoneOutline />}
            >
              {unit.phone}
            </SimpleCell>
          )}
        </Group>
      )}
      <div style={{ height: 16 }} />
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(UnitMoreInfoModal);
