import PanelHeaderButtonMobile, {
  PanelHeaderButtonProps,
} from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import PanelHeaderButtonDesktop from './PanelHeaderButtonDesktop';
import { getPlatformComponent } from '../utils/getPlatformComponent';

export default getPlatformComponent<PanelHeaderButtonProps>({
  mobile: PanelHeaderButtonMobile,
  desktop: PanelHeaderButtonDesktop,
});
