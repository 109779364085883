import React, { HTMLAttributes, useRef, useEffect, useState, useCallback, memo } from 'react';
import Icon16Chevron from '@vkontakte/icons/dist/16/chevron';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { isDesktop } from '../utils/platform';

const useStyles = makeStyles({
  HorizontalScroll: {
    position: 'relative',
    '&:hover $HorizontalScroll__arrow': {
      opacity: 0.72,
      '&:hover': {
        opacity: 1,
      },
    },
  },
  HorizontalScroll__in: {
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  HorizontalScroll__arrow: {
    position: 'absolute',
    cursor: 'pointer',
    userSelect: 'auto',
    top: 0,
    height: '100%',
    opacity: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  'HorizontalScroll__arrow-icon': {
    backgroundColor: 'var(--modal_card_background)',
    color: 'var(--icon_secondary)',
    width: 26,
    height: 26,
    borderRadius: 13,
    boxShadow: '0 0 2px rgba(0, 0, 0, .08), 0 0 16px rgba(0, 0, 0, .24)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'HorizontalScroll__arrow-left': {
    marginLeft: 24,
    left: 0,
    '& $HorizontalScroll__arrow-icon': {
      transform: 'rotate(180deg)',
    },
    '&:hover ~ $HorizontalScroll__in $HorizontalScroll__in-wrapper': {
      transform: 'translateX(8px)',
    },
  },
  'HorizontalScroll__arrow-right': {
    marginRight: 24,
    right: 0,
    '&:hover ~ $HorizontalScroll__in $HorizontalScroll__in-wrapper': {
      transform: 'translateX(-8px)',
    },
  },
  'HorizontalScroll__in-wrapper': {
    transition: 'transform .2s',
  },
});

type GetScrollPositionCallback = (currentPosition: number) => number;
type Callback = () => void;
type ScrollContext = {
  scrollElement: HTMLElement;
  scrollAnimationDuration?: number;
  animationQueue: Callback[];
  getScrollPosition: GetScrollPositionCallback;
  onScrollToRightBorder: Callback;
  onScrollEnd: Callback;
  onScrollStart: Callback;
  /**
   * Начальная ширина прокрутки.
   * В некоторых случаях может отличаться от текущей ширины прокрутки из-за transforms: translate
   */
  initialScrollWidth: number;
};

interface HorizontalScrollProps extends HTMLAttributes<HTMLDivElement> {
  getScrollToLeft?: GetScrollPositionCallback;
  getScrollToRight?: GetScrollPositionCallback;
  showArrows?: boolean;
  scrollAnimationDuration?: number;
}

interface HorizontalScrollArrowProps {
  onClick: () => void;
  direction: 'left' | 'right';
}

/**
 * ease function
 * @param x absolute progress of the animation in bounds 0 (beginning) and 1 (end)
 */
function easeInOutSine(x: number) {
  return 0.5 * (1 - Math.cos(Math.PI * x));
}

/**
 * timing method
 */
function now() {
  return performance && performance.now ? performance.now() : Date.now();
}

/**
 * Код анимации скрола, на основе полифила: https://github.com/iamdustan/smoothscroll
 * Константа взята из полифила (468), на дизайн-ревью уточнили до 250
 * @var {number} SCROLL_ONE_FRAME_TIME время анимации скролла
 */
const SCROLL_ONE_FRAME_TIME = 250;

function doScroll({
  scrollElement,
  getScrollPosition,
  animationQueue,
  onScrollToRightBorder,
  onScrollEnd,
  onScrollStart,
  initialScrollWidth,
  scrollAnimationDuration = SCROLL_ONE_FRAME_TIME,
}: ScrollContext) {
  if (!scrollElement || !getScrollPosition) {
    return;
  }

  /**
   * максимальное значение сдвига влево
   */
  const maxLeft = initialScrollWidth - scrollElement.offsetWidth;

  const startLeft = scrollElement.scrollLeft;
  let endLeft = getScrollPosition(startLeft);

  onScrollStart();

  if (endLeft >= maxLeft) {
    endLeft = maxLeft;
  }

  if (endLeft <= 0) {
    endLeft = 0;
  }

  const startTime = now();

  (function scroll() {
    if (!scrollElement) {
      onScrollEnd();
      return;
    }

    const time = now();
    const elapsed = Math.min((time - startTime) / scrollAnimationDuration, 1);

    const value = easeInOutSine(elapsed);

    const currentLeft = startLeft + (endLeft - startLeft) * value;
    scrollElement.scrollLeft = Math.ceil(currentLeft);

    if (scrollElement.scrollLeft !== endLeft) {
      requestAnimationFrame(scroll);
      return;
    }

    onScrollEnd();
    animationQueue.shift();
    if (animationQueue.length > 0) {
      animationQueue[0]();
    }
  })();
}

const HorizontalScrollArrow = memo((props: HorizontalScrollArrowProps) => {
  const { onClick, direction } = props;
  const mc = useStyles();

  return (
    <div
      className={c(mc.HorizontalScroll__arrow, {
        [mc['HorizontalScroll__arrow-left']]: direction === 'left',
        [mc['HorizontalScroll__arrow-right']]: direction === 'right',
      })}
      onClick={onClick}
    >
      <div className={mc['HorizontalScroll__arrow-icon']}>
        <Icon16Chevron />
      </div>
    </div>
  );
});

const HorizontalScroll = memo((props: HorizontalScrollProps) => {
  const {
    children,
    getScrollToLeft = (n: number) => n - window.innerWidth * 0.8,
    getScrollToRight = (n: number) => n + window.innerWidth * 0.8,
    showArrows = true,
    scrollAnimationDuration = SCROLL_ONE_FRAME_TIME,
    className,
    ...restProps
  } = props;

  const mc = useStyles();
  const hasMouse = isDesktop;

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const isCustomScrollingRef = useRef(false);

  const scrollerRef = useRef<HTMLDivElement>(null);

  const animationQueue = useRef<Callback[]>([]);

  function scrollTo(getScrollPosition: (offset: number) => number) {
    animationQueue.current.push(() =>
      doScroll({
        scrollElement: scrollerRef.current!,
        getScrollPosition,
        animationQueue: animationQueue.current,
        onScrollToRightBorder: () => setCanScrollRight(false),
        onScrollEnd: () => (isCustomScrollingRef.current = false),
        onScrollStart: () => (isCustomScrollingRef.current = true),
        initialScrollWidth: scrollerRef.current!.scrollWidth,
        scrollAnimationDuration,
      }),
    );
    if (animationQueue.current.length === 1) {
      animationQueue.current[0]();
    }
  }

  const onscroll = useCallback(() => {
    if (showArrows && hasMouse && scrollerRef.current && !isCustomScrollingRef.current) {
      setCanScrollLeft(scrollerRef.current.scrollLeft > 0);

      setCanScrollRight(
        scrollerRef.current.scrollLeft + scrollerRef.current.offsetWidth < scrollerRef.current.scrollWidth,
      );
    }
  }, [hasMouse, showArrows]);

  useEffect(() => {
    const scrollerEl = scrollerRef.current;
    if (!scrollerEl) return;

    scrollerEl.addEventListener('scroll', onscroll);

    return () => {
      scrollerEl.removeEventListener('scroll', onscroll);
    };
  }, [onscroll]);

  useEffect(onscroll, [scrollerRef]);

  return (
    <div {...restProps} className={c(mc.HorizontalScroll, className)}>
      {showArrows && hasMouse && canScrollLeft && (
        <HorizontalScrollArrow direction="left" onClick={() => scrollTo(getScrollToLeft)} />
      )}
      {showArrows && hasMouse && canScrollRight && (
        <HorizontalScrollArrow direction="right" onClick={() => scrollTo(getScrollToRight)} />
      )}
      <div className={mc.HorizontalScroll__in} ref={scrollerRef}>
        <div className={mc['HorizontalScroll__in-wrapper']}>{children}</div>
      </div>
    </div>
  );
});

export default HorizontalScroll;
