import React, { memo } from 'react';
import { OverlayModalPageWrapper } from '..';

function withModalPageOverlayWrapper<T>(Component: React.FC<T>): React.FC<T & { show: boolean }> {
  return memo(({ show, ...modalPageProps }) => (
    <OverlayModalPageWrapper show={show}>
      <Component {...(modalPageProps as T)} />
    </OverlayModalPageWrapper>
  ));
}

export default withModalPageOverlayWrapper;
