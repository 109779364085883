import React, { memo, useCallback } from 'react';
import { View } from '@vkontakte/vkui';
import { Panel } from '@overrided-vkui';
import OrganizationActionButtonSettingsPanel from '../panels/OrganizationActionButtonSettingsPanel';
import OrganizationWidgetSettingsPanel from '../panels/OrganizationWidgetSettingsPanel';
import OrganizationUnitsPanel from '../panels/OrganizationUnitsPanel';
import OrganizationSettingsPanel from '../panels/OrganizationSettingsPanel';
import { useRouteNode } from 'react-router5';
import { RootRoute } from 'src/router';
import { useGetVkGroupAttachedOrganizationQuery } from 'src/gql/generated/types';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import { L } from 'src/lang/L';

interface OrganizationSettingsViewProps {
  id: string;
}

const OrganizationSettingsView = memo((props: OrganizationSettingsViewProps) => {
  const { id: viewId } = props;

  /* Навигация */

  const { route, router } = useRouteNode(RootRoute.ORGANIZATION_SETTINGS);

  const openUnitsEdit = useCallback(() => router.navigate(RootRoute.CURRENT_YCLIENTS_UNITS_EDIT), [router]);
  const openRegister = useCallback(() => router.navigate(RootRoute.REGISTER), [router]);
  const openUnit = useCallback(
    (organizationUnitId: number) => {
      router.navigate(RootRoute.ORGANIZATION_UNIT, { organizationUnitId, from: RootRoute.ORGANIZATION_SETTINGS });
    },
    [router],
  );

  /* Навигация со свайп-беком */

  const prevRoute = route.params.from as RootRoute | undefined;
  const activeRoute = route.name;

  const history =
    prevRoute?.startsWith(RootRoute.ORGANIZATION_SETTINGS) && prevRoute !== activeRoute
      ? [prevRoute, activeRoute]
      : [activeRoute];

  const activePanel = history[history.length - 1];

  const goHistoryBack = useCallback(() => window.history.back(), []);

  const openWidgetSettings = useCallback(() => {
    router.navigate(RootRoute.ORGANIZATION_SETTINGS_WIDGET, { from: RootRoute.ORGANIZATION_SETTINGS });
  }, [router]);

  const openActionButtonSettings = useCallback(() => {
    router.navigate(RootRoute.ORGANIZATION_SETTINGS_ACTION_BUTTON, { from: RootRoute.ORGANIZATION_SETTINGS });
  }, [router]);

  const openUnits = useCallback(() => {
    router.navigate(RootRoute.ORGANIZATION_SETTINGS_UNITS, { from: RootRoute.ORGANIZATION_SETTINGS });
  }, [router]);

  /* Текущая организация */

  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useGetVkGroupAttachedOrganizationQuery({ variables: { withSettingsData: true } });

  const attachedOrganization = organizationData?.attachedOrganization || void 0;
  const yClientsOrganization = organizationData?.attachedOrganization?.yClientsOrganization || void 0;
  const isSingleUnitYClientsOrganization =
    !yClientsOrganization || yClientsOrganization?.yClientsOrganizationUnits.length === 1;

  /* Рендер */

  if (!attachedOrganization) {
    return (
      <View key="organization-settings-fetching" id={viewId} activePanel={RootRoute.ORGANIZATION_SETTINGS}>
        <Panel id={RootRoute.ORGANIZATION_SETTINGS}>
          <Panel.Content center>
            {organizationLoading ? (
              <StatusPlaceholder status="loading" />
            ) : organizationError ? (
              <StatusPlaceholder status="error" errorText={L.t('organization_fetch_error')} />
            ) : null}
          </Panel.Content>
        </Panel>
      </View>
    );
  }

  return (
    <View
      key="organization-settings"
      id={viewId}
      activePanel={activePanel}
      history={history}
      onSwipeBack={goHistoryBack}
    >
      <OrganizationSettingsPanel
        id={RootRoute.ORGANIZATION_SETTINGS}
        openActionButtonSettings={openActionButtonSettings}
        openWidgetSettings={openWidgetSettings}
        widgetVariant={attachedOrganization.vkGroupWidgetPreview?.variant}
        openOrganizationUnits={openUnits}
        openUnit={openUnit}
        openRegister={openRegister}
        organization={attachedOrganization}
        isSingleUnitYClientsOrganization={isSingleUnitYClientsOrganization}
      />
      <OrganizationUnitsPanel
        id={RootRoute.ORGANIZATION_SETTINGS_UNITS}
        openUnitsEdit={openUnitsEdit}
        organization={attachedOrganization}
        onBack={goHistoryBack}
        openUnit={openUnit}
      />
      <OrganizationActionButtonSettingsPanel
        id={RootRoute.ORGANIZATION_SETTINGS_ACTION_BUTTON}
        onNext={goHistoryBack}
        onBack={goHistoryBack}
      />
      <OrganizationWidgetSettingsPanel
        id={RootRoute.ORGANIZATION_SETTINGS_WIDGET}
        onNext={goHistoryBack}
        onBack={goHistoryBack}
      />
    </View>
  );
});

export default OrganizationSettingsView;
