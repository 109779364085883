import DatePickerSubheadMobile from './DatePickerSubheadMobile';
import DatePickerSubheadDesktop from './DatePickerSubheadDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';
import { DatePickerProps } from '../../DatePicker';

export interface DatePickerSubheadProps extends DatePickerProps {}

export default getPlatformComponent<DatePickerSubheadProps>({
  mobile: DatePickerSubheadMobile,
  desktop: DatePickerSubheadDesktop,
});
