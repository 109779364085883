import React, { memo } from 'react';
import Icon56CheckCircleOutline from '@vkontakte/icons/dist/56/check_circle_outline';
import { L } from 'src/lang/L';
import { makeStyles } from '@material-ui/styles';
import { getPlatformClassName, Button, Placeholder, Panel } from '@overrided-vkui';

const useStyles = makeStyles({
  icon: {
    color: 'var(--dynamic_green)',
  },
  placeholder: {
    '& .Placeholder__action': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      top: 0,
      bottom: 0,
      '& > *': {
        margin: 0,
      },
      '& > * + *': {
        marginTop: 16,
      },
    },
    '&--desktop .Placeholder__action > * + *': {
      marginTop: '20px!important',
    },
  },
});

interface RecordResultPanelProps {
  id: string;
  record: {
    unitTitle: string;
    serviceTitle: string;
    datetime: string;
  };
  openRecords(): void;
  newRecord(): void;
}

const RecordResultPanel = memo((props: RecordResultPanelProps) => {
  const { record, openRecords, newRecord, id: panelId } = props;

  const mc = useStyles();

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Content center>
        <Placeholder
          header={L.t('make_record_step_result_header')}
          className={getPlatformClassName(mc.placeholder)}
          icon={<Icon56CheckCircleOutline className={mc.icon} />}
          action={
            <>
              <Button mode="primary" size="l" onClick={openRecords}>
                {L.t('make_record_step_result_button_records')}
              </Button>
              <Button mode="secondary" size="l" onClick={newRecord}>
                {L.t('make_record_step_result_button_again')}
              </Button>
            </>
          }
        >
          <L.Jsx
            t="make_record_step_result_summary"
            vars={{
              unit_title: record.unitTitle,
              service_title: record.serviceTitle,
              record_time: L.format.dateTime(record.datetime),
            }}
          />
        </Placeholder>
      </Panel.Content>
    </Panel>
  );
});

export default RecordResultPanel;
