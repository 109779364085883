import React, { memo, useState } from 'react';
import { YClientsOrganizationSelectPanelProps } from './index';
import {
  Panel,
  PanelHeader,
  PanelHeaderButton,
  Footer,
  SimpleCell,
  Avatar,
  Separator,
  Radio,
  Button,
  Group,
  Div,
} from '@overrided-vkui';
import { L } from 'src/lang/L';
import EmptyOrganizationsPlaceholder from './EmptyOrganizationsPlaceholder';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { YClientsOrganization } from 'src/types/yclients';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  radioCell: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .SimpleCell': {
      paddingLeft: 0,
      '& .Avatar': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    '& .Radio__icon': {
      marginRight: 20,
    },
  },
  description: {
    padding: '48px 32px',
    fontSize: 15,
    lineHeight: '18px',
    textAlign: 'center',
    color: 'var(--steel_gray_400)',
  },
});

const YClientsOrganizationSelectPanelDesktop = memo((props: YClientsOrganizationSelectPanelProps) => {
  const { yClientsOrganizations, onBack, onNext, id: panelId } = props;

  const [selectedOrganization, setSelectedOrganization] = useState<YClientsOrganization | null>(null);

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      <PanelHeader right={<PanelHeaderButton onClick={onBack}>{L.t('attach_ycl_logout')}</PanelHeaderButton>}>
        {L.t('attach_ycl_organization_title')}
      </PanelHeader>
      {yClientsOrganizations.length === 0 ? (
        <Panel.Content center>
          <EmptyOrganizationsPlaceholder />
        </Panel.Content>
      ) : (
        <>
          <Panel.Content>
            <Div className={mc.description}>{L.t('attach_ycl_organization_info')}</Div>
            <Separator />
            {yClientsOrganizations.map((organization) => (
              <Group key={organization.id}>
                <Radio
                  checked={organization.id === selectedOrganization?.id}
                  onChange={() => setSelectedOrganization(organization)}
                  className={mc.radioCell}
                >
                  <SimpleCell
                    description={L.t('organization_units_amount', {
                      count: organization.yClientsOrganizationUnits.length,
                    })}
                    before={<Avatar src={organization.yClientsOrganizationUnits[0].logoUrl} size={72} mode="image" />}
                  >
                    {organization.title}
                  </SimpleCell>
                </Radio>
              </Group>
            ))}
            <Footer>{L.t('organizations_amount', { count: yClientsOrganizations.length })}</Footer>
          </Panel.Content>
          <Panel.Footer fixed>
            <FooterButtons>
              <Button
                disabled={selectedOrganization === null}
                onClick={() => selectedOrganization && onNext(selectedOrganization)}
              >
                {L.t('attach_ycl_organization_submit')}
              </Button>
            </FooterButtons>
          </Panel.Footer>
        </>
      )}
    </Panel>
  );
});

export default YClientsOrganizationSelectPanelDesktop;
