import styled from '@material-ui/styles/styled';
import { Checkbox } from '@vkontakte/vkui';
import { CheckboxProps } from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';

export default styled(Checkbox)({
  padding: '10px 24px',
  '--separator_common': 'transparent',
  '&:not([disabled])': {
    cursor: 'pointer',
    '&:hover .Checkbox__input:not(:checked)~.Checkbox__container > .Checkbox__icon': {
      background: 'var(--steel_gray_40)',
    },
  },

  '& .Checkbox__icon': {
    '--accent': 'var(--blue_400)',
    '--icon_secondary': 'var(--steel_gray_140)',
    margin: '1px 14px 1px 1px',
    borderWidth: 1,
    width: 16,
    height: 16,
    borderRadius: 3,
    '& > .Icon': {
      height: '14px!important',
      width: '14px!important',
      margin: 1,
    },
    '& > .Icon > svg': {
      height: '12px!important',
      width: '12px!important',
    },
  },
  '& .Checkbox__input:disabled~.Checkbox__container': {
    opacity: 0.4,
  },
}) as React.FC<CheckboxProps>;
