import { getPlatformComponent } from '@overrided-vkui';
import YClientsOrganizationUnitCheckboxDesktop from './YClientsOrganizationUnitCheckboxDesktop';
import YClientsOrganizationUnitCheckboxMobile from './YClientsOrganizationUnitCheckboxMobile';

export interface YClientsOrganizationUnitCheckboxProps {
  title: string;
  logoUrl: string;
  address: string;
  city: string;
  checked: boolean;
  onClick(): void;
}

export default getPlatformComponent<YClientsOrganizationUnitCheckboxProps>({
  mobile: YClientsOrganizationUnitCheckboxMobile,
  desktop: YClientsOrganizationUnitCheckboxDesktop,
});
