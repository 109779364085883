import React from 'react';
import ReactDOM from 'react-dom';
import { AppRoot } from './components/AppRoot';
import { getInsets } from './utils/dom';
import { isProduction, isDevelopment } from './utils/environment';
import config from './config';
import * as Sentry from '@sentry/browser';
import '@vkontakte/vkui/dist/vkui.css';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/array/fill';
import 'core-js/es/array/includes';
import 'core-js/es/string/pad-start';
import 'core-js/es/string/starts-with';
import 'core-js/es/promise/finally';
import 'core-js/es/object/values';
import 'core-js/es/object/entries';
import 'core-js/es/object/from-entries';
import 'core-js/es/object/keys';
import 'core-js/es/object/values';

if (isDevelopment) {
  // eslint-disable-next-line
  const eruda = require('eruda');

  const el = document.createElement('div');

  el.textContent = '🛠';
  el.style.position = 'fixed';
  el.style.right = '0';
  el.style.bottom = '0';
  el.style.zIndex = '99999';
  el.style.fontSize = '12px';
  el.style.padding = '6px';
  el.style.color = 'rgba(42,88,133,0.5)';

  document.body.appendChild(el);

  // eruda.init({
  //   container: el,
  //   tool: ['console', 'elements', 'network'],
  // });
}

if (isProduction) {
  Sentry.init({
    dsn: config.sentryDsn,
    beforeSend(event: any, hint: any) {
      const error = hint?.originalException;

      if (error && error.error_data && error.error_data.error_reason) {
        event.fingerprint = [error.error_data.error_reason];
      }

      return event;
    },
  });
}

// Getting launch parameters
const launchParams = window.location.search.slice(1);

// According to that we know, there are CSS-variables defined in index.html
// we are getting insets from them
const insets = getInsets();

// Display application
ReactDOM.render(
  <AppRoot url={window.location.href} launchParams={launchParams} insets={insets} config={config} />,
  document.getElementById('root'),
);
