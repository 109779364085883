import React, { useCallback, memo, useEffect, useRef, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, HorizontalScroll } from '@overrided-vkui';
import dayjs from 'dayjs';
import c from 'classnames';
import { L } from 'src/lang/L';
import { capitalizeFirstLetter } from 'src/utils/formatter';

const useStyles = makeStyles({
  tabbarItem: {
    cursor: 'pointer',
    scrollMargin: '96px',
  },
  tabbarItem_disabled: {
    '&:not(.TabsItem--selected)': {
      opacity: 0.4,
    },
  },
});

export interface DatePickerProps {
  // дата, начиная от которой нужно рисовать список
  dateFrom: string; // YYYY-MM-DD
  // сколько дат минимум нужно отрисовать
  dateCountMin: number;
  // доступные даты
  availableDates: string[]; // YYYY-MM-DD
  // выбранная дата
  selectedDate?: string;
  // клик по дате
  onDateClick(date: string): void;
  disabled?: boolean;
}

const DatePicker = memo((props: DatePickerProps) => {
  const { dateFrom, dateCountMin, selectedDate, disabled, availableDates, onDateClick } = props;
  const mc = useStyles();

  const tabsRef = useRef<HTMLDivElement>(null);

  const dateCount = useMemo(() => {
    const lastActiveDate = availableDates[availableDates.length - 1];
    if (!lastActiveDate) return dateCountMin;
    return Math.max(dayjs(lastActiveDate).diff(dateFrom, 'day') + 1, dateCountMin);
  }, [dateCountMin, dateFrom, availableDates]);

  const getNextDate = useCallback(
    (dayOffset: number) => {
      return dayjs(dateFrom).add(dayOffset, 'day').format('YYYY-MM-DD');
    },
    [dateFrom],
  );

  useEffect(() => {
    if (!tabsRef.current || !selectedDate) return;
    const activeTabEl = tabsRef.current.querySelector(`.TabsItem--selected`);

    if (activeTabEl && 'scrollIntoView' in activeTabEl) {
      (activeTabEl as any).scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'nearest',
      });
    }
  }, [selectedDate]);

  return (
    <HorizontalScroll>
      <Tabs getRootRef={tabsRef}>
        {new Array(dateCount)
          .fill(0)
          .map((_, index) => getNextDate(index))
          .map((date) => (
            <Tabs.Item
              className={c(mc.tabbarItem, { [mc.tabbarItem_disabled]: !availableDates.includes(date) })}
              key={date}
              selected={selectedDate === date}
              onClick={() => onDateClick(date)}
              style={disabled ? { pointerEvents: 'none' } : void 0}
            >
              {capitalizeFirstLetter(L.format.date(date, { shortMonth: true, withWeekDay: true }))}
            </Tabs.Item>
          ))}
      </Tabs>
    </HorizontalScroll>
  );
});

export default DatePicker;
