import React, { memo, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { HorizontalScroll, Tabs } from '@overrided-vkui';

const useStyles = makeStyles({
  tabs: {
    '& .TabsItem': {
      flexGrow: 0,
    },
  },
  tab: {
    scrollMargin: '96px',
  },
});

interface ServiceCategory {
  id: number;
  title: string;
}

interface ServiceCategoryTabsProps {
  className?: string;
  serviceCategories: ServiceCategory[];
  selectedServiceCategoryId?: number;
  selectServiceCategory(serviceCategoryId: number): void;
}

const ServiceCategoryTabs: React.FC<ServiceCategoryTabsProps> = memo((props) => {
  const { className, serviceCategories, selectServiceCategory, selectedServiceCategoryId } = props;

  const tabsRef = useRef<HTMLDivElement>(null);

  const mc = useStyles();

  useEffect(() => {
    if (!tabsRef.current || !selectedServiceCategoryId) return;
    const activeTabEl = tabsRef.current.querySelector(`.TabsItem--selected`);

    if (activeTabEl && 'scrollIntoView' in activeTabEl) {
      (activeTabEl as any).scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'nearest',
      });
    }
  }, [selectedServiceCategoryId]);

  return (
    <HorizontalScroll className={className}>
      <Tabs className={mc.tabs} getRootRef={tabsRef}>
        {serviceCategories.map((category) => (
          <Tabs.Item
            key={category.id}
            className={mc.tab}
            selected={category.id === selectedServiceCategoryId}
            onClick={() => selectServiceCategory(category.id)}
          >
            {category.title}
          </Tabs.Item>
        ))}
      </Tabs>
    </HorizontalScroll>
  );
});

export default ServiceCategoryTabs;
