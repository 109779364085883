import dayjs from 'dayjs';

export const getFutureRecords = <T extends { datetime: string }>(records: T[]): T[] => {
  const now = new Date();
  return records
    .filter((record) => new Date(record.datetime) > now)
    .sort((a, b) => Number(new Date(a.datetime)) - Number(new Date(b.datetime)));
};

export const getPreviousRecords = <T extends { datetime: string }>(records: T[]): T[] => {
  const now = new Date();
  return records
    .filter((record) => new Date(record.datetime) <= now)
    .sort((a, b) => Number(new Date(b.datetime)) - Number(new Date(a.datetime)));
};

export const getIntervalDatetimes = (
  datetimes: string[],
  activeDate: string,
  timeFrom: string | null,
  timeTo: string | null,
): string[] => {
  let dateTimeFrom = dayjs(activeDate);
  if (timeFrom) {
    const [hourFrom, minutesFrom] = timeFrom.split(':').map(Number);
    dateTimeFrom = dateTimeFrom.hour(hourFrom).minute(minutesFrom);
  }

  let dateTimeTo = dayjs(activeDate);
  if (timeTo) {
    const [hourTo, minutesTo] = timeTo.split(':').map(Number);
    dateTimeTo = dateTimeTo.hour(hourTo).minute(minutesTo);
  }

  return datetimes.filter((datetime) => {
    const current = dayjs(datetime);
    return (
      (!timeFrom || current.isAfter(dateTimeFrom) || current.isSame(dateTimeFrom)) &&
      (!timeTo || current.isBefore(dateTimeTo))
    );
  });
};

export const getStaffMaxPrice = (serviceStaff: Array<{ priceMax: number }>) => {
  return Math.max.apply(
    null,
    serviceStaff.map((unit) => unit.priceMax),
  );
};

export const getStaffMinPrice = (serviceStaff: Array<{ priceMin: number }>) => {
  return Math.min.apply(
    null,
    serviceStaff.map((unit) => unit.priceMin),
  );
};

export const getStaffMaxDuration = (serviceStaff: Array<{ duration: number }>) => {
  return Math.max.apply(
    null,
    serviceStaff.map((unit) => unit.duration),
  );
};

export const getStaffMinDuration = (serviceStaff: Array<{ duration: number }>) => {
  return Math.min.apply(
    null,
    serviceStaff.map((unit) => unit.duration),
  );
};
