import React, { memo, useCallback } from 'react';
import vkBridge from '@vkontakte/vk-bridge';
import AddToCommunityPanelComponent from '../panelComponents/AddToCommunityPanel';
import { tapticNotification } from 'src/utils/taptic';

export interface GroupInfo {
  id: number;
  photoUrl?: string;
}

interface AddToCommunityPanelProps {
  id: string;
  onClose(): void;
  onNext(groupInfo: GroupInfo): void;
}

const AddToCommunityPanel = memo((props: AddToCommunityPanelProps) => {
  const { id: panelId, onClose, onNext } = props;

  const onNextHandler = useCallback(() => {
    vkBridge
      .send('VKWebAppAddToCommunity')
      .then((data) =>
        vkBridge
          .send('VKWebAppGetGroupInfo', { group_id: Number(data.group_id) })
          .then((data: any) => {
            // BUGFIX VKWebAppGetGroupInfo
            const info = 'result' in data ? data.result : data;
            onNext({ id: info.id, photoUrl: info.photo_200 });
          })
          .catch(() => onNext({ id: data.group_id }))
          .finally(() => tapticNotification('success')),
      )
      .catch(() => null);
  }, [onNext]);

  return <AddToCommunityPanelComponent id={panelId} onClose={onClose} onNext={onNextHandler} />;
});

export default AddToCommunityPanel;
