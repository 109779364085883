import React, { memo } from 'react';
import { Button, Title, getPlatformClassName, isDesktop, Panel, PanelHeaderClose } from '@overrided-vkui';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { APP_INFO_URL } from './LoginPanel';
import { makeStyles } from '@material-ui/styles';
import logoUrl from 'src/assets/logo.svg';
import Icon28StatisticsOutline from '@vkontakte/icons/dist/28/statistics_outline';
import Icon28UsersOutline from '@vkontakte/icons/dist/28/users_outline';
import { ReactComponent as ShareIcon28 } from 'src/assets/share-28.svg';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  panel: {
    '&--desktop': {
      '& $panelContent': {
        flex: 'none',
        padding: 0,
        maxWidth: 330,
        margin: 'auto auto 0',
      },
      '& $panelFooter': {
        marginTop: 0,
        marginBottom: 'auto',
        '& .Div': {
          padding: 12,
        },
        '& .Separator': {
          display: 'none',
        },
      },
      '& $feature': {
        margin: '20px 0',
      },
      '& $feature__icon': {
        marginRight: 20,
      },
      '& $title': {
        margin: '32px 0 4px',
      },
      '& $logo': {
        '& .Avatar__in': {
          height: '64px!important',
          width: '64px!important',
        },
      },
      '& $panelFooterButtons': {
        justifyContent: 'center',
        '& > div': {
          flexDirection: 'row',
          '& > * + *': {
            marginRight: 0,
            marginLeft: 8,
          },
        },
      },
    },
  },
  logo: {
    height: 80,
    width: 80,
    backgroundImage: `url(${logoUrl})`,
    backgroundSize: 'contain',
    borderRadius: 10,
  },
  panelContent: {
    padding: 24,
  },
  title: {
    margin: '24px 0 8px',
  },
  featurelist: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  feature: {
    margin: '24px 0',
    display: 'flex',
    textAlign: 'left',
  },
  feature__icon: {
    color: 'var(--accent)',
    marginRight: 16,
    display: 'block',
    height: 28,
    width: 28,
    flexShrink: 0,
  },
  feature__text: {
    margin: 'auto 0',
  },
  panelFooter: {
    '& .Div': {
      padding: 24,
    },
  },
  panelFooterButtons: {},
});

interface AddToCommunityPanelProps {
  id: string;
  onClose(): void;
  onNext(): void;
}

const AddToCommunityPanel = memo((props: AddToCommunityPanelProps) => {
  const { onClose, onNext, id: panelId } = props;

  const mc = useStyles();

  return (
    <Panel id={panelId} className={getPlatformClassName(mc.panel)} elastic={false}>
      <Panel.Header
        left={<PanelHeaderClose onClick={onClose}>{L.t('cancel')}</PanelHeaderClose>}
        separator={isDesktop}
        transparent={!isDesktop}
      >
        {isDesktop && L.t('unit_onboard_header')}
      </Panel.Header>
      <Panel.Content center indented className={mc.panelContent}>
        <div className={mc.logo} />
        <Title level="1" weight="bold" className={mc.title}>
          <L.Jsx t="unit_onboard_title" />
        </Title>
        <ul className={mc.featurelist}>
          <li className={mc.feature}>
            <Icon28StatisticsOutline className={mc.feature__icon} />
            <Title className={mc.feature__text} level="3" weight="regular">
              <L.Jsx t="unit_onboard_feature_get_records" />
            </Title>
          </li>
          <li className={mc.feature}>
            <ShareIcon28 className={mc.feature__icon} />
            <Title className={mc.feature__text} level="3" weight="regular">
              <L.Jsx t="unit_onboard_feature_conversion" />
            </Title>
          </li>
          <li className={mc.feature}>
            <Icon28UsersOutline className={mc.feature__icon} />
            <Title className={mc.feature__text} level="3" weight="regular">
              <L.Jsx t="unit_onboard_feature_manage" />
            </Title>
          </li>
        </ul>
      </Panel.Content>
      <Panel.Footer className={mc.panelFooter}>
        <FooterButtons className={mc.panelFooterButtons}>
          <Button size="xl" onClick={onNext}>
            {L.t('unit_onboard_button_link')}
          </Button>
          <Button size="xl" href={APP_INFO_URL} target="_blank" mode="secondary">
            {L.t('unit_onboard_button_more_info')}
          </Button>
        </FooterButtons>
      </Panel.Footer>
    </Panel>
  );
});

export default AddToCommunityPanel;
