import CitySelectModalPageMobile from './CitySelectModalPageMobile';
import CitySelectModalPageDesktop from './CitySelectModalPageDesktop';
import { getPlatformComponent } from '@overrided-vkui';

export interface City {
  id: number;
  title: string;
  unitsCount?: number;
}

export interface CitySelectModalPageProps {
  id: string;
  onClose(): void;
  cities: City[];
  selectedCityId?: number;
  onSelectCity(cityId: number): void;
  searchValue: string;
  setSearchValue(searchValue: string): void;
}

export default getPlatformComponent<CitySelectModalPageProps>({
  mobile: CitySelectModalPageMobile,
  desktop: CitySelectModalPageDesktop,
});
