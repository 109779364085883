import React, { memo, useMemo } from 'react';
import {
  Panel,
  PanelHeaderBack,
  Button,
  Placeholder,
  isDesktop,
  OverlayPopoutWrapper,
  ScreenSpinner,
} from '@overrided-vkui';
import { WidgetVariant } from 'src/gql/generated/types';
import FooterButtons from '../atomic/FooterButtons';
import WidgetPreview from 'src/components/atomic/WidgetPreview';
import { makeStyles } from '@material-ui/styles';
import { L } from 'src/lang/L';
import StatusPlaceholder from '../atomic/StatusPlaceholder';

const useStyles = makeStyles({
  placeholder: {
    width: '100%',
    '& .Placeholder__in': {
      paddingBottom: 0,
      paddingTop: 0,
      width: '100%',
    },
    '& .Placeholder__icon': {
      width: '100%',
      maxWidth: 330,
      marginBottom: 32,
    },
  },
  destructiveSecondaryButton: {
    backgroundColor: 'var(--field_error_background)',
    color: 'var(--field_error_border)',
  },
  panelFooter: {
    '& .Div': {
      padding: 24,
    },
  },
});

interface Widget {
  variant: WidgetVariant;
  code: string;
  status: 'invalid' | 'enabled' | 'disabled';
}

interface OrganizationWidgetSettingsPanelProps {
  id: string;
  onBack?(): void;
  onSkip?(): void;
  widgetLoading?: boolean;
  widgetError?: boolean;
  widget?: Widget;
  onWidgetEnable(): void;
  onWidgetDisable(): void;
  widgetSwitchLoading: boolean;
}

const OrganizationWidgetSettingsPanel = memo((props: OrganizationWidgetSettingsPanelProps) => {
  const {
    id: panelId,
    widgetLoading,
    widgetError,
    widget,
    onBack,
    onSkip,
    onWidgetEnable,
    onWidgetDisable,
    widgetSwitchLoading,
  } = props;

  const mc = useStyles();

  const widgetStatusData = useMemo(
    () => ({
      enabled: {
        title: {
          [WidgetVariant.Services]: 'widget_settings_services_enabled_title',
          [WidgetVariant.Units]: 'widget_settings_units_enabled_title',
        },
        description: {
          [WidgetVariant.Services]: 'widget_settings_services_enabled_description',
          [WidgetVariant.Units]: 'widget_settings_units_enabled_description',
        },
        previewDescription: 'widget_settings_preview_installed',
        actions: [
          <Button
            key="unset"
            size="l"
            mode="secondary"
            className={!isDesktop ? mc.destructiveSecondaryButton : void 0}
            onClick={onWidgetDisable}
          >
            {L.t('widget_settings_button_unset')}
          </Button>,
        ],
      },
      disabled: {
        title: {
          [WidgetVariant.Services]: 'widget_settings_services_disabled_title',
          [WidgetVariant.Units]: 'widget_settings_units_disabled_title',
        },
        description: {
          [WidgetVariant.Services]: 'widget_settings_services_disabled_description',
          [WidgetVariant.Units]: 'widget_settings_units_disabled_description',
        },
        previewDescription: 'widget_settings_preview_example',
        actions: [
          <Button key="set" size="l" mode="primary" onClick={onWidgetEnable}>
            {L.t('widget_settings_button_set')}
          </Button>,
        ],
      },
      invalid: {
        title: {
          [WidgetVariant.Services]: 'widget_settings_invalid_title',
          [WidgetVariant.Units]: 'widget_settings_invalid_title',
        },
        description: {
          [WidgetVariant.Services]: 'widget_settings_invalid_description',
          [WidgetVariant.Units]: 'widget_settings_invalid_description',
        },
        previewDescription: 'widget_settings_preview_installed',
        actions: [
          <Button key="update" size="l" mode="primary" onClick={onWidgetEnable}>
            {L.t('widget_settings_button_update')}
          </Button>,
          <Button key="unset" size="l" mode="secondary" onClick={onWidgetDisable}>
            {L.t('widget_settings_button_unset')}
          </Button>,
        ],
      },
    }),
    [onWidgetDisable, onWidgetEnable, mc.destructiveSecondaryButton],
  );

  const skipAction = (
    <Button key="skip" size="l" mode="secondary" onClick={onSkip}>
      {L.t('widget_settings_button_skip')}
    </Button>
  );

  const renderContent = (widget: Widget) => {
    const actions = widgetStatusData[widget.status].actions.concat(onSkip ? skipAction : []);

    return (
      <>
        <Panel.Content center>
          <Placeholder
            className={mc.placeholder}
            icon={
              <WidgetPreview
                code={widget.code}
                description={<L.Jsx t={widgetStatusData[widget.status].previewDescription} />}
                invalid={widget.status === 'invalid'}
              />
            }
            header={<L.Jsx t={widgetStatusData[widget.status].title[widget.variant]} />}
            action={isDesktop && actions}
          >
            <L.Jsx t={widgetStatusData[widget.status].description[widget.variant]} />
          </Placeholder>
        </Panel.Content>
        {!isDesktop && (
          <Panel.Footer className={mc.panelFooter}>
            <FooterButtons>{actions}</FooterButtons>
          </Panel.Footer>
        )}
      </>
    );
  };

  const title =
    widget &&
    (widget.variant === WidgetVariant.Services
      ? L.t('organization_settings_nav_services_widget')
      : L.t('organization_settings_nav_units_widget'));

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Header
        left={onBack && <PanelHeaderBack onClick={onBack} />}
        transparent={true}
        separator={widget && isDesktop}
      >
        {isDesktop && title}
      </Panel.Header>
      {widget ? (
        renderContent(widget)
      ) : widgetLoading ? (
        <Panel.Content center>
          <StatusPlaceholder status="loading" />
        </Panel.Content>
      ) : widgetError ? (
        <Panel.Content center>
          <StatusPlaceholder status="error" errorText={L.t('widget_settings_fetch_error')} />
        </Panel.Content>
      ) : null}
      <OverlayPopoutWrapper show={widgetSwitchLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </Panel>
  );
});

export default OrganizationWidgetSettingsPanel;
