import React, { memo } from 'react';
import { ModalCard } from '@overrided-vkui';
import Icon56PhoneOutline from '@vkontakte/icons/dist/56/phone_outline';
import { L } from 'src/lang/L';
import { PhoneRequestModalCardProps } from './index';

const PhoneRequestModalCardMobile: React.FC<PhoneRequestModalCardProps> = memo((props) => {
  const { onReject, onConfirm, ...modalCardProps } = props;

  return (
    <ModalCard
      id="PHONE_REQUEST"
      {...modalCardProps}
      icon={<Icon56PhoneOutline />}
      header={L.t('native_phone_request_title')}
      caption={L.t('native_phone_request_description')}
      actions={[
        {
          title: L.t('native_phone_request_button_reject'),
          action: onReject,
          mode: 'secondary',
        },
        {
          title: L.t('native_phone_request_button_confirm'),
          action: onConfirm,
          mode: 'primary',
        },
      ]}
    />
  );
});

export default PhoneRequestModalCardMobile;
