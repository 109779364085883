import React, { memo, useCallback } from 'react';
import { useRouteNode } from 'react-router5';
import { RootRoute } from 'src/router';
import { Epic, FixedLayout } from '@vkontakte/vkui';
import SettingsView from '../../views/SettingsView';
import { Tabs, PanelHeaderButton, HoverTultip, PanelHeader, PanelHeaderBack } from '@overrided-vkui';
import { L } from 'src/lang/L';
import { makeStyles } from '@material-ui/styles';
import Icon24GearOutline from '@vkontakte/icons/dist/24/gear_outline';
import RecordsView from '../RecordsView';
import CatalogView from '../CatalogView';
import { MainViewProps } from './index';

const useStyles = makeStyles({
  tabs: {
    '& .TabsItem': {
      flex: 0,
    },
  },
  settingsButton: {
    marginLeft: 'auto',
    marginRight: 12,
    '& .PanelHeaderButton': {
      color: 'var(--steel_gray_300)',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
  },
});

const MainViewDesktop = memo((props: MainViewProps) => {
  const { onCatalogBack, id: viewId } = props;
  const { route, router } = useRouteNode(RootRoute.MAIN);

  const activeStory = route.name.split('.').slice(0, 2).join('.') as RootRoute;

  const onRouteClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      router.navigate(e.currentTarget.dataset.route as RootRoute, { from: route.name });
    },
    [router, route],
  );

  const backTo = useCallback(
    (backRoute: RootRoute) => {
      if (route.params.from) {
        window.history.back();
      } else {
        router.navigate(backRoute, {}, { replace: true });
      }
    },
    [route, router],
  );

  const mc = useStyles();

  const panelStart = (
    <>
      <FixedLayout vertical="top">
        {onCatalogBack && (
          <PanelHeader left={<PanelHeaderBack onClick={onCatalogBack} />}>{L.t('app_name')}</PanelHeader>
        )}
        <Tabs className={mc.tabs}>
          <Tabs.Item
            selected={activeStory === RootRoute.MAIN_CATALOG}
            data-route={RootRoute.MAIN_CATALOG}
            onClick={onRouteClick}
          >
            {L.t('main_tab_catalog')}
          </Tabs.Item>
          <Tabs.Item
            selected={activeStory === RootRoute.MAIN_RECORDS}
            data-route={RootRoute.MAIN_RECORDS}
            onClick={onRouteClick}
          >
            {L.t('main_tab_own_records')}
          </Tabs.Item>
          <HoverTultip
            title={L.t('common:settings')}
            placement="bottom-end"
            margin="-4px 4px 0 0"
            className={mc.settingsButton}
          >
            <PanelHeaderButton data-route={RootRoute.MAIN_SETTINGS} onClick={onRouteClick}>
              <Icon24GearOutline />
            </PanelHeaderButton>
          </HoverTultip>
        </Tabs>
      </FixedLayout>
      <div style={{ height: 48 }} />
      {onCatalogBack && <div style={{ height: 48 }} />}
    </>
  );

  return (
    <Epic id={viewId} activeStory={activeStory} tabbar={null}>
      <CatalogView id={RootRoute.MAIN_CATALOG} panelStart={panelStart} />
      <RecordsView id={RootRoute.MAIN_RECORDS} panelStart={panelStart} />
      <SettingsView id={RootRoute.MAIN_SETTINGS} onBack={() => backTo(RootRoute.MAIN_RECORDS)} />
    </Epic>
  );
});

export default MainViewDesktop;
