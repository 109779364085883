import React, { memo } from 'react';
import { SimpleCell, getPlatformClassName } from '@overrided-vkui';
import Icon24UnblockOutline from '@vkontakte/icons/dist/24/unblock_outline';
import Icon24DoneOutline from '@vkontakte/icons/dist/24/done_outline';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  selectorCell: {
    '& .Icon': {
      paddingLeft: '0!important',
      paddingRight: '12px!important',
      color: 'var(--accent)',
    },
    '& .SimpleCell__children': {
      color: 'var(--text_link)',
      fontSize: 16,
      lineHeight: '20px',
    },
    '&--desktop': {
      '& .Icon': {
        color: 'var(--blue_400)!important',
        paddingRight: '16px!important',
      },
      '& .SimpleCell__children': {
        color: 'var(--blue_600)',
        fontSize: 15,
      },
    },
  },
});

interface MulticheckerProps {
  isFilled: boolean;
  onClear(): void;
  onFill(): void;
  className?: string;
}

const Multichecker: React.FC<MulticheckerProps> = memo((props) => {
  const { isFilled, onClear, onFill, className } = props;

  const Icon = isFilled ? Icon24UnblockOutline : Icon24DoneOutline;
  const onClickHandler = isFilled ? onClear : onFill;

  const mc = useStyles();

  return (
    <SimpleCell
      className={c(getPlatformClassName(mc.selectorCell), className)}
      before={<Icon />}
      onClick={onClickHandler}
    >
      {L.t(isFilled ? 'uncheck_all' : 'check_all')}
    </SimpleCell>
  );
});

export default Multichecker;
