import React, { memo } from 'react';
import ServiceCategoryTabs from 'src/components/atomic/ServiceCategoryTabs';
import { Panel, Placeholder } from '@overrided-vkui';
import { tapticSelectionChanged } from 'src/utils/taptic';
import { L } from 'src/lang/L';
import CardGrid from 'src/components/atomic/vkui/CardGrid';
import ServiceCard from 'src/components/atomic/ServiceCard';
import StatusPlaceholder from '../StatusPlaceholder';

interface CategoryService {
  id: number;
  title: string;
  description?: string | null;
  serviceStaff: Array<{ priceMin: number; priceMax: number; duration: number }>;
}

interface ServiceCategory {
  id: number;
  title: string;
}

interface ServiceCategorySelectionProps {
  serviceCategories: ServiceCategory[];
  selectedServiceCategoryId?: number;
  selectCategory(categoryId: number): void;
  selectedCategoryServices?: CategoryService[];
  selectedCategoryServiceId?: number;
  selectCategoryService(categoryServiceId: number): void;
  selectedCategoryServicesLoading?: boolean;
  selectedCategoryServicesError?: boolean;
  refetchSelectedCategoryServices(): void;
}

const ServiceCategorySelection = memo((props: ServiceCategorySelectionProps) => {
  const {
    serviceCategories,
    selectedServiceCategoryId,
    selectCategory,
    selectCategoryService,
    selectedCategoryServices,
    selectedCategoryServiceId,
    selectedCategoryServicesLoading,
    selectedCategoryServicesError,
    refetchSelectedCategoryServices,
  } = props;

  return (
    <>
      <ServiceCategoryTabs
        serviceCategories={serviceCategories}
        selectedServiceCategoryId={selectedServiceCategoryId}
        selectServiceCategory={(categoryId) => {
          tapticSelectionChanged();
          selectCategory(categoryId);
        }}
      />
      {selectedCategoryServices ? (
        <Panel.Content>
          {selectedCategoryServices.length === 0 ? (
            <Placeholder>{L.t('make_record_step_services_not_found')}</Placeholder>
          ) : (
            <CardGrid style={{ margin: '4px 0 14px' }}>
              {selectedCategoryServices.map((categoryService) => (
                <ServiceCard
                  key={categoryService.id}
                  service={categoryService}
                  onClick={() => {
                    tapticSelectionChanged();
                    selectCategoryService(categoryService.id);
                  }}
                  isSelected={categoryService.id === selectedCategoryServiceId}
                />
              ))}
            </CardGrid>
          )}
        </Panel.Content>
      ) : selectedCategoryServicesLoading ? (
        <Panel.Content center>
          <StatusPlaceholder status="loading" />
        </Panel.Content>
      ) : selectedCategoryServicesError ? (
        <Panel.Content center>
          <StatusPlaceholder
            status="error"
            errorText={L.t('make_record_step_services_fetch_error')}
            onRetry={refetchSelectedCategoryServices}
          />
        </Panel.Content>
      ) : null}
    </>
  );
});

export default ServiceCategorySelection;
