import React, { memo } from 'react';
import { InfoRow } from '@vkontakte/vkui';
import { Avatar, SimpleCell, Title, PanelHeaderButton, Div } from '@overrided-vkui';
import { formatPrice, capitalizeFirstLetter, formatCityAddress } from '../../../utils/formatter';
import Icon28PhoneOutline from '@vkontakte/icons/dist/28/phone_outline';
import { RecordInfoProps } from '.';
import { L } from 'src/lang/L';
import { openPhoneCall } from 'src/utils/openPhoneCall';

const RecordInfoMobile: React.FC<RecordInfoProps> = memo((props) => {
  const { serviceStaff, hidePhone, onStaffClick, organizationUnit, datetime, comment, priceMin, priceMax } = props;

  const hasPrice = priceMin !== 0 && priceMax !== 0;

  return (
    <>
      <Div>
        <Title level="1" weight="bold" style={{ wordBreak: 'break-word' }}>
          {organizationUnit.title}
        </Title>
      </Div>
      <SimpleCell
        multiline
        before={<Avatar src={serviceStaff.staff.avatarUrl || ''} size={48} shadow={false} />}
        description={serviceStaff.staff.specialization}
        disabled={!onStaffClick}
        expandable={Boolean(onStaffClick)}
        onClick={onStaffClick}
      >
        {serviceStaff.staff.name}
      </SimpleCell>
      <SimpleCell disabled multiline>
        <InfoRow header={L.t('record_meta_service')} />
        {serviceStaff.service.title}
      </SimpleCell>
      <SimpleCell disabled multiline>
        <InfoRow header={L.t('record_meta_price')} />
        {hasPrice ? formatPrice(priceMin, priceMax) : L.t('price_not_specified_short')}
      </SimpleCell>
      <SimpleCell disabled multiline>
        <InfoRow header={L.t('record_meta_address')} />
        {formatCityAddress(organizationUnit.city.title, organizationUnit.address)}
      </SimpleCell>
      <SimpleCell disabled multiline>
        <InfoRow header={L.t('record_meta_time')} />
        {capitalizeFirstLetter(L.format.dateTime(datetime))}
      </SimpleCell>
      {!hidePhone && organizationUnit.phone && (
        <SimpleCell
          disabled
          multiline
          after={
            <PanelHeaderButton onClick={() => openPhoneCall(organizationUnit.phone!)}>
              <Icon28PhoneOutline />
            </PanelHeaderButton>
          }
        >
          <InfoRow header={L.t('record_meta_phone')} />
          {organizationUnit.phone}
        </SimpleCell>
      )}
      {comment && (
        <SimpleCell disabled multiline>
          <InfoRow header={L.t('record_meta_comment')} />
          {comment}
        </SimpleCell>
      )}
    </>
  );
});

export default RecordInfoMobile;
