import React, { memo } from 'react';
import RecordInfo from '../../RecordInfo';
import { Div, Button, ModalPage, withModalPageOverlayWrapper, ModalPageHeader } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';
import { RecordInfoModalProps } from '.';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  modal: {
    paddingBottom: 16,
  },
  modal__buttons: {
    paddingBottom: 8,
    '& > * + *': {
      marginLeft: 10,
    },
  },
});

const RecordInfoModalDesktop: React.FC<RecordInfoModalProps> = memo((props) => {
  const { onClose, record, onCancelRecord, onRepeatRecord, onTransferRecord } = props;

  const mc = useStyles();

  const isActualRecord = record && new Date(record.datetime) > new Date();

  return (
    <ModalPage id="MY_RECORD" header={<ModalPageHeader>{L.t('record_card_title')}</ModalPageHeader>} onClose={onClose}>
      {record && (
        <div className={mc.modal}>
          <RecordInfo {...record} hidePhone={!isActualRecord} />
          {isActualRecord ? (
            (record.organizationUnit.bookingConfig.isAllowTransferRecord ||
              record.organizationUnit.bookingConfig.isAllowCancelRecord) && (
              <Div className={mc.modal__buttons}>
                {record.organizationUnit.bookingConfig.isAllowTransferRecord && (
                  <Button size="m" mode="secondary" onClick={onTransferRecord}>
                    {L.t('record_card_button_transfer')}
                  </Button>
                )}
                {record.organizationUnit.bookingConfig.isAllowCancelRecord && (
                  <Button size="m" mode="secondary" onClick={onCancelRecord}>
                    {L.t('record_card_button_cancel')}
                  </Button>
                )}
              </Div>
            )
          ) : (
            <Div className={mc.modal__buttons}>
              <Button size="m" mode="primary" onClick={onRepeatRecord}>
                {L.t('record_card_button_repeat')}
              </Button>
            </Div>
          )}
        </div>
      )}
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(RecordInfoModalDesktop);
