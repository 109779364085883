import React, { memo } from 'react';
import { ModalPageHeader, PanelHeaderButton, isDesktop } from '@overrided-vkui';
import { ModalPageHeaderProps } from '@vkontakte/vkui/dist/components/ModalPageHeader/ModalPageHeader';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import { usePlatform, ANDROID } from '@vkontakte/vkui';

interface ModalPageHeaderWithCloseProps extends Omit<ModalPageHeaderProps, 'left' | 'right'> {
  aside?: React.ReactNode;
  onClose?(): void;
}

const ModalPageHeaderWithClose = memo((props: ModalPageHeaderWithCloseProps) => {
  const { aside, onClose, ...panelHeaderProps } = props;

  const IS_PLATFORM_ANDROID = usePlatform() === ANDROID;

  const closeButton = onClose && !isDesktop && (
    <PanelHeaderButton onClick={onClose}>
      {IS_PLATFORM_ANDROID ? <Icon24Cancel /> : <Icon24Dismiss />}
    </PanelHeaderButton>
  );

  return (
    <ModalPageHeader
      left={IS_PLATFORM_ANDROID ? closeButton : aside}
      right={IS_PLATFORM_ANDROID ? aside : closeButton}
      {...panelHeaderProps}
    />
  );
});

export default ModalPageHeaderWithClose;
