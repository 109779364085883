import React, { memo } from 'react';
import { Avatar, SimpleCell, Group } from '@overrided-vkui';
import { CatalogOrganizationUnitProps } from '.';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  group: {
    '& .Group__separator, &>:not(.Header):first-child': {
      marginTop: 0,
    },
  },
  cell: {
    position: 'relative',
    '& .Avatar': {
      padding: '12px 16px 12px 0!important',
    },
  },
});

const CatalogOrganizationUnitDesktop: React.FC<CatalogOrganizationUnitProps> = memo((props) => {
  const { logoUrl, title, address, onClick } = props;

  const mc = useStyles();

  return (
    <Group className={mc.group}>
      <SimpleCell
        before={<Avatar src={logoUrl || ''} mode="image" size={72} />}
        className={mc.cell}
        description={address}
        onClick={onClick}
      >
        {title}
      </SimpleCell>
    </Group>
  );
});

export default CatalogOrganizationUnitDesktop;
