import unionize, { ofType, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';
import { GetGeodataResult } from 'src/utils/getGeodata';

interface Phone {
  number: string;
  sign: string;
}

export interface UserReducerState {
  isFirstVisit: boolean;
  areNotificationsEnabled: boolean;
  phone: Phone | null;
  geodata?: GetGeodataResult;
  accessToken: string;
}

export const userActions = unionize(
  {
    setUserPhone: ofType<Phone>(),
    setUserGeodata: ofType<GetGeodataResult>(),
    setAreNotificationsEnabled: ofType<boolean>(),
  },
  unionizeConfig,
);

type UserAction = UnionOf<typeof userActions>;

const initialState: UserReducerState = {
  isFirstVisit: false,
  areNotificationsEnabled: false,
  phone: null,
  geodata: void 0,
  accessToken: '',
};

/**
 * @param {UserReducerState} state
 * @param {UserAction} action
 * @returns {unknown}
 */
export function userReducer(state: UserReducerState = initialState, action: UserAction) {
  return userActions.match<UserReducerState>(action, {
    setUserPhone: (phone) => ({ ...state, phone }),
    setUserGeodata: (geodata) => ({ ...state, geodata }),
    setAreNotificationsEnabled: (areNotificationsEnabled) => ({ ...state, areNotificationsEnabled }),
    default: () => state,
  });
}
