import styled from '@material-ui/styles/styled';
import { Group } from '@vkontakte/vkui';
import { GroupProps } from '@vkontakte/vkui/dist/components/Group/Group';

export default styled(Group)({
  marginTop: -1,
  '&:last-of-type .Group__separator': {
    display: 'initial',
  },
  '&:last-of-type > .Group__separator': {
    display: 'none',
  },
  '& .Separator': {
    transform: 'translateY(-1px)',
    height: 0,
    zIndex: 1,
    position: 'relative',
    pointerEvents: 'none',
  },
  '& .Separator__in': {
    marginLeft: '24px!important',
    marginRight: '24px!important',
    position: 'relative',
    zIndex: 1,
    height: 1,
    backgroundColor: 'var(--steel_gray_80)',
    transformOrigin: 'center center',
  },
  '& .Group__description': {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
  },
}) as React.FC<GroupProps>;
