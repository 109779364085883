import React, { memo, useMemo, useCallback } from 'react';
import UserRecordsPanelComponent from '../panelComponents/UserRecordsPanel';
import { GqlErrorCode, useCancelRecordMutation, useGetUserRecordsQuery } from 'src/gql/generated/types';
import { getFutureRecords, getPreviousRecords } from 'src/utils/selectors';
import RecordInfoModal from '../atomic/modals/RecordInfoModal';
import { ScreenSpinner, OverlayPopoutWrapper, useSnackbar } from '@overrided-vkui';
import { deleteCacheRecord } from 'src/utils/apolloCache';
import { statEvents, StatEvent } from 'src/utils/statEvents';
import { getGqlErrorCode } from 'src/types/gql';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import { L } from 'src/lang/L';
import CancelRecordAlert from '../atomic/alerts/CancelRecordAlert';

interface UserRecordsPanelProps {
  id: string;
  panelHeader?: React.ReactNode;
  modalRecordId?: number;
  openRecordModal(recordId: number): void;
  closeRecordModal(): void;
  recordCancelAlertOpened: boolean;
  openRecordCancelAlert(recordId: number): void;
  closeRecordCancelAlert(): void;
  openUnit(unitId: number): void;
  openTransferRecord(serviceId: number, serviceStaffId: number, recordId: number): void;
}

const UserRecordsPanel = memo((props: UserRecordsPanelProps) => {
  const {
    id: panelId,
    panelHeader,
    modalRecordId,
    openRecordModal,
    closeRecordModal,
    openUnit,
    openTransferRecord,
    recordCancelAlertOpened,
    openRecordCancelAlert,
    closeRecordCancelAlert,
  } = props;

  const openSnackbar = useSnackbar();

  /* Записи пользователя */

  const {
    data: userRecordsData,
    loading: userRecordsLoading,
    error: userRecordsError,
    refetch: refetchUserRecords,
  } = useGetUserRecordsQuery({ notifyOnNetworkStatusChange: true });

  const records = useMemo(() => {
    return userRecordsData?.currentUser.records.map((record) => ({
      id: record.id,
      datetime: record.datetime,
      priceMin: record.priceMin,
      priceMax: record.priceMax,
      unitTitle: record.organizationUnit.title,
      serviceTitle: record.serviceStaff.service.title,
      unitLogoUrl: record.organizationUnit.logoUrl || void 0,
    }));
  }, [userRecordsData]);

  const futureRecords = useMemo(() => records && getFutureRecords(records), [records]);
  const previousRecords = useMemo(() => records && getPreviousRecords(records), [records]);

  /* Модальное окно */

  const modalRecord = userRecordsData?.currentUser.records.find((record) => record.id === modalRecordId);

  const onTransferRecordHandler = useCallback(() => {
    if (!modalRecord) return;

    openTransferRecord(modalRecord.serviceStaff.service.id, modalRecord.serviceStaff.id, modalRecord.id);
  }, [modalRecord, openTransferRecord]);

  const onRepeatRecordHandler = useCallback(() => {
    if (!modalRecord) return;

    openUnit(modalRecord.organizationUnit.id);
  }, [modalRecord, openUnit]);

  /* Удаление записи */

  const [cancelRecord, { loading: cancelRecordLoading }] = useCancelRecordMutation({
    update(cache, { data }) {
      if (data && modalRecord) {
        statEvents.send(StatEvent.Canceled, { organizationUnitId: modalRecord.organizationUnit.id });
        deleteCacheRecord(cache, modalRecord.id);
      }
    },
    onCompleted() {
      closeRecordModal();
      openSnackbar(<SuccessSnackbar text={L.t('cancel_record_done')} onClose={() => null} />);
    },
    onError(e) {
      if (getGqlErrorCode(e) === GqlErrorCode.CancelRecordForbidden) {
        openSnackbar(<ErrorRetrySnackbar text={L.t('cancel_record_forbidden')} onClose={() => null} />);
      } else {
        openSnackbar(<ErrorRetrySnackbar text={L.t('cancel_record_error')} onClose={() => null} />);
      }
    },
  });

  /* Рендер */

  return (
    <>
      <UserRecordsPanelComponent
        id={panelId}
        panelHeader={panelHeader}
        refetchRecords={() => refetchUserRecords().catch(() => null)}
        isRecordsFetching={userRecordsLoading}
        isRecordsFetchError={Boolean(userRecordsError)}
        openRecord={openRecordModal}
        records={
          previousRecords &&
          futureRecords && {
            future: futureRecords,
            previous: previousRecords,
          }
        }
      />
      <OverlayPopoutWrapper show={cancelRecordLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
      <CancelRecordAlert
        show={Boolean(modalRecord) && recordCancelAlertOpened}
        onClose={closeRecordCancelAlert}
        onCancelRecord={() => {
          modalRecord && cancelRecord({ variables: { recordId: modalRecord.id } }).catch(() => null);
        }}
      />
      <RecordInfoModal
        show={Boolean(modalRecord)}
        onClose={closeRecordModal}
        record={modalRecord!}
        onCancelRecord={() => modalRecord && openRecordCancelAlert(modalRecord.id)}
        onRepeatRecord={onRepeatRecordHandler}
        onTransferRecord={onTransferRecordHandler}
      />
    </>
  );
});

export default UserRecordsPanel;
