import React, { memo } from 'react';
import VKSimpleCell, { SimpleCellProps } from '@vkontakte/vkui/dist/components/SimpleCell/SimpleCell';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';

const useStyles = makeStyles({
  simpleCell: {
    '& .SimpleCell__children': {
      wordBreak: 'break-word',
    },
    paddingLeft: 24,
    paddingRight: 24,
    minHeight: 40,
    '--text_secondary': 'var(--steel_gray_400)',
    '& .SimpleCell__children, & .SimpleCell__indicator': {
      fontSize: 15,
      lineHeight: '20px',
    },
    '& .SimpleCell__main': {
      padding: '10px 0',
    },
    '& >.Icon:first-child': {
      padding: '8px 12px 8px 0',
    },
  },
  simpleCell_tappable: {
    '&.Tappable': {
      cursor: 'pointer',
      transition: 'none',
      '&:hover': {
        backgroundColor: 'var(--steel_gray_40)',
      },
    },
  },
});

const SimpleCell: React.FC<SimpleCellProps> = memo((props) => {
  const { className, disabled, onClick, ...rest } = props;
  const mc = useStyles();

  return (
    <VKSimpleCell
      className={c(className, mc.simpleCell, !disabled && onClick && mc.simpleCell_tappable)}
      {...rest}
      onClick={onClick}
      disabled
      expandable={false}
    />
  );
});

export default SimpleCell;
