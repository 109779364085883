import React, { memo } from 'react';
import { View } from '@vkontakte/vkui';
import { useRouter } from 'react-router5';
import { RootRoute } from 'src/router';
import ReloginPanel from '../panels/ReloginPanel';
import { useSnackbar } from '@overrided-vkui';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';
import { L } from 'src/lang/L';

interface ReloginViewProps {
  id: string;
}

const ReloginView = memo((props: ReloginViewProps) => {
  const { id: viewId } = props;
  const router = useRouter();

  const openSnackbar = useSnackbar();

  return (
    <View id={viewId} activePanel={RootRoute.RELOGIN}>
      <ReloginPanel
        id={RootRoute.RELOGIN}
        onNext={() => {
          openSnackbar(<SuccessSnackbar text={L.t('relogin_success')} onClose={() => null} />);
          router.navigate(RootRoute.ORGANIZATION_SETTINGS);
        }}
      />
    </View>
  );
});

export default ReloginView;
