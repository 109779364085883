import React, { memo } from 'react';
import { parseWidgetPreviewCode } from './utils';
import { makeStyles } from '@material-ui/styles';
import { Caption, getPlatformClassName } from '@overrided-vkui';
import { ReactComponent as Icon24InfoCircleOutline } from 'src/assets/info-circle-outline-24.svg';

const useSyles = makeStyles({
  widget: {
    width: '100%',
  },
  widgetList: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: 12,
    background: 'var(--snippet_background)',
    listStyle: 'none',
    margin: 0,
    padding: '2px 0',
  },
  widgetItem: {
    padding: '12px 16px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 24,
    '&:not(:last-child):after': {
      content: '""',
      position: 'absolute',
      bottom: -0.5,
      left: 16,
      right: 16,
      height: 1,
      background: 'var(--separator_common)',
      '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)': {
        transform: 'scaleY(.5)',
      },
    },
  },
  widgetItem__left: {
    overflow: 'hidden',
  },
  widgetItem__right: {},
  widgetItem__title: {
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'var(--text_primary)',
  },
  widgetItem__descr: {
    marginTop: 5,
    alignItems: 'center',
    color: 'var(--text_secondary)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    textOverflow: 'ellipsis',
  },
  widgetDescription: {
    color: 'var(--text_secondary)',
    marginTop: 18,
    '&--desktop': {
      color: 'var(--steel_gray_400)',
      textTransform: 'initial',
      fontSize: 13,
      lineHeight: '16px',
      fontWeight: 400,
      marginTop: 12,
    },
  },
  invalidIcon: {
    display: 'block',
    color: 'var(--destructive)',
    marginLeft: 12,
  },
});

interface WidgetPreviewProps {
  code: string;
  description: React.ReactNode;
  invalid?: boolean;
}

const WidgetPreview: React.FC<WidgetPreviewProps> = memo((props) => {
  const { code, invalid, description } = props;

  const widgetData = parseWidgetPreviewCode(code);
  const mc = useSyles();

  if (!widgetData) {
    return null;
  }

  return (
    <div className={mc.widget}>
      <ul className={mc.widgetList}>
        {widgetData.rows.slice(0, 3).map((row, index) => (
          <li key={index} className={mc.widgetItem}>
            <div className={mc.widgetItem__left}>
              <Caption weight="regular" level="1" className={mc.widgetItem__title}>
                {row.title}
              </Caption>
              {row.descr && (
                <Caption weight="regular" level="2" className={mc.widgetItem__descr}>
                  {row.descr}
                </Caption>
              )}
            </div>
            {invalid && (
              <div className={mc.widgetItem__right}>
                <Icon24InfoCircleOutline className={mc.invalidIcon} />
              </div>
            )}
          </li>
        ))}
      </ul>
      <Caption level="1" weight="semibold" caps className={getPlatformClassName(mc.widgetDescription)}>
        {description}
      </Caption>
    </div>
  );
});

export default WidgetPreview;
