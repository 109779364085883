import React, { memo, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Div, HorizontalScroll } from '@overrided-vkui';
import CategoryIcon from '../CategoryIcon';
import ButtonTab from '../ButtonTab';
import { L } from 'src/lang/L';
import c from 'classnames';

const useStyles = makeStyles({
  tabs: {
    display: 'flex',
    width: 'fit-content',
    '& > * + *': {
      marginLeft: 12,
    },
  },
  tab: {
    scrollMargin: '96px',
  },
  tab_active: {},
});

interface Category {
  id: number;
  slug: string;
  title: string;
}

interface CategoryTabsProps {
  className?: string;
  categories: Category[];
  onClick(category?: Category): void;
  selectedCategoryId?: number;
}

const CategoryTabs: React.FC<CategoryTabsProps> = memo((props) => {
  const { className, categories, onClick, selectedCategoryId } = props;

  const tabsRef = useRef<HTMLDivElement>(null);

  const mc = useStyles();

  useEffect(() => {
    if (!tabsRef.current || !selectedCategoryId) return;
    const activeTabEl = tabsRef.current.querySelector(`.${mc.tab_active}`);

    if (activeTabEl && 'scrollIntoView' in activeTabEl) {
      (activeTabEl as any).scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'nearest',
      });
    }
  }, [selectedCategoryId, mc.tab_active]);

  return (
    <HorizontalScroll className={className}>
      <Div className={mc.tabs} getRootRef={tabsRef}>
        {categories.map((category) => (
          <ButtonTab
            key={category.id}
            className={c(mc.tab, { [mc.tab_active]: selectedCategoryId === category.id })}
            selected={selectedCategoryId === category.id}
            before={<CategoryIcon size={24} categoryTitle={category.title} />}
            onClick={() => onClick(category)}
          >
            {L.t(category.title)}
          </ButtonTab>
        ))}
      </Div>
    </HorizontalScroll>
  );
});

export default CategoryTabs;
