import RecordInfoMobile from './RecordInfoMobile';
import RecordInfoDesktop from './RecordInfoDesktop';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';

export interface RecordInfoProps {
  hidePhone?: boolean;
  organizationUnit: {
    title: string;
    address: string;
    phone?: string | null;
    city: { title: string };
  };
  serviceStaff: {
    service: {
      title: string;
    };
    staff: {
      name: string;
      avatarUrl?: string | null;
      specialization: string;
    };
  };
  priceMin: number;
  priceMax: number;
  datetime: string; // ISO8601
  comment?: string | null;
  onStaffClick?(): void;
}

export default getPlatformComponent<RecordInfoProps>({ mobile: RecordInfoMobile, desktop: RecordInfoDesktop });
