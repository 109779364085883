import React, { memo } from 'react';
import { formatPrice, capitalizeFirstLetter, formatCityAddress } from '../../../utils/formatter';
import { Div, SimpleCell, Title, Text, Avatar } from '@overrided-vkui';
import Icon28ArticleOutline from '@vkontakte/icons/dist/28/article_outline';
import Icon28CalendarOutline from '@vkontakte/icons/dist/28/calendar_outline';
import Icon28PlaceOutline from '@vkontakte/icons/dist/28/place_outline';
import Icon28MoneyCircleOutline from '@vkontakte/icons/dist/28/money_circle_outline';
import Icon28CommentOutline from '@vkontakte/icons/dist/28/comment_outline';
import Icon28PhoneOutline from '@vkontakte/icons/dist/28/phone_outline';
import { makeStyles } from '@material-ui/styles';
import { RecordInfoProps } from '.';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  title: {
    margin: '0!important',
    fontSize: '20px!important',
    lineHeight: '24px!important',
    fontWeight: '500!important' as any,
    wordBreak: 'break-word',
  },
  fields: {
    marginTop: 4,
  },
  icon: {
    color: 'var(--content_placeholder_icon)!important',
    paddingLeft: '0!important',
    paddingRight: '12px!important',
    alignSelf: 'flex-start',
    width: '24px!important',
    height: '24px!important',
    '&>svg': {
      width: '24px!important',
      height: '24px!important',
    },
  },
});

const RecordInfoDesktop: React.FC<RecordInfoProps> = memo((props) => {
  const { serviceStaff, hidePhone, onStaffClick, organizationUnit, datetime, comment, priceMin, priceMax } = props;

  const hasPrice = priceMin !== 0 && priceMax !== 0;

  const mc = useStyles();

  return (
    <>
      <Div>
        <Title level="2" weight="medium" className={mc.title}>
          {organizationUnit.title}
        </Title>
      </Div>
      <SimpleCell
        multiline
        before={<Avatar src={serviceStaff.staff.avatarUrl || ''} size={48} shadow={false} />}
        description={serviceStaff.staff.specialization}
        disabled={!onStaffClick}
        expandable={Boolean(onStaffClick)}
        onClick={onStaffClick}
      >
        {serviceStaff.staff.name}
      </SimpleCell>
      <div className={mc.fields}>
        <SimpleCell
          disabled
          multiline
          before={<Icon28ArticleOutline className={mc.icon} />}
          title={L.t('record_meta_service')}
        >
          <Text weight="regular">{serviceStaff.service.title}</Text>
        </SimpleCell>
        <SimpleCell
          disabled
          multiline
          before={<Icon28CalendarOutline className={mc.icon} />}
          title={L.t('record_meta_time')}
        >
          <Text weight="regular">{capitalizeFirstLetter(L.format.dateTime(datetime))}</Text>
        </SimpleCell>
        <SimpleCell
          disabled
          multiline
          before={<Icon28PlaceOutline className={mc.icon} />}
          title={L.t('record_meta_address')}
        >
          <Text weight="regular">{formatCityAddress(organizationUnit.city.title, organizationUnit.address)}</Text>
        </SimpleCell>
        <SimpleCell
          disabled
          multiline
          before={<Icon28MoneyCircleOutline className={mc.icon} />}
          title={L.t('record_meta_price')}
        >
          <Text weight="regular">{hasPrice ? formatPrice(priceMin, priceMax) : L.t('price_not_specified_short')} </Text>
        </SimpleCell>
        {!hidePhone && organizationUnit.phone && (
          <SimpleCell
            disabled
            multiline
            before={<Icon28PhoneOutline className={mc.icon} />}
            title={L.t('record_meta_phone')}
          >
            <Text weight="regular">{organizationUnit.phone} </Text>
          </SimpleCell>
        )}
        {comment && (
          <SimpleCell
            disabled
            multiline
            before={<Icon28CommentOutline className={mc.icon} />}
            title={L.t('record_meta_comment')}
          >
            <Text weight="regular">{comment}</Text>
          </SimpleCell>
        )}
      </div>
    </>
  );
});

export default RecordInfoDesktop;
