import React, { memo, useCallback } from 'react';
import { View } from '@vkontakte/vkui';
import YClientsOrganizationUnitsEditPanel from '../panels/YClientsOrganizationUnitsEditPanel';
import { RootRoute } from 'src/router';

interface CurrentYClientsUnitsEditViewProps {
  id: string;
}

const CurrentYClientsUnitsEditView = memo((props: CurrentYClientsUnitsEditViewProps) => {
  const { id: viewId } = props;

  const goHistoryBack = useCallback(() => window.history.back(), []);

  /* Рендер */

  return (
    <View id={viewId} activePanel={RootRoute.CURRENT_YCLIENTS_UNITS_EDIT}>
      <YClientsOrganizationUnitsEditPanel id={RootRoute.CURRENT_YCLIENTS_UNITS_EDIT} onBack={goHistoryBack} />
    </View>
  );
});

export default CurrentYClientsUnitsEditView;
