import React, { memo, useCallback, useEffect, useMemo } from 'react';
import StaffServiceSelectPanel from '../panelComponents/StaffServiceSelectPanel';
import { Staff } from '../../components/atomic/StaffItem';
import { ServiceStaff } from './OrganizationUnitStepServiceStaff';
import { useGetStaffServiceCategoriesQuery, useGetStaffCategoryServicesQuery } from 'src/gql/generated/types';
import { RootRoute } from 'src/router';
import { useRouteNode } from 'react-router5';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { filterByTitle } from 'src/utils/filter';
import { useSnackbar } from '@overrided-vkui';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import { L } from 'src/lang/L';

interface Service {
  id: number;
  title: string;
  description?: string | null;
}

interface OrganizationUnitStepStaffServicesProps {
  id: string;
  staff: Staff;
  routeName: RootRoute;
  onNext(service: Service, serviceStaff: ServiceStaff): void;
  onBack(): void;
}

const SELECTED_CATEGORY_ID_ROUTE_PARAM = 'staffServiceCategoryId';
const SELECTED_SERVICE_ID_ROUTE_PARAM = 'staffServiceId';
const SERVICE_SEARCH_ROUTE_PARAM = 'staffQ';

const OrganizationUnitStepStaffServices = memo((props: OrganizationUnitStepStaffServicesProps) => {
  const { staff, onBack, routeName, onNext, id: panelId } = props;

  const openSnackbar = useSnackbar();

  const { route, router } = useRouteNode(routeName);

  /* Получаем список категорий сотрудника */

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useGetStaffServiceCategoriesQuery({ variables: { staffId: staff.id }, fetchPolicy: 'cache-and-network' });

  const categories = categoriesData?.staffServiceCategories || void 0;

  /* Работа с выбранной категорией */

  const selectedCategoryId = Number(route.params[SELECTED_CATEGORY_ID_ROUTE_PARAM]) || void 0;
  const setSelectedCategoryId = useCallback(
    (categoryId?: number) => {
      router.navigate(
        route.name,
        {
          ...route.params,
          [SELECTED_CATEGORY_ID_ROUTE_PARAM]: categoryId,
          [SELECTED_SERVICE_ID_ROUTE_PARAM]: void 0,
        },
        { replace: true },
      );
    },
    [router, route],
  );

  /* Работа с выбранной услугой внутри категории */

  const selectedCategoryServiceId = Number(route.params[SELECTED_SERVICE_ID_ROUTE_PARAM]) || void 0;
  const setSelectedCategoryServiceId = useCallback(
    (categoryServiceId: number) => {
      router.navigate(
        route.name,
        {
          ...route.params,
          [SELECTED_SERVICE_ID_ROUTE_PARAM]:
            selectedCategoryServiceId === categoryServiceId ? void 0 : categoryServiceId,
        },
        { replace: true },
      );
    },
    [router, route, selectedCategoryServiceId],
  );

  /* Работа с поиском внутри категории */

  const [categoryServicesSearchValue, setCategoryServicesSearchValue] = useSearchQuery(
    routeName,
    SERVICE_SEARCH_ROUTE_PARAM,
  );

  /* После загрузки данных о категориях устанавливаем первую категорию активной */

  useEffect(() => {
    if (!selectedCategoryId && categories) {
      setSelectedCategoryId(categories[0]?.id || void 0);
    }
  }, [categories, selectedCategoryId]); // eslint-disable-line

  /* Подгрузка данных об услугах внутри категории */

  const {
    data: categoryServicesData,
    loading: categoryServicesLoading,
    error: categoryServicesError,
    refetch: refetchCategoryServices,
  } = useGetStaffCategoryServicesQuery({
    variables: { categoryId: selectedCategoryId!, staffId: staff.id },
    skip: !categories || !selectedCategoryId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const categoryServices = categoryServicesData?.categoryServices || void 0;

  /* Фильтруем данные по поисковому запросу */

  const foundCategoryServices = useMemo(() => {
    if (!categoryServices) return;

    return filterByTitle(categoryServices, categoryServicesSearchValue).filter((categoryService) =>
      categoryService.serviceStaff.some((serviceStaffItem) => serviceStaffItem.staff.id === staff.id),
    );
  }, [categoryServices, categoryServicesSearchValue, staff.id]);

  /* Обработчик перехода далее */

  const selectedService = useMemo(
    () => foundCategoryServices?.find((categoryService) => categoryService.id === selectedCategoryServiceId),
    [selectedCategoryServiceId, foundCategoryServices],
  );

  const onNextHandler = useCallback(() => {
    if (!selectedService) return;

    const serviceStaff = selectedService.serviceStaff.find(
      (serviceStaffItem) => serviceStaffItem.staff.id === staff.id,
    );

    if (serviceStaff) {
      onNext(selectedService, serviceStaff);
    } else {
      openSnackbar(
        <ErrorRetrySnackbar text={L.t('make_record_step_staff_services_select_fetch_error')} onClose={() => null} />,
      );
    }
  }, [selectedService, onNext, openSnackbar, staff.id]);

  return (
    <StaffServiceSelectPanel
      id={panelId}
      onBack={onBack}
      staff={staff}
      serviceCategories={categories}
      serviceCategoriesLoading={categoriesLoading}
      serviceCategoriesError={Boolean(categoriesError)}
      selectedCategoryId={selectedCategoryId}
      selectCategory={setSelectedCategoryId}
      selectedCategoryServiceId={selectedService?.id}
      selectCategoryService={setSelectedCategoryServiceId}
      categoryServicesSearchValue={categoryServicesSearchValue}
      setCategoryServicesSearchValue={setCategoryServicesSearchValue}
      refetchSelectedCategoryServices={() => refetchCategoryServices().catch(() => null)}
      selectedCategoryServicesLoading={categoryServicesLoading}
      selectedCategoryServicesError={Boolean(categoryServicesError)}
      selectedCategoryServices={foundCategoryServices}
      onNext={onNextHandler}
    />
  );
});

export default OrganizationUnitStepStaffServices;
