import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { View } from '@vkontakte/vkui';
import { isDesktop, Panel, PanelHeaderClose, PanelHeaderButton } from '@overrided-vkui';
import { useRouteNode } from 'react-router5';
import { RootRoute } from '../../router';
import OrganizationUnitStepMain from '../panels/OrganizationUnitStepMain';
import OrganizationUnitStepServiceStaff from '../panels/OrganizationUnitStepServiceStaff';
import OrganizationUnitStepStaff from '../panels/OrganizationUnitStepStaff';
import OrganizationUnitStepServiceStaffSlots from '../panels/OrganizationUnitStepServiceStaffSlots';
import OrganizationUnitStepStaffServices from '../panels/OrganizationUnitStepStaffServices';
import OrganizationUnitStepConfirm, { RecordData } from '../panels/OrganizationUnitStepConfirm';
import OrganizationUnitStepPhone from '../panels/OrganizationUnitStepPhone';
import OrganizationUnitStepResult from '../panelComponents/RecordResultPanel';
import { useGetOrganizationUnitQuery } from 'src/gql/generated/types';
import { tapticNotification } from 'src/utils/taptic';
import { L } from 'src/lang/L';
import vkBridge from '@vkontakte/vk-bridge';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import OrganizationUnitNotActivePanel from '../panelComponents/OrganizationUnitNotActivePanel';
import { PhoneData } from '../hocs/withPhoneRequest';

interface Staff {
  id: number;
  name: string;
  avatarUrl?: string | null;
  specialization: string;
}

interface Service {
  id: number;
  title: string;
  description?: string | null;
}

interface ServiceStaff {
  id: number;
  staff: Staff;
  priceMin: number;
  priceMax: number;
  duration: number;
}

enum RecordStep {
  MAIN = 'main',
  STAFF = 'staff',
  SERVICE_STAFF = 'serviceStaff',
  STAFF_SERVICES = 'staffServices',
  SLOTS = 'slots',
  CONFIRM = 'confirm',
  PHONE = 'phone',
  RESULT = 'result',
}

interface OrganizationUnitViewProps {
  id: string;
}

const OrganizationUnitView = memo((props: OrganizationUnitViewProps) => {
  const { id: viewId } = props;

  const { route, router } = useRouteNode(RootRoute.ORGANIZATION_UNIT);
  const organizationUnitId = Number(route.params.organizationUnitId);
  const isOrganizationUnitFromAnotherPanel = Boolean(route.params.from);

  /* Шаги оформления записи */

  const step = (route.params.step || RecordStep.MAIN) as RecordStep;
  const setStep = useCallback(
    (nextStep: RecordStep, skipFromStep?: boolean) => {
      router.navigate(RootRoute.ORGANIZATION_UNIT, {
        ...route.params,
        step: nextStep,
        fromStep: skipFromStep ? void 0 : step,
      });
    },
    [route, step, router],
  );

  const activeStep = route.name === RootRoute.ORGANIZATION_UNIT ? step : RecordStep.MAIN;
  const prevStep = route.params.fromStep as RecordStep | undefined;
  const history = prevStep && prevStep !== activeStep ? [prevStep, activeStep] : [activeStep];

  /**
   * На мобильных устройствах устанавливаем внешний хэш миниаппа,
   * чтобы делиться приложением ссылкой, с привязкой к открытому салону
   */

  useEffect(() => {
    if (isDesktop || !organizationUnitId) return;

    vkBridge.send('VKWebAppSetLocation', { location: `/unit/${organizationUnitId}` }).catch(() => null);

    return () => {
      vkBridge.send('VKWebAppSetLocation', { location: '' }).catch(() => null);
    };
  }, [organizationUnitId]);

  /* Получаем данные о салоне */

  const {
    data: organizationUnitData,
    error: organizationUnitError,
    loading: organizationUnitLoading,
    refetch: refetchOrganizationUnit,
  } = useGetOrganizationUnitQuery({
    variables: { organizationUnitId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const organizationUnit = organizationUnitData?.organizationUnit || void 0;

  /* Коллебки */

  const goHistoryBack = useCallback(() => window.history.back(), []);
  const openRecords = useCallback(() => router.navigate(RootRoute.MAIN_RECORDS), [router]);
  const openCatalog = useCallback(() => router.navigate(RootRoute.MAIN_CATALOG), [router]);
  const clearRecordState = useCallback(() => {
    router.navigate(RootRoute.ORGANIZATION_UNIT, { organizationUnitId }, { reload: true });
  }, [router, organizationUnitId]);

  /* Данные процесса записи */

  const [service, setService] = useState<Service>();
  const [staff, setStaff] = useState<Staff>();
  const [serviceStaff, setServiceStaff] = useState<ServiceStaff>();
  const [slotDatetime, setSlotDatetime] = useState<string>();
  const [slotServiceStaff, setSlotServiceStaff] = useState<ServiceStaff[]>([]);
  const [phone, setPhone] = useState<PhoneData>();
  const [record, setRecord] = useState<RecordData>();

  /**
   * Обязательные поля для каждого из шагов записи. Ниже идет проверка на наличие
   * этих полей на каждом из шагов записи. Защищает от открытия промежуточного
   * шага записи по прямой ссылке.
   */

  const stepRequiredProps = {
    [RecordStep.MAIN]: {},
    [RecordStep.STAFF]: {},
    [RecordStep.STAFF_SERVICES]: {
      staff: staff!, // eslint-disable-line
    },
    [RecordStep.SERVICE_STAFF]: {
      serviceId: service?.id!, // eslint-disable-line
    },
    [RecordStep.SLOTS]: {
      serviceId: service?.id!, // eslint-disable-line
    },
    [RecordStep.PHONE]: {
      serviceId: service?.id!, // eslint-disable-line
    },
    [RecordStep.CONFIRM]: {
      organizationUnit: organizationUnit!, // eslint-disable-line
      phone: phone!, // eslint-disable-line
      service: service!, // eslint-disable-line
      serviceStaff: serviceStaff!, // eslint-disable-line
      slotDatetime: slotDatetime!, // eslint-disable-line
    },
    [RecordStep.RESULT]: {
      record: record!, // eslint-disable-line
    },
  };

  const hasStepRequiredProps = useMemo(() => {
    return !Object.values(stepRequiredProps[activeStep]).some((value) => value === void 0);
  }, [activeStep, stepRequiredProps]);

  /* Рендер */

  const leftButton = useMemo(
    () =>
      isOrganizationUnitFromAnotherPanel && hasStepRequiredProps ? (
        <PanelHeaderClose onClick={goHistoryBack}>{L.t('cancel')}</PanelHeaderClose>
      ) : (
        <PanelHeaderButton onClick={openCatalog}>{L.t('main_tab_catalog')}</PanelHeaderButton>
      ),
    [isOrganizationUnitFromAnotherPanel, hasStepRequiredProps, openCatalog, goHistoryBack],
  );

  if (!organizationUnit) {
    return (
      <View key="organizationu-unit-fetching" id={viewId} activePanel={RootRoute.ORGANIZATION_UNIT}>
        <Panel id={RootRoute.ORGANIZATION_UNIT}>
          <Panel.Header left={leftButton} transparent separator={false} />
          <Panel.Content center>
            {organizationUnitLoading ? (
              <StatusPlaceholder status="loading" />
            ) : organizationUnitError ? (
              <StatusPlaceholder
                status="error"
                errorText={L.t('make_record_step_services_unit_fetch_error')}
                onRetry={() => refetchOrganizationUnit().catch(() => null)}
              />
            ) : null}
          </Panel.Content>
        </Panel>
      </View>
    );
  }

  if (!organizationUnit.isActive) {
    return (
      <View key="organizationu-unit-not-active" id={viewId} activePanel={RootRoute.ORGANIZATION_UNIT}>
        <OrganizationUnitNotActivePanel
          id={RootRoute.ORGANIZATION_UNIT}
          title={organizationUnit.title}
          phone={organizationUnit.phone || void 0}
          logoUrl={organizationUnit.logoUrl || void 0}
          leftButton={leftButton}
          openCatalog={openCatalog}
        />
      </View>
    );
  }

  return (
    <View
      key="organization-unit"
      id={viewId}
      activePanel={hasStepRequiredProps ? activeStep : RecordStep.MAIN}
      history={hasStepRequiredProps ? history : []}
      onSwipeBack={goHistoryBack}
      onTransition={({ to }) => {
        if (to === RecordStep.RESULT) {
          setService(void 0);
          setStaff(void 0);
          setServiceStaff(void 0);
          setSlotDatetime(void 0);
          setSlotServiceStaff([]);
        }
      }}
    >
      <OrganizationUnitStepMain
        id={RecordStep.MAIN}
        routeName={RootRoute.ORGANIZATION_UNIT}
        leftButton={leftButton}
        organizationUnit={organizationUnit}
        onNext={(service) => {
          setService(service);
          setStep(RecordStep.SERVICE_STAFF);
        }}
        onNextWithStaff={(staff) => {
          setStaff(staff);
          setStep(RecordStep.STAFF_SERVICES);
        }}
        openStaffStep={() => setStep(RecordStep.STAFF)}
      />
      <OrganizationUnitStepStaff
        id={RecordStep.STAFF}
        staff={organizationUnit.staff}
        onNext={(staff) => {
          setStaff(staff);
          setStep(RecordStep.STAFF_SERVICES);
        }}
        onBack={goHistoryBack}
      />
      <OrganizationUnitStepStaffServices
        id={RecordStep.STAFF_SERVICES}
        {...stepRequiredProps[RecordStep.STAFF_SERVICES]}
        routeName={RootRoute.ORGANIZATION_UNIT}
        onBack={goHistoryBack}
        onNext={(service, serviceStaff) => {
          setService(service);
          setServiceStaff(serviceStaff);
          setStep(RecordStep.SLOTS);
        }}
      />
      <OrganizationUnitStepServiceStaff
        id={RecordStep.SERVICE_STAFF}
        {...stepRequiredProps[RecordStep.SERVICE_STAFF]}
        onBack={goHistoryBack}
        onNext={(serviceStaff) => {
          serviceStaff && setServiceStaff(serviceStaff);
          setStep(RecordStep.SLOTS);
        }}
      />
      <OrganizationUnitStepServiceStaffSlots
        id={RecordStep.SLOTS}
        {...stepRequiredProps[RecordStep.SLOTS]}
        routeName={RootRoute.ORGANIZATION_UNIT}
        serviceStaffId={serviceStaff?.id}
        onBack={goHistoryBack}
        fetchSlotServiceStaff
        onNext={(datetime, slotServiceStaff, phone) => {
          setSlotDatetime(datetime);
          setSlotServiceStaff(slotServiceStaff);
          if (phone) {
            setPhone(phone);
            setStep(RecordStep.CONFIRM);
          } else {
            setStep(RecordStep.PHONE);
          }
        }}
      />
      <OrganizationUnitStepPhone
        id={RecordStep.PHONE}
        {...stepRequiredProps[RecordStep.PHONE]}
        onBack={goHistoryBack}
        onNext={(phone) => {
          setPhone(phone);
          setStep(RecordStep.CONFIRM);
        }}
      />
      <OrganizationUnitStepConfirm
        id={RecordStep.CONFIRM}
        {...stepRequiredProps[RecordStep.CONFIRM]}
        onChangeServiceStaff={setServiceStaff}
        slotServiceStaff={slotServiceStaff}
        onBack={goHistoryBack}
        onNext={(record) => {
          setRecord(record);
          tapticNotification('success');
          setStep(RecordStep.RESULT, true);
        }}
      />
      <OrganizationUnitStepResult
        id={RecordStep.RESULT}
        {...stepRequiredProps[RecordStep.RESULT]}
        openRecords={openRecords}
        newRecord={clearRecordState}
      />
    </View>
  );
});

export default OrganizationUnitView;
