import OrganizationUnitStepMainPanelMobile from './OrganizationUnitStepMainPanelMobile';
import OrganizationUnitStepMainPanelDesktop from './OrganizationUnitStepMainPanelDesktop';
import { getPlatformComponent } from '@overrided-vkui';
import { OrganizationUnit, Staff } from 'src/types/yclients';

interface CategoryService {
  id: number;
  title: string;
  description?: string | null;
  serviceStaff: Array<{ priceMin: number; priceMax: number; duration: number }>;
}

export interface OrganizationUnitStepMainPanelProps {
  id: string;
  unit: OrganizationUnit;
  staff: Staff[];
  leftButton?: React.ReactNode;
  categoryServicesSearchValue: string;
  setCategoryServicesSearchValue(searchValue: string): void;
  selectedCategoryId?: number;
  selectCategory(categoryId: number): void;
  selectedCategoryServices?: CategoryService[];
  selectedCategoryServicesLoading?: boolean;
  selectedCategoryServicesError?: boolean;
  refetchSelectedCategoryServices(): void;
  selectedCategoryServiceId?: number;
  selectCategoryService(categoryServiceId: number): void;
  onNext(): void;
  onStaffClick(staff: Staff): void;
  openStaffStep(): void;
  onMoreInfo(): void;
}

export default getPlatformComponent<OrganizationUnitStepMainPanelProps>({
  mobile: OrganizationUnitStepMainPanelMobile,
  desktop: OrganizationUnitStepMainPanelDesktop,
});
