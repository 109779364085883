import styled from '@material-ui/styles/styled';
import Textarea, { TextareaProps } from '@vkontakte/vkui/dist/components/Textarea/Textarea';

export default styled(Textarea)({
  padding: 1,
  '& .FormField__border': {
    borderRadius: '8px',
    '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)': {
      borderRadius: '16px',
    },
    '@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx)': {
      borderRadius: '24px',
    },
  },
  '& .Textarea__el:focus ~ .FormField__border': {
    borderRadius: '8px!important',
  },
  '& .Textarea__el': {
    lineHeight: '20px',
    fontSize: 15,
    padding: '10px 12px',
    borderRadius: '8px',
  },
}) as React.FC<TextareaProps>;
