import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Дата */
  Date: any;
  /** Дата время с таймзоной */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  /** Получить информацию о пользователе */
  currentUser: CurrentUser;
  /** Поиск по филиалам */
  organizationUnits: Array<OrganizationUnit>;
  /** Получает сеть филиалов, привязанных к группе ВК */
  vkGroupAttachedOrganization?: Maybe<AttachedOrganization>;
  /** Получает информацию об услугах филиала в определенной категории */
  categoryServices: Array<Service>;
  /** Получает информацию об категориях услуг, выполняемых мастером */
  staffServiceCategories: Array<ServiceCategory>;
  /** Информация об исполнителях/исполнителе услуги с предварительной проверкой возможности записи */
  service: Service;
  /** Получить доступные даты */
  availableDates: Array<Scalars['Date']>;
  /** Получить доступные слоты для записи на определенную услугу в указанный день */
  dateSlots: Array<Slot>;
  /** Получить сотрудника на указанный слот */
  slotServiceStaff: Array<ServiceStaff>;
  /** Получить филиал */
  organizationUnit: OrganizationUnit;
  /** Получить организации пользователя от Y-Client */
  yClientsOrganizations: Array<YClientsOrganization>;
  /** Список всех стран */
  countries: Array<Country>;
  /** Список категорий бизнеса */
  organizationUnitCategoryGroups: Array<OrganizationUnitCategoryGroup>;
};


export type QueryOrganizationUnitsArgs = {
  input?: Maybe<OrganizationUnitsInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCategoryServicesArgs = {
  categoryId: Scalars['Int'];
};


export type QueryStaffServiceCategoriesArgs = {
  staffId: Scalars['Int'];
};


export type QueryServiceArgs = {
  serviceId: Scalars['Int'];
};


export type QueryAvailableDatesArgs = {
  serviceId: Scalars['Int'];
  serviceStaffId?: Maybe<Scalars['Int']>;
};


export type QueryDateSlotsArgs = {
  serviceId: Scalars['Int'];
  serviceStaffId?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
};


export type QuerySlotServiceStaffArgs = {
  serviceId: Scalars['Int'];
  datetime: Scalars['DateTime'];
};


export type QueryOrganizationUnitArgs = {
  organizationUnitId: Scalars['Int'];
};

/** Текущий пользователь */
export type CurrentUser = {
  __typename?: 'CurrentUser';
  /** Записи */
  records: Array<Record>;
};

/** Запись */
export type Record = {
  __typename?: 'Record';
  id: Scalars['Int'];
  /** Филиал */
  organizationUnit: OrganizationUnit;
  /** Запись о сотруднике, выполняющем услугу */
  serviceStaff: ServiceStaff;
  /** Минимальная стоимость услуги на момент записи */
  priceMin: Scalars['Float'];
  /** Максимальная стоимость услуги на момент записи */
  priceMax: Scalars['Float'];
  /** Дата и время записи */
  datetime: Scalars['DateTime'];
  /** Комментарий */
  comment?: Maybe<Scalars['String']>;
};

/** Филиал */
export type OrganizationUnit = {
  __typename?: 'OrganizationUnit';
  id: Scalars['Int'];
  /** Название */
  title: Scalars['String'];
  /** Описание филила */
  description: Scalars['String'];
  /** Категория бизнеса, к которой принадлежит филиал */
  organizationUnitCategory: OrganizationUnitCategory;
  /** Сылка на логотип */
  logoUrl?: Maybe<Scalars['String']>;
  /** Номер телефона */
  phone?: Maybe<Scalars['String']>;
  /** Город, в котором находится филиал */
  city: City;
  /** Часовой пояс, в котором находится организация */
  timezone?: Maybe<Scalars['String']>;
  /** Адрес точки */
  address: Scalars['String'];
  /** Координаты точки */
  coords: Coords;
  /** Категории услуг филиала */
  serviceCategories: Array<ServiceCategory>;
  /** Список сотрудников */
  staff: Array<Staff>;
  /** Список ID сообществ ВК, к которым привязан филиал */
  attachedToVkGroupIds: Array<Scalars['Int']>;
  /** Флаг активности организации */
  isActive: Scalars['Boolean'];
  /** Настройки бронирования для филиала */
  bookingConfig: BookingConfig;
  /** ID сущности филиала в системе YCLIENTS */
  yClientsId?: Maybe<Scalars['Int']>;
};

/** Категория бизнеса */
export type OrganizationUnitCategory = {
  __typename?: 'OrganizationUnitCategory';
  id: Scalars['Int'];
  /** Название */
  title: Scalars['String'];
  /** SEO-метка */
  slug: Scalars['String'];
  /** Группа, к которой принадлежит категория */
  categoryGroup: OrganizationUnitCategoryGroup;
};

/** Группа категории бизнеса */
export type OrganizationUnitCategoryGroup = {
  __typename?: 'OrganizationUnitCategoryGroup';
  id: Scalars['Int'];
  /** Название группы категорий */
  title: Scalars['String'];
  /** SEO-метка */
  slug: Scalars['String'];
  /** Список категорий, принадлежащих группе */
  categories: Array<OrganizationUnitCategory>;
};

/** Город */
export type City = {
  __typename?: 'City';
  id: Scalars['Int'];
  /** Название */
  title: Scalars['String'];
  /** Страна, в которой находится город */
  country: Country;
};

/** Страна */
export type Country = {
  __typename?: 'Country';
  id: Scalars['Int'];
  /** Название страны */
  title: Scalars['String'];
  /** ID страны (в системе ВК) */
  vkCountryId: Scalars['Int'];
  /** Символ валюты */
  currencySymbol: Scalars['String'];
  /** Код страны */
  phoneCode: Scalars['String'];
  /** Список городов, находящихся в стране */
  cities: Array<City>;
};

/** Координаты */
export type Coords = {
  __typename?: 'Coords';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** Категория услуг */
export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  id: Scalars['Int'];
  /** Наименование */
  title: Scalars['String'];
  /**
   * Список сервисов, входящих в категорию
   * @deprecated DEV
   */
  services: Array<Service>;
};

/** Услуга */
export type Service = {
  __typename?: 'Service';
  id: Scalars['Int'];
  /** Категория, к которой принадлежит сервис */
  serviceCategory: ServiceCategory;
  /** Наименование */
  title: Scalars['String'];
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Записи о сотрудниках, выполняющих услугу */
  serviceStaff: Array<ServiceStaff>;
};


/** Услуга */
export type ServiceServiceStaffArgs = {
  staffId?: Maybe<Scalars['Int']>;
};

/** Запись о сотруднике, выпоняющем услугу */
export type ServiceStaff = {
  __typename?: 'ServiceStaff';
  id: Scalars['Int'];
  /** Актуальная минимальная стоимость услуги */
  priceMin: Scalars['Float'];
  /** Актуальная максимальная стоимость услуги */
  priceMax: Scalars['Float'];
  /** Услуга, выполняемая сотрудником */
  service: Service;
  /** Сотрудник, выполняющий услугу */
  staff: Staff;
  /** Длительность услуги, оказываемой сотрудником */
  duration: Scalars['Int'];
  /** Информация о слотах, хранящихся в базе */
  dateSlots: Array<DateSlot>;
  /**
   * Ближайшие свободные слоты у сотрудника для оказания услуги
   * @deprecated DEV
   */
  closestDateSlots?: Maybe<DateSlot>;
};

/** Сотрудник */
export type Staff = {
  __typename?: 'Staff';
  id: Scalars['Int'];
  /** Имя сотрудника */
  name: Scalars['String'];
  /** Ссылка на фото сотрудника */
  avatarUrl: Scalars['String'];
  /** Специализаwия сотрудника */
  specialization: Scalars['String'];
};

/** Свободные слоты в определенный день */
export type DateSlot = {
  __typename?: 'DateSlot';
  id: Scalars['ID'];
  /** Привязка к записи сотрудника, выполняющего определенную услугу */
  serviceStaff?: Maybe<ServiceStaff>;
  /** Дата */
  date: Scalars['Date'];
  /** Сводобные слоты в этот день */
  slots: Array<Slot>;
};


/** Слот для записи */
export type Slot = {
  __typename?: 'Slot';
  /** Хэш-строка идентификатор тайм слота */
  id: Scalars['String'];
  /** Время */
  datetime: Scalars['DateTime'];
};


/** Настройки бронирования */
export type BookingConfig = {
  __typename?: 'BookingConfig';
  /** ID филиала */
  id: Scalars['Int'];
  /** Флаг предоставления возможности отмены записи в данном филиале */
  isAllowCancelRecord: Scalars['Boolean'];
  /** Флаг предоставления возможности переноса записи в данном филиале */
  isAllowTransferRecord: Scalars['Boolean'];
  /** Флаг необходимости email при бронировании */
  isEmailRequired: Scalars['Boolean'];
  /** Флаг необходимости комментария при бронировании */
  isCommentRequired: Scalars['Boolean'];
  /** Подсказка для комменттария */
  commentPlaceholder?: Maybe<Scalars['String']>;
};

/** Данные для сортировки */
export type OrganizationUnitsInput = {
  /** Строка поискового запроса */
  q?: Maybe<Scalars['String']>;
  /** Широта */
  coordLat?: Maybe<Scalars['Float']>;
  /** Долгота */
  coordLng?: Maybe<Scalars['Float']>;
  /** ID категории бизнеса */
  categoryGroupId?: Maybe<Scalars['Int']>;
  /** ID группы категории бизнеса */
  categoryId?: Maybe<Scalars['Int']>;
  /** ID страны */
  countryId?: Maybe<Scalars['Int']>;
  /** ID города */
  cityId?: Maybe<Scalars['Int']>;
};

/** Сеть филиалов, привязанная к группе ВК */
export type AttachedOrganization = {
  __typename?: 'AttachedOrganization';
  /** ID привязки сети филиалов к группе */
  id: Scalars['String'];
  /** ID организации */
  organizationId: Scalars['Int'];
  /** Название организации */
  title: Scalars['String'];
  /** Филиалы, входящие в организацию */
  units: Array<OrganizationUnit>;
  /** ID сущности организации в системе YCLIENTS */
  yClientsOrganizationId?: Maybe<Scalars['Int']>;
  /** Флаг валидности токена синхонизации YCLIENTS */
  isYClientsOrganizationSynced: Scalars['Boolean'];
  /** ID привязанной группы VK */
  vkGroupId: Scalars['Int'];
  /** Сеть YCLIENTS, связанная с организацией */
  yClientsOrganization?: Maybe<YClientsOrganization>;
  /** Статус токена */
  vkGroupAccessTokenStatus: AccessTokenStatus;
  /** Установлен ли виджет в группе ВК */
  isVkGroupWidgetEnabled: Scalars['Boolean'];
  /** Превью для виджета с услугами */
  vkGroupWidgetPreview?: Maybe<Widget>;
  /** Установлена ли кнопка действия в группе ВК */
  isVkGroupActionButtonEnabled: Scalars['Boolean'];
};

/** Организация Y-Client */
export type YClientsOrganization = {
  __typename?: 'YClientsOrganization';
  id: Scalars['Int'];
  /** Название сети компаний */
  title: Scalars['String'];
  /** Филиалы */
  yClientsOrganizationUnits: Array<YClientsOrganizationUnit>;
};

/** Филиал Y-Client */
export type YClientsOrganizationUnit = {
  __typename?: 'YClientsOrganizationUnit';
  id: Scalars['Int'];
  /** Название */
  title: Scalars['String'];
  /** Ссылка на логотип */
  logoUrl: Scalars['String'];
  /** Адрес */
  address: Scalars['String'];
  /** Город */
  city: YClientsCity;
  /** Флаг активности салона */
  isActive: Scalars['Boolean'];
};

/** Сущность города YCLIENTS */
export type YClientsCity = {
  __typename?: 'YClientsCity';
  /** ID города в системе YCLIENTS */
  id: Scalars['Int'];
  /** Название */
  title: Scalars['String'];
};

export enum AccessTokenStatus {
  Exists = 'Exists',
  Empty = 'Empty',
  Invalid = 'Invalid'
}

/** Виджет сообщества */
export type Widget = {
  __typename?: 'Widget';
  type: Scalars['String'];
  code: Scalars['String'];
  variant: WidgetVariant;
};

/** Cпособ отображения виджета */
export enum WidgetVariant {
  Services = 'Services',
  Units = 'Units'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Создает запись */
  createRecord: Record;
  /** Переносит запись */
  transferRecord: Record;
  /** Отменяет запись */
  cancelRecord: Scalars['Boolean'];
  /** Привязать сеть филиалов YCLIENTS к группе ВК */
  attachYClientsOrganization: AttachedOrganization;
  /** Обновить привязку сети филиалов YCLIENTS к группе ВК */
  reloginYClientsOrganization: AttachedOrganization;
  /** Установить токен доступа группы ВК */
  setVkGroupAccessToken: AttachedOrganization;
  /** Устанавливает/удаляет виджет в привязанном сообществе ВК */
  setVkGroupWidget: AttachedOrganization;
  /** Устанавливает/удаляет кнопку действия в привязанном сообществе ВК */
  setVkGroupActionButton: AttachedOrganization;
  /** Отвязать сеть филиалов от группы вк (отвязывает кнопку действия и виджет сообщества при наличии) */
  detachVkGroupOrganization: Scalars['Boolean'];
};


export type MutationCreateRecordArgs = {
  input: CreateRecordInput;
};


export type MutationTransferRecordArgs = {
  recordId: Scalars['Int'];
  serviceStaffId: Scalars['Int'];
  datetime: Scalars['DateTime'];
};


export type MutationCancelRecordArgs = {
  recordId: Scalars['Int'];
};


export type MutationAttachYClientsOrganizationArgs = {
  yClientsOrganizationId: Scalars['Int'];
  yClientsOrganizationUnitIds: Array<Scalars['Int']>;
};


export type MutationSetVkGroupAccessTokenArgs = {
  accessToken: Scalars['String'];
};


export type MutationSetVkGroupWidgetArgs = {
  enabled: Scalars['Boolean'];
};


export type MutationSetVkGroupActionButtonArgs = {
  enabled: Scalars['Boolean'];
};

export type CreateRecordInput = {
  /** ID записи о сотруднике, выпоняющем услугу */
  serviceStaffId: Scalars['Int'];
  /** Время записи */
  datetime: Scalars['DateTime'];
  /** Комментарий к записи */
  comment?: Maybe<Scalars['String']>;
  /** Номер телефона пользователя */
  phone: Scalars['String'];
  /** Email пользователя */
  email?: Maybe<Scalars['String']>;
  /** Подпись для номера телефона */
  phoneSign: Scalars['String'];
};

export enum GqlErrorCode {
  /** Доступ к запрошенным полям запрещен */
  ForbiddenFieldsRequested = 'ForbiddenFieldsRequested',
  /** Ошибка авторизации */
  AuthorizationError = 'AuthorizationError',
  /** Лицензия истекла */
  LicenseExpired = 'LicenseExpired',
  /** Ошибка авторизации к партнеру */
  ProviderAuthorizationError = 'ProviderAuthorizationError',
  /** Ресурс не найден */
  NotFound = 'NotFound',
  /** Выбранное время занято */
  StaffIsBusy = 'StaffIsBusy',
  /** Пользователь в черном списке */
  UserIsBlacklisted = 'UserIsBlacklisted',
  /** Внутренняя ошибка сервера */
  InternalError = 'InternalError',
  /** Номер телефоа не совпадает с подписанной */
  PhoneNumberIsCorrupted = 'PhoneNumberIsCorrupted',
  /** У организации нет услуг. Всплывает в servicesWidgetPreview */
  HasNoServices = 'HasNoServices',
  /** Превышено максимальное значение аргумента */
  ArgumentValueExceeded = 'ArgumentValueExceeded',
  /** Попытка совершить операцию с филиалом, к которому не привязана группа вк */
  VkGroupIsNotAttached = 'VkGroupIsNotAttached',
  /** Ошибка токена сообщества при работе с виджетом */
  WidgetTokenInvalidated = 'WidgetTokenInvalidated',
  /** Попытка использовать несуществующий токен сообщества */
  WidgetTokenIsEmpty = 'WidgetTokenIsEmpty',
  /** Токен сообщества не имеет доступа к виджетам */
  TokenHasNoWidgetAccess = 'TokenHasNoWidgetAccess',
  /** Токен сообщества не имеет доступа к администрированию */
  TokenHasNoManageAccess = 'TokenHasNoManageAccess',
  /** Слишком частый вызов метода */
  TooManyActions = 'TooManyActions',
  /** Виджет управляется другим мини приложением */
  WidgetIsManagedByAnotherApp = 'WidgetIsManagedByAnotherApp',
  /** Превышена длина заголовка для строки виджета */
  TooLongWidgetRowTitle = 'TooLongWidgetRowTitle',
  /** Перенос записи запрещен организацией */
  TransferRecordForbidden = 'TransferRecordForbidden',
  /** Отмена записи запрещена организацией */
  CancelRecordForbidden = 'CancelRecordForbidden',
  /** Время ожидания выполнения задачи истекло */
  TimeoutExpired = 'TimeoutExpired',
  /** Неизвестная ошибка */
  UnknownError = 'UnknownError'
}

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type GetAvailableDatesQueryVariables = Exact<{
  serviceId: Scalars['Int'];
  serviceStaffId?: Maybe<Scalars['Int']>;
}>;


export type GetAvailableDatesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'availableDates'>
);

export type GetCategoryGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoryGroupsQuery = (
  { __typename?: 'Query' }
  & { organizationUnitCategoryGroups: Array<(
    { __typename?: 'OrganizationUnitCategoryGroup' }
    & Pick<OrganizationUnitCategoryGroup, 'id' | 'title' | 'slug'>
  )> }
);

export type GetCategoryServicesQueryVariables = Exact<{
  categoryId: Scalars['Int'];
}>;


export type GetCategoryServicesQuery = (
  { __typename?: 'Query' }
  & { categoryServices: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'title' | 'description'>
    & { serviceStaff: Array<(
      { __typename?: 'ServiceStaff' }
      & Pick<ServiceStaff, 'id' | 'priceMin' | 'priceMax' | 'duration'>
    )> }
  )> }
);

export type GetStaffCategoryServicesQueryVariables = Exact<{
  categoryId: Scalars['Int'];
  staffId: Scalars['Int'];
}>;


export type GetStaffCategoryServicesQuery = (
  { __typename?: 'Query' }
  & { categoryServices: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'title' | 'description'>
    & { serviceStaff: Array<(
      { __typename?: 'ServiceStaff' }
      & Pick<ServiceStaff, 'id' | 'priceMin' | 'priceMax' | 'duration'>
      & { staff: (
        { __typename?: 'Staff' }
        & Pick<Staff, 'id' | 'name' | 'avatarUrl' | 'specialization'>
      ) }
    )> }
  )> }
);

export type GetDateSlotsQueryVariables = Exact<{
  serviceId: Scalars['Int'];
  serviceStaffId?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
}>;


export type GetDateSlotsQuery = (
  { __typename?: 'Query' }
  & { dateSlots: Array<(
    { __typename?: 'Slot' }
    & Pick<Slot, 'datetime'>
  )> }
);

export type GetOrganizationUnitQueryVariables = Exact<{
  organizationUnitId: Scalars['Int'];
}>;


export type GetOrganizationUnitQuery = (
  { __typename?: 'Query' }
  & { organizationUnit: (
    { __typename?: 'OrganizationUnit' }
    & Pick<OrganizationUnit, 'id' | 'title' | 'logoUrl' | 'address' | 'phone' | 'description' | 'attachedToVkGroupIds' | 'isActive'>
    & { city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'title'>
    ), organizationUnitCategory: (
      { __typename?: 'OrganizationUnitCategory' }
      & Pick<OrganizationUnitCategory, 'id' | 'title'>
      & { categoryGroup: (
        { __typename?: 'OrganizationUnitCategoryGroup' }
        & Pick<OrganizationUnitCategoryGroup, 'id' | 'title' | 'slug'>
      ) }
    ), coords: (
      { __typename?: 'Coords' }
      & Pick<Coords, 'lat' | 'lng'>
    ), staff: Array<(
      { __typename?: 'Staff' }
      & Pick<Staff, 'id' | 'name' | 'avatarUrl' | 'specialization'>
    )>, serviceCategories: Array<(
      { __typename?: 'ServiceCategory' }
      & Pick<ServiceCategory, 'id' | 'title'>
    )>, bookingConfig: (
      { __typename?: 'BookingConfig' }
      & Pick<BookingConfig, 'id' | 'isEmailRequired' | 'isCommentRequired' | 'commentPlaceholder'>
    ) }
  ) }
);

export type GetServiceStaffQueryVariables = Exact<{
  serviceId: Scalars['Int'];
}>;


export type GetServiceStaffQuery = (
  { __typename?: 'Query' }
  & { service: (
    { __typename?: 'Service' }
    & Pick<Service, 'id'>
    & { serviceStaff: Array<(
      { __typename?: 'ServiceStaff' }
      & Pick<ServiceStaff, 'id' | 'priceMin' | 'priceMax' | 'duration'>
      & { staff: (
        { __typename?: 'Staff' }
        & Pick<Staff, 'id' | 'name' | 'avatarUrl' | 'specialization'>
      ) }
    )> }
  ) }
);

export type GetSlotServiceStaffQueryVariables = Exact<{
  serviceId: Scalars['Int'];
  datetime: Scalars['DateTime'];
}>;


export type GetSlotServiceStaffQuery = (
  { __typename?: 'Query' }
  & { slotServiceStaff: Array<(
    { __typename?: 'ServiceStaff' }
    & Pick<ServiceStaff, 'id' | 'priceMin' | 'priceMax' | 'duration'>
    & { staff: (
      { __typename?: 'Staff' }
      & Pick<Staff, 'id' | 'name' | 'avatarUrl' | 'specialization'>
    ) }
  )> }
);

export type GetStaffServiceCategoriesQueryVariables = Exact<{
  staffId: Scalars['Int'];
}>;


export type GetStaffServiceCategoriesQuery = (
  { __typename?: 'Query' }
  & { staffServiceCategories: Array<(
    { __typename?: 'ServiceCategory' }
    & Pick<ServiceCategory, 'id' | 'title'>
  )> }
);

export type GetOrganizationUnitsQueryVariables = Exact<{
  q?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  categoryGroupId?: Maybe<Scalars['Int']>;
}>;


export type GetOrganizationUnitsQuery = (
  { __typename?: 'Query' }
  & { organizationUnits: Array<(
    { __typename?: 'OrganizationUnit' }
    & Pick<OrganizationUnit, 'id' | 'title' | 'logoUrl' | 'address'>
    & { city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'title'>
    ) }
  )> }
);

export type RecordDataFragment = (
  { __typename?: 'Record' }
  & Pick<Record, 'id' | 'priceMin' | 'priceMax' | 'datetime' | 'comment'>
  & { organizationUnit: (
    { __typename?: 'OrganizationUnit' }
    & Pick<OrganizationUnit, 'id' | 'title' | 'address' | 'logoUrl' | 'phone'>
    & { bookingConfig: (
      { __typename?: 'BookingConfig' }
      & Pick<BookingConfig, 'id' | 'isAllowCancelRecord' | 'isAllowTransferRecord'>
    ), city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'title'>
    ) }
  ), serviceStaff: (
    { __typename?: 'ServiceStaff' }
    & Pick<ServiceStaff, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'title'>
    ), staff: (
      { __typename?: 'Staff' }
      & Pick<Staff, 'id' | 'name' | 'avatarUrl' | 'specialization'>
    ) }
  ) }
);

export type GetUserRecordsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRecordsQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'CurrentUser' }
    & { records: Array<(
      { __typename?: 'Record' }
      & RecordDataFragment
    )> }
  ) }
);

export type CreateRecordMutationVariables = Exact<{
  serviceStaffId: Scalars['Int'];
  datetime: Scalars['DateTime'];
  comment?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  phoneSign: Scalars['String'];
  email?: Maybe<Scalars['String']>;
}>;


export type CreateRecordMutation = (
  { __typename?: 'Mutation' }
  & { createRecord: (
    { __typename?: 'Record' }
    & RecordDataFragment
  ) }
);

export type CancelRecordMutationVariables = Exact<{
  recordId: Scalars['Int'];
}>;


export type CancelRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelRecord'>
);

export type TransferRecordMutationVariables = Exact<{
  recordId: Scalars['Int'];
  serviceStaffId: Scalars['Int'];
  datetime: Scalars['DateTime'];
}>;


export type TransferRecordMutation = (
  { __typename?: 'Mutation' }
  & { transferRecord: (
    { __typename?: 'Record' }
    & Pick<Record, 'id' | 'datetime'>
    & { organizationUnit: (
      { __typename?: 'OrganizationUnit' }
      & Pick<OrganizationUnit, 'id'>
    ) }
  ) }
);

export type SetVkGroupAccessTokenMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type SetVkGroupAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { setVkGroupAccessToken: (
    { __typename?: 'AttachedOrganization' }
    & Pick<AttachedOrganization, 'id' | 'vkGroupAccessTokenStatus' | 'isVkGroupWidgetEnabled' | 'isVkGroupActionButtonEnabled'>
  ) }
);

export type AttachedOrganizationActionButtonDataFragment = (
  { __typename?: 'AttachedOrganization' }
  & Pick<AttachedOrganization, 'id' | 'vkGroupAccessTokenStatus' | 'isVkGroupActionButtonEnabled'>
);

export type GetVkGroupActionButtonDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVkGroupActionButtonDataQuery = (
  { __typename?: 'Query' }
  & { attachedOrganization?: Maybe<(
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationActionButtonDataFragment
  )> }
);

export type SetVkGroupActionButtonMutationVariables = Exact<{ [key: string]: never; }>;


export type SetVkGroupActionButtonMutation = (
  { __typename?: 'Mutation' }
  & { attachedOrganization: (
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationActionButtonDataFragment
  ) }
);

export type UnsetVkGroupActionButtonMutationVariables = Exact<{ [key: string]: never; }>;


export type UnsetVkGroupActionButtonMutation = (
  { __typename?: 'Mutation' }
  & { attachedOrganization: (
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationActionButtonDataFragment
  ) }
);

export type YClientsOrganizationDataFragment = (
  { __typename?: 'YClientsOrganization' }
  & Pick<YClientsOrganization, 'id' | 'title'>
  & { yClientsOrganizationUnits: Array<(
    { __typename?: 'YClientsOrganizationUnit' }
    & Pick<YClientsOrganizationUnit, 'id' | 'title' | 'logoUrl' | 'address' | 'isActive'>
    & { city: (
      { __typename?: 'YClientsCity' }
      & Pick<YClientsCity, 'id' | 'title'>
    ) }
  )> }
);

export type GetYClientsOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetYClientsOrganizationsQuery = (
  { __typename?: 'Query' }
  & { yClientsOrganizations: Array<(
    { __typename?: 'YClientsOrganization' }
    & YClientsOrganizationDataFragment
  )> }
);

export type AttachedOrganizationDataFragment = (
  { __typename?: 'AttachedOrganization' }
  & Pick<AttachedOrganization, 'id' | 'organizationId' | 'title' | 'isYClientsOrganizationSynced'>
  & { units: Array<(
    { __typename?: 'OrganizationUnit' }
    & Pick<OrganizationUnit, 'id' | 'title' | 'address' | 'logoUrl' | 'isActive' | 'yClientsId'>
    & { city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'title'>
    ), coords: (
      { __typename?: 'Coords' }
      & Pick<Coords, 'lat' | 'lng'>
    ) }
  )> }
);

export type AttachedOrganizationSettingsDataFragment = (
  { __typename?: 'AttachedOrganization' }
  & Pick<AttachedOrganization, 'id' | 'vkGroupAccessTokenStatus' | 'isVkGroupWidgetEnabled' | 'isVkGroupActionButtonEnabled'>
  & { yClientsOrganization?: Maybe<(
    { __typename?: 'YClientsOrganization' }
    & YClientsOrganizationDataFragment
  )>, vkGroupWidgetPreview?: Maybe<(
    { __typename?: 'Widget' }
    & Pick<Widget, 'type' | 'variant' | 'code'>
  )> }
);

export type AttachYClientsOrganizationMutationVariables = Exact<{
  yClientsOrganizationId: Scalars['Int'];
  yClientsOrganizationUnitIds: Array<Scalars['Int']>;
}>;


export type AttachYClientsOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { attachYClientsOrganization: (
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationDataFragment
    & AttachedOrganizationSettingsDataFragment
  ) }
);

export type ReloginYClientsOrganizationMutationVariables = Exact<{ [key: string]: never; }>;


export type ReloginYClientsOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { reloginYClientsOrganization: (
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationDataFragment
    & AttachedOrganizationSettingsDataFragment
  ) }
);

export type DetachVkGroupOrganizationMutationVariables = Exact<{ [key: string]: never; }>;


export type DetachVkGroupOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'detachVkGroupOrganization'>
);

export type GetVkGroupAttachedOrganizationQueryVariables = Exact<{
  withSettingsData: Scalars['Boolean'];
}>;


export type GetVkGroupAttachedOrganizationQuery = (
  { __typename?: 'Query' }
  & { attachedOrganization?: Maybe<(
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationDataFragment
    & AttachedOrganizationSettingsDataFragment
  )> }
);

export type AttachedOrganizationWidgetDataFragment = (
  { __typename?: 'AttachedOrganization' }
  & Pick<AttachedOrganization, 'id' | 'vkGroupAccessTokenStatus' | 'isVkGroupWidgetEnabled'>
  & { vkGroupWidgetPreview?: Maybe<(
    { __typename?: 'Widget' }
    & Pick<Widget, 'type' | 'code' | 'variant'>
  )> }
);

export type GetVkGroupWidgetDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVkGroupWidgetDataQuery = (
  { __typename?: 'Query' }
  & { attachedOrganization?: Maybe<(
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationWidgetDataFragment
  )> }
);

export type SetVkGroupWidgetMutationVariables = Exact<{ [key: string]: never; }>;


export type SetVkGroupWidgetMutation = (
  { __typename?: 'Mutation' }
  & { attachedOrganization: (
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationWidgetDataFragment
  ) }
);

export type UnsetVkGroupWidgetMutationVariables = Exact<{ [key: string]: never; }>;


export type UnsetVkGroupWidgetMutation = (
  { __typename?: 'Mutation' }
  & { attachedOrganization: (
    { __typename?: 'AttachedOrganization' }
    & AttachedOrganizationWidgetDataFragment
  ) }
);

export const RecordDataFragmentDoc = gql`
    fragment RecordData on Record {
  id
  organizationUnit {
    id
    title
    address
    logoUrl
    phone
    bookingConfig {
      id
      isAllowCancelRecord
      isAllowTransferRecord
    }
    city {
      id
      title
    }
  }
  serviceStaff {
    id
    service {
      id
      title
    }
    staff {
      id
      name
      avatarUrl
      specialization
    }
  }
  priceMin
  priceMax
  datetime
  comment
}
    `;
export const AttachedOrganizationActionButtonDataFragmentDoc = gql`
    fragment AttachedOrganizationActionButtonData on AttachedOrganization {
  id
  vkGroupAccessTokenStatus
  isVkGroupActionButtonEnabled
}
    `;
export const AttachedOrganizationDataFragmentDoc = gql`
    fragment AttachedOrganizationData on AttachedOrganization {
  id
  organizationId
  title
  units {
    id
    title
    address
    logoUrl
    isActive
    city {
      id
      title
    }
    coords {
      lat
      lng
    }
    yClientsId
  }
  isYClientsOrganizationSynced
}
    `;
export const YClientsOrganizationDataFragmentDoc = gql`
    fragment YClientsOrganizationData on YClientsOrganization {
  id
  title
  yClientsOrganizationUnits {
    id
    title
    logoUrl
    address
    isActive
    city {
      id
      title
    }
  }
}
    `;
export const AttachedOrganizationSettingsDataFragmentDoc = gql`
    fragment AttachedOrganizationSettingsData on AttachedOrganization {
  id
  yClientsOrganization {
    ...YClientsOrganizationData
  }
  vkGroupWidgetPreview {
    type
    variant
    code
  }
  vkGroupAccessTokenStatus
  isVkGroupWidgetEnabled
  isVkGroupActionButtonEnabled
}
    ${YClientsOrganizationDataFragmentDoc}`;
export const AttachedOrganizationWidgetDataFragmentDoc = gql`
    fragment AttachedOrganizationWidgetData on AttachedOrganization {
  id
  vkGroupAccessTokenStatus
  isVkGroupWidgetEnabled
  vkGroupWidgetPreview {
    type
    code
    variant
  }
}
    `;
export const GetAvailableDatesDocument = gql`
    query getAvailableDates($serviceId: Int!, $serviceStaffId: Int) {
  availableDates(serviceId: $serviceId, serviceStaffId: $serviceStaffId)
}
    `;

/**
 * __useGetAvailableDatesQuery__
 *
 * To run a query within a React component, call `useGetAvailableDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableDatesQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      serviceStaffId: // value for 'serviceStaffId'
 *   },
 * });
 */
export function useGetAvailableDatesQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableDatesQuery, GetAvailableDatesQueryVariables>) {
        return Apollo.useQuery<GetAvailableDatesQuery, GetAvailableDatesQueryVariables>(GetAvailableDatesDocument, baseOptions);
      }
export function useGetAvailableDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableDatesQuery, GetAvailableDatesQueryVariables>) {
          return Apollo.useLazyQuery<GetAvailableDatesQuery, GetAvailableDatesQueryVariables>(GetAvailableDatesDocument, baseOptions);
        }
export type GetAvailableDatesQueryHookResult = ReturnType<typeof useGetAvailableDatesQuery>;
export type GetAvailableDatesLazyQueryHookResult = ReturnType<typeof useGetAvailableDatesLazyQuery>;
export type GetAvailableDatesQueryResult = Apollo.QueryResult<GetAvailableDatesQuery, GetAvailableDatesQueryVariables>;
export const GetCategoryGroupsDocument = gql`
    query getCategoryGroups {
  organizationUnitCategoryGroups {
    id
    title
    slug
  }
}
    `;

/**
 * __useGetCategoryGroupsQuery__
 *
 * To run a query within a React component, call `useGetCategoryGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryGroupsQuery, GetCategoryGroupsQueryVariables>) {
        return Apollo.useQuery<GetCategoryGroupsQuery, GetCategoryGroupsQueryVariables>(GetCategoryGroupsDocument, baseOptions);
      }
export function useGetCategoryGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryGroupsQuery, GetCategoryGroupsQueryVariables>) {
          return Apollo.useLazyQuery<GetCategoryGroupsQuery, GetCategoryGroupsQueryVariables>(GetCategoryGroupsDocument, baseOptions);
        }
export type GetCategoryGroupsQueryHookResult = ReturnType<typeof useGetCategoryGroupsQuery>;
export type GetCategoryGroupsLazyQueryHookResult = ReturnType<typeof useGetCategoryGroupsLazyQuery>;
export type GetCategoryGroupsQueryResult = Apollo.QueryResult<GetCategoryGroupsQuery, GetCategoryGroupsQueryVariables>;
export const GetCategoryServicesDocument = gql`
    query getCategoryServices($categoryId: Int!) {
  categoryServices(categoryId: $categoryId) {
    id
    title
    description
    serviceStaff {
      id
      priceMin
      priceMax
      duration
    }
  }
}
    `;

/**
 * __useGetCategoryServicesQuery__
 *
 * To run a query within a React component, call `useGetCategoryServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryServicesQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetCategoryServicesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>) {
        return Apollo.useQuery<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>(GetCategoryServicesDocument, baseOptions);
      }
export function useGetCategoryServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>) {
          return Apollo.useLazyQuery<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>(GetCategoryServicesDocument, baseOptions);
        }
export type GetCategoryServicesQueryHookResult = ReturnType<typeof useGetCategoryServicesQuery>;
export type GetCategoryServicesLazyQueryHookResult = ReturnType<typeof useGetCategoryServicesLazyQuery>;
export type GetCategoryServicesQueryResult = Apollo.QueryResult<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>;
export const GetStaffCategoryServicesDocument = gql`
    query getStaffCategoryServices($categoryId: Int!, $staffId: Int!) {
  categoryServices(categoryId: $categoryId) {
    id
    title
    description
    serviceStaff(staffId: $staffId) {
      id
      priceMin
      priceMax
      duration
      staff {
        id
        name
        avatarUrl
        specialization
      }
    }
  }
}
    `;

/**
 * __useGetStaffCategoryServicesQuery__
 *
 * To run a query within a React component, call `useGetStaffCategoryServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffCategoryServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffCategoryServicesQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useGetStaffCategoryServicesQuery(baseOptions: Apollo.QueryHookOptions<GetStaffCategoryServicesQuery, GetStaffCategoryServicesQueryVariables>) {
        return Apollo.useQuery<GetStaffCategoryServicesQuery, GetStaffCategoryServicesQueryVariables>(GetStaffCategoryServicesDocument, baseOptions);
      }
export function useGetStaffCategoryServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffCategoryServicesQuery, GetStaffCategoryServicesQueryVariables>) {
          return Apollo.useLazyQuery<GetStaffCategoryServicesQuery, GetStaffCategoryServicesQueryVariables>(GetStaffCategoryServicesDocument, baseOptions);
        }
export type GetStaffCategoryServicesQueryHookResult = ReturnType<typeof useGetStaffCategoryServicesQuery>;
export type GetStaffCategoryServicesLazyQueryHookResult = ReturnType<typeof useGetStaffCategoryServicesLazyQuery>;
export type GetStaffCategoryServicesQueryResult = Apollo.QueryResult<GetStaffCategoryServicesQuery, GetStaffCategoryServicesQueryVariables>;
export const GetDateSlotsDocument = gql`
    query getDateSlots($serviceId: Int!, $serviceStaffId: Int, $date: Date!) {
  dateSlots(serviceId: $serviceId, serviceStaffId: $serviceStaffId, date: $date) {
    datetime
  }
}
    `;

/**
 * __useGetDateSlotsQuery__
 *
 * To run a query within a React component, call `useGetDateSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDateSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDateSlotsQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      serviceStaffId: // value for 'serviceStaffId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDateSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetDateSlotsQuery, GetDateSlotsQueryVariables>) {
        return Apollo.useQuery<GetDateSlotsQuery, GetDateSlotsQueryVariables>(GetDateSlotsDocument, baseOptions);
      }
export function useGetDateSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDateSlotsQuery, GetDateSlotsQueryVariables>) {
          return Apollo.useLazyQuery<GetDateSlotsQuery, GetDateSlotsQueryVariables>(GetDateSlotsDocument, baseOptions);
        }
export type GetDateSlotsQueryHookResult = ReturnType<typeof useGetDateSlotsQuery>;
export type GetDateSlotsLazyQueryHookResult = ReturnType<typeof useGetDateSlotsLazyQuery>;
export type GetDateSlotsQueryResult = Apollo.QueryResult<GetDateSlotsQuery, GetDateSlotsQueryVariables>;
export const GetOrganizationUnitDocument = gql`
    query getOrganizationUnit($organizationUnitId: Int!) {
  organizationUnit(organizationUnitId: $organizationUnitId) {
    id
    title
    logoUrl
    address
    phone
    city {
      id
      title
    }
    description
    organizationUnitCategory {
      id
      title
      categoryGroup {
        id
        title
        slug
      }
    }
    coords {
      lat
      lng
    }
    staff {
      id
      name
      avatarUrl
      specialization
    }
    serviceCategories {
      id
      title
    }
    bookingConfig {
      id
      isEmailRequired
      isCommentRequired
      commentPlaceholder
    }
    attachedToVkGroupIds
    isActive
  }
}
    `;

/**
 * __useGetOrganizationUnitQuery__
 *
 * To run a query within a React component, call `useGetOrganizationUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationUnitQuery({
 *   variables: {
 *      organizationUnitId: // value for 'organizationUnitId'
 *   },
 * });
 */
export function useGetOrganizationUnitQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationUnitQuery, GetOrganizationUnitQueryVariables>) {
        return Apollo.useQuery<GetOrganizationUnitQuery, GetOrganizationUnitQueryVariables>(GetOrganizationUnitDocument, baseOptions);
      }
export function useGetOrganizationUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationUnitQuery, GetOrganizationUnitQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationUnitQuery, GetOrganizationUnitQueryVariables>(GetOrganizationUnitDocument, baseOptions);
        }
export type GetOrganizationUnitQueryHookResult = ReturnType<typeof useGetOrganizationUnitQuery>;
export type GetOrganizationUnitLazyQueryHookResult = ReturnType<typeof useGetOrganizationUnitLazyQuery>;
export type GetOrganizationUnitQueryResult = Apollo.QueryResult<GetOrganizationUnitQuery, GetOrganizationUnitQueryVariables>;
export const GetServiceStaffDocument = gql`
    query getServiceStaff($serviceId: Int!) {
  service(serviceId: $serviceId) {
    id
    serviceStaff {
      id
      staff {
        id
        name
        avatarUrl
        specialization
      }
      priceMin
      priceMax
      duration
    }
  }
}
    `;

/**
 * __useGetServiceStaffQuery__
 *
 * To run a query within a React component, call `useGetServiceStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceStaffQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useGetServiceStaffQuery(baseOptions: Apollo.QueryHookOptions<GetServiceStaffQuery, GetServiceStaffQueryVariables>) {
        return Apollo.useQuery<GetServiceStaffQuery, GetServiceStaffQueryVariables>(GetServiceStaffDocument, baseOptions);
      }
export function useGetServiceStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceStaffQuery, GetServiceStaffQueryVariables>) {
          return Apollo.useLazyQuery<GetServiceStaffQuery, GetServiceStaffQueryVariables>(GetServiceStaffDocument, baseOptions);
        }
export type GetServiceStaffQueryHookResult = ReturnType<typeof useGetServiceStaffQuery>;
export type GetServiceStaffLazyQueryHookResult = ReturnType<typeof useGetServiceStaffLazyQuery>;
export type GetServiceStaffQueryResult = Apollo.QueryResult<GetServiceStaffQuery, GetServiceStaffQueryVariables>;
export const GetSlotServiceStaffDocument = gql`
    query getSlotServiceStaff($serviceId: Int!, $datetime: DateTime!) {
  slotServiceStaff(serviceId: $serviceId, datetime: $datetime) {
    id
    staff {
      id
      name
      avatarUrl
      specialization
    }
    priceMin
    priceMax
    duration
  }
}
    `;

/**
 * __useGetSlotServiceStaffQuery__
 *
 * To run a query within a React component, call `useGetSlotServiceStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlotServiceStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlotServiceStaffQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      datetime: // value for 'datetime'
 *   },
 * });
 */
export function useGetSlotServiceStaffQuery(baseOptions: Apollo.QueryHookOptions<GetSlotServiceStaffQuery, GetSlotServiceStaffQueryVariables>) {
        return Apollo.useQuery<GetSlotServiceStaffQuery, GetSlotServiceStaffQueryVariables>(GetSlotServiceStaffDocument, baseOptions);
      }
export function useGetSlotServiceStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlotServiceStaffQuery, GetSlotServiceStaffQueryVariables>) {
          return Apollo.useLazyQuery<GetSlotServiceStaffQuery, GetSlotServiceStaffQueryVariables>(GetSlotServiceStaffDocument, baseOptions);
        }
export type GetSlotServiceStaffQueryHookResult = ReturnType<typeof useGetSlotServiceStaffQuery>;
export type GetSlotServiceStaffLazyQueryHookResult = ReturnType<typeof useGetSlotServiceStaffLazyQuery>;
export type GetSlotServiceStaffQueryResult = Apollo.QueryResult<GetSlotServiceStaffQuery, GetSlotServiceStaffQueryVariables>;
export const GetStaffServiceCategoriesDocument = gql`
    query getStaffServiceCategories($staffId: Int!) {
  staffServiceCategories(staffId: $staffId) {
    id
    title
  }
}
    `;

/**
 * __useGetStaffServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetStaffServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffServiceCategoriesQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useGetStaffServiceCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetStaffServiceCategoriesQuery, GetStaffServiceCategoriesQueryVariables>) {
        return Apollo.useQuery<GetStaffServiceCategoriesQuery, GetStaffServiceCategoriesQueryVariables>(GetStaffServiceCategoriesDocument, baseOptions);
      }
export function useGetStaffServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffServiceCategoriesQuery, GetStaffServiceCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetStaffServiceCategoriesQuery, GetStaffServiceCategoriesQueryVariables>(GetStaffServiceCategoriesDocument, baseOptions);
        }
export type GetStaffServiceCategoriesQueryHookResult = ReturnType<typeof useGetStaffServiceCategoriesQuery>;
export type GetStaffServiceCategoriesLazyQueryHookResult = ReturnType<typeof useGetStaffServiceCategoriesLazyQuery>;
export type GetStaffServiceCategoriesQueryResult = Apollo.QueryResult<GetStaffServiceCategoriesQuery, GetStaffServiceCategoriesQueryVariables>;
export const GetOrganizationUnitsDocument = gql`
    query getOrganizationUnits($q: String, $offset: Int, $lat: Float, $lng: Float, $categoryGroupId: Int) {
  organizationUnits(input: {q: $q, coordLat: $lat, coordLng: $lng, categoryGroupId: $categoryGroupId}, limit: 15, offset: $offset) {
    id
    title
    logoUrl
    address
    city {
      id
      title
    }
  }
}
    `;

/**
 * __useGetOrganizationUnitsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationUnitsQuery({
 *   variables: {
 *      q: // value for 'q'
 *      offset: // value for 'offset'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      categoryGroupId: // value for 'categoryGroupId'
 *   },
 * });
 */
export function useGetOrganizationUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationUnitsQuery, GetOrganizationUnitsQueryVariables>) {
        return Apollo.useQuery<GetOrganizationUnitsQuery, GetOrganizationUnitsQueryVariables>(GetOrganizationUnitsDocument, baseOptions);
      }
export function useGetOrganizationUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationUnitsQuery, GetOrganizationUnitsQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationUnitsQuery, GetOrganizationUnitsQueryVariables>(GetOrganizationUnitsDocument, baseOptions);
        }
export type GetOrganizationUnitsQueryHookResult = ReturnType<typeof useGetOrganizationUnitsQuery>;
export type GetOrganizationUnitsLazyQueryHookResult = ReturnType<typeof useGetOrganizationUnitsLazyQuery>;
export type GetOrganizationUnitsQueryResult = Apollo.QueryResult<GetOrganizationUnitsQuery, GetOrganizationUnitsQueryVariables>;
export const GetUserRecordsDocument = gql`
    query getUserRecords {
  currentUser {
    records {
      ...RecordData
    }
  }
}
    ${RecordDataFragmentDoc}`;

/**
 * __useGetUserRecordsQuery__
 *
 * To run a query within a React component, call `useGetUserRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRecordsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserRecordsQuery, GetUserRecordsQueryVariables>) {
        return Apollo.useQuery<GetUserRecordsQuery, GetUserRecordsQueryVariables>(GetUserRecordsDocument, baseOptions);
      }
export function useGetUserRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRecordsQuery, GetUserRecordsQueryVariables>) {
          return Apollo.useLazyQuery<GetUserRecordsQuery, GetUserRecordsQueryVariables>(GetUserRecordsDocument, baseOptions);
        }
export type GetUserRecordsQueryHookResult = ReturnType<typeof useGetUserRecordsQuery>;
export type GetUserRecordsLazyQueryHookResult = ReturnType<typeof useGetUserRecordsLazyQuery>;
export type GetUserRecordsQueryResult = Apollo.QueryResult<GetUserRecordsQuery, GetUserRecordsQueryVariables>;
export const CreateRecordDocument = gql`
    mutation createRecord($serviceStaffId: Int!, $datetime: DateTime!, $comment: String, $phone: String!, $phoneSign: String!, $email: String) {
  createRecord(input: {serviceStaffId: $serviceStaffId, datetime: $datetime, comment: $comment, phone: $phone, phoneSign: $phoneSign, email: $email}) {
    ...RecordData
  }
}
    ${RecordDataFragmentDoc}`;
export type CreateRecordMutationFn = Apollo.MutationFunction<CreateRecordMutation, CreateRecordMutationVariables>;

/**
 * __useCreateRecordMutation__
 *
 * To run a mutation, you first call `useCreateRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordMutation, { data, loading, error }] = useCreateRecordMutation({
 *   variables: {
 *      serviceStaffId: // value for 'serviceStaffId'
 *      datetime: // value for 'datetime'
 *      comment: // value for 'comment'
 *      phone: // value for 'phone'
 *      phoneSign: // value for 'phoneSign'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecordMutation, CreateRecordMutationVariables>) {
        return Apollo.useMutation<CreateRecordMutation, CreateRecordMutationVariables>(CreateRecordDocument, baseOptions);
      }
export type CreateRecordMutationHookResult = ReturnType<typeof useCreateRecordMutation>;
export type CreateRecordMutationResult = Apollo.MutationResult<CreateRecordMutation>;
export type CreateRecordMutationOptions = Apollo.BaseMutationOptions<CreateRecordMutation, CreateRecordMutationVariables>;
export const CancelRecordDocument = gql`
    mutation cancelRecord($recordId: Int!) {
  cancelRecord(recordId: $recordId)
}
    `;
export type CancelRecordMutationFn = Apollo.MutationFunction<CancelRecordMutation, CancelRecordMutationVariables>;

/**
 * __useCancelRecordMutation__
 *
 * To run a mutation, you first call `useCancelRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecordMutation, { data, loading, error }] = useCancelRecordMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useCancelRecordMutation(baseOptions?: Apollo.MutationHookOptions<CancelRecordMutation, CancelRecordMutationVariables>) {
        return Apollo.useMutation<CancelRecordMutation, CancelRecordMutationVariables>(CancelRecordDocument, baseOptions);
      }
export type CancelRecordMutationHookResult = ReturnType<typeof useCancelRecordMutation>;
export type CancelRecordMutationResult = Apollo.MutationResult<CancelRecordMutation>;
export type CancelRecordMutationOptions = Apollo.BaseMutationOptions<CancelRecordMutation, CancelRecordMutationVariables>;
export const TransferRecordDocument = gql`
    mutation transferRecord($recordId: Int!, $serviceStaffId: Int!, $datetime: DateTime!) {
  transferRecord(recordId: $recordId, serviceStaffId: $serviceStaffId, datetime: $datetime) {
    id
    datetime
    organizationUnit {
      id
    }
  }
}
    `;
export type TransferRecordMutationFn = Apollo.MutationFunction<TransferRecordMutation, TransferRecordMutationVariables>;

/**
 * __useTransferRecordMutation__
 *
 * To run a mutation, you first call `useTransferRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferRecordMutation, { data, loading, error }] = useTransferRecordMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      serviceStaffId: // value for 'serviceStaffId'
 *      datetime: // value for 'datetime'
 *   },
 * });
 */
export function useTransferRecordMutation(baseOptions?: Apollo.MutationHookOptions<TransferRecordMutation, TransferRecordMutationVariables>) {
        return Apollo.useMutation<TransferRecordMutation, TransferRecordMutationVariables>(TransferRecordDocument, baseOptions);
      }
export type TransferRecordMutationHookResult = ReturnType<typeof useTransferRecordMutation>;
export type TransferRecordMutationResult = Apollo.MutationResult<TransferRecordMutation>;
export type TransferRecordMutationOptions = Apollo.BaseMutationOptions<TransferRecordMutation, TransferRecordMutationVariables>;
export const SetVkGroupAccessTokenDocument = gql`
    mutation setVkGroupAccessToken($accessToken: String!) {
  setVkGroupAccessToken(accessToken: $accessToken) {
    id
    vkGroupAccessTokenStatus
    isVkGroupWidgetEnabled
    isVkGroupActionButtonEnabled
  }
}
    `;
export type SetVkGroupAccessTokenMutationFn = Apollo.MutationFunction<SetVkGroupAccessTokenMutation, SetVkGroupAccessTokenMutationVariables>;

/**
 * __useSetVkGroupAccessTokenMutation__
 *
 * To run a mutation, you first call `useSetVkGroupAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVkGroupAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVkGroupAccessTokenMutation, { data, loading, error }] = useSetVkGroupAccessTokenMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSetVkGroupAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetVkGroupAccessTokenMutation, SetVkGroupAccessTokenMutationVariables>) {
        return Apollo.useMutation<SetVkGroupAccessTokenMutation, SetVkGroupAccessTokenMutationVariables>(SetVkGroupAccessTokenDocument, baseOptions);
      }
export type SetVkGroupAccessTokenMutationHookResult = ReturnType<typeof useSetVkGroupAccessTokenMutation>;
export type SetVkGroupAccessTokenMutationResult = Apollo.MutationResult<SetVkGroupAccessTokenMutation>;
export type SetVkGroupAccessTokenMutationOptions = Apollo.BaseMutationOptions<SetVkGroupAccessTokenMutation, SetVkGroupAccessTokenMutationVariables>;
export const GetVkGroupActionButtonDataDocument = gql`
    query getVkGroupActionButtonData {
  attachedOrganization: vkGroupAttachedOrganization {
    ...AttachedOrganizationActionButtonData
  }
}
    ${AttachedOrganizationActionButtonDataFragmentDoc}`;

/**
 * __useGetVkGroupActionButtonDataQuery__
 *
 * To run a query within a React component, call `useGetVkGroupActionButtonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVkGroupActionButtonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVkGroupActionButtonDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVkGroupActionButtonDataQuery(baseOptions?: Apollo.QueryHookOptions<GetVkGroupActionButtonDataQuery, GetVkGroupActionButtonDataQueryVariables>) {
        return Apollo.useQuery<GetVkGroupActionButtonDataQuery, GetVkGroupActionButtonDataQueryVariables>(GetVkGroupActionButtonDataDocument, baseOptions);
      }
export function useGetVkGroupActionButtonDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVkGroupActionButtonDataQuery, GetVkGroupActionButtonDataQueryVariables>) {
          return Apollo.useLazyQuery<GetVkGroupActionButtonDataQuery, GetVkGroupActionButtonDataQueryVariables>(GetVkGroupActionButtonDataDocument, baseOptions);
        }
export type GetVkGroupActionButtonDataQueryHookResult = ReturnType<typeof useGetVkGroupActionButtonDataQuery>;
export type GetVkGroupActionButtonDataLazyQueryHookResult = ReturnType<typeof useGetVkGroupActionButtonDataLazyQuery>;
export type GetVkGroupActionButtonDataQueryResult = Apollo.QueryResult<GetVkGroupActionButtonDataQuery, GetVkGroupActionButtonDataQueryVariables>;
export const SetVkGroupActionButtonDocument = gql`
    mutation setVkGroupActionButton {
  attachedOrganization: setVkGroupActionButton(enabled: true) {
    ...AttachedOrganizationActionButtonData
  }
}
    ${AttachedOrganizationActionButtonDataFragmentDoc}`;
export type SetVkGroupActionButtonMutationFn = Apollo.MutationFunction<SetVkGroupActionButtonMutation, SetVkGroupActionButtonMutationVariables>;

/**
 * __useSetVkGroupActionButtonMutation__
 *
 * To run a mutation, you first call `useSetVkGroupActionButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVkGroupActionButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVkGroupActionButtonMutation, { data, loading, error }] = useSetVkGroupActionButtonMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetVkGroupActionButtonMutation(baseOptions?: Apollo.MutationHookOptions<SetVkGroupActionButtonMutation, SetVkGroupActionButtonMutationVariables>) {
        return Apollo.useMutation<SetVkGroupActionButtonMutation, SetVkGroupActionButtonMutationVariables>(SetVkGroupActionButtonDocument, baseOptions);
      }
export type SetVkGroupActionButtonMutationHookResult = ReturnType<typeof useSetVkGroupActionButtonMutation>;
export type SetVkGroupActionButtonMutationResult = Apollo.MutationResult<SetVkGroupActionButtonMutation>;
export type SetVkGroupActionButtonMutationOptions = Apollo.BaseMutationOptions<SetVkGroupActionButtonMutation, SetVkGroupActionButtonMutationVariables>;
export const UnsetVkGroupActionButtonDocument = gql`
    mutation unsetVkGroupActionButton {
  attachedOrganization: setVkGroupActionButton(enabled: false) {
    ...AttachedOrganizationActionButtonData
  }
}
    ${AttachedOrganizationActionButtonDataFragmentDoc}`;
export type UnsetVkGroupActionButtonMutationFn = Apollo.MutationFunction<UnsetVkGroupActionButtonMutation, UnsetVkGroupActionButtonMutationVariables>;

/**
 * __useUnsetVkGroupActionButtonMutation__
 *
 * To run a mutation, you first call `useUnsetVkGroupActionButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetVkGroupActionButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetVkGroupActionButtonMutation, { data, loading, error }] = useUnsetVkGroupActionButtonMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnsetVkGroupActionButtonMutation(baseOptions?: Apollo.MutationHookOptions<UnsetVkGroupActionButtonMutation, UnsetVkGroupActionButtonMutationVariables>) {
        return Apollo.useMutation<UnsetVkGroupActionButtonMutation, UnsetVkGroupActionButtonMutationVariables>(UnsetVkGroupActionButtonDocument, baseOptions);
      }
export type UnsetVkGroupActionButtonMutationHookResult = ReturnType<typeof useUnsetVkGroupActionButtonMutation>;
export type UnsetVkGroupActionButtonMutationResult = Apollo.MutationResult<UnsetVkGroupActionButtonMutation>;
export type UnsetVkGroupActionButtonMutationOptions = Apollo.BaseMutationOptions<UnsetVkGroupActionButtonMutation, UnsetVkGroupActionButtonMutationVariables>;
export const GetYClientsOrganizationsDocument = gql`
    query getYClientsOrganizations {
  yClientsOrganizations {
    ...YClientsOrganizationData
  }
}
    ${YClientsOrganizationDataFragmentDoc}`;

/**
 * __useGetYClientsOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetYClientsOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYClientsOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYClientsOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetYClientsOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetYClientsOrganizationsQuery, GetYClientsOrganizationsQueryVariables>) {
        return Apollo.useQuery<GetYClientsOrganizationsQuery, GetYClientsOrganizationsQueryVariables>(GetYClientsOrganizationsDocument, baseOptions);
      }
export function useGetYClientsOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetYClientsOrganizationsQuery, GetYClientsOrganizationsQueryVariables>) {
          return Apollo.useLazyQuery<GetYClientsOrganizationsQuery, GetYClientsOrganizationsQueryVariables>(GetYClientsOrganizationsDocument, baseOptions);
        }
export type GetYClientsOrganizationsQueryHookResult = ReturnType<typeof useGetYClientsOrganizationsQuery>;
export type GetYClientsOrganizationsLazyQueryHookResult = ReturnType<typeof useGetYClientsOrganizationsLazyQuery>;
export type GetYClientsOrganizationsQueryResult = Apollo.QueryResult<GetYClientsOrganizationsQuery, GetYClientsOrganizationsQueryVariables>;
export const AttachYClientsOrganizationDocument = gql`
    mutation attachYClientsOrganization($yClientsOrganizationId: Int!, $yClientsOrganizationUnitIds: [Int!]!) {
  attachYClientsOrganization(yClientsOrganizationId: $yClientsOrganizationId, yClientsOrganizationUnitIds: $yClientsOrganizationUnitIds) {
    ...AttachedOrganizationData
    ...AttachedOrganizationSettingsData
  }
}
    ${AttachedOrganizationDataFragmentDoc}
${AttachedOrganizationSettingsDataFragmentDoc}`;
export type AttachYClientsOrganizationMutationFn = Apollo.MutationFunction<AttachYClientsOrganizationMutation, AttachYClientsOrganizationMutationVariables>;

/**
 * __useAttachYClientsOrganizationMutation__
 *
 * To run a mutation, you first call `useAttachYClientsOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachYClientsOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachYClientsOrganizationMutation, { data, loading, error }] = useAttachYClientsOrganizationMutation({
 *   variables: {
 *      yClientsOrganizationId: // value for 'yClientsOrganizationId'
 *      yClientsOrganizationUnitIds: // value for 'yClientsOrganizationUnitIds'
 *   },
 * });
 */
export function useAttachYClientsOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AttachYClientsOrganizationMutation, AttachYClientsOrganizationMutationVariables>) {
        return Apollo.useMutation<AttachYClientsOrganizationMutation, AttachYClientsOrganizationMutationVariables>(AttachYClientsOrganizationDocument, baseOptions);
      }
export type AttachYClientsOrganizationMutationHookResult = ReturnType<typeof useAttachYClientsOrganizationMutation>;
export type AttachYClientsOrganizationMutationResult = Apollo.MutationResult<AttachYClientsOrganizationMutation>;
export type AttachYClientsOrganizationMutationOptions = Apollo.BaseMutationOptions<AttachYClientsOrganizationMutation, AttachYClientsOrganizationMutationVariables>;
export const ReloginYClientsOrganizationDocument = gql`
    mutation reloginYClientsOrganization {
  reloginYClientsOrganization {
    ...AttachedOrganizationData
    ...AttachedOrganizationSettingsData
  }
}
    ${AttachedOrganizationDataFragmentDoc}
${AttachedOrganizationSettingsDataFragmentDoc}`;
export type ReloginYClientsOrganizationMutationFn = Apollo.MutationFunction<ReloginYClientsOrganizationMutation, ReloginYClientsOrganizationMutationVariables>;

/**
 * __useReloginYClientsOrganizationMutation__
 *
 * To run a mutation, you first call `useReloginYClientsOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReloginYClientsOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reloginYClientsOrganizationMutation, { data, loading, error }] = useReloginYClientsOrganizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useReloginYClientsOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ReloginYClientsOrganizationMutation, ReloginYClientsOrganizationMutationVariables>) {
        return Apollo.useMutation<ReloginYClientsOrganizationMutation, ReloginYClientsOrganizationMutationVariables>(ReloginYClientsOrganizationDocument, baseOptions);
      }
export type ReloginYClientsOrganizationMutationHookResult = ReturnType<typeof useReloginYClientsOrganizationMutation>;
export type ReloginYClientsOrganizationMutationResult = Apollo.MutationResult<ReloginYClientsOrganizationMutation>;
export type ReloginYClientsOrganizationMutationOptions = Apollo.BaseMutationOptions<ReloginYClientsOrganizationMutation, ReloginYClientsOrganizationMutationVariables>;
export const DetachVkGroupOrganizationDocument = gql`
    mutation detachVkGroupOrganization {
  detachVkGroupOrganization
}
    `;
export type DetachVkGroupOrganizationMutationFn = Apollo.MutationFunction<DetachVkGroupOrganizationMutation, DetachVkGroupOrganizationMutationVariables>;

/**
 * __useDetachVkGroupOrganizationMutation__
 *
 * To run a mutation, you first call `useDetachVkGroupOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachVkGroupOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachVkGroupOrganizationMutation, { data, loading, error }] = useDetachVkGroupOrganizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDetachVkGroupOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DetachVkGroupOrganizationMutation, DetachVkGroupOrganizationMutationVariables>) {
        return Apollo.useMutation<DetachVkGroupOrganizationMutation, DetachVkGroupOrganizationMutationVariables>(DetachVkGroupOrganizationDocument, baseOptions);
      }
export type DetachVkGroupOrganizationMutationHookResult = ReturnType<typeof useDetachVkGroupOrganizationMutation>;
export type DetachVkGroupOrganizationMutationResult = Apollo.MutationResult<DetachVkGroupOrganizationMutation>;
export type DetachVkGroupOrganizationMutationOptions = Apollo.BaseMutationOptions<DetachVkGroupOrganizationMutation, DetachVkGroupOrganizationMutationVariables>;
export const GetVkGroupAttachedOrganizationDocument = gql`
    query getVkGroupAttachedOrganization($withSettingsData: Boolean!) {
  attachedOrganization: vkGroupAttachedOrganization {
    ...AttachedOrganizationData
    ...AttachedOrganizationSettingsData @include(if: $withSettingsData)
  }
}
    ${AttachedOrganizationDataFragmentDoc}
${AttachedOrganizationSettingsDataFragmentDoc}`;

/**
 * __useGetVkGroupAttachedOrganizationQuery__
 *
 * To run a query within a React component, call `useGetVkGroupAttachedOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVkGroupAttachedOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVkGroupAttachedOrganizationQuery({
 *   variables: {
 *      withSettingsData: // value for 'withSettingsData'
 *   },
 * });
 */
export function useGetVkGroupAttachedOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetVkGroupAttachedOrganizationQuery, GetVkGroupAttachedOrganizationQueryVariables>) {
        return Apollo.useQuery<GetVkGroupAttachedOrganizationQuery, GetVkGroupAttachedOrganizationQueryVariables>(GetVkGroupAttachedOrganizationDocument, baseOptions);
      }
export function useGetVkGroupAttachedOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVkGroupAttachedOrganizationQuery, GetVkGroupAttachedOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<GetVkGroupAttachedOrganizationQuery, GetVkGroupAttachedOrganizationQueryVariables>(GetVkGroupAttachedOrganizationDocument, baseOptions);
        }
export type GetVkGroupAttachedOrganizationQueryHookResult = ReturnType<typeof useGetVkGroupAttachedOrganizationQuery>;
export type GetVkGroupAttachedOrganizationLazyQueryHookResult = ReturnType<typeof useGetVkGroupAttachedOrganizationLazyQuery>;
export type GetVkGroupAttachedOrganizationQueryResult = Apollo.QueryResult<GetVkGroupAttachedOrganizationQuery, GetVkGroupAttachedOrganizationQueryVariables>;
export const GetVkGroupWidgetDataDocument = gql`
    query getVkGroupWidgetData {
  attachedOrganization: vkGroupAttachedOrganization {
    ...AttachedOrganizationWidgetData
  }
}
    ${AttachedOrganizationWidgetDataFragmentDoc}`;

/**
 * __useGetVkGroupWidgetDataQuery__
 *
 * To run a query within a React component, call `useGetVkGroupWidgetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVkGroupWidgetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVkGroupWidgetDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVkGroupWidgetDataQuery(baseOptions?: Apollo.QueryHookOptions<GetVkGroupWidgetDataQuery, GetVkGroupWidgetDataQueryVariables>) {
        return Apollo.useQuery<GetVkGroupWidgetDataQuery, GetVkGroupWidgetDataQueryVariables>(GetVkGroupWidgetDataDocument, baseOptions);
      }
export function useGetVkGroupWidgetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVkGroupWidgetDataQuery, GetVkGroupWidgetDataQueryVariables>) {
          return Apollo.useLazyQuery<GetVkGroupWidgetDataQuery, GetVkGroupWidgetDataQueryVariables>(GetVkGroupWidgetDataDocument, baseOptions);
        }
export type GetVkGroupWidgetDataQueryHookResult = ReturnType<typeof useGetVkGroupWidgetDataQuery>;
export type GetVkGroupWidgetDataLazyQueryHookResult = ReturnType<typeof useGetVkGroupWidgetDataLazyQuery>;
export type GetVkGroupWidgetDataQueryResult = Apollo.QueryResult<GetVkGroupWidgetDataQuery, GetVkGroupWidgetDataQueryVariables>;
export const SetVkGroupWidgetDocument = gql`
    mutation setVkGroupWidget {
  attachedOrganization: setVkGroupWidget(enabled: true) {
    ...AttachedOrganizationWidgetData
  }
}
    ${AttachedOrganizationWidgetDataFragmentDoc}`;
export type SetVkGroupWidgetMutationFn = Apollo.MutationFunction<SetVkGroupWidgetMutation, SetVkGroupWidgetMutationVariables>;

/**
 * __useSetVkGroupWidgetMutation__
 *
 * To run a mutation, you first call `useSetVkGroupWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVkGroupWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVkGroupWidgetMutation, { data, loading, error }] = useSetVkGroupWidgetMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetVkGroupWidgetMutation(baseOptions?: Apollo.MutationHookOptions<SetVkGroupWidgetMutation, SetVkGroupWidgetMutationVariables>) {
        return Apollo.useMutation<SetVkGroupWidgetMutation, SetVkGroupWidgetMutationVariables>(SetVkGroupWidgetDocument, baseOptions);
      }
export type SetVkGroupWidgetMutationHookResult = ReturnType<typeof useSetVkGroupWidgetMutation>;
export type SetVkGroupWidgetMutationResult = Apollo.MutationResult<SetVkGroupWidgetMutation>;
export type SetVkGroupWidgetMutationOptions = Apollo.BaseMutationOptions<SetVkGroupWidgetMutation, SetVkGroupWidgetMutationVariables>;
export const UnsetVkGroupWidgetDocument = gql`
    mutation unsetVkGroupWidget {
  attachedOrganization: setVkGroupWidget(enabled: false) {
    ...AttachedOrganizationWidgetData
  }
}
    ${AttachedOrganizationWidgetDataFragmentDoc}`;
export type UnsetVkGroupWidgetMutationFn = Apollo.MutationFunction<UnsetVkGroupWidgetMutation, UnsetVkGroupWidgetMutationVariables>;

/**
 * __useUnsetVkGroupWidgetMutation__
 *
 * To run a mutation, you first call `useUnsetVkGroupWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetVkGroupWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetVkGroupWidgetMutation, { data, loading, error }] = useUnsetVkGroupWidgetMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnsetVkGroupWidgetMutation(baseOptions?: Apollo.MutationHookOptions<UnsetVkGroupWidgetMutation, UnsetVkGroupWidgetMutationVariables>) {
        return Apollo.useMutation<UnsetVkGroupWidgetMutation, UnsetVkGroupWidgetMutationVariables>(UnsetVkGroupWidgetDocument, baseOptions);
      }
export type UnsetVkGroupWidgetMutationHookResult = ReturnType<typeof useUnsetVkGroupWidgetMutation>;
export type UnsetVkGroupWidgetMutationResult = Apollo.MutationResult<UnsetVkGroupWidgetMutation>;
export type UnsetVkGroupWidgetMutationOptions = Apollo.BaseMutationOptions<UnsetVkGroupWidgetMutation, UnsetVkGroupWidgetMutationVariables>;