import React, { memo } from 'react';
import { Avatar, SimpleCell, Headline, Caption, getPlatformClassName, Group, isDesktop } from '@overrided-vkui';
import { formatPrice } from '../../../utils/formatter';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  group: {
    '&$--desktop': {
      '& .Group__separator, &>:not(.Header):first-child': {
        marginTop: 0,
      },
      '& .Avatar__in': {
        height: '60px!important',
        width: '60px!important',
        borderRadius: '8px!important',
      },
      '& $record': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
  },
  record: {
    alignItems: 'flex-start',
    paddingTop: 8,
    paddingBottom: 10,
    '& .SimpleCell__main': {
      padding: 0,
    },
    '& .Avatar__img': {
      objectFit: 'cover',
    },
  },
  recordBefore: {
    width: 80,
    overflow: 'hidden',
  },
  recordAfter: {
    paddingLeft: 16,
  },
  recordDate_previous: {
    color: 'var(--text_secondary)',
  },
  recordTime: {
    marginTop: 3,
    color: 'var(--text_secondary)',
  },
  recordService: {
    marginTop: 3,
    color: 'var(--text_secondary)',
  },
  recordPrice: {
    marginTop: 5,
    color: 'var(--text_secondary)',
  },
});

export interface RecordListItem {
  id: number;
  datetime: string;
  unitTitle: string;
  unitLogoUrl?: string;
  serviceTitle: string;
  priceMin: number;
  priceMax: number;
}

interface RecordItemProps {
  record: RecordListItem;
  previous?: boolean;
  onClick?(): void;
}

const RecordItem = memo((props: RecordItemProps) => {
  const { record, previous, onClick } = props;
  const { datetime, priceMin, priceMax, unitLogoUrl, unitTitle, serviceTitle } = record;

  const Wrapper = isDesktop ? Group : 'div';

  const hasPrice = priceMin !== 0 && priceMax !== 0;

  const mc = useStyles();

  return (
    <Wrapper className={getPlatformClassName(mc.group)}>
      <SimpleCell
        className={mc.record}
        onClick={onClick}
        before={
          <div className={mc.recordBefore}>
            <Headline weight="medium" className={c({ [mc.recordDate_previous]: previous })}>
              {L.format.date(datetime, { shortMonth: true })}
            </Headline>
            <Caption className={mc.recordTime} level="1" weight="regular">
              {L.format.time(datetime)}
            </Caption>
          </div>
        }
        after={unitLogoUrl && <Avatar className={mc.recordAfter} src={unitLogoUrl} mode="app" size={56} />}
        multiline
      >
        <Headline weight="regular">{unitTitle}</Headline>
        <Caption className={mc.recordService} level="1" weight="regular">
          {serviceTitle}
        </Caption>
        <Caption className={mc.recordPrice} level="1" weight="regular">
          {hasPrice ? formatPrice(record.priceMin, record.priceMax) : L.t('price_not_specified')}
        </Caption>
      </SimpleCell>
    </Wrapper>
  );
});

export default RecordItem;
