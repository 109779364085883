import React, { memo, useCallback } from 'react';
import { View } from '@vkontakte/vkui';
import { Panel } from '@overrided-vkui';
import OrganizationNotConfiguredPanel from '../panelComponents/OrganizationNotConfiguredPanel';
import OrganizationPanel from '../panels/OrganizationPanel';
import { useRouter } from 'react-router5';
import { RootRoute } from 'src/router';
import { useGetVkGroupAttachedOrganizationQuery } from 'src/gql/generated/types';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import { L } from 'src/lang/L';

interface OrganizationViewProps {
  id: string;
}

const OrganizationView = memo((props: OrganizationViewProps) => {
  const { id: viewId } = props;

  /* Навигация */

  const router = useRouter();

  const openCatalog = useCallback(() => router.navigate(RootRoute.MAIN_CATALOG), [router]);
  const openUnit = useCallback(
    (organizationUnitId: number) =>
      router.navigate(RootRoute.ORGANIZATION_UNIT, { organizationUnitId, from: RootRoute.ORGANIZATION }),
    [router],
  );

  /* Текущая организация */

  const {
    data: organizationUnitsData,
    loading: organizationUnitsLoading,
    error: organizationUnitsError,
  } = useGetVkGroupAttachedOrganizationQuery({ variables: { withSettingsData: false } });

  const attachedOrganization = organizationUnitsData?.attachedOrganization || void 0;

  /* Рендер */

  if (!attachedOrganization) {
    return (
      <View key="organization-fetching" id={viewId} activePanel={RootRoute.ORGANIZATION}>
        {organizationUnitsLoading ? (
          <Panel id={RootRoute.ORGANIZATION}>
            <Panel.Content center>
              <StatusPlaceholder status="loading" />
            </Panel.Content>
          </Panel>
        ) : organizationUnitsError ? (
          <Panel id={RootRoute.ORGANIZATION}>
            <Panel.Content center>
              <StatusPlaceholder status="error" errorText={L.t('organization_fetch_error')} />
            </Panel.Content>
          </Panel>
        ) : (
          <OrganizationNotConfiguredPanel id={RootRoute.ORGANIZATION} openCatalog={openCatalog} />
        )}
      </View>
    );
  }

  return (
    <View key="organization" id={viewId} activePanel={RootRoute.ORGANIZATION}>
      <OrganizationPanel
        id={RootRoute.ORGANIZATION}
        routeName={RootRoute.ORGANIZATION}
        units={attachedOrganization.units}
        openUnit={openUnit}
        openCatalog={openCatalog}
      />
    </View>
  );
});

export default OrganizationView;
