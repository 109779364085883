import React, { memo } from 'react';
import { Button, Avatar, isDesktop, Placeholder, Panel } from '@overrided-vkui';
import logoUrl from '../../assets/logo.svg';
import { L } from 'src/lang/L';

interface OrganizationNotConfiguredPanelProps {
  id: string;
  openCatalog(): void;
}

const OrganizationNotConfiguredPanel = memo((props: OrganizationNotConfiguredPanelProps) => {
  const { id: panelId, openCatalog } = props;

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Content center>
        <Placeholder
          header={L.t('organization_not_configured_title')}
          icon={<Avatar src={logoUrl} size={isDesktop ? 64 : 80} mode="app" shadow={false} alt="" />}
          action={
            <Button size="l" onClick={openCatalog}>
              {L.t('organization_not_configured_nav_catalog')}
            </Button>
          }
        >
          {L.t('organization_not_configured_description')}
        </Placeholder>
      </Panel.Content>
    </Panel>
  );
});

export default OrganizationNotConfiguredPanel;
