// @ts-nocheck
import React, { Component, ReactElement } from 'react';
import PropTypes from 'prop-types';
import getClassName from '@vkontakte/vkui/dist/helpers/getClassName';
import classNames from '@vkontakte/vkui/dist/lib/classNames';
import { isFunction } from '@vkontakte/vkui/dist/lib/utils';
import { HasChildren, HasPlatform } from '@vkontakte/vkui/dist/types';
import withPlatform from '@vkontakte/vkui/dist/hoc/withPlatform';
import ModalRootContext, {
  ModalRootContextInterface,
} from '@vkontakte/vkui/dist/components/ModalRoot/ModalRootContext';
import { WebviewType } from '@vkontakte/vkui/dist/components/ConfigProvider/ConfigProviderContext';
import { ModalsStateEntry, ModalType } from '@vkontakte/vkui/dist/components/ModalRoot/types';

export interface ModalRootProps extends HasChildren, HasPlatform {
  activeModal?: string | null;

  /**
   * Будет вызвано при закрытии активной модалки с её id
   */
  onClose?(modalId: string): void;
}

export interface ModalRootState {
  activeModal?: string;
  prevModal?: string;
  nextModal?: string;
  visibleModals?: string[];
  animated?: boolean;
  switching?: boolean;
  history?: string[];
  isBack?: boolean;
  inited?: boolean;
  touchDown?: boolean;
  dragging?: boolean;
}

class ModalRoot extends Component<ModalRootProps, ModalRootState> {
  constructor(props: ModalRootProps) {
    super(props);

    const activeModal = props.activeModal;

    this.state = {
      activeModal: null,
      prevModal: null,
      nextModal: activeModal,
      visibleModals: activeModal ? [activeModal] : [],
      animated: !!activeModal,
      switching: false,
      history: activeModal ? [activeModal] : [],
      isBack: false,
      inited: false,
      touchDown: true,
      dragging: false,
    };

    this.activeTransitions = 0;
    this.maskElementRef = React.createRef();

    this.initModalsState();

    this.modalRootContext = {
      updateModalHeight: () => null,
    };

    this.frameIds = {};
  }

  private modalsState: { [id: string]: ModalsStateEntry };
  private documentScrolling: boolean;
  private activeTransitions: number;
  private readonly maskElementRef: React.RefObject<HTMLDivElement>;
  private readonly modalRootContext: ModalRootContextInterface;
  private readonly frameIds: {
    [index: string]: number;
  };

  static contextTypes = {
    window: PropTypes.any,
    document: PropTypes.any,
    webviewType: PropTypes.oneOf([WebviewType.VKAPPS, WebviewType.INTERNAL]),
  };

  get document(): Document {
    return this.context.document || document;
  }

  get window(): Window {
    return this.context.window || window;
  }

  get webviewType(): WebviewType {
    return this.context.webviewType || WebviewType.VKAPPS;
  }

  get modals() {
    return [].concat(this.props.children);
  }

  initModalsState() {
    this.modalsState = this.modals.reduce((acc, Modal) => {
      const modalProps = Modal.props;
      const state: ModalsStateEntry = {
        id: Modal.props.id,
        onClose: Modal.props.onClose,
        dynamicContentHeight: !!modalProps.dynamicContentHeight,
      };

      // ModalPage props
      if (typeof modalProps.settlingHeight === 'number') {
        state.settlingHeight = modalProps.settlingHeight;
      }

      acc[state.id] = state;
      return acc;
    }, {});
  }

  componentDidMount() {
    this.initActiveModal();
  }

  componentWillUnmount() {
    this.toggleDocumentScrolling(true);
  }

  componentDidUpdate(prevProps: ModalRootProps, prevState: ModalRootState) {
    if (this.props.activeModal !== prevProps.activeModal && !this.state.switching) {
      const nextModal = this.props.activeModal;
      const prevModal = prevProps.activeModal;

      if (nextModal !== null && !this.modalsState[nextModal]) {
        return console.warn(`[ModalRoot.componentDidUpdate] nextModal ${nextModal} not found`);
      }

      let history = [...this.state.history];
      let isBack = false;

      if (nextModal === null) {
        history = [];
      } else if (history.includes(nextModal)) {
        history = history.splice(0, history.indexOf(nextModal) + 1);
        isBack = true;
      } else {
        history.push(nextModal);
      }

      return this.setState(
        {
          activeModal: null,
          nextModal,
          prevModal,
          visibleModals: [nextModal],
          history,
          isBack,
          animated: false,
          inited: false,
          switching: false,
        },
        () => {
          if (nextModal === null) {
            this.closeActiveModal();
          } else {
            this.initActiveModal();
          }
        }
      );
    }

    if (this.state.switching && !prevState.switching) {
      requestAnimationFrame(() => this.switchPrevNext());
    }

    if (!this.state.activeModal && !this.state.prevModal && !this.state.nextModal) {
      this.toggleDocumentScrolling(true);
    } else {
      this.toggleDocumentScrolling(false);
    }
  }

  /* Отключает скролл документа */
  toggleDocumentScrolling(enabled: boolean) {
    if (this.documentScrolling === enabled) {
      return;
    }
    this.documentScrolling = enabled;

    if (enabled) {
      // Здесь нужен последний аргумент с такими же параметрами, потому что
      // некоторые браузеры на странных вендорах типа Meizu не удаляют обработчик.
      // https://github.com/VKCOM/VKUI/issues/444
      this.window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    } else {
      this.window.addEventListener('touchmove', this.preventTouch, { passive: false });
    }
  }

  preventTouch = (event: any) => {
    if (!event) {
      return false;
    }
    while (event.originalEvent) {
      event = event.originalEvent;
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    return false;
  };

  pickModal(modalId: string) {
    return this.document.getElementById('modal-' + modalId);
  }

  /**
   * Инициализирует модалку перед анимацией открытия
   */
  initActiveModal() {
    const activeModal = this.state.activeModal || this.state.nextModal;
    if (!activeModal) {
      return;
    }

    const modalElement = this.pickModal(activeModal);
    const modalState = this.modalsState[activeModal];

    if (modalElement.querySelector('.ModalPage')) {
      modalState.type = ModalType.PAGE;
    } else if (modalElement.querySelector('.ModalCard')) {
      modalState.type = ModalType.CARD;
    }

    switch (modalState.type) {
      case ModalType.PAGE:
        modalState.settlingHeight = modalState.settlingHeight || 75;
        this.initPageModal(modalState, modalElement);
        break;

      case ModalType.CARD:
        this.initCardModal(modalState, modalElement);
        break;

      default:
        console.warn('[ModalRoot.initActiveModal] modalState.type is unknown');
    }

    this.setState({ inited: true, switching: true });
  }

  initPageModal(modalState: ModalsStateEntry, modalElement: HTMLElement) {
    const contentElement: HTMLElement = modalElement.querySelector('.ModalPage__content');
    const contentHeight = (contentElement.firstElementChild as HTMLElement).offsetHeight;

    const prevTranslateY = modalState.translateY;

    modalState.expandable = contentHeight > contentElement.clientHeight;

    modalState.modalElement = modalElement;
    modalState.innerElement = modalElement.querySelector('.ModalPage__in-wrap');
    modalState.headerElement = modalElement.querySelector('.ModalPage__header');
    modalState.contentElement = modalElement.querySelector('.ModalPage__content');
    modalState.footerElement = modalElement.querySelector('.ModalPage__footer');

    let collapsed = false;
    let expanded = false;
    let translateYFrom;
    let translateY;
    let expandedRange: [number, number];
    let collapsedRange: [number, number];
    let hiddenRange: [number, number];

    if (modalState.expandable) {
      translateYFrom = 100 - modalState.settlingHeight;

      const shiftHalf = translateYFrom / 2;
      const visiblePart = 100 - translateYFrom;

      expandedRange = [0, shiftHalf];
      collapsedRange = [shiftHalf, translateYFrom + visiblePart / 4];
      hiddenRange = [translateYFrom + visiblePart / 4, 100];

      collapsed = translateYFrom > 0;
      expanded = translateYFrom <= 0;
      translateY = translateYFrom;
    } else {
      const headerHeight = modalState.headerElement.offsetHeight;
      const height = contentHeight + headerHeight;

      translateYFrom = 100 - (height / modalState.innerElement.parentElement.offsetHeight) * 100;
      translateY = translateYFrom;

      expandedRange = [translateY, translateY + 25];
      collapsedRange = [translateY + 25, translateY + 25];
      hiddenRange = [translateY + 25, translateY + 100];
    }

    // Если модалка может открываться на весь экран, и новый сдвиг больше предыдущего, то откроем её на весь экран
    if (modalState.expandable && translateY > prevTranslateY) {
      translateY = 0;
    }

    modalState.expandedRange = expandedRange;
    modalState.collapsedRange = collapsedRange;
    modalState.hiddenRange = hiddenRange;
    modalState.translateY = translateY;
    modalState.translateYFrom = translateYFrom;
    modalState.collapsed = collapsed;
    modalState.expanded = expanded;
  }

  initCardModal(modalState: ModalsStateEntry, modalElement: HTMLElement) {
    modalState.modalElement = modalElement;
    modalState.innerElement = modalElement.querySelector('.ModalCard__in');

    modalState.translateY = 0;
  }

  closeActiveModal() {
    const { prevModal } = this.state;
    if (!prevModal) {
      return console.warn(`[ModalRoot.closeActiveModal] prevModal is ${prevModal}`);
    }

    const prevModalState = this.modalsState[prevModal];

    this.waitTransitionFinish(prevModalState, this.prevNextSwitchEndHandler);
  }

  waitTransitionFinish(modalState: ModalsStateEntry, eventHandler: () => void) {
    setTimeout(eventHandler, 0);
  }

  switchPrevNext() {
    const { prevModal, nextModal } = this.state;

    const prevModalState = this.modalsState[prevModal];
    const nextModalState = this.modalsState[nextModal];

    if (!prevModalState && !nextModalState) {
      return console.warn(`[ModalRoot.switchPrevNext] prevModal is ${prevModal}, nextModal is ${nextModal}`);
    }

    const prevIsCard = !!prevModalState && prevModalState.type === ModalType.CARD;

    const nextIsPage = !!nextModalState && nextModalState.type === ModalType.PAGE;
    const nextIsCard = !!nextModalState && nextModalState.type === ModalType.CARD;

    // Ждём полного скрытия предыдущей модалки
    if (prevModalState && (nextIsCard || (prevIsCard && nextIsPage))) {
      this.waitTransitionFinish(prevModalState, () => {
        this.activeTransitions += 1;
        this.waitTransitionFinish(nextModalState, this.prevNextSwitchEndHandler);
      });

      return;
    }

    if (prevModalState && nextIsPage) {
      this.activeTransitions += 1;
      this.waitTransitionFinish(prevModalState, this.prevNextSwitchEndHandler);
    }

    this.activeTransitions += 1;
    this.waitTransitionFinish(nextModalState, this.prevNextSwitchEndHandler);
  }

  prevNextSwitchEndHandler = () => {
    this.activeTransitions = Math.max(0, this.activeTransitions - 1);
    if (this.activeTransitions > 0) {
      return;
    }

    const activeModal = this.state.nextModal;

    const newState: ModalRootState = {
      prevModal: null,
      nextModal: null,
      visibleModals: [activeModal],
      activeModal: activeModal,
      animated: false,
      switching: false,
    };

    if (!activeModal) {
      newState.history = [];
    }

    this.setState(newState);
  };

  /**
   * Закрывает текущую модалку
   */
  triggerActiveModalClose() {
    const activeModalState = this.modalsState[this.state.activeModal];
    if (activeModalState) {
      this.doCloseModal(activeModalState);
    }
  }

  private readonly doCloseModal = (modalState: ModalsStateEntry) => {
    if (isFunction(modalState.onClose)) {
      modalState.onClose();
    } else if (isFunction(this.props.onClose)) {
      this.props.onClose(modalState.id);
    } else {
      console.error('[ModalRoot] onClose is undefined');
    }
  };

  /**
   * По клику на полупрозрачный черный фон нужно закрыть текущую модалку
   */
  onMaskClick = () => {
    if (!this.state.switching) {
      this.triggerActiveModalClose();
    }
  };

  render() {
    const { prevModal, activeModal, nextModal, visibleModals, animated, dragging, switching } = this.state;

    if (!activeModal && !prevModal && !nextModal && !animated) {
      return null;
    }

    return (
      <ModalRootContext.Provider value={this.modalRootContext}>
        <div
          className={classNames(getClassName('ModalRoot', 'ModalRoot--touched', this.props.platform), {
            'ModalRoot--switching': switching,
          })}
        >
          <div
            className="ModalRoot__mask"
            onClick={this.onMaskClick}
            ref={this.maskElementRef}
            style={{ animation: 'none' }}
          />
          <div className="ModalRoot__viewport">
            {this.modals.map((Modal: ReactElement) => {
              const modalId = Modal.props.id;
              if (!visibleModals.includes(Modal.props.id)) {
                return null;
              }
              const modalState = { ...this.modalsState[modalId] };

              const isPage = modalState.type === ModalType.PAGE;
              const key = `modal-${modalId}`;

              return (
                <div
                  key={key}
                  id={key}
                  className={classNames('ModalRoot__modal', {
                    'ModalRoot__modal--active': modalId === activeModal,
                    'ModalRoot__modal--prev': modalId === prevModal,
                    'ModalRoot__modal--next': modalId === nextModal,

                    'ModalRoot__modal--dragging': dragging,

                    'ModalRoot__modal--expandable': isPage && modalState.expandable,
                    'ModalRoot__modal--expanded': isPage && modalState.expanded,
                  })}
                >
                  {Modal}
                </div>
              );
            })}
          </div>
        </div>
      </ModalRootContext.Provider>
    );
  }
}

export default withPlatform(ModalRoot);
