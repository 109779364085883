import React, { memo } from 'react';
import { Avatar, SimpleCell } from '@overrided-vkui';
import { CatalogOrganizationUnitProps } from '.';

const CatalogOrganizationUnitMobile: React.FC<CatalogOrganizationUnitProps> = memo((props) => {
  const { logoUrl, title, address, onClick } = props;

  return (
    <SimpleCell before={<Avatar src={logoUrl || ''} mode="image" size={72} />} description={address} onClick={onClick}>
      {title}
    </SimpleCell>
  );
});

export default CatalogOrganizationUnitMobile;
