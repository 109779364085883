import React, { memo } from 'react';
import RecordInfo from '../../RecordInfo';
import { Separator, Button, ModalPage, withModalPageOverlayWrapper } from '@overrided-vkui';
import { FormLayoutGroup, FormLayout } from '@vkontakte/vkui';
import { RecordInfoModalProps } from '.';
import ModalPageHeaderWithClose from '../../../atomic/ModalPageHeaderWithClose';
import { L } from 'src/lang/L';

const RecordInfoModalMobile: React.FC<RecordInfoModalProps> = memo((props) => {
  const { onClose, record, onCancelRecord, onRepeatRecord, onTransferRecord } = props;

  const isActualRecord = record && new Date(record.datetime) > new Date();
  const hasAllButtons = Boolean(
    record &&
      record.organizationUnit.bookingConfig.isAllowTransferRecord &&
      record.organizationUnit.bookingConfig.isAllowCancelRecord,
  );

  return (
    <ModalPage
      id="MY_RECORD"
      header={
        <ModalPageHeaderWithClose noShadow onClose={onClose}>
          {L.t('record_card_title')}
        </ModalPageHeaderWithClose>
      }
      onClose={onClose}
    >
      <Separator />
      {record && (
        <>
          <RecordInfo {...record} hidePhone={!isActualRecord || hasAllButtons} />
          <div style={{ paddingTop: 16 }}>
            {isActualRecord ? (
              (record.organizationUnit.bookingConfig.isAllowTransferRecord ||
                record.organizationUnit.bookingConfig.isAllowCancelRecord) && (
                <>
                  <Separator wide />
                  <FormLayout>
                    <FormLayoutGroup>
                      {record.organizationUnit.bookingConfig.isAllowTransferRecord && (
                        <Button size="xl" mode="secondary" onClick={onTransferRecord}>
                          {L.t('record_card_button_transfer')}
                        </Button>
                      )}
                      {record.organizationUnit.bookingConfig.isAllowCancelRecord && (
                        <Button
                          size="xl"
                          mode="secondary"
                          style={{
                            backgroundColor: 'var(--field_error_background)',
                            color: 'var(--field_error_border)',
                          }}
                          onClick={onCancelRecord}
                        >
                          {L.t('record_card_button_cancel')}
                        </Button>
                      )}
                    </FormLayoutGroup>
                  </FormLayout>
                </>
              )
            ) : (
              <>
                <Separator wide />
                <FormLayout>
                  <FormLayoutGroup>
                    <Button size="xl" mode="secondary" onClick={onRepeatRecord}>
                      {L.t('record_card_button_repeat')}
                    </Button>
                  </FormLayoutGroup>
                </FormLayout>
              </>
            )}
          </div>
        </>
      )}
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(RecordInfoModalMobile);
