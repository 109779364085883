import React, { useCallback, useState } from 'react';
import { memo } from 'react';
import { useSelector } from 'src/hooks/useSelector';
import { userActions } from 'src/redux/reducers/user';
import { useActions } from 'src/hooks/useActions';
import { Subtract } from 'src/types/utility';
import vkBridge from '@vkontakte/vk-bridge';
import { useStorageValue } from 'src/hooks/useStorageValue';
import { StorageField } from 'src/types';
import PhoneRequestModalCard from 'src/components/atomic/modals/PhoneRequestModalCard';

export interface PhoneData {
  number: string;
  sign: string;
}

export interface WithPhoneRequestInjectedProps {
  // пользователь разрешал доступ к номеру телефона
  phoneRequestConfirmed: boolean;
  // запрос номера телефона (undefined - закрыл окно с запросом, Phone - предоставил, reject - отклонил)
  requestPhone(): Promise<PhoneData | undefined>;
}

/**
 * Пробрасывает requestPhone, который единожды отрисовывает фэйковое окно с запросом номера телефона,
 * последующие разы резолвятся без окна с запросом с помощью сайлента на VKWebAppGetPhoneNumber
 */
function withPhoneRequest<T extends WithPhoneRequestInjectedProps>(
  Component: React.FC<T>,
): React.FC<Subtract<T, WithPhoneRequestInjectedProps>> {
  return memo((props) => {
    const phone = useSelector((state) => state.user.phone);
    const setPhone = useActions(userActions.setUserPhone);
    const [phoneRequestConfirmed = false, setPhoneRequestConfirmed] = useStorageValue(
      StorageField.PhoneRequestConfirmed,
    );

    const [phoneRequestCallbacks, setPhoneRequestCallbacks] = useState<{
      resolve: (confirmed: boolean) => void;
      reject: () => void;
    }>();

    const getPhoneNumber = useCallback(() => {
      if (phone) {
        return Promise.resolve(phone);
      }

      return vkBridge.send('VKWebAppGetPhoneNumber').then((result) => {
        const phone = { number: result.phone_number, sign: result.sign };
        setPhone(phone);
        return phone;
      });
    }, [phone, setPhone]);

    const requestPhone = useCallback(() => {
      if (phoneRequestConfirmed) {
        return getPhoneNumber();
      }

      return new Promise<boolean>((resolve, reject) => setPhoneRequestCallbacks({ resolve, reject }))
        .then((confirmed) => {
          if (confirmed) {
            setPhoneRequestConfirmed(true);
            return getPhoneNumber();
          }

          return void 0;
        })
        .finally(() => setPhoneRequestCallbacks(void 0));
    }, [setPhoneRequestConfirmed, getPhoneNumber, phoneRequestConfirmed]);

    return (
      <>
        <Component {...(props as T)} phoneRequestConfirmed={phoneRequestConfirmed} requestPhone={requestPhone} />
        <PhoneRequestModalCard
          show={Boolean(phoneRequestCallbacks)}
          onConfirm={() => phoneRequestCallbacks && phoneRequestCallbacks.resolve(true)}
          onClose={() => phoneRequestCallbacks && phoneRequestCallbacks.resolve(false)}
          onReject={() => phoneRequestCallbacks && phoneRequestCallbacks.reject()}
        />
      </>
    );
  });
}

export default withPhoneRequest;
