import React, { memo } from 'react';
import { Avatar, Button, Placeholder, Panel } from '@overrided-vkui';
import { AddToCommunityResultPanelProps } from '.';
import { openApplication } from 'src/utils/openApplication';
import { L } from 'src/lang/L';

const SettingsAddToCommunityResultPanelDesktop = memo((props: AddToCommunityResultPanelProps) => {
  const { groupId, appId, photoUrl, onClose, id: panelId } = props;

  return (
    <Panel id={panelId}>
      <Panel.Content center>
        <Placeholder
          icon={photoUrl && <Avatar src={photoUrl} alt="" mode="app" shadow={false} size={64} />}
          header={<L.Jsx t="unit_add_to_group_result_header" />}
          action={
            <>
              <Button onClick={onClose} size="l" mode="secondary">
                {L.t('unit_add_to_group_result_button_skip')}
              </Button>
              <Button onClick={() => openApplication(appId, { groupId })} size="l">
                {L.t('unit_add_to_group_result_button_ycl_login')}
              </Button>
            </>
          }
        >
          {L.t('unit_add_to_group_result_info')}
        </Placeholder>
      </Panel.Content>
    </Panel>
  );
});

export default SettingsAddToCommunityResultPanelDesktop;
