import React, { useEffect, memo, HTMLAttributes, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Div from '../Div';
import c from 'classnames';
import { FixedLayout } from '@vkontakte/vkui';
import { isDesktop, Separator, getPlatformClassName } from '@overrided-vkui';

const useStyles = makeStyles({
  panelFooter: {
    bottom: 0,
    marginTop: 'auto',
  },
  panelFooter_filled: {
    background: 'var(--background_content)',
  },
  separator: {
    '&--desktop > .Separator__in': {
      backgroundColor: 'var(--steel_gray_80)',
      transform: 'none!important',
      height: 1,
    },
  },
});

export interface PanelFooterProps extends HTMLAttributes<HTMLDivElement> {
  transparent?: boolean;
  fixed?: boolean;
}

const PanelFooter: React.FC<PanelFooterProps> = memo((props) => {
  const { className, fixed, transparent, children, ...rest } = props;
  const mc = useStyles();

  const layoutParentRef = useRef<HTMLDivElement>(null);

  // Устанавливаем высоту подложки, равную высоте фиксированной части футера

  useEffect(() => {
    const layoutParentEl = layoutParentRef.current;
    const fixedLayoutContentEl = layoutParentEl?.children[0]?.children[0];

    if (layoutParentEl && fixedLayoutContentEl && fixed) {
      layoutParentEl.style.height = `${fixedLayoutContentEl.clientHeight}px`;
    }
  }, [layoutParentRef, children, fixed]);

  const Wrapper = fixed ? FixedLayout : 'div';

  return (
    <div
      ref={layoutParentRef}
      className={c(className, mc.panelFooter)}
      style={fixed ? { height: 10 * window.innerHeight } : void 0}
    >
      <Wrapper vertical="bottom" className={c({ [mc.panelFooter_filled]: !transparent })} {...rest}>
        {isDesktop && <Separator wide className={getPlatformClassName(mc.separator)} />}
        <Div>{children}</Div>
      </Wrapper>
    </div>
  );
});

export default PanelFooter;
