import React, { createContext, useContext } from 'react';

const empty = () => null;

export interface OverlayContext {
  showModal(modalId: string, content: React.ReactElement): void;
  hideModal(modalId: string): void;

  showPopout(popoutId: string, content: React.ReactElement): void;
  hidePopout(popoutId: string): void;

  overlayOpened: boolean;

  openSnackbar(content: React.ReactElement): void;
}

export const overlayContext = createContext<OverlayContext>({
  showModal: empty,
  hideModal: empty,
  showPopout: empty,
  hidePopout: empty,
  openSnackbar: empty,
  overlayOpened: false,
});

export const useOverlayContext = () => useContext(overlayContext);
