import React, { memo } from 'react';
import {
  Panel,
  PanelHeaderBack,
  Button,
  Placeholder,
  isDesktop,
  OverlayPopoutWrapper,
  ScreenSpinner,
  getPlatformClassName,
} from '@overrided-vkui';
import FooterButtons from '../atomic/FooterButtons';
import { ReactComponent as ActionButtonPreviewMobile } from '../../assets/action-button-preview-mobile.svg';
import { ReactComponent as ActionButtonPreviewDesktop } from '../../assets/action-button-preview-desktop.svg';
import { makeStyles } from '@material-ui/styles';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  placeholder: {
    '& .Placeholder__in': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  destructiveSecondaryButton: {
    backgroundColor: 'var(--field_error_background)',
    color: 'var(--field_error_border)',
  },
  panelFooter: {
    '& .Div': {
      padding: 24,
    },
  },
  actionButtonPreview: {
    width: '100%',
    '&--mobile': {
      maxWidth: 320,
    },
    '&--desktop': {
      width: 526,
      marginBottom: -20,
    },
  },
});

interface ActionButton {
  enabled: boolean;
}

interface OrganizationActionButtonSettingsPanelProps {
  id: string;
  onBack?(): void;
  onSkip?(): void;
  actionButtonLoading?: boolean;
  actionButtonError?: boolean;
  actionButton?: ActionButton;
  onActionButtonSwitch(): void;
  actionButtonSwitchLoading: boolean;
}

const OrganizationActionButtonSettingsPanel = memo((props: OrganizationActionButtonSettingsPanelProps) => {
  const {
    id: panelId,
    onActionButtonSwitch,
    actionButtonLoading,
    actionButtonError,
    actionButton,
    actionButtonSwitchLoading,
    onBack,
    onSkip,
  } = props;

  const mc = useStyles();

  const renderContent = (actionButton: ActionButton) => {
    const actions = [
      actionButton.enabled && (
        <Button
          key="unset"
          size="l"
          mode="secondary"
          className={isDesktop ? void 0 : mc.destructiveSecondaryButton}
          onClick={onActionButtonSwitch}
        >
          {L.t('organization_action_button_settings_action_unset')}
        </Button>
      ),
      !actionButton.enabled && (
        <Button key="set" size="l" mode="primary" onClick={onActionButtonSwitch}>
          {L.t('organization_action_button_settings_action_set')}
        </Button>
      ),
      onSkip && (
        <Button key="skip" size="l" mode="secondary" onClick={onSkip}>
          {L.t('organization_action_button_settings_nav_skip')}
        </Button>
      ),
    ];

    const ActionButtonPreview = isDesktop ? ActionButtonPreviewDesktop : ActionButtonPreviewMobile;

    return (
      <>
        <Panel.Content center>
          <Placeholder
            className={mc.placeholder}
            icon={<ActionButtonPreview className={getPlatformClassName(mc.actionButtonPreview)} />}
            header={L.t('organization_action_button_settings_title')}
            action={isDesktop && actions}
          >
            {actionButton.enabled
              ? L.t('organization_action_button_settings_info_done')
              : L.t('organization_action_button_settings_info')}
          </Placeholder>
        </Panel.Content>
        {!isDesktop && (
          <Panel.Footer className={mc.panelFooter}>
            <FooterButtons>{actions}</FooterButtons>
          </Panel.Footer>
        )}
      </>
    );
  };

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Header left={onBack && <PanelHeaderBack onClick={onBack} />} transparent={true} separator={isDesktop}>
        {isDesktop && L.t('organization_action_button_settings_title')}
      </Panel.Header>
      {actionButton ? (
        renderContent(actionButton)
      ) : actionButtonLoading ? (
        <Panel.Content center>
          <StatusPlaceholder status="loading" />
        </Panel.Content>
      ) : actionButtonError ? (
        <Panel.Content center>
          <StatusPlaceholder status="error" errorText={L.t('organization_action_button_settings_fetch_error')} />
        </Panel.Content>
      ) : null}
      <OverlayPopoutWrapper show={actionButtonSwitchLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </Panel>
  );
});

export default OrganizationActionButtonSettingsPanel;
