import React, { memo, useState, useEffect } from 'react';
import { ModalPage, ModalPageHeader, Search, SimpleCell, PanelFooter, Button } from '@overrided-vkui';
import { List, Footer } from '@vkontakte/vkui';
import Icon24CheckCircleOn from '@vkontakte/icons/dist/24/check_circle_on';
import { makeStyles } from '@material-ui/styles';
import { CitySelectModalPageProps, City } from './index';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  modalPage: {
    '& .ModalPage__content-in': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  modalPage__footer: {
    borderRadius: '0 0 8px 8px',
  },
  search: {
    position: 'sticky',
    top: 0,
  },
  list: {
    marginTop: 8,
  },
});

const CitySelectModalPageDesktop = memo((props: CitySelectModalPageProps) => {
  const { id: modalId, onClose, selectedCityId, searchValue, setSearchValue, onSelectCity, cities } = props;

  const [checkedCity, setCheckedCityId] = useState<City | null>(null);

  useEffect(() => {
    setCheckedCityId(cities.find((city) => city.id === selectedCityId) || null);
  }, [cities, selectedCityId]);

  const mc = useStyles();

  return (
    <ModalPage
      id={modalId}
      onClose={onClose}
      className={mc.modalPage}
      fullHeight
      header={<ModalPageHeader>{L.t('city_select_modal_title')}</ModalPageHeader>}
    >
      <Search
        className={mc.search}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        maxLength={255}
      />
      <List className={mc.list}>
        {cities.map((city) => (
          <SimpleCell
            key={city.id}
            description={
              typeof city.unitsCount === 'number'
                ? L.t('organization_units_amount', { count: city.unitsCount })
                : void 0
            }
            onClick={() => setCheckedCityId(city)}
            after={checkedCity?.id === city.id && <Icon24CheckCircleOn />}
          >
            {city.title}
          </SimpleCell>
        ))}
      </List>
      <Footer>{L.t('cities_amout', { count: cities.length })}</Footer>
      <PanelFooter className={mc.modalPage__footer}>
        <FooterButtons>
          <Button onClick={() => checkedCity && onSelectCity(checkedCity.id)} disabled={!checkedCity}>
            {L.t('city_select_modal_submit')}
          </Button>
        </FooterButtons>
      </PanelFooter>
    </ModalPage>
  );
});

export default CitySelectModalPageDesktop;
