import React, { memo, useRef, useCallback, useEffect } from 'react';
import VKAlert, { AlertProps } from '@vkontakte/vkui/dist/components/Alert/Alert';
import c from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Div, Button } from '@overrided-vkui';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';

const useStyles = makeStyles({
  content: {},
  alert: {
    '& .Alert': {
      width: 400,
      maxWidth: 'calc(100% - 100px)',
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16)',
      overflow: 'initial',
      borderRadius: 8,
    },
    '& .Alert.Alert--closing': {
      transitionDuration: '0.01s',
    },
    '& .Alert__footer': {
      display: 'none',
    },
    '& .Alert:before, & $content': {
      borderRadius: 'inherit',
      overflow: 'hidden',
    },
    '& .Alert__content': {
      padding: '8px 0',
      textAlign: 'left',
      boxShadow: 'inset 0 0 0 0.5px rgba(0, 0, 0, 0.08)',
      borderRadius: 'inherit',
      '&:after': {
        display: 'none',
      },
      '& h2': {
        fontSize: 16,
        lineHeight: '20px',
        marginBottom: 8,
        fontWeight: 500,
      },
      '& p': {
        fontSize: 13,
        lineHeight: '18px',
        color: 'var(--gray_600)',
      },
    },
  },
  buttons: {
    textAlign: 'right',
    paddingTop: 8,
    paddingBottom: 8,
    '& > * + *': {
      marginLeft: 8,
    },
    '&$buttons_reversed': {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& > * + *': {
        marginRight: 8,
        marginLeft: 0,
      },
    },
  },
  buttons_reversed: {},
  cross: {
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    margin: 10,
    background: 'rgba(0, 0, 0, 0.35)',
    top: 0,
    position: 'absolute',
    right: -24,
    transform: 'translate(100%, 0)',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'opacity .2s ease-out',
    '&:hover': {
      opacity: 0.88,
    },
  },
});

const AlertDesktop: React.FC<AlertProps> = memo(({ children, className, ...alertProps }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const onButtonClick = useCallback(
    (index: number) => {
      if (contentRef.current) {
        const alertFooter = contentRef.current.parentElement?.parentElement?.querySelector('.Alert__footer');
        const alertFooterButton = alertFooter?.children[index] as HTMLElement | undefined;
        alertFooterButton && alertFooterButton.click();
      }
    },
    [contentRef],
  );

  const onCloseClick = useCallback(() => {
    const wrapperEl = document.querySelector('.PopoutWrapper__container') as HTMLElement | undefined;
    wrapperEl && wrapperEl.click();
  }, []);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCloseClick();
      }
    };
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [onCloseClick]);

  const mc = useStyles();

  return (
    <VKAlert {...alertProps} className={c(className, mc.alert)}>
      <div className={mc.cross} onClick={onCloseClick}>
        <Icon24Cancel width={20} height={20} />
      </div>
      <div className={mc.content} ref={contentRef}>
        <Div>{children}</Div>
        {alertProps.actions && (
          <Div className={c(mc.buttons, { [mc.buttons_reversed]: alertProps.actionsLayout === 'vertical' })}>
            {alertProps.actions.map((action, index) => (
              <Button
                key={action.title}
                mode={action.mode === 'destructive' ? 'primary' : 'secondary'}
                size="l"
                onClick={() => onButtonClick(index)}
              >
                {action.title}
              </Button>
            ))}
          </Div>
        )}
      </div>
    </VKAlert>
  );
});

export default AlertDesktop;
