import styled from '@material-ui/styles/styled';
import { Banner } from '@vkontakte/vkui';
import { BannerProps } from '@vkontakte/vkui/dist/components/Banner/Banner';

export default styled(Banner)({
  padding: '0 24px',
  margin: '8px 0',
  '& .Banner__in': {
    backgroundColor: 'var(--steel_gray_40)',
    borderRadius: 8,
    padding: '12px 16px',
  },
  '& .Banner__in:before': {
    border: '0.5px solid rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
    zIndex: 2,
  },
  '& .Banner__dismissIcon': {
    zIndex: 2,
    cursor: 'pointer',
    // eslint-disable-next-line
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792893 0.792893C0.402369 1.18342 0.402369 1.81658 0.792893 2.20711L5.58579 7L0.792893 11.7929C0.402369 12.1834 0.402369 12.8166 0.792893 13.2071C1.18342 13.5976 1.81658 13.5976 2.20711 13.2071L7 8.41421L11.7929 13.2071C12.1834 13.5976 12.8166 13.5976 13.2071 13.2071C13.5976 12.8166 13.5976 12.1834 13.2071 11.7929L8.41421 7L13.2071 2.20711C13.5976 1.81658 13.5976 1.18342 13.2071 0.792893C12.8166 0.402369 12.1834 0.402369 11.7929 0.792893L7 5.58579L2.20711 0.792893Z' fill='%23818c99'/%3E%3C/svg%3E%0A")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'transparent',
  },
}) as React.FC<BannerProps>;
