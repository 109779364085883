import React, { memo, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetOrganizationUnitsQuery } from 'src/gql/generated/types';
import { formatCityAddress } from 'src/utils/formatter';
import CatalogOrganizationUnit from 'src/components/atomic/CatalogOrganizationUnit';
import useThrottle from 'src/hooks/useThrottle';
import StatusPlaceholder from 'src/components/atomic/StatusPlaceholder';
import { Placeholder } from '@overrided-vkui';
import { Footer } from '@vkontakte/vkui';
import { L } from 'src/lang/L';

interface OrganizationUnitListProps {
  search: string;
  categoryGroupId?: number;
  coord?: { lat: number; lng: number };
  openOrganizationUnit(ogranizationUnitId: number): void;
  className?: string;
  style?: React.CSSProperties;
}

const OrganizationUnitList: React.FC<OrganizationUnitListProps> = memo((props) => {
  const { search: realSearch, coord, className, style, categoryGroupId, openOrganizationUnit } = props;

  const search = useThrottle(realSearch, 500);

  const { data, fetchMore, loading } = useGetOrganizationUnitsQuery({
    variables: { q: search, lat: coord?.lat, lng: coord?.lng, categoryGroupId },
    notifyOnNetworkStatusChange: true,
  });

  const units = data?.organizationUnits || [];

  const loadMore = useCallback(() => {
    try {
      fetchMore({ variables: { offset: units.length } }).catch(() => null);
    } catch {}
  }, [fetchMore, units]);

  return (
    <InfiniteScroll
      dataLength={units.length}
      hasMore={true}
      next={loadMore}
      loader={null}
      initialScrollY={5000}
      className={className}
      style={style}
    >
      {units?.map((unit) => (
        <CatalogOrganizationUnit
          key={unit.id}
          logoUrl={unit.logoUrl}
          title={unit.title}
          address={formatCityAddress(unit.city.title, unit.address)}
          onClick={() => openOrganizationUnit(unit.id)}
        />
      ))}
      {loading && <StatusPlaceholder status="loading" />}
      {!loading &&
        (units.length === 0 ? (
          <Placeholder>{L.t('catalog_search_not_found')}</Placeholder>
        ) : (
          <Footer>{L.t('company_amount', { count: units.length })}</Footer>
        ))}
    </InfiniteScroll>
  );
});

export default OrganizationUnitList;
