import { useCallback } from 'react';
import { useRouteNode } from 'react-router5';

const useQueryState = <T extends string>(routeNode: string, queryName: string, replace = false) => {
  const { route, router } = useRouteNode(routeNode);

  const value = (route.params[queryName] && (decodeURIComponent(route.params[queryName]) as T)) || void 0;

  const setValue = useCallback(
    (value: T | undefined) => {
      router.navigate(routeNode, { ...route.params, [queryName]: value && encodeURIComponent(value) }, { replace });
    },
    [router, route, routeNode, queryName, replace],
  );

  return [value, setValue] as const;
};

export default useQueryState;
