interface WidgetData {
  title: string;
  title_url: string;
  more: string;
  more_url: string;
  rows: Array<{
    title: string;
    title_url: string;
    descr?: string;
  }>;
}

function isWidgetData(data: any): data is WidgetData {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.title === 'string' &&
    typeof data.title_url === 'string' &&
    typeof data.more === 'string' &&
    typeof data.more_url === 'string' &&
    Array.isArray(data.rows) &&
    data.rows[0] &&
    typeof data.rows[0].title === 'string' &&
    typeof data.rows[0].title_url === 'string' &&
    (typeof data.rows[0].descr === 'string' || typeof data.rows[0].descr === 'undefined')
  );
}

export function parseWidgetPreviewCode(code: string): WidgetData | null {
  try {
    const data = JSON.parse(code.slice(7, -1));

    return isWidgetData(data) ? data : null;
  } catch {
    return null;
  }
}

