import OrganizationSettingsPanelMobile from './OrganizationSettingsPanelMobile';
import OrganizationSettingsPanelDesktop from './OrganizationSettingsPanelDesktop';
import { getPlatformComponent } from '@overrided-vkui';
import { WidgetVariant, AccessTokenStatus } from 'src/gql/generated/types';
import { AttachedOrganization } from 'src/types/yclients';

export interface OrganizationSettingsPanelProps {
  id: string;
  organization: AttachedOrganization & {
    isVkGroupWidgetEnabled: boolean;
    isVkGroupActionButtonEnabled: boolean;
    vkGroupAccessTokenStatus: AccessTokenStatus;
  };
  isSingleUnitYClientsOrganization: boolean;
  widgetVariant?: WidgetVariant;
  openOrganizationUnits(): void;
  openUnit(unitId: number): void;
  openWidgetSettings(): void;
  openActionButtonSettings(): void;
  onLogout(): void;
}

export default getPlatformComponent<OrganizationSettingsPanelProps>({
  mobile: OrganizationSettingsPanelMobile,
  desktop: OrganizationSettingsPanelDesktop,
});
