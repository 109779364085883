import React, { memo, useCallback } from 'react';
import { Button, Placeholder, PanelHeaderBack, Panel } from '@overrided-vkui';
import Icon56PhoneOutline from '@vkontakte/icons/dist/56/phone_outline';
import { L } from 'src/lang/L';
import withPhoneRequest, { PhoneData, WithPhoneRequestInjectedProps } from 'src/components/hocs/withPhoneRequest';

interface OrganizationUnitStepPhoneProps extends WithPhoneRequestInjectedProps {
  id: string;
  onNext(phone: PhoneData): void;
  onBack(): void;
}

const OrganizationUnitStepPhone = memo((props: OrganizationUnitStepPhoneProps) => {
  const { onBack, onNext, requestPhone, id: panelId } = props;

  const onAllowClickHandler = useCallback(() => {
    requestPhone()
      .then((phone) => phone && onNext(phone))
      .catch(() => null);
  }, [requestPhone, onNext]);

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Header left={<PanelHeaderBack onClick={onBack} />}>{L.t('make_record_step_phone_header')}</Panel.Header>
      <Panel.Content center>
        <Placeholder
          icon={<Icon56PhoneOutline />}
          header={L.t('make_record_step_phone_title')}
          action={
            <Button size="l" onClick={onAllowClickHandler}>
              {L.t('make_record_step_phone_button_allow')}
            </Button>
          }
        >
          {L.t('native_phone_request_description')}
        </Placeholder>
      </Panel.Content>
    </Panel>
  );
});

export default withPhoneRequest(OrganizationUnitStepPhone);
