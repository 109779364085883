import React, { memo } from 'react';
import { Avatar as VKAvatar } from '@vkontakte/vkui';
import { AvatarProps as VKAvatarProps } from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';

const SQUIRCLE_ID = 'squircle';

document.body.insertAdjacentHTML(
  'beforeend',
  `<svg width="0" height="0" style="display:block;">
    <defs>
      <clipPath id="squircle" clipPathUnits="objectBoundingBox">
        <path d="M.653 0C.933 0 1 .067 1 .347v.306C1 .933.933 1 .653 1H.347C.067 1 0 .933 0 .653V.347C0 .067.067 0 .347 0h.306z"/>
      </clipPath>
    </defs>
   </svg>`,
);

const useStyles = makeStyles({
  avatar: {
    '& .Avatar__img': {
      objectFit: 'cover',
    },
  },
  avatar__squircle: {
    '& .Avatar__img': {
      clipPath: `url(#${SQUIRCLE_ID})`,
      '-webkit-clip-path': `url(#${SQUIRCLE_ID})`,
    },
  },
});

interface AvatarProps extends Omit<VKAvatarProps, 'mode'> {
  mode?: 'default' | 'image' | 'app' | 'squircle';
}

const Avatar: React.FC<AvatarProps> = memo((props) => {
  const { className, mode = 'default', shadow = true, ...rest } = props;
  const mc = useStyles();
  return (
    <VKAvatar
      className={c(className, mc.avatar, { [mc.avatar__squircle]: mode === 'squircle' })}
      mode={mode === 'squircle' ? 'app' : mode}
      shadow={mode === 'squircle' ? false : shadow}
      {...rest}
    />
  );
});

export default Avatar;
