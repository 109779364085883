import React, { memo } from 'react';
import { Panel, SimpleCell, Search, Separator } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';
import { PanelHeaderButton, List, PanelHeaderContent, Footer } from '@vkontakte/vkui';
import CompaniesMap from 'src/components/atomic/OrganizationUnitsMap';
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown';
import { OrganizationPanelProps } from './index';
import { formatCityAddress } from 'src/utils/formatter';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  map: {
    height: 275,
    width: '100%',
  },
  list: {
    marginTop: 8,
  },
});

const OrganizationPanelDesktop = memo((props: OrganizationPanelProps) => {
  const { id: panelId, openCatalog, openUnit, cityTitle, searchValue, setSearchValue, units, onCityTitleClick } = props;

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      <Panel.Header
        left={<PanelHeaderButton onClick={openCatalog}>{L.t('organization_map_panel_nav_catalog')}</PanelHeaderButton>}
      >
        <PanelHeaderContent
          onClick={onCityTitleClick}
          aside={onCityTitleClick && <Icon16Dropdown />}
          status={<span />}
          before={null}
        >
          {cityTitle}
        </PanelHeaderContent>
      </Panel.Header>
      <Panel.Content>
        <CompaniesMap className={mc.map} cityId={units[0]?.city.id || -1} onUnitClick={openUnit} units={units} />
        <Separator wide />
        <Search
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={L.t('common:search_placeholder')}
          after={L.t('common:cancel')}
          maxLength={255}
        />
        <List className={mc.list}>
          {units.map((unit) => (
            <SimpleCell
              key={unit.id}
              onClick={() => openUnit(unit.id)}
              description={formatCityAddress(unit.city.title, unit.address)}
              expandable
            >
              {unit.title}
            </SimpleCell>
          ))}
        </List>
        <Footer>{L.t('organization_units_amount', { count: units.length })}</Footer>
      </Panel.Content>
    </Panel>
  );
});

export default OrganizationPanelDesktop;
