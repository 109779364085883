import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UnitHeadSectionProps } from './index';
import Icon16Chevron from '@vkontakte/icons/dist/16/chevron';
import { Link } from '@overrided-vkui';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  wallpaperContent: {
    display: 'flex',
    padding: 24,
    alignItems: 'center',
    zIndex: 1,
    minWidth: 0,
    position: 'relative',
    '&:after': {
      left: 0,
      right: 0,
      bottom: 0,
      height: 1,
      content: '""',
      zIndex: 1,
      position: 'absolute',
      backgroundColor: 'var(--steel_gray_80)',
    },
  },
  wallpaperContent__logo: {
    height: 72,
    width: 72,
    borderRadius: 8,
  },
  wallpaperContentTexts: {
    marginLeft: 16,
    flex: 1,
    minWidth: 0,
  },
  wallpaperContentTexts__title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    display: '-webkit-box',
    overflow: 'hidden',
    maxHeight: '56px',
    wordBreak: 'break-word',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  wallpaperContentTexts__address: {
    color: 'var(--steel_gray_400)',
    fontSize: 15,
    lineHeight: '20px',
    letterSpacing: -0.24,
    marginTop: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wallpaperContentTexts__more: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 15,
    lineHeight: '20px',
    marginTop: 3,
    '& .Icon': {
      marginTop: 2,
      marginLeft: 1,
    },
  },
});

const UnitHeadSectionDesktop = memo((props: UnitHeadSectionProps) => {
  const { logoUrl, title, address, onMoreInfo } = props;
  const mc = useStyles();

  return (
    <div className={mc.wallpaperContent}>
      <img src={logoUrl} alt="" className={mc.wallpaperContent__logo} />
      <div className={mc.wallpaperContentTexts}>
        <div className={mc.wallpaperContentTexts__title}>{title}</div>
        <div className={mc.wallpaperContentTexts__address}>{address}</div>
        <Link Component="span" onClick={onMoreInfo} className={mc.wallpaperContentTexts__more}>
          <span>{L.t('unit_open_meta_link')}</span>
          <Icon16Chevron height={12} width={12} />
        </Link>
      </div>
    </div>
  );
});

export default UnitHeadSectionDesktop;
