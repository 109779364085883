/**
 * Типы событий
 */
export enum StatType {
  Navgo = 'type_navgo',
  View = 'type_view',
  Click = 'type_click',
  Action = 'type_action',
}

/**
 * Список поддерживаемых событий
 */
export enum StatEvent {
  /* Пользователь успешно оформил запись в салон */
  Booked = 'booked',
  /* Пользователь отменил существующую запись */
  Canceled = 'canceled',
  /* Пользователь перенес существующую запись */
  Moved = 'moved',
  /* Пользователь повторил существующую запись */
  Repeat = 'repeat',
  /* Воспользовался поисковой строкой в каталоге */
  Search = 'search',
  /* Запись в салон открыта из каталога */
  Open = 'open',
  /* Успешная авторизация в yclients и привязка салона к сообществу */
  AuthSuccess = 'auth_success',
}

/**
 * Описывает то, какое событие имеет какой тип
 */
export const statEventTypeMap: Record<StatEvent, StatType> = {
  [StatEvent.Booked]: StatType.Click,
  [StatEvent.Canceled]: StatType.Click,
  [StatEvent.Moved]: StatType.Click,
  [StatEvent.Repeat]: StatType.Click,
  [StatEvent.Search]: StatType.View,
  [StatEvent.Open]: StatType.Navgo,
  [StatEvent.AuthSuccess]: StatType.Action,
};

/**
 * Описывает то, какое событие имеет какие типы передаваемых данных
 */
interface StatEventPayloadMap {
  [StatEvent.Booked]: { organizationUnitId: number; type: string; bookDate: string };
  [StatEvent.Canceled]: { organizationUnitId: number };
  [StatEvent.Moved]: { organizationUnitId: number };
  [StatEvent.Repeat]: {};
  [StatEvent.Search]: {};
  [StatEvent.Open]: { organizationUnitId: number };
  [StatEvent.AuthSuccess]: { organizationId: number; organizationUnitIds: number[]; groupId: number };
}

/**
 * Получает типы передаваемых данных для конкретного события
 */
export type StatEventPayload<T extends StatEvent> = StatEventPayloadMap[T];
