/**
 * Список полей, которые могут храниться в хранилище bridge.
 */
export enum StorageField {
  ApplicationVisited = 'application-visited',
  PhoneRequestConfirmed = 'phone-request-confirmed',
  GeolocationSuggestSkipped = 'geolocation-suggest-skipped',
}

/**
 * Описывает то, какое поле имеет какой тип данных в хранилище bridge.
 */
export interface StorageValuesMap {
  [StorageField.ApplicationVisited]: boolean;
  [StorageField.PhoneRequestConfirmed]: boolean;
  [StorageField.GeolocationSuggestSkipped]: boolean;
}

/**
 * Возвращает тип данных для указанного поля хранилища.
 */
export type StorageValueType<T extends StorageField> = StorageValuesMap[T];
