import styled from '@material-ui/styles/styled';
import { Group } from '@vkontakte/vkui';
import { GroupProps } from '@vkontakte/vkui/dist/components/Group/Group';

export default styled(Group)({
  '&:last-of-type .Group__separator': {
    display: 'initial',
  },
  '&:last-of-type > .Group__separator': {
    display: 'none',
  },
}) as React.FC<GroupProps>;
