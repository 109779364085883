import React from 'react';
import styled from '@material-ui/styles/styled';

import { PlaceholderProps } from '@vkontakte/vkui/dist/components/Placeholder/Placeholder';
import { Placeholder } from '@vkontakte/vkui';

export default styled(Placeholder)({
  '& .Placeholder__action': {
    top: -4,
    bottom: -4,
    position: 'relative',
    '& > *': {
      margin: 4,
    },
  },
}) as React.FC<PlaceholderProps>;
