import React, { memo } from 'react';
import PanelHeaderButton, {
  PanelHeaderButtonProps,
} from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import Icon28ChevronLeftOutline from '@vkontakte/icons/dist/28/chevron_left_outline';
import { L } from '../../../lang/L';
import c from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  button: {
    minWidth: 110,
    marginLeft: -12,
    paddingLeft: 12,
    marginRight: -32,
    color: 'var(--steel_gray_400)',
    opacity: '0.88!important',
    height: '100%',
    '&:hover': {
      background: 'linear-gradient(to right,var(--steel_gray_40) 50%,var(--header_background))',
      opacity: '1!important',
    },
  },
  icon: {
    paddingRight: '0!important',
    paddingLeft: '4px!important',
  },
});

const PanelHeaderBackDesktop: React.FC<PanelHeaderButtonProps> = memo((props) => {
  const { children, className, ...restProps } = props;
  const mc = useStyles();
  return (
    <PanelHeaderButton className={c(className, mc.button)} {...restProps}>
      <Icon28ChevronLeftOutline className={mc.icon} />
      <div>{children || L.t('common:back')}</div>
    </PanelHeaderButton>
  );
});

export default PanelHeaderBackDesktop;
