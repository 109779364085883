import React, { memo, useCallback, useState } from 'react';
import { View } from '@vkontakte/vkui';
import RegisterPanel from '../panels/RegisterPanel';
import { useRouteNode } from 'react-router5';
import { RootRoute } from 'src/router';
import { YClientsOrganization } from 'src/types/yclients';
import YClientsOrganizationSelectPanel from '../panelComponents/YClientsOrganizationSelectPanel';
import YClientsOrganizationUnitsSelectPanel from '../panels/YClientsOrganizationUnitsSelectPanel';
import OrganizationWidgetSettingsPanel from '../panels/OrganizationWidgetSettingsPanel';
import OrganizationActionButtonSettingsPanel from '../panels/OrganizationActionButtonSettingsPanel';
import { useActions } from 'src/hooks/useActions';
import { organizationActions } from 'src/redux/reducers/organization';
import { StatEvent } from 'src/types/statEvents';
import { statEvents } from 'src/utils/statEvents';
import { useSnackbar } from '@overrided-vkui';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';
import { L } from 'src/lang/L';
import { useSelector } from 'src/hooks/useSelector';

interface RegisterViewProps {
  id: string;
}

const RegisterView = memo((props: RegisterViewProps) => {
  const { id: viewId } = props;
  const { router, route } = useRouteNode(RootRoute.REGISTER);

  const vkGroupId = useSelector((state) => state.launchParams.groupId);
  const openSnackbar = useSnackbar();

  const setAttachedUnitIds = useActions(organizationActions.setAttachedUnitIds);
  const [authData, setAuthData] = useState<{ login: string; password: string }>();
  const [yClientsOrganizations, setYClientsOrganizations] = useState<YClientsOrganization[]>([]);
  const [yClientsOrganization, setYClientsOrganization] = useState<YClientsOrganization | null>(null);
  const goBack = useCallback(() => window.history.back(), []);

  const openOrganizationSettings = useCallback(() => {
    if (!yClientsOrganization) return;

    if (yClientsOrganization.yClientsOrganizationUnits.length === 1) {
      openSnackbar(
        <SuccessSnackbar
          text={L.t('attach_ycl_single_unit_organization_submit_success', {
            title: yClientsOrganization.yClientsOrganizationUnits[0].title,
          })}
          onClose={() => null}
        />,
      );
    } else {
      openSnackbar(
        <SuccessSnackbar
          text={L.t('attach_ycl_organization_units_submit_success', {
            title: yClientsOrganization.title,
          })}
          onClose={() => null}
        />,
      );
    }

    router.navigate(RootRoute.ORGANIZATION_SETTINGS);
  }, [router, openSnackbar, yClientsOrganization]);

  const activePanel = route.name;

  return (
    <View id={viewId} activePanel={authData ? activePanel : RootRoute.REGISTER}>
      <RegisterPanel
        id={RootRoute.REGISTER}
        onNext={(yClientsOrganizations, authData) => {
          setYClientsOrganizations(yClientsOrganizations);
          setAuthData(authData);
          if (yClientsOrganizations.length === 1) {
            setYClientsOrganization(yClientsOrganizations[0]);
            router.navigate(RootRoute.REGISTER_YCLIENTS_ORGANIZATION_UNITS);
          } else {
            router.navigate(RootRoute.REGISTER_YCLIENTS_ORGANIZATIONS);
          }
        }}
      />
      <YClientsOrganizationSelectPanel
        id={RootRoute.REGISTER_YCLIENTS_ORGANIZATIONS}
        yClientsOrganizations={yClientsOrganizations}
        onBack={goBack}
        onNext={(organizationUnit) => {
          setYClientsOrganization(organizationUnit);
          router.navigate(RootRoute.REGISTER_YCLIENTS_ORGANIZATION_UNITS);
        }}
      />
      <YClientsOrganizationUnitsSelectPanel
        id={RootRoute.REGISTER_YCLIENTS_ORGANIZATION_UNITS}
        units={yClientsOrganization?.yClientsOrganizationUnits || []}
        organizationId={yClientsOrganization?.id!}
        isSingleOrganization={yClientsOrganizations.length === 1}
        authData={authData!}
        onBack={goBack}
        onNext={(attachedUnitIds, showWidgetSuggest) => {
          setAttachedUnitIds(attachedUnitIds);

          if (yClientsOrganization && vkGroupId) {
            statEvents.send(StatEvent.AuthSuccess, {
              organizationId: yClientsOrganization.id,
              organizationUnitIds: attachedUnitIds,
              groupId: vkGroupId,
            });
          }

          if (showWidgetSuggest) {
            router.navigate(RootRoute.REGISTER_WIDGET);
          } else {
            openOrganizationSettings();
          }
        }}
      />
      <OrganizationWidgetSettingsPanel
        id={RootRoute.REGISTER_WIDGET}
        onNext={openOrganizationSettings}
        onSkip={openOrganizationSettings}
        hideSuccessSnackbar
      />
      <OrganizationActionButtonSettingsPanel
        id={RootRoute.REGISTER_ACTION_BUTTON}
        onBack={route.params.from === RootRoute.REGISTER_WIDGET ? goBack : void 0}
        onNext={openOrganizationSettings}
        onSkip={openOrganizationSettings}
        hideSuccessSnackbar
      />
    </View>
  );
});

export default RegisterView;
