import React, { memo } from 'react';
import { Button, Avatar, isDesktop, Placeholder, Panel } from '@overrided-vkui';
import { L } from 'src/lang/L';
import FooterButtons from '../atomic/FooterButtons';
import { openPhoneCall } from 'src/utils/openPhoneCall';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  panelFooter: {
    '& .Div': {
      padding: 24,
    },
  },
});

interface OrganizationUnitNotActivePanelProps {
  id: string;
  logoUrl?: string;
  title: string;
  phone?: string;
  openCatalog(): void;
  leftButton?: React.ReactNode;
}

const OrganizationUnitNotActivePanel = memo((props: OrganizationUnitNotActivePanelProps) => {
  const { id: panelId, logoUrl, title, leftButton, phone, openCatalog } = props;

  const mc = useStyles();

  return (
    <Panel id={panelId} elastic={false}>
      <Panel.Header left={leftButton} transparent separator={false} />
      <Panel.Content center>
        <Placeholder
          header={<L.Jsx t="not_synced_unit_title" vars={{ title }} />}
          icon={logoUrl && <Avatar src={logoUrl} size={80} mode="app" shadow={false} alt="" />}
          action={
            isDesktop && (
              <Button size="l" onClick={openCatalog}>
                {L.t('not_synced_unit_button_back')}
              </Button>
            )
          }
        >
          <L.Jsx t="not_synced_unit_description" />
        </Placeholder>
      </Panel.Content>
      {!isDesktop && (
        <Panel.Footer className={mc.panelFooter}>
          <FooterButtons>
            {phone && <Button onClick={() => openPhoneCall(phone)}>{L.t('call')}</Button>}
            <Button mode="tertiary" onClick={openCatalog}>
              {L.t('not_synced_unit_button_back')}
            </Button>
          </FooterButtons>
        </Panel.Footer>
      )}
    </Panel>
  );
});

export default OrganizationUnitNotActivePanel;
