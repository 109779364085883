import { ofType, unionize, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';
import {
  AppearanceSchemeType,
  DefaultUpdateConfigData,
  MVKUpdateConfigData,
  VKUpdateConfigData,
  UpdateConfigData,
} from '@vkontakte/vk-bridge';

interface PreparedMVKAppConfig extends Omit<MVKUpdateConfigData, 'api_host'> {
  apiHost: string;
}

interface PreparedVKAppConfig
  extends Omit<VKUpdateConfigData, 'api_host' | 'viewport_width' | 'viewport_height' | 'is_layer'> {
  apiHost: string;
  viewportWidth: number;
  viewportHeight: number;
  isLayer: boolean;
}

interface PreparedDefaultAppConfig extends Omit<DefaultUpdateConfigData, 'app_id'> {
  appId: string;
}

type PreparedUpdateConfigData = PreparedMVKAppConfig | PreparedVKAppConfig | PreparedDefaultAppConfig;

export interface AppConfigReducerState extends PreparedMVKAppConfig, PreparedVKAppConfig, PreparedDefaultAppConfig {}

export const appConfigActions = unionize(
  {
    updateConfig: ofType<UpdateConfigData>(),
    updateAppearanceScheme: ofType<AppearanceSchemeType>(),
  },
  unionizeConfig,
);

type AppConfigAction = UnionOf<typeof appConfigActions>;

const initialState: AppConfigReducerState = {
  app: 'vkclient',
  appId: '',
  appearance: 'light',
  scheme: 'client_light',
  insets: { top: 0, bottom: 0, left: 0, right: 0 },
  viewportHeight: 0,
  viewportWidth: 0,
  apiHost: '',
  isLayer: false,
};

export function prepareAppConfig(config: UpdateConfigData & { is_layer?: boolean }): PreparedUpdateConfigData {
  if ('insets' in config) {
    const { app_id, ...restConfig } = config;

    return {
      ...restConfig,
      appId: app_id,
    };
  }

  if ('viewport_height' in config && 'viewport_width' in config) {
    const { viewport_height, viewport_width, api_host, is_layer, ...restConfig } = config;

    return {
      ...restConfig,
      viewportHeight: viewport_height,
      viewportWidth: viewport_width,
      apiHost: api_host,
      isLayer: Boolean(is_layer),
    };
  }

  return {
    ...config,
    apiHost: config.api_host,
  };
}

export function appConfigReducer(state: AppConfigReducerState = initialState, action: AppConfigAction) {
  return appConfigActions.match(action, {
    updateConfig: (config) => ({ ...state, ...prepareAppConfig(config) }),
    updateAppearanceScheme: (scheme) => ({ ...state, scheme }),
    default: () => state,
  });
}
