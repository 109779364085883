import React, { memo, useCallback } from 'react';
import { useSnackbar, ScreenSpinner, OverlayPopoutWrapper } from '@overrided-vkui';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import { useReloginYClientsOrganizationMutation } from 'src/gql/generated/types';
import { L } from 'src/lang/L';
import LoginPanel, { LoginData } from '../panelComponents/LoginPanel';
import { tapticNotification } from 'src/utils/taptic';

interface ReloginPanelProps {
  id: string;
  onNext(): void;
}

const ReloginPanel: React.FC<ReloginPanelProps> = memo((props) => {
  const { id: panelId, onNext } = props;

  const openSnackbar = useSnackbar();

  const [
    reloginYClientsOrganizations,
    { loading: reloginYClientsOrganizationsLoading },
  ] = useReloginYClientsOrganizationMutation({
    onError() {
      openSnackbar(<ErrorRetrySnackbar text={L.t('register_ycl_incorrect_password_error')} onClose={() => null} />);
    },
    onCompleted() {
      tapticNotification('success');
      onNext();
    },
  });

  const onNextHandler = useCallback(
    (loginData: LoginData) => {
      if (!loginData.login || !loginData.password) {
        openSnackbar(<ErrorRetrySnackbar text={L.t('register_ycl_empty_form_error')} onClose={() => null} />);
        return;
      }

      reloginYClientsOrganizations({
        context: {
          headers: {
            'x-auth-login': encodeURIComponent(loginData.login),
            'x-auth-password': encodeURIComponent(loginData.password),
          },
        },
      }).catch(() => null);
    },
    [reloginYClientsOrganizations, openSnackbar],
  );

  return (
    <>
      <LoginPanel id={panelId} onNext={onNextHandler} onNextLoading={reloginYClientsOrganizationsLoading} />
      <OverlayPopoutWrapper show={reloginYClientsOrganizationsLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </>
  );
});

export default ReloginPanel;
