import React, { memo, useState } from 'react';
import { StaffSelectModalProps } from '.';
import { Avatar, SimpleCell, Header, Separator, Group, ModalPage, withModalPageOverlayWrapper } from '@overrided-vkui';
import { List, Footer } from '@vkontakte/vkui';
import ModalPageHeaderWithClose from '../../atomic/ModalPageHeaderWithClose';
import { L } from 'src/lang/L';
import Icon24CheckCircleOn from '@vkontakte/icons/dist/24/check_circle_on';
import { delay } from 'src/utils/misc';
import { tapticSelectionChanged } from 'src/utils/taptic';

const StaffSelectModalMobile = memo((props: StaffSelectModalProps) => {
  const { onClose, activeStaffId, staffItems, datetime } = props;

  const [selectedStaffId, setSelectedStaffId] = useState(activeStaffId);

  const onCloseHandler = () => onClose(selectedStaffId);

  return (
    <ModalPage
      id="STAFF_SELECT"
      onClose={onCloseHandler}
      header={
        <ModalPageHeaderWithClose noShadow onClose={onCloseHandler}>
          {L.t('make_record_step_staff_header')}
        </ModalPageHeaderWithClose>
      }
    >
      <Separator />
      {staffItems && (
        <Group
          header={
            <Header mode="secondary">
              {L.t('make_record_step_staff_available_label', { datetime: L.format.dateTime(datetime) })}
            </Header>
          }
        >
          <List>
            {staffItems.map((staff) => (
              <SimpleCell
                key={staff.id}
                before={<Avatar src={staff.avatarUrl || void 0} size={48} />}
                after={staff.id === selectedStaffId && <Icon24CheckCircleOn />}
                description={staff.specialization}
                onClick={() => {
                  tapticSelectionChanged();
                  setSelectedStaffId(staff.id);
                  delay(200).then(() => onClose(staff.id));
                }}
              >
                {staff.name}
              </SimpleCell>
            ))}
          </List>
          <Footer style={{ margin: 0, padding: '24px 0' }}>{L.t('staff_amout', { count: staffItems.length })}</Footer>
        </Group>
      )}
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(StaffSelectModalMobile);
