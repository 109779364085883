import Tabs, { TabsProps } from '@vkontakte/vkui/dist/components/Tabs/Tabs';
import styled from '@material-ui/styles/styled';

export default styled(Tabs)({
  position: 'relative',
  width: 'fit-content',
  minWidth: '100%',
  '&:after': {
    content: '""',
    position: 'absolute',
    backgroundColor: 'var(--steel_gray_80)',
    zIndex: 1,
    height: 1,
    left: 0,
    right: 0,
    bottom: 0,
  }
}) as React.FC<TabsProps>;
