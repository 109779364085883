import React, { memo, useCallback } from 'react';
import { View } from '@vkontakte/vkui';
import UserOnboardPanel from '../panelComponents/UserOnboardPanel';
import { useRouter } from 'react-router5';
import { RootRoute } from '../../router';

interface OnboardViewProps {
  id: string;
}

const OnboardView = memo((props: OnboardViewProps) => {
  const { id: viewId } = props;
  const router = useRouter();

  const onNextHandler = useCallback(() => {
    router.navigate(RootRoute.MAIN_CATALOG, {}, { replace: true });
  }, [router]);

  return (
    <View id={viewId} activePanel="onboard.panel">
      <UserOnboardPanel id="onboard.panel" onNext={onNextHandler} />
    </View>
  );
});

export default OnboardView;
