import React, { memo } from 'react';
import {
  Panel,
  PanelHeader,
  SimpleCell,
  Avatar,
  Button,
  PanelHeaderBack,
  Search,
  Placeholder,
  Separator,
  isDesktop,
  getPlatformClassName,
} from '@overrided-vkui';
import { Staff } from '../../components/atomic/StaffItem';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import { L } from 'src/lang/L';
import ServiceCategorySelection from '../atomic/ServiceCategorySelection';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'column',
    '&--mobile $separator': {
      margin: '8px 0 4px',
    },
    '&--desktop $staffCell': {
      margin: '17px 0 18px',
      '& .SimpleCell__children': {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '24px',
      },
      '& .SimpleCell__description': {
        fontSize: 15,
        lineHeight: '20px',
        marginTop: 4,
      },
      '& .Avatar': {
        paddingRight: 16,
      },
    },
    '&--desktop $search': {
      order: 2,
    },
  },
  search: {},
  staffCell: {},
  separator: {},
});

interface CategoryService {
  id: number;
  title: string;
  description?: string | null;
  serviceStaff: Array<{ priceMin: number; priceMax: number; duration: number }>;
}

interface StaffServiceSelectPanelProps {
  id: string;
  staff: Staff;
  onBack(): void;
  serviceCategories?: Array<{ id: number; title: string }>;
  serviceCategoriesLoading?: boolean;
  serviceCategoriesError?: boolean;
  categoryServicesSearchValue: string;
  setCategoryServicesSearchValue(searchValue: string): void;
  selectedCategoryId?: number;
  selectCategory(categoryId: number): void;
  selectedCategoryServices?: CategoryService[];
  selectedCategoryServicesLoading?: boolean;
  selectedCategoryServicesError?: boolean;
  refetchSelectedCategoryServices(): void;
  selectedCategoryServiceId?: number;
  selectCategoryService(categoryServiceId: number): void;
  onNext(): void;
}

const StaffServiceSelectPanel = memo((props: StaffServiceSelectPanelProps) => {
  const {
    id: panelId,
    staff,
    onBack,
    serviceCategories,
    serviceCategoriesError,
    serviceCategoriesLoading,
    refetchSelectedCategoryServices,
    selectCategory,
    selectedCategoryServicesLoading,
    selectedCategoryServicesError,
    selectCategoryService,
    selectedCategoryServiceId,
    selectedCategoryServices,
    selectedCategoryId,
    categoryServicesSearchValue,
    setCategoryServicesSearchValue,
    onNext,
  } = props;

  const hasCategories = serviceCategories && serviceCategories.length > 0;

  const isSearchMode = Boolean(categoryServicesSearchValue.trim());

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      <PanelHeader left={<PanelHeaderBack onClick={onBack} />} separator={isDesktop}>
        {L.t('make_record_step_staff_services_title')}
      </PanelHeader>
      <div className={getPlatformClassName(mc.header)}>
        <Search
          value={categoryServicesSearchValue}
          placeholder={L.t('common:search_placeholder')}
          after={L.t('common:cancel')}
          maxLength={255}
          className={mc.search}
          onChange={(e) => setCategoryServicesSearchValue(e.target.value)}
        />
        {(!isSearchMode || isDesktop) && (
          <>
            <SimpleCell
              disabled
              before={<Avatar size={72} src={staff.avatarUrl || void 0} />}
              description={staff.specialization}
              className={mc.staffCell}
            >
              {staff.name}
            </SimpleCell>
            <Separator className={mc.separator} wide={isDesktop} />
          </>
        )}
      </div>
      {serviceCategories && hasCategories ? (
        <ServiceCategorySelection
          refetchSelectedCategoryServices={refetchSelectedCategoryServices}
          selectCategory={selectCategory}
          selectedCategoryServicesLoading={selectedCategoryServicesLoading}
          selectedCategoryServicesError={selectedCategoryServicesError}
          selectCategoryService={selectCategoryService}
          selectedCategoryServiceId={selectedCategoryServiceId}
          selectedCategoryServices={selectedCategoryServices}
          selectedServiceCategoryId={selectedCategoryId}
          serviceCategories={serviceCategories}
        />
      ) : serviceCategories ? (
        <Panel.Content center>
          <Placeholder>{L.t('make_record_step_services_empty')}</Placeholder>
        </Panel.Content>
      ) : serviceCategoriesLoading ? (
        <Panel.Content center>
          <StatusPlaceholder status="loading" />
        </Panel.Content>
      ) : serviceCategoriesError ? (
        <Panel.Content center>
          <StatusPlaceholder status="error" errorText={L.t('make_record_step_staff_services_fetch_error')} />
        </Panel.Content>
      ) : null}
      {hasCategories && (
        <Panel.Footer fixed>
          <FooterButtons>
            <Button disabled={!selectedCategoryServiceId} onClick={onNext}>
              {L.t('make_record_step_slots_button_select')}
            </Button>
          </FooterButtons>
        </Panel.Footer>
      )}
    </Panel>
  );
});

export default StaffServiceSelectPanel;
