import React, { useEffect, useRef, memo } from 'react';
import DG from '2gis-maps';

interface OrganizationUnitPreviewMapProps extends React.HTMLAttributes<HTMLDivElement> {
  logoUrl?: string;
  lat: number;
  lng: number;
  watchOnly?: boolean;
}

const OrganizationUnitPreviewMap = memo((props: OrganizationUnitPreviewMapProps) => {
  const { logoUrl, lat, lng, watchOnly, ...restProps } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<any>(null);
  const markers = useRef<any[]>([]).current;

  useEffect(() => {
    if (containerRef.current) {
      try {
        mapRef.current =
          mapRef.current ||
          DG.map(containerRef.current, {
            center: [lat, lng],
            ...(watchOnly
              ? {
                  dragging: false,
                  touchZoom: false,
                  scrollWheelZoom: false,
                  doubleClickZoom: false,
                  boxZoom: false,
                  geoclicker: false,
                }
              : void 0),
            zoomControl: false,
            fullscreenControl: false,
            zoom: 13,
          });
        markers.forEach((marker) => marker.remove());

        const marker = DG.marker([lat, lng], {
          icon: DG.divIcon({
            html: `<div style="\
                height: 32px;\
                width: 32px;\
                border-radius: 50%;\
                transform: translate(-10px, -10px);\
                border: 2px solid #fff;\
                box-sizing: border-box;\
                background-size: cover;\
                cursor: initial;\
                background-position: center;\
                box-shadow: 0 2px 8px rgba(0,0,0,.16);\
                background-image: url('${logoUrl}');\
                background-color: #f0f0f0;\
              "></div>`.replace(/\s+/g, ' '),
          }),
        });
        marker.addTo(mapRef.current);
        markers.push(marker);
      } catch {}
    }
  }, [lat, lng, watchOnly, logoUrl, markers]);

  return <div ref={containerRef} {...restProps} />;
});

export default OrganizationUnitPreviewMap;
