import React, { useCallback } from 'react';
import { memo } from 'react';
import { useSelector } from 'src/hooks/useSelector';
import { userActions } from 'src/redux/reducers/user';
import { useActions } from 'src/hooks/useActions';
import { Subtract } from 'src/types/utility';
import vkBridge from '@vkontakte/vk-bridge';

export interface WithUserNotificationsInjectedProps {
  areNotificationsEnabled: boolean;
  setAreNotificationsEnabled(enabled: boolean): Promise<boolean>;
}

/**
 * Пробрасывает пропсы для работы с уведомлениями и автоматически устанавливет
 * актуальные значения в Redux-store
 */
function withUserNotifications<T extends WithUserNotificationsInjectedProps>(
  Component: React.FC<T>,
): React.FC<Subtract<T, WithUserNotificationsInjectedProps>> {
  return memo((props) => {
    const areNotificationsEnabled = useSelector((state) => state.user.areNotificationsEnabled);
    const setAreNotificationsEnabledAction = useActions(userActions.setAreNotificationsEnabled);

    const setAreNotificationsEnabled = useCallback(
      (checked: boolean) => {
        return Promise.resolve()
          .then(() => {
            setAreNotificationsEnabledAction(checked);
            return vkBridge.send(checked ? 'VKWebAppAllowNotifications' : 'VKWebAppDenyNotifications');
          })
          .then(() => true)
          .catch(() => {
            setAreNotificationsEnabledAction(!checked);
            return false;
          });
      },
      [setAreNotificationsEnabledAction],
    );

    return (
      <Component
        {...(props as T)}
        areNotificationsEnabled={areNotificationsEnabled}
        setAreNotificationsEnabled={setAreNotificationsEnabled}
      />
    );
  });
}

export default withUserNotifications;
