import React, { memo, useCallback } from 'react';
import { View } from '@vkontakte/vkui';
import OrganizationUnitStepServiceStaffSlots from '../panels/OrganizationUnitStepServiceStaffSlots';
import { useRouteNode } from 'react-router5';
import { RootRoute } from 'src/router';
import { useSnackbar } from '@overrided-vkui';
import SuccessSnackbar from '../atomic/snackbars/SuccessSnackbar';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import { delay } from 'src/utils/misc';
import { useTransferRecordMutation } from 'src/gql/generated/types';
import { L } from 'src/lang/L';
import { StatEvent, statEvents } from '../../utils/statEvents';

interface TransferRecordViewProps {
  id: string;
}

const TransferRecordView = memo((props: TransferRecordViewProps) => {
  const { id: viewId } = props;
  const { route, router } = useRouteNode(RootRoute.TRASFER_RECORD);
  const { serviceId, serviceStaffId, recordId, from } = route.params;

  const openSnackbar = useSnackbar();

  const onBack = useCallback(() => {
    if (from === RootRoute.MAIN_RECORDS) {
      window.history.back();
    } else {
      router.navigate(RootRoute.MAIN_RECORDS);
      delay(100).then(() => {
        router.navigate(RootRoute.MAIN_RECORDS, { recordId });
      });
    }
  }, [from, recordId, router]);

  const [transferRecord, { loading: transferRecordLoading }] = useTransferRecordMutation({
    onCompleted(data) {
      statEvents.send(StatEvent.Moved, { organizationUnitId: data.transferRecord.organizationUnit.id });
      openSnackbar(<SuccessSnackbar text={L.t('transfer_record_fetch_success')} onClose={() => null} />);
      onBack();
    },
    onError() {
      openSnackbar(<ErrorRetrySnackbar text={L.t('transfer_record_fetch_error')} onClose={() => null} />);
    },
  });

  const onNext = useCallback(
    (datetime: string) => {
      return transferRecord({ variables: { recordId, datetime, serviceStaffId } }).catch(() => null);
    },
    [transferRecord, recordId, serviceStaffId],
  );

  return (
    <View id={viewId} activePanel="transferRecordView.panel">
      <OrganizationUnitStepServiceStaffSlots
        id="transferRecordView.panel"
        routeName={RootRoute.TRASFER_RECORD}
        onBack={onBack}
        onNext={onNext}
        onNextLoading={transferRecordLoading}
        serviceId={serviceId}
        serviceStaffId={serviceStaffId}
      />
    </View>
  );
});

export default TransferRecordView;
