import React, { memo, useEffect, useMemo } from 'react';
import { useOverlayContext } from './context';
import { createIterator } from './utils';
import usePrevious from './usePrevious';

interface PopoutContextWrapperProps {
  show: boolean;
  children: React.ReactElement;
}

const nextPopoutId = createIterator('overlay-popout');

/**
 * Обертка для отображения Alert, ActionSheet и ScreenSpinner
 */
const PopoutContextWrapper: React.FC<PopoutContextWrapperProps> = memo(({ show, children }) => {
  const { showPopout, hidePopout } = useOverlayContext();

  const id = useMemo(nextPopoutId, []);
  const prevShow = usePrevious(show);

  useEffect(() => {
    if (!show && prevShow) {
      hidePopout(id);
    }
  }, [show, prevShow, id, hidePopout]);

  useEffect(() => {
    if (show) {
      showPopout(id, children);
    }
  }, [showPopout, show, id, children]);

  useEffect(() => (): void => hidePopout(id), [id, hidePopout]);

  return null;
});

export default PopoutContextWrapper;
