import React, { useCallback, memo } from 'react';
import { View } from '@vkontakte/vkui';
import { RootRoute } from '../../router';
import { useRoute } from 'react-router5';
import UserRecordsPanel from '../panels/UserRecordsPanel';

interface RecordsViewProps {
  id: string;
  panelStart?: React.ReactNode;
}

const RECORD_MODAL_KEY = 'record';
const CANCEL_RECORD_ALERT_KEY = 'cancel-record';

const RecordsView: React.FC<RecordsViewProps> = memo((props) => {
  const { id: viewId, panelStart } = props;

  const goHistoryBack = useCallback(() => window.history.back(), []);

  /* Модалка и алерт с подтверждением удаления */

  const { route, router } = useRoute();

  const modalRecordId = (route.params.modal === RECORD_MODAL_KEY && Number(route.params.recordId)) || void 0;

  const openRecordModal = useCallback(
    (recordId: number) => {
      router.navigate(route.name, { ...route.params, modal: RECORD_MODAL_KEY, recordId });
    },
    [route, router],
  );

  const cancelAlertRecordId =
    (route.params.alert === CANCEL_RECORD_ALERT_KEY && Number(route.params.recordId)) || void 0;

  const openRecordCancelAlert = useCallback(
    (recordId: number) => {
      router.navigate(route.name, { ...route.params, recordId, alert: CANCEL_RECORD_ALERT_KEY });
    },
    [route, router],
  );

  /* Обработчики действий */

  const openUnit = useCallback(
    (unitId: number) => {
      router.navigate(RootRoute.ORGANIZATION_UNIT, { organizationUnitId: unitId, from: RootRoute.MAIN_RECORDS });
    },
    [router],
  );

  const openTransferRecord = useCallback(
    (serviceId: number, serviceStaffId: number, recordId: number) => {
      router.navigate(RootRoute.TRASFER_RECORD, {
        serviceId,
        serviceStaffId,
        recordId,
        from: RootRoute.MAIN_RECORDS,
      });
    },
    [router],
  );

  return (
    <View id={viewId} activePanel={RootRoute.MAIN_RECORDS}>
      <UserRecordsPanel
        id={RootRoute.MAIN_RECORDS}
        panelHeader={panelStart}
        openRecordModal={openRecordModal}
        openTransferRecord={openTransferRecord}
        openUnit={openUnit}
        closeRecordModal={goHistoryBack}
        modalRecordId={modalRecordId || void 0}
        recordCancelAlertOpened={Boolean(cancelAlertRecordId)}
        openRecordCancelAlert={openRecordCancelAlert}
        closeRecordCancelAlert={goHistoryBack}
      />
    </View>
  );
});

export default RecordsView;
