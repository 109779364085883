import React, { memo, useCallback, useState } from 'react';
import { useSnackbar, ScreenSpinner, OverlayPopoutWrapper } from '@overrided-vkui';
import ErrorRetrySnackbar from '../atomic/snackbars/ErrorRetrySnackbar';
import { YClientsOrganization } from 'src/types/yclients';
import { useGetYClientsOrganizationsLazyQuery } from 'src/gql/generated/types';
import { L } from 'src/lang/L';
import LoginPanel, { LoginData } from '../panelComponents/LoginPanel';
import { tapticNotification } from 'src/utils/taptic';

interface RegisterPanelProps {
  id: string;
  onNext(yClientsOrganizations: YClientsOrganization[], loginData: LoginData): void;
}

const RegisterPanel: React.FC<RegisterPanelProps> = memo((props) => {
  const { id: panelId, onNext } = props;

  const openSnackbar = useSnackbar();

  const [loginData, setLoginData] = useState<LoginData>({ login: '', password: '' });

  const [getYClientsOrganizations, { loading: getYClientsOrganizationsLoading }] = useGetYClientsOrganizationsLazyQuery(
    {
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'x-auth-login': encodeURIComponent(loginData.login),
          'x-auth-password': encodeURIComponent(loginData.password),
        },
      },
      onError: () => {
        openSnackbar(<ErrorRetrySnackbar text={L.t('register_ycl_incorrect_password_error')} onClose={() => null} />);
      },
      onCompleted: (data) => {
        tapticNotification('success');
        onNext(data.yClientsOrganizations, loginData);
      },
    },
  );

  const onNextHandler = useCallback(
    (loginData: LoginData) => {
      if (!loginData.login || !loginData.password) {
        openSnackbar(<ErrorRetrySnackbar text={L.t('register_ycl_empty_form_error')} onClose={() => null} />);
        return;
      }

      setLoginData(loginData);
      getYClientsOrganizations();
    },
    [getYClientsOrganizations, openSnackbar],
  );

  return (
    <>
      <LoginPanel id={panelId} onNext={onNextHandler} onNextLoading={getYClientsOrganizationsLoading} />
      <OverlayPopoutWrapper show={getYClientsOrganizationsLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </>
  );
});

export default RegisterPanel;
