import React, { memo, useCallback } from 'react';
import { View } from '@vkontakte/vkui';
import CatalogPanel from '../panels/catalog/CatalogPanel';
import CategoriesPanel from '../panels/catalog/CategoriesPanel';
import CategoryCatalogPanel from '../panels/catalog/CategoryCatalogPanel';
import { useRouteNode } from 'react-router5';
import { RootRoute } from '../../router';
import { StatEvent, statEvents } from '../../utils/statEvents';

interface CatalogViewProps {
  id: string;
  panelStart?: React.ReactNode;
  onBack?(): void;
}

const CatalogView = memo((props: CatalogViewProps) => {
  const { panelStart, onBack, id: viewId } = props;

  const { router, route } = useRouteNode(RootRoute.MAIN_CATALOG);

  /* Навигация со свайп-беком */

  const prevRoute = route.params.from as RootRoute | undefined;
  const activeRoute = route.name;

  const history =
    prevRoute?.startsWith(RootRoute.MAIN_CATALOG) && prevRoute !== activeRoute
      ? [prevRoute, activeRoute]
      : [activeRoute];

  const activePanel = history[history.length - 1];

  const goHistoryBack = useCallback(() => window.history.back(), []);

  /* Рендер */

  return (
    <View id={viewId} activePanel={activePanel} history={history} onSwipeBack={goHistoryBack}>
      <CatalogPanel
        id={RootRoute.MAIN_CATALOG_MAIN}
        route={RootRoute.MAIN_CATALOG_MAIN}
        panelStart={panelStart}
        openCategories={() => router.navigate(RootRoute.MAIN_CATALOG_CATEGORIES, { from: RootRoute.MAIN_CATALOG_MAIN })}
        onBack={onBack}
        onOrganizationUnitClick={(organizationUnitId) => {
          statEvents.send(StatEvent.Open, { organizationUnitId });
          router.navigate(RootRoute.ORGANIZATION_UNIT, {
            organizationUnitId,
            from: RootRoute.MAIN_CATALOG_MAIN,
          });
        }}
      />
      <CategoriesPanel
        id={RootRoute.MAIN_CATALOG_CATEGORIES}
        onBack={goHistoryBack}
        onCategoryClick={(categoryGroupSlug) => {
          router.navigate(RootRoute.MAIN_CATALOG_CATEGORY, {
            categoryGroupSlug,
            from: RootRoute.MAIN_CATALOG_CATEGORIES,
          });
        }}
      />
      <CategoryCatalogPanel
        id={RootRoute.MAIN_CATALOG_CATEGORY}
        route={RootRoute.MAIN_CATALOG_CATEGORY}
        onBack={goHistoryBack}
        onOrganizationUnitClick={(organizationUnitId) => {
          statEvents.send(StatEvent.Open, { organizationUnitId });
          router.navigate(RootRoute.ORGANIZATION_UNIT, {
            organizationUnitId,
            from: RootRoute.MAIN_CATALOG_CATEGORY,
          });
        }}
      />
    </View>
  );
});

export default CatalogView;
