import React, { useCallback, memo } from 'react';
import { useSelector } from '../../hooks/useSelector';
import { useActions } from '../../hooks/useActions';
import { useStorageValue } from '../../hooks/useStorageValue';
import { userActions } from '../../redux/reducers/user';
import { Subtract } from '../../types/utility';
import { StorageField } from '../../types';
import { getGeodata, GetGeodataResult } from 'src/utils/getGeodata';

export interface WithGeodataComponentProps {
  geodata?: GetGeodataResult;
  geodataSuggestSkipped: boolean;
  skipGeodataSuggest(): void;
  requestGeodata(): Promise<GetGeodataResult>;
}

/**
 * Пробрасывает пропсы для работы с геолокацией и автоматически устанавливет
 * актуальные значения в Redux-store
 */
function withGeodata<T extends WithGeodataComponentProps>(
  Component: React.FC<T>,
): React.FC<Subtract<T, WithGeodataComponentProps>> {
  return memo((props) => {
    const geodata = useSelector((state) => state.user.geodata);
    const setGeodata = useActions(userActions.setUserGeodata);

    const isNativeClient = useSelector((state) => !['desktop_web', 'mobile_web'].includes(state.launchParams.platform));

    const [geodataSuggestSkipped, setGeodataSuggestSkipped] = useStorageValue(StorageField.GeolocationSuggestSkipped);
    const skipGeodataSuggest = useCallback(() => setGeodataSuggestSkipped(true), [setGeodataSuggestSkipped]);

    const requestGeodata = useCallback(() => {
      return getGeodata(isNativeClient).then((geodata) => {
        setGeodata(geodata);
        return geodata;
      });
    }, [setGeodata, isNativeClient]);

    return (
      <Component
        {...(props as T)}
        geodata={geodata}
        geodataSuggestSkipped={Boolean(geodataSuggestSkipped)}
        skipGeodataSuggest={skipGeodataSuggest}
        requestGeodata={requestGeodata}
      />
    );
  });
}

export default withGeodata;
