import React, { memo } from 'react';
import { Panel, Group, Header, Separator, Link, Search, Placeholder, PanelHeader, Button } from '@overrided-vkui';
import { L } from 'src/lang/L';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { OrganizationUnitStepMainPanelProps } from './index';
import UserHorizontalList from '../../atomic/UserHorizontalList';
import ServiceCategorySelection from '../../atomic/ServiceCategorySelection';
import UnitHeadSection from 'src/components/atomic/UnitHeadSection';
import { formatCityAddress } from 'src/utils/formatter';

const OrganizationUnitStepMainPanelMobile = memo((props: OrganizationUnitStepMainPanelProps) => {
  const {
    id: panelId,
    unit,
    leftButton,
    categoryServicesSearchValue,
    setCategoryServicesSearchValue,
    selectCategory,
    staff,
    selectedCategoryId,
    selectedCategoryServices,
    selectedCategoryServicesLoading,
    selectedCategoryServicesError,
    refetchSelectedCategoryServices,
    selectedCategoryServiceId,
    selectCategoryService,
    onNext,
    openStaffStep,
    onStaffClick,
    onMoreInfo,
  } = props;

  const hasStaff = staff.length > 0;
  const hasCategories = unit.serviceCategories.length > 0;
  const isSearchMode = Boolean(categoryServicesSearchValue.trim());

  return (
    <Panel id={panelId}>
      <PanelHeader left={leftButton} separator={false} hiddenOffset={75} appearanceDistance={25}>
        {unit.title}
      </PanelHeader>
      <UnitHeadSection
        logoUrl={unit.logoUrl || void 0}
        title={unit.title}
        address={formatCityAddress(unit.city.title, unit.address)}
        onMoreInfo={onMoreInfo}
      />
      <Search
        value={categoryServicesSearchValue}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        maxLength={255}
        onChange={(e) => setCategoryServicesSearchValue(e.target.value)}
      />
      {hasStaff && (
        <>
          <Group
            header={
              <Header
                aside={
                  !isSearchMode && (
                    <Link Component="span" onClick={openStaffStep}>
                      {L.t('make_record_step_services_section_staff_show_more')}
                    </Link>
                  )
                }
              >
                {L.t('make_record_step_services_section_staff')}
              </Header>
            }
          >
            <UserHorizontalList users={staff} onUserClick={onStaffClick} />
          </Group>
          <Separator style={{ marginTop: 12 }} />
        </>
      )}
      {hasCategories ? (
        <ServiceCategorySelection
          refetchSelectedCategoryServices={refetchSelectedCategoryServices}
          selectCategory={selectCategory}
          selectedCategoryServicesLoading={selectedCategoryServicesLoading}
          selectedCategoryServicesError={selectedCategoryServicesError}
          selectCategoryService={selectCategoryService}
          selectedCategoryServiceId={selectedCategoryServiceId}
          selectedCategoryServices={selectedCategoryServices}
          selectedServiceCategoryId={selectedCategoryId}
          serviceCategories={unit.serviceCategories}
        />
      ) : (
        <Panel.Content center>
          <Placeholder>{L.t('make_record_step_services_empty')}</Placeholder>
        </Panel.Content>
      )}
      {hasCategories && (
        <Panel.Footer fixed>
          <FooterButtons>
            <Button disabled={!selectedCategoryServiceId} onClick={onNext}>
              {L.t('make_record_step_slots_button_select')}
            </Button>
          </FooterButtons>
        </Panel.Footer>
      )}
    </Panel>
  );
});

export default OrganizationUnitStepMainPanelMobile;
