import styled from '@material-ui/styles/styled';
import { FormLayout } from '@vkontakte/vkui';
import { FormLayoutProps } from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';

export default styled(FormLayout)({
  '& .FormLayout__row-top': {
    color: '#000',
    fontWeight: 500,
  },
}) as React.FC<FormLayoutProps>;
