import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useGetDateSlotsQuery,
  useGetAvailableDatesQuery,
  useGetSlotServiceStaffLazyQuery,
  GetAvailableDatesDocument,
  GetAvailableDatesQuery,
  GetAvailableDatesQueryVariables,
} from 'src/gql/generated/types';
import SelectDateSlotPanel from 'src/components/panelComponents/SelectDateSlotPanel';
import dayjs from 'dayjs';
import useQueryState from 'src/hooks/useQueryState';
import { RootRoute } from 'src/router';
import withPhoneRequest, { WithPhoneRequestInjectedProps, PhoneData } from 'src/components/hocs/withPhoneRequest';
import { useApolloClient } from '@apollo/client';

interface ServiceStaff {
  id: number;
  staff: {
    id: number;
    name: string;
    avatarUrl?: string | null;
    specialization: string;
  };
  priceMin: number;
  priceMax: number;
  duration: number;
}

interface OrganizationUnitStepServiceStaffSlotsProps extends WithPhoneRequestInjectedProps {
  id: string;
  routeName: RootRoute;
  serviceId: number;
  serviceStaffId?: number;
  fetchSlotServiceStaff?: boolean;
  onNext(slot: string, slotServiceStaff: ServiceStaff[], phone?: PhoneData): void;
  onNextLoading?: boolean;
  onBack(): void;
}

const DATE_PICKER_DAYS_COUNT_MIN = 30;

const OrganizationUnitStepServiceStaffSlots = memo((props: OrganizationUnitStepServiceStaffSlotsProps) => {
  const {
    serviceId,
    serviceStaffId,
    routeName,
    onBack,
    onNext,
    id: panelId,
    fetchSlotServiceStaff,
    phoneRequestConfirmed,
    onNextLoading = false,
    requestPhone,
  } = props;

  const startDate = useMemo(() => dayjs(Date.now()).format('YYYY-MM-DD'), []);

  /* Выбранные день и слот */

  const [selectedDate, setSelectedDate] = useQueryState(routeName, 'selectedDate', true);
  const [selectedSlot, setSelectedSlot] = useQueryState(routeName, 'selectedSlot', true);

  /* Получаем данные о доступных датах */

  const setFirstAvailableDateRef = useRef(false);

  const {
    loading: availableDatesLoading,
    error: availableDatesError,
    data: availableDatesData,
  } = useGetAvailableDatesQuery({
    variables: { serviceId, serviceStaffId },
    onCompleted({ availableDates }) {
      if (!setFirstAvailableDateRef.current && !availableDates.includes(selectedDate)) {
        setFirstAvailableDateRef.current = true;
        setSelectedDate(availableDates[0]);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const availableDates = availableDatesData?.availableDates || void 0;

  /* Получаем данные о доступных слотах в выбранной дате */

  const apolloClient = useApolloClient();

  const {
    loading: selectedDateSlotsLoading,
    error: selectedDateSlotsError,
    data: selectedDateSlotsData,
  } = useGetDateSlotsQuery({
    variables: { serviceId, serviceStaffId, date: selectedDate! },
    skip: !selectedDate,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      // Если в выбранной дате нет слотов, очищаем эту дату из списка доступных
      if (
        data.dateSlots.length === 0 &&
        selectedDate &&
        availableDatesData &&
        availableDatesData.availableDates.includes(selectedDate)
      ) {
        apolloClient.cache.writeQuery<GetAvailableDatesQuery, GetAvailableDatesQueryVariables>({
          query: GetAvailableDatesDocument,
          variables: { serviceId, serviceStaffId },
          data: {
            ...availableDatesData,
            availableDates: availableDatesData.availableDates.filter((dates) => dates !== selectedDate),
          },
        });
      }
    },
  });

  useEffect(() => {
    selectedDateSlotsLoading && setSelectedSlot(void 0);
  }, [selectedDateSlotsLoading]); // eslint-disable-line

  const selectedDateSlots = selectedDateSlotsData?.dateSlots.map((dateSlot) => dateSlot.datetime) || void 0;

  useEffect(() => {
    // Если выбранного слота нет в полученных слотах, то очищаем его
    if (!selectedDateSlots || !selectedDateSlots.includes(selectedSlot)) {
      setSelectedSlot(void 0);
    }
  }, [selectedSlot]); // eslint-disable-line

  /* Обработчик onNext с запросом номера телефона */

  const [prepareLoading, setPrepareLoading] = useState(false);

  const onNextWithPhone = useCallback(
    (selectedSlot: string, slotServiceStaff: ServiceStaff[]) => {
      setPrepareLoading(phoneRequestConfirmed);
      return requestPhone()
        .then((phone) => phone && onNext(selectedSlot, slotServiceStaff, phone))
        .catch(() => onNext(selectedSlot, slotServiceStaff))
        .finally(() => setPrepareLoading(false));
    },
    [onNext, requestPhone, phoneRequestConfirmed],
  );

  /* Получаем данные о доступных сотрудниках в выбранном слоте */

  const [getSlotServiceStaff] = useGetSlotServiceStaffLazyQuery({
    fetchPolicy: 'no-cache',
    onError() {
      selectedSlot && onNextWithPhone(selectedSlot, []);
    },
    onCompleted(data) {
      selectedSlot && onNextWithPhone(selectedSlot, data.slotServiceStaff);
    },
  });

  /* Обработчик клика на кнопку Продолжить */

  const onNextClickHandler = useCallback(() => {
    if (!selectedSlot) return;
    setPrepareLoading(true);

    if (fetchSlotServiceStaff) {
      getSlotServiceStaff({ variables: { datetime: selectedSlot, serviceId } });
    } else {
      onNextWithPhone(selectedSlot, []);
    }
  }, [getSlotServiceStaff, onNextWithPhone, serviceId, selectedSlot, fetchSlotServiceStaff]);

  /* Рендер */

  return (
    <SelectDateSlotPanel
      id={panelId}
      datesCountMin={DATE_PICKER_DAYS_COUNT_MIN}
      startDate={startDate}
      onBack={onBack}
      selectedDate={selectedDate}
      selectDate={setSelectedDate}
      availableDates={availableDates}
      availableDatesLoading={availableDatesLoading}
      availableDatesError={Boolean(availableDatesError)}
      selectedSlot={selectedSlot}
      selectSlot={setSelectedSlot}
      selectedDateSlots={selectedDateSlots}
      selectedDateSlotsLoading={selectedDateSlotsLoading}
      selectedDateSlotsError={Boolean(selectedDateSlotsError)}
      showPhoneBadge={!phoneRequestConfirmed}
      onNext={onNextClickHandler}
      onNextLoading={onNextLoading || prepareLoading}
    />
  );
});

export default withPhoneRequest(OrganizationUnitStepServiceStaffSlots);
