import React, { memo } from 'react';
import { Avatar, Button, Panel, Placeholder } from '@overrided-vkui';
import FooterButtons from 'src/components/atomic/FooterButtons';
import { AddToCommunityResultPanelProps } from '.';
import { openApplication } from 'src/utils/openApplication';
import { makeStyles } from '@material-ui/styles';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  panel: {
    '& .Panel__in': {
      padding: 12,
      boxSizing: 'border-box',
    },
  },
});

const AddToCommunityResultPanelMobile = memo((props: AddToCommunityResultPanelProps) => {
  const { groupId, appId, photoUrl, onClose, id: panelId } = props;

  const mc = useStyles();

  return (
    <Panel id={panelId} elastic={false} className={mc.panel}>
      <Panel.Header separator={false} transparent />
      <Panel.Content center>
        <Placeholder
          icon={photoUrl && <Avatar src={photoUrl} alt="" mode="app" size={80} />}
          header={<L.Jsx t="unit_add_to_group_result_header" />}
        >
          {L.t('unit_add_to_group_result_info')}
        </Placeholder>
      </Panel.Content>
      <Panel.Footer>
        <FooterButtons>
          <Button onClick={() => openApplication(appId, { groupId, mVk: true })}>
            {L.t('unit_add_to_group_result_button_ycl_login')}
          </Button>
          <Button onClick={onClose} mode="secondary">
            {L.t('unit_add_to_group_result_button_skip')}
          </Button>
        </FooterButtons>
      </Panel.Footer>
    </Panel>
  );
});

export default AddToCommunityResultPanelMobile;
