import styled from '@material-ui/styles/styled';
import { Header } from '@vkontakte/vkui';
import { HeaderProps } from '@vkontakte/vkui/dist/components/Header/Header';

export default styled(Header)({
  padding: '0 24px',
  paddingTop: 5,
  boxSizing: 'border-box',
  '--text_secondary': 'var(--steel_gray_400)',
  '&.Header': {
    height: 54,
  },
  '& .Header__in': {
    height: '100%',
    alignItems: 'center',
  },
  '& .Header__content': {
    padding: '0!important',
  },
  '& .Header__aside': {
    fontSize: 14,
    lineHeight: '18px',
  },
  '&.Header--mode-primary': {
    '& .Header__content': {
      color: 'var(--black)',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  '&.Header--mode-secondary': {
    height: 50,
    '& .Header__content': {
      fontWeight: 400,
      color: 'var(--black)',
      fontSize: 13,
      lineHeight: '16px',
      textTransform: 'initial',
    },
    '& .Header__subtitle': {
      textTransform: 'initial',
      color: 'var(--black)',
    },
  },
}) as React.FC<HeaderProps>;
