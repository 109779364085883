import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import Icon28PhoneOutline from '@vkontakte/icons/dist/28/phone_outline';
import { Subhead, isDesktop } from '@overrided-vkui';
import { L } from 'src/lang/L';

const useStyles = makeStyles<any, { isDesktop: boolean }>({
  phoneBadge: {
    display: 'flex',
    alignItems: 'center',
    color: 'var(--text_subhead)',
    '& .Icon': {
      marginRight: ({ isDesktop }) => (isDesktop ? 15 : 10),
    },
  },
});

const PhoneBadge: React.FC = memo(() => {
  const mc = useStyles({ isDesktop });

  return (
    <div className={mc.phoneBadge}>
      <Icon28PhoneOutline fill={isDesktop ? 'var(--content_placeholder_icon)' : 'var(--accent)'} />
      <Subhead weight="regular">{L.t('badge_phone')}</Subhead>
    </div>
  );
});

export default PhoneBadge;
