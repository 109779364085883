import UnitHeadSectionDesktop from './UnitHeadSectionDesktop';
import UnitHeadSectionMobile from './UnitHeadSectionMobile';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';

export interface UnitHeadSectionProps {
  logoUrl?: string;
  title: string;
  address: string;
  onMoreInfo(): void;
}

export default getPlatformComponent<UnitHeadSectionProps>({
  mobile: UnitHeadSectionMobile,
  desktop: UnitHeadSectionDesktop,
});
