import React, { memo, useCallback, useMemo } from 'react';
import { useGetServiceStaffQuery } from 'src/gql/generated/types';
import SelectStaffPanel from '../panelComponents/SelectStaffPanel';
import { L } from 'src/lang/L';

export interface ServiceStaff {
  id: number;
  staff: {
    id: number;
    name: string;
    avatarUrl?: string | null;
    specialization: string;
  };
  priceMin: number;
  priceMax: number;
  duration: number;
}

interface OrganizationUnitStepServiceStaffProps {
  id: string;
  serviceId: number;
  onNext(serviceStaff?: ServiceStaff): void;
  onBack(): void;
}

const OrganizationUnitStepServiceStaff = memo((props: OrganizationUnitStepServiceStaffProps) => {
  const { serviceId, onBack, onNext, id: panelId } = props;

  const { data: staffData, loading: staffLoading, error: staffError } = useGetServiceStaffQuery({
    variables: { serviceId },
    fetchPolicy: 'cache-and-network',
  });

  const serviceStaff = staffData?.service.serviceStaff || void 0;

  const onStaffSelectHandler = useCallback(
    (staffId: number) => {
      onNext(serviceStaff?.find((serviceStaffItem) => serviceStaffItem.staff.id === staffId));
    },
    [serviceStaff, onNext],
  );

  const staff = useMemo(() => {
    return staffData?.service.serviceStaff.map((serviceStaffItem) => serviceStaffItem.staff) || void 0;
  }, [staffData]);

  return (
    <SelectStaffPanel
      id={panelId}
      onBack={onBack}
      title={L.t('make_record_step_staff_header')}
      onStaffSelect={onStaffSelectHandler}
      onSkipStaffSelect={onNext}
      staff={staff}
      staffLoading={staffLoading}
      staffError={Boolean(staffError)}
    />
  );
});

export default OrganizationUnitStepServiceStaff;
