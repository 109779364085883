import PhoneRequestModalCardMobile from './PhoneRequestModalCardMobile';
import PhoneRequestModalCardDesktop from './PhoneRequestModalCardDesktop';
import { getPlatformComponent, withModalPageOverlayWrapper } from '@overrided-vkui';

export interface PhoneRequestModalCardProps {
  onConfirm(): void;
  onReject(): void;
  onClose(): void;
  className?: string;
}

export default withModalPageOverlayWrapper(
  getPlatformComponent<PhoneRequestModalCardProps>({
    mobile: PhoneRequestModalCardMobile,
    desktop: PhoneRequestModalCardDesktop,
  }),
);
