import React, { memo } from 'react';
import { YClientsOrganizationSelectPanelProps } from './index';
import { Panel, PanelHeader, PanelHeaderButton, Footer, SimpleCell, Avatar, Placeholder } from '@overrided-vkui';
import { L } from 'src/lang/L';
import EmptyOrganizationsPlaceholder from './EmptyOrganizationsPlaceholder';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  descriptionPlaceholder: {
    '& .Placeholder__in': {
      paddingTop: 20,
    },
    '& .Placeholder__header': {
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 600,
    },
  },
});

const YClientsOrganizationSelectPanelMobile = memo((props: YClientsOrganizationSelectPanelProps) => {
  const { yClientsOrganizations, onBack, onNext, id: panelId } = props;

  const mc = useStyles();

  const headerBack = <PanelHeaderButton onClick={onBack}>{L.t('attach_ycl_logout')}</PanelHeaderButton>;

  return (
    <Panel id={panelId}>
      {yClientsOrganizations.length === 0 ? (
        <>
          <PanelHeader left={headerBack} separator={false} transparent />
          <Panel.Content center>
            <EmptyOrganizationsPlaceholder />
          </Panel.Content>
        </>
      ) : (
        <>
          <PanelHeader left={headerBack} separator={false} hiddenOffset={50} appearanceDistance={15}>
            {L.t('attach_ycl_organization_title')}
          </PanelHeader>
          <Panel.Content>
            <Placeholder className={mc.descriptionPlaceholder} header={L.t('attach_ycl_organization_title')}>
              {L.t('attach_ycl_organization_info')}
            </Placeholder>
            {yClientsOrganizations.map((organization) => (
              <SimpleCell
                key={organization.id}
                description={L.t('organization_units_amount', { count: organization.yClientsOrganizationUnits.length })}
                expandable
                onClick={() => onNext(organization)}
                before={<Avatar src={organization.yClientsOrganizationUnits[0].logoUrl} size={72} mode="image" />}
              >
                {organization.title}
              </SimpleCell>
            ))}
            <Footer>{L.t('organizations_amount', { count: yClientsOrganizations.length })}</Footer>
          </Panel.Content>
        </>
      )}
    </Panel>
  );
});

export default YClientsOrganizationSelectPanelMobile;
