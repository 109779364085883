import React, { memo, useState } from 'react';
import { StaffSelectModalProps } from '.';
import { List } from '@vkontakte/vkui';
import { L } from 'src/lang/L';
import {
  PanelFooter,
  Header,
  Avatar,
  Button,
  SimpleCell,
  Group,
  ModalPage,
  withModalPageOverlayWrapper,
  Radio,
  ModalPageHeader,
} from '@overrided-vkui';
import FooterButtons from '../../atomic/FooterButtons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  radio: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .SimpleCell': {
      padding: 0,
    },
  },
  footer: {
    borderRadius: '0 0 8px 8px',
    overflow: 'hidden',
    marginTop: 12,
  },
});

const StaffSelectModalDesktop = memo((props: StaffSelectModalProps) => {
  const { onClose, activeStaffId, staffItems, datetime } = props;

  const [selectedStaffId, setSelectedStaffId] = useState(activeStaffId);

  const mc = useStyles();

  return (
    <ModalPage
      id="STAFF_SELECT"
      header={<ModalPageHeader>{L.t('make_record_step_staff_header')}</ModalPageHeader>}
      onClose={() => onClose(activeStaffId)}
    >
      {staffItems && (
        <Group
          header={
            <Header mode="secondary">
              {L.t('make_record_step_staff_available_label', { datetime: L.format.dateTime(datetime) })}
            </Header>
          }
        >
          <List>
            {staffItems.map((staff) => (
              <Radio
                key={staff.id}
                className={mc.radio}
                checked={staff.id === selectedStaffId}
                onClick={() => setSelectedStaffId(staff.id)}
                onChange={() => null}
              >
                <SimpleCell
                  disabled
                  before={<Avatar src={staff.avatarUrl || void 0} size={48} />}
                  description={staff.specialization}
                >
                  {staff.name}
                </SimpleCell>
              </Radio>
            ))}
          </List>
        </Group>
      )}
      <PanelFooter className={mc.footer}>
        <FooterButtons>
          <Button onClick={() => onClose(selectedStaffId)}>{L.t('common:save')}</Button>
        </FooterButtons>
      </PanelFooter>
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(StaffSelectModalDesktop);
