import React, { memo, useEffect } from 'react';
import Snackbar, { SnackbarProps } from '@overrided-vkui/Snackbar';
import { ReactComponent as Icon24CheckCircle } from '../../../../assets/check-circle-24.svg';
import { tapticNotification } from 'src/utils/taptic';

interface SuccessSnackbarProps extends SnackbarProps {
  text: string;
}

const SuccessSnackbar: React.FC<SuccessSnackbarProps> = memo(({ text, ...snackbarProps }) => {
  useEffect(() => {
    tapticNotification('success');
  }, []);

  return (
    <Snackbar {...snackbarProps} before={<Icon24CheckCircle style={{ display: 'block', color: 'var(--accent)' }} />}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </Snackbar>
  );
});

export default SuccessSnackbar;
