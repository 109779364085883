import { TabsItem } from '@vkontakte/vkui';
import { TabsItemProps } from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import styled from '@material-ui/styles/styled';

export default styled(TabsItem)({
  maxWidth: '70vw!important',
  '& .TabsItem__in': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}) as React.FC<TabsItemProps>;
