import React, { memo, useCallback, useState } from 'react';
import { View } from '@vkontakte/vkui';
import AddToCommunityPanel, { GroupInfo } from '../panels/AddToCommunityPanel';
import AddToCommunityResultPanel from '../panelComponents/AddToCommunityResultPanel';
import { useRouteNode } from 'react-router5';
import { RootRoute } from '../../router';
import { useSelector } from 'src/hooks/useSelector';

interface AddToCommunityViewProps {
  id: string;
}

const AddToCommunityView = memo((props: AddToCommunityViewProps) => {
  const { id: viewId } = props;

  const appId = useSelector((state) => state.launchParams.appId);

  const { route, router } = useRouteNode(RootRoute.ADD_TO_COMMUNITY);
  const activePanel = route.name.split('.').slice(0, 2).join('.') as RootRoute;

  const [groupInfo, setGroupInfo] = useState<GroupInfo>({ id: 0 });

  const onNext = useCallback(
    (groupInfo: GroupInfo) => {
      setGroupInfo(groupInfo);
      router.navigate(RootRoute.ADD_TO_COMMUNITY_RESULT, {}, { replace: true });
    },
    [router],
  );

  const goHistoryBack = useCallback(() => window.history.back(), []);

  return (
    <View id={viewId} activePanel={activePanel}>
      <AddToCommunityPanel id={RootRoute.ADD_TO_COMMUNITY_ONBOARD} onClose={goHistoryBack} onNext={onNext} />
      <AddToCommunityResultPanel
        id={RootRoute.ADD_TO_COMMUNITY_RESULT}
        groupId={groupInfo.id}
        appId={appId}
        photoUrl={groupInfo.photoUrl}
        onClose={goHistoryBack}
      />
    </View>
  );
});

export default AddToCommunityView;
