import React, { memo, useEffect, useRef } from 'react';
import { Panel, ModalPageHeader, SimpleCell, Search } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';
import { PanelHeaderButton, List, PanelHeaderContent, Footer } from '@vkontakte/vkui';
import OrganizationUnitsMap from 'src/components/atomic/OrganizationUnitsMap';
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown';
import { OrganizationPanelProps } from './index';
import { formatCityAddress } from 'src/utils/formatter';
import { L } from 'src/lang/L';

const useStyles = makeStyles({
  content: {},
  map: {
    position: 'fixed',
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
    '& .leaflet-bottom.leaflet-right': {
      bottom: 'calc(var(--panelheader_height_ios) + 85px)',
    },
  },
  fixedBackground: {
    position: 'fixed',
    background: 'var(--background_content)',
    top: '100%',
    left: 0,
    right: 0,
    bottom: -1,
    zIndex: 1,
    transformOrigin: 'bottom',
  },
  modalOffset: {
    display: 'block',
    paddingTop: 'calc(100vh - var(--panelheader_height_ios) - 85px)',
  },
  modal: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: '14px 14px 0 0',
    background: 'var(--background_content)',
    display: 'block',
    zIndex: 6,
  },
  modal__header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    borderRadius: '14px 14px 0 0',
    background: 'var(--background_content)',
    '& .PanelHeaderContent__in': {
      alignItems: 'center',
    },
    '& .ModalPageHeader__right, & .ModalPageHeader__left': {
      flexGrow: 1,
      flexBasis: 0,
    },
    '& .ModalPageHeader__content': {
      position: 'relative',
      zIndex: 1,
      flexGrow: 'initial',
    },
  },
  modal__headderContent: {
    '& .PanelHeaderContent__in': {
      flexDirection: 'column-reverse',
    },
  },
  modal__content: {
    zIndex: 9,
    position: 'relative',
    background: 'var(--background_content)',
    boxShadow: '0 var(--safe-area-inset-bottom) 0 var(--safe-area-inset-bottom) var(--background_content)',
    overflow: 'hidden',
    minHeight: 400,
  },
});

let firstVisit = true;

const OrganizationPanelMobile = memo((props: OrganizationPanelProps) => {
  const { id: panelId, openCatalog, openUnit, cityTitle, searchValue, setSearchValue, units, onCityTitleClick } = props;

  /* Поднимаем модалку на половину экрана */

  useEffect(() => {
    if (firstVisit) {
      window.scrollTo({ top: window.innerHeight * 0.5, behavior: 'smooth' });
      firstVisit = false;
    } else {
      // ждем, когда завершится анимация открытия панели
      setTimeout(() => {
        if (window.scrollY === 0) {
          window.scrollTo({ top: window.innerHeight * 0.5, behavior: 'smooth' });
        }
      }, 600);
    }
  }, []);

  /**
   * Фиксированная подложка, идущая вслед за скроллом.
   * Закрывает карту на заднем плане при резиновом скролле на iOS
   */

  const fixedBackgroundRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fixedBackgroundEl = fixedBackgroundRef.current;
    if (!fixedBackgroundEl) return;

    const listener = () => {
      const scrollY = window.scrollY;
      const scrollHeight = document.body.scrollHeight;
      const offsetHeight = window.innerHeight;

      fixedBackgroundEl.style.transform = `scaleY(${offsetHeight + scrollY - scrollHeight + 60})`;
    };

    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [fixedBackgroundRef]);

  /* Рендер */

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      <div className={mc.content}>
        <div className={mc.modalOffset} />
        <OrganizationUnitsMap
          className={mc.map}
          onScroll={(e) => e.preventDefault()}
          cityId={units[0]?.city.id || -1}
          onUnitClick={openUnit}
          units={units}
        />
        <div className={mc.fixedBackground} ref={fixedBackgroundRef} />
        <div className={mc.modal}>
          <div className={mc.modal__header}>
            <ModalPageHeader
              noShadow
              left={
                <PanelHeaderButton onClick={openCatalog}>{L.t('organization_map_panel_nav_catalog')}</PanelHeaderButton>
              }
            >
              <PanelHeaderContent
                onClick={onCityTitleClick}
                className={mc.modal__headderContent}
                aside={onCityTitleClick && <Icon16Dropdown />}
                status={L.t('organization_units')}
                before={null}
              >
                {cityTitle}
              </PanelHeaderContent>
            </ModalPageHeader>
            <Search
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={L.t('common:search_placeholder')}
              after={L.t('common:cancel')}
              maxLength={255}
            />
          </div>
          <div className={mc.modal__content}>
            <List>
              {units.map((unit) => (
                <SimpleCell
                  key={unit.id}
                  onClick={() => openUnit(unit.id)}
                  description={formatCityAddress(unit.city.title, unit.address)}
                  expandable
                >
                  {unit.title}
                </SimpleCell>
              ))}
            </List>
            <Footer>{L.t('organization_units_amount', { count: units.length })}</Footer>
          </div>
        </div>
      </div>
    </Panel>
  );
});

export default OrganizationPanelMobile;
