import React, { memo } from 'react';
import { Spinner } from '@vkontakte/vkui';
import { Button, Placeholder } from '@overrided-vkui';
import { L } from 'src/lang/L';

interface StatusPlaceholderProps {
  status: 'idle' | 'loading' | 'done' | 'error';
  errorText?: string;
  onRetry?(): void;
}

const StatusPlaceholder: React.FC<StatusPlaceholderProps> = memo(({ status, errorText, onRetry }) => (
  <Placeholder
    action={
      status === 'error' &&
      onRetry && (
        <Button size="m" onClick={onRetry}>
          {L.t('common:error_try_again_btn')}
        </Button>
      )
    }
  >
    {status === 'loading' && <Spinner size="regular" />}
    {status === 'error' && (errorText || L.t('common:error_unknown'))}
  </Placeholder>
));

export default StatusPlaceholder;
