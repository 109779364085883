import React, { memo, useCallback, useMemo } from 'react';
import { List, Counter } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import {
  Panel,
  Footer,
  OverlayPopoutWrapper,
  ScreenSpinner,
  Search,
  Div,
  Button,
  Separator,
  isDesktop,
  getPlatformClassName,
} from '@overrided-vkui';
import FooterButtons from '../atomic/FooterButtons';
import Multichecker from '../atomic/Multichecker';
import YClientsOrganizationUnitCheckbox from '../atomic/YClientsOrganizationUnitCheckbox';
import { YClientsOrganizationUnit } from 'src/types/yclients';
import StatusPlaceholder from '../atomic/StatusPlaceholder';
import { L } from 'src/lang/L';
import { tapticSelectionChanged } from 'src/utils/taptic';

const useStyles = makeStyles({
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .Checkbox__icon': {
      alignSelf: 'center',
      marginRight: 2,
    },
  },
  description: {
    color: 'var(--text_secondary)',
    fontSize: 14,
    lineHeight: '18px',
    paddingTop: 12,
    paddingBottom: 20,
    '&--desktop': {
      color: 'var(--steel_gray_400)',
      padding: '48px 32px',
      fontSize: 15,
      textAlign: 'center',
      lineHeight: '18px',
    },
  },
  multiChecker: {
    marginTop: 8,
  },
  list: {
    marginTop: 8,
  },
});

interface YClientsUnitsSelectPanelProps {
  id: string;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  headerTitleText: string;
  nextButtonText: string;
  withNextButtonCounter?: boolean;
  onNext(): void;
  onNextLoading: boolean;
  onNextDisabled?: boolean;
  units?: YClientsOrganizationUnit[];
  unitsLoading?: boolean;
  unitsError?: boolean;
  selectedUnitIds: number[];
  setSelectedUnitIds(unitIds: number[]): void;
  searchValue: string;
  setSearchValue(searchValue: string): void;
}

const YClientsUnitsSelectPanel = memo((props: YClientsUnitsSelectPanelProps) => {
  const {
    id: panelId,
    headerLeft,
    headerRight,
    headerTitleText,
    nextButtonText,
    onNext,
    units,
    unitsError,
    unitsLoading,
    onNextLoading,
    onNextDisabled,
    selectedUnitIds,
    setSelectedUnitIds,
    withNextButtonCounter,
    searchValue,
    setSearchValue,
  } = props;

  const setUnitSelection = useCallback(
    (unitId: number, checked: boolean) => {
      tapticSelectionChanged();
      setSelectedUnitIds(
        checked
          ? selectedUnitIds.concat(unitId)
          : selectedUnitIds.filter((selectedUnitId) => selectedUnitId !== unitId),
      );
    },
    [setSelectedUnitIds, selectedUnitIds],
  );

  const isAllUnitsSelected = selectedUnitIds.length === units?.length;

  const clearSelection = useCallback(() => {
    tapticSelectionChanged();
    setSelectedUnitIds([]);
  }, [setSelectedUnitIds]);

  const selectAllUnits = useCallback(() => {
    tapticSelectionChanged();
    setSelectedUnitIds(units?.map((unit) => unit.id) || []);
  }, [setSelectedUnitIds, units]);

  const mc = useStyles();

  const isSearchMode = useMemo(() => Boolean(searchValue.trim()), [searchValue]);

  return (
    <Panel id={panelId}>
      <Panel.Header left={headerLeft} right={headerRight} separator={isDesktop}>
        {headerTitleText}
      </Panel.Header>
      <Search
        defaultValue={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        maxLength={255}
      />
      {!isSearchMode && (
        <>
          <Div className={getPlatformClassName(mc.description)}>{L.t('attach_ycl_organization_units_info')}</Div>
          <Separator />
        </>
      )}
      {units ? (
        <Panel.Content>
          {units.length > 1 && !isSearchMode && (
            <Multichecker
              className={mc.multiChecker}
              onClear={clearSelection}
              onFill={selectAllUnits}
              isFilled={isAllUnitsSelected}
            />
          )}
          <List className={mc.list}>
            {units.map((unit) => {
              const checked = selectedUnitIds.includes(unit.id);
              return (
                <YClientsOrganizationUnitCheckbox
                  key={unit.id}
                  {...unit}
                  city={unit.city.title}
                  checked={checked}
                  onClick={() => setUnitSelection(unit.id, !checked)}
                />
              );
            })}
          </List>
          <Footer>{L.t('organization_units_amount', { count: units.length })}</Footer>
        </Panel.Content>
      ) : unitsLoading ? (
        <Panel.Content center>
          <StatusPlaceholder status="loading" />
        </Panel.Content>
      ) : unitsError ? (
        <Panel.Content center>
          <StatusPlaceholder status="error" errorText={L.t('attach_ycl_organization_units_fetch_error')} />
        </Panel.Content>
      ) : null}
      <Panel.Footer fixed>
        <FooterButtons>
          <Button
            onClick={onNext}
            disabled={selectedUnitIds.length === 0 || onNextDisabled || onNextLoading}
            after={
              withNextButtonCounter &&
              selectedUnitIds.length > 0 &&
              !isDesktop && <Counter>{selectedUnitIds.length}</Counter>
            }
          >
            {nextButtonText}
          </Button>
        </FooterButtons>
      </Panel.Footer>
      <OverlayPopoutWrapper show={onNextLoading}>
        <ScreenSpinner />
      </OverlayPopoutWrapper>
    </Panel>
  );
});

export default YClientsUnitsSelectPanel;
