import { useCallback } from 'react';
import { useActions } from './useActions';
import { storageActions, StorageReducerState } from '../redux/reducers/storage';
import { dropStorage } from '../utils/storage';
import { useSelector } from './useSelector';

type DropStorage = () => Promise<void>;

/**
 * Позволяет работать с мемоизированными значениями bridge storage
 * @returns {[StorageReducerState, DropStorage]}
 */
export function useStorage(): [StorageReducerState, DropStorage] {
  const dropAllValues = useActions(storageActions.dropAllValues);
  const storage = useSelector((state) => state.storage);
  const drop = useCallback<DropStorage>(() => {
    return dropStorage()
      .then(() => {
        dropAllValues();
      })
      .catch(() => void 0);
  }, [dropAllValues]);

  return [storage, drop];
}
