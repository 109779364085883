import React, { memo } from 'react';
import { getPlatformClassName, Header, Title, Group } from '@overrided-vkui';
import { List } from '@vkontakte/vkui';
import RecordItem, { RecordListItem } from '../RecodItem';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  title: {
    '&--desktop': {
      fontWeight: '400!important',
    },
  },
});

interface RecordListProps {
  title: string;
  records: RecordListItem[];
  onRecordClick(recordId: number): void;
  previous?: boolean;
}

const RecordList = memo((props: RecordListProps) => {
  const { title, records, previous, onRecordClick } = props;

  const mc = useStyles();

  return (
    <Group
      header={
        <Header>
          <Title level="3" weight="semibold" className={getPlatformClassName(mc.title)}>
            {title}
          </Title>
        </Header>
      }
    >
      <List>
        {records.map((record) => (
          <RecordItem key={record.id} record={record} onClick={() => onRecordClick(record.id)} previous={previous} />
        ))}
      </List>
    </Group>
  );
});

export default RecordList;
