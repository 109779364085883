import React, { memo, useCallback, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { View } from '@vkontakte/vkui';
import { copyToClipboard } from '../../utils/copying';
import Icon56AddCircleOutline from '@vkontakte/icons/dist/56/add_circle_outline';
import { ModalPage, ModalRoot, Root, Div, Button, ModalPageHeader } from '@overrided-vkui';
import CenteredLayout from '../atomic/CenteredLayout';
import { L } from 'src/lang/L';

interface IProps {
  onRestart(): void;
  error: string;
}

const useStyles = makeStyles({
  crashIcon: {
    transform: 'rotate(45deg)',
    color: 'var(--activity_indicator_tint)',
  },
  button: {
    '& + &': {
      marginTop: 10,
    },
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  header: {
    fontSize: 21,
    lineHeight: '26px',
  },
  dismissIcon: {
    color: 'var(--overlay_status_icon)',
  },
});

enum AppCrashModal {
  BUTTONS = 'buttons',
  INFO = 'info',
}

/**
 * Вью которая отображается в случае, когда в приложении произошла ошибка.
 * @type {React.NamedExoticComponent<IProps>}
 */
const AppCrashedView = memo((props: IProps) => {
  const { onRestart, error } = props;
  const mc = useStyles(props);

  const [activeModal, setActiveModal] = useState<AppCrashModal>(AppCrashModal.BUTTONS);
  const [copying, setCopying] = useState(false);

  const onCopyClick = useCallback(() => {
    setCopying(true);
    copyToClipboard(error).finally(() => setCopying(false));
  }, [error]);

  const modal = (
    <ModalRoot activeModal={activeModal}>
      <ModalPage
        id={AppCrashModal.BUTTONS}
        header={<ModalPageHeader>{L.t(['crash_view_title', 'Произошла ошибка'])}</ModalPageHeader>}
      >
        <Div>
          <Button size="xl" onClick={onRestart} className={mc.button}>
            {L.t(['restart_app', 'Перезапустить мини-приложение'])}
          </Button>
          <Button size="xl" mode="secondary" onClick={() => setActiveModal(AppCrashModal.INFO)} className={mc.button}>
            {L.t(['crash_view_button_more', 'Подробнее об ошибке'])}
          </Button>
        </Div>
      </ModalPage>
      <ModalPage
        id={AppCrashModal.INFO}
        onClose={() => setActiveModal(AppCrashModal.BUTTONS)}
        header={<ModalPageHeader>{L.t(['common:error', 'Ошибка'])}</ModalPageHeader>}
      >
        <Div className={mc.error}>{error}</Div>
        <Div>
          <Button size="xl" onClick={onCopyClick} disabled={copying}>
            {L.t(['copy_to_clipboard', 'Скопировать в буфер обмена'])}
          </Button>
        </Div>
      </ModalPage>
    </ModalRoot>
  );

  return (
    <Root activeView="main" modal={modal}>
      <View id="main">
        <CenteredLayout id="error">
          <Icon56AddCircleOutline className={mc.crashIcon} />
        </CenteredLayout>
      </View>
    </Root>
  );
});

export default AppCrashedView;
