import React, { memo } from 'react';
import { Avatar, SimpleCell, Group, Checkbox } from '@overrided-vkui';
import { formatCityAddress } from 'src/utils/formatter';
import { makeStyles } from '@material-ui/styles';
import { YClientsOrganizationUnitCheckboxProps } from '.';

const useStyles = makeStyles({
  group: {
    '& .Group__separator, &>:not(.Header):first-child': {
      marginTop: 0,
    },
  },
  checkbox: {
    '& .SimpleCell': {
      paddingLeft: 0,
      paddingRight: 0,
      '& .Avatar': {
        paddingTop: 2,
        paddingBottom: 2,
      },
    },
    '& .Checkbox__icon': {
      margin: '0 20px 0 2px',
      alignSelf: 'center',
    },
  },
});

const YClientsOrganizationUnitCheckboxDesktop = memo((props: YClientsOrganizationUnitCheckboxProps) => {
  const { title, logoUrl, address, city, checked, onClick } = props;

  const mc = useStyles();

  return (
    <Group className={mc.group}>
      <Checkbox checked={checked} onClick={onClick} onChange={() => null} className={mc.checkbox}>
        <SimpleCell
          disabled
          before={<Avatar size={72} src={logoUrl} mode="image" />}
          description={formatCityAddress(city, address)}
        >
          {title}
        </SimpleCell>
      </Checkbox>
    </Group>
  );
});

export default YClientsOrganizationUnitCheckboxDesktop;
