import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { OrganizationUnit, Staff } from 'src/types/yclients';
import { useGetCategoryServicesQuery } from 'src/gql/generated/types';
import { useRouteNode } from 'react-router5';
import OrganizationUnitStepMainPanel from '../panelComponents/OrganizationUnitStepMainPanel';
import UnitMoreInfoModal from '../panelComponents/UnitMoreInfoModal';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { filterByTitle, filterByName } from 'src/utils/filter';
import { RootRoute } from 'src/router';
import { useSelector } from 'src/hooks/useSelector';
import { getVkGroupScreenNames } from 'src/utils/getVkGroupScreenNames';
import useQueryFlag from 'src/hooks/useQueryFlag';

interface Service {
  id: number;
  title: string;
  description?: string | null;
}

interface OrganizationUnitStepMainProps {
  id: string;
  organizationUnit: OrganizationUnit;
  routeName: RootRoute;
  leftButton: React.ReactNode;
  onNext(service: Service): void;
  openStaffStep(): void;
  onNextWithStaff(staff: Staff): void;
}

const SELECTED_CATEGORY_ID_ROUTE_PARAM = 'serviceCategoryId';
const SELECTED_SERVICE_ID_ROUTE_PARAM = 'serviceId';
const SERVICE_SEARCH_ROUTE_PARAM = 'q';
const MORE_INFO_MODAL = 'moreInfo';

const OrganizationUnitStepMain = memo((props: OrganizationUnitStepMainProps) => {
  const { onNext, leftButton, routeName, organizationUnit, id: panelId, onNextWithStaff, openStaffStep } = props;

  const { route, router } = useRouteNode(routeName);

  const platform = useSelector((state) => state.launchParams.platform);
  const accessToken = useSelector((state) => state.user.accessToken);

  /* Работа с выбранной категорией */

  const selectedCategoryId = Number(route.params[SELECTED_CATEGORY_ID_ROUTE_PARAM]) || void 0;
  const setSelectedCategoryId = useCallback(
    (categoryId?: number) => {
      router.navigate(
        route.name,
        {
          ...route.params,
          [SELECTED_CATEGORY_ID_ROUTE_PARAM]: categoryId,
          [SELECTED_SERVICE_ID_ROUTE_PARAM]: void 0,
        },
        { replace: true },
      );
    },
    [router, route],
  );

  /* Работа с выбранной услугой внутри категории */

  const selectedCategoryServiceId = Number(route.params[SELECTED_SERVICE_ID_ROUTE_PARAM]) || void 0;
  const setSelectedCategoryServiceId = useCallback(
    (categoryServiceId: number) => {
      router.navigate(
        route.name,
        {
          ...route.params,
          [SELECTED_SERVICE_ID_ROUTE_PARAM]:
            selectedCategoryServiceId === categoryServiceId ? void 0 : categoryServiceId,
        },
        { replace: true },
      );
    },
    [router, route, selectedCategoryServiceId],
  );

  /* Работа с поиском внутри категории */

  const [categoryServicesSearchValue, setCategoryServicesSearchValue] = useSearchQuery(
    routeName,
    SERVICE_SEARCH_ROUTE_PARAM,
  );

  /* Устанавливаем первую категорию активной */

  useEffect(() => {
    if (!selectedCategoryId) {
      setSelectedCategoryId(organizationUnit.serviceCategories[0]?.id || void 0);
    }
  }, [organizationUnit, selectedCategoryId]); // eslint-disable-line

  /* Подгрузка данных об услугах внутри категории */

  const {
    data: categoryServicesData,
    loading: categoryServicesLoading,
    error: categoryServicesError,
    refetch: refetchCategoryServices,
  } = useGetCategoryServicesQuery({
    variables: { categoryId: selectedCategoryId! },
    skip: !selectedCategoryId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const categoryServices = categoryServicesData?.categoryServices || void 0;

  /* Фильтруем данные по поисковому запросу */

  const foundCategoryServices = useMemo(() => {
    if (!categoryServices) return;

    return filterByTitle(categoryServices, categoryServicesSearchValue).filter(
      (categoryService) => categoryService.serviceStaff.length > 0,
    );
  }, [categoryServices, categoryServicesSearchValue]);

  const foundStaff = useMemo(() => {
    return filterByName(organizationUnit.staff, categoryServicesSearchValue);
  }, [organizationUnit, categoryServicesSearchValue]);

  /* Обработчик перехода далее */

  const selectedService = useMemo(
    () => foundCategoryServices?.find((categoryService) => categoryService.id === selectedCategoryServiceId),
    [selectedCategoryServiceId, foundCategoryServices],
  );

  const onNextHandler = useCallback(() => {
    if (selectedService) {
      onNext(selectedService);
    }
  }, [onNext, selectedService]);

  /* Обработчик открытыя модалки сподробной информацией */

  const [moreInfoShown, showMoreInfo, hideMoreInfo] = useQueryFlag(routeName, MORE_INFO_MODAL);

  /* Доменные имена привязанных групп */

  const [vkGroupScreenNames, setVkGroupScreenNames] = useState(() =>
    organizationUnit.attachedToVkGroupIds.map((vkGroupId) => `club${vkGroupId}`),
  );

  useEffect(() => {
    getVkGroupScreenNames(organizationUnit.attachedToVkGroupIds, accessToken).then(setVkGroupScreenNames);
  }, [moreInfoShown, organizationUnit, accessToken]);

  /* Рендер */

  return (
    <>
      <OrganizationUnitStepMainPanel
        key="organization-unit"
        id={panelId}
        leftButton={leftButton}
        unit={organizationUnit}
        staff={foundStaff}
        selectedCategoryId={selectedCategoryId}
        selectCategory={setSelectedCategoryId}
        selectedCategoryServiceId={selectedService?.id}
        selectCategoryService={setSelectedCategoryServiceId}
        categoryServicesSearchValue={categoryServicesSearchValue}
        setCategoryServicesSearchValue={setCategoryServicesSearchValue}
        refetchSelectedCategoryServices={() => refetchCategoryServices().catch(() => null)}
        selectedCategoryServicesLoading={categoryServicesLoading}
        selectedCategoryServicesError={Boolean(categoryServicesError)}
        selectedCategoryServices={foundCategoryServices}
        onNext={onNextHandler}
        onStaffClick={onNextWithStaff}
        openStaffStep={openStaffStep}
        onMoreInfo={showMoreInfo}
      />
      <UnitMoreInfoModal
        onClose={hideMoreInfo}
        show={moreInfoShown}
        platform={platform}
        unit={{
          ...organizationUnit,
          logoUrl: organizationUnit.logoUrl || void 0,
          phone: organizationUnit.phone || void 0,
          city: organizationUnit.city.title,
          vkGroupScreenNames,
        }}
      />
    </>
  );
});

export default OrganizationUnitStepMain;
