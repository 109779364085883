import React, { memo } from 'react';
import { Avatar, SimpleCell } from '@overrided-vkui';

export interface Staff {
  id: number;
  name: string;
  avatarUrl?: string | null;
  specialization: string;
}

interface StaffItemProps {
  staff: Staff;
  onStaffClick?(): void;
}

const StaffItem = memo((props: StaffItemProps) => {
  const { staff, onStaffClick } = props;

  return (
    <SimpleCell
      before={<Avatar size={72} src={staff.avatarUrl || undefined} shadow={false} />}
      description={staff.specialization}
      onClick={onStaffClick}
      expandable
      multiline
    >
      {staff.name}
    </SimpleCell>
  );
});

export default StaffItem;
