import MainViewDesktop from './MainViewDesktop';
import MainViewMobile from './MainViewMobile';
import { getPlatformComponent } from '@overrided-vkui/utils/getPlatformComponent';

export interface MainViewProps {
  id: string;
  onCatalogBack?(): void;
}

export default getPlatformComponent<MainViewProps>({ mobile: MainViewMobile, desktop: MainViewDesktop });
