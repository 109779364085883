import { StatEvent, StatEventPayload, statEventTypeMap } from '../types/statEvents';
import { Platform } from '../types/launch-params';
import vkBridge from '@vkontakte/vk-bridge';
export { StatEvent } from '../types/statEvents';

type QueueEvent = {
  [K in StatEvent]: {
    statEvent: K;
    payload: StatEventPayload<K>;
    timestamp: number;
  };
}[StatEvent];

interface StatEventInstance {
  /* Помещает событие в очередь на отправку */
  push<T extends StatEvent>(statEvent: T, payload: StatEventPayload<T>): void;
  /* Отправляет одиночное событие */
  send<T extends StatEvent>(statEvent: T, payload: StatEventPayload<T>): void;
}

interface StatEventData {
  appId: number;
  userId: number;
  accessToken: string;
  vkPlatform: Platform;
  url: string;
}

function sendStatEvents(events: QueueEvent[], data: StatEventData) {
  vkBridge
    .send('VKWebAppCallAPIMethod', {
      method: 'statEvents.addMiniApps',
      params: {
        v: '5.107',
        access_token: data.accessToken,
        events: JSON.stringify(
          events.map(({ statEvent, payload, timestamp }) => ({
            user_id: data.userId,
            mini_app_id: data.appId,
            type: statEventTypeMap[statEvent],
            [statEventTypeMap[statEvent]]: {
              type: 'type_mini_app_custom_event_item',
            },
            url: data.url,
            vk_platform: data.vkPlatform,
            event: statEvent,
            json: JSON.stringify(payload || ''),
            timestamp,
          })),
        ),
      },
    })
    .catch(() => null);
}

const BATCH_INTERVAL_DURATION = 3000;

export const statEvents: StatEventInstance = {
  push: () => null,
  send: () => null,
};

let eventsQueue: QueueEvent[] = [];
let intervalId: NodeJS.Timeout;

export function initStatEventInstance(data: StatEventData): void {
  eventsQueue = [];

  statEvents.push = (statEvent, payload) => {
    eventsQueue.push({ statEvent, payload, timestamp: Date.now() } as QueueEvent);
  };

  statEvents.send = (statEvent, payload) => {
    sendStatEvents([{ statEvent, payload, timestamp: Date.now() } as QueueEvent], data);
  };

  clearInterval(intervalId);

  intervalId = setInterval(() => {
    if (eventsQueue.length > 0) {
      sendStatEvents(eventsQueue, data);
      eventsQueue = [];
    }
  }, BATCH_INTERVAL_DURATION);
}
