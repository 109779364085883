import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button } from '@overrided-vkui';
import { SeanceTimeProps } from '.';
import { L } from 'src/lang/L';
import c from 'classnames';

const useStyles = makeStyles({
  seanceTime: {
    padding: '0!important',
    minWidth: 64,
    cursor: 'pointer',
    '& .Button__content': {
      fontWeight: 'initial',
      lineHeight: '20px',
    },
    '&:active .Button__content': {
      top: 0,
    },
  },
  seanceTime_active: {
    position: 'relative',
    '& .Button__in:before': {
      content: '""',
      borderRadius: 6,
      position: 'absolute',
      left: -2,
      right: -2,
      top: -2,
      bottom: -2,
      boxShadow: '0 0 0 2px var(--blue_400)',
    },
  },
});

const SeanceTimeDesktop: React.FC<SeanceTimeProps> = ({ datetime, active, onClick }) => {
  const mc = useStyles();

  return (
    <Button
      mode="secondary"
      size="l"
      onClick={onClick}
      className={c(mc.seanceTime, { [mc.seanceTime_active]: active })}
    >
      {L.format.time(datetime)}
    </Button>
  );
};

export default SeanceTimeDesktop;
