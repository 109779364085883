import { PanelHeaderProps as VKPanelHeaderProps } from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderDesktop from './PanelHeaderDesktop';
import PanelHeaderMobile from './PanelHeaderMobile';
import { getPlatformComponent } from '../utils/getPlatformComponent';

export interface PanelHeaderProps extends Omit<VKPanelHeaderProps, 'addon'> {
  hiddenOffset?: number;
  appearanceDistance?: number;
  onAppeared?(): void;
  onHidding?(): void;
}

export default getPlatformComponent<PanelHeaderProps>({ mobile: PanelHeaderMobile, desktop: PanelHeaderDesktop });
